import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useMemo } from "react";
import { Text, View } from "react-native";

import { pieChartProps } from "./types";

const CustomPieChart = ({
  chartDimension,
  subTitle,
  title,
  colors,
  pieChartData,
}: pieChartProps) => {
  const chartOptions = useMemo(
    () => ({
      chart: {
        backgroundColor: 'transparent',
        plotBackgroundColor: "transparent",
        plotBorderColor: "transparent",
        plotBorderWidth: 0,
        height: chartDimension.y,
        width: chartDimension.x * 0.65,
        plotShadow: false,
        type: "pie",
        margin: 20
      },
      legend: {
        enabled: false,
      },
      title: {
        text: title ? title : undefined,
        align: "left",
        style: {
          color: colors.text,
        },
        margin: 0,
      },
      subtitle: {
        text: subTitle ? subTitle : undefined,
        align: "left",
        style: {
          color: colors.text,
        },
      },
      tooltip: {
        pointFormat: "<b>{point.y}</b>",
      },
      accessibility: {
        enabled: false,
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          borderRadius: 0,
          allowPointSelect: true,
          cursor: "pointer",
          borderWidth: 0,
          dataLabels: {
            distance: -50,
            enabled: false,
          },
          showInLegend: true,
          startAngle: -90,
          endAngle: 90,
          center: ["50%", "75%"],
          size: "125%",
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          type: "pie",
          innerSize: "50%",
          name: "Payroll",
          data: pieChartData,
        },
      ],
    }),
    [chartDimension, subTitle, title, colors, pieChartData]
  );

  return (
    <View style={{  flexDirection: "row" }}>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />

      <View
        style={{
          position: 'absolute',
          right: 0,
          width: chartDimension.x * 0.3,
bottom:0,
top:0,
          justifyContent: "space-evenly",
          // alignItems: "center",
          // backgroundColor:'blue'
        }}
      >
        {pieChartData.map((item: any, index) => (
          <View
            key={index}
            style={{
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              gap: 5,
            }}
          >
            <View
              style={{
                height: "100%",
                width: 3,
                borderRadius: 100,
                backgroundColor: item.color,
              }}
            />
            <View style={{ flex: 1 }}>
              <Text style={{ color: colors.text, opacity: 0.6, margin: 0 }}>
                {item.name}
              </Text>
              <Text
                style={{ color: colors.text, fontWeight: "bold", margin: 0 }}
                numberOfLines={1}
              >
                $ {item.y}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

export default CustomPieChart;
