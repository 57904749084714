export const CurrencyList = [
    {
        code: 'USD',
        symbol: '$',
        name: 'United States Dollar',
        country: 'United States'
    },
    {
        code: 'EUR',
        symbol: '€',
        name: 'Euro',
        country: 'Eurozone'
    },
    {
        code: 'GBP',
        symbol: '£',
        name: 'British Pound Sterling',
        country: 'United Kingdom'
    },
    {
        code: 'JPY',
        symbol: '¥',
        name: 'Japanese Yen',
        country: 'Japan'
    },
    {
        code: 'AUD',
        symbol: 'A$',
        name: 'Australian Dollar',
        country: 'Australia'
    },
    {
        code: 'CAD',
        symbol: 'CA$',
        name: 'Canadian Dollar',
        country: 'Canada'
    },
    {
        code: 'CHF',
        symbol: 'CHF',
        name: 'Swiss Franc',
        country: 'Switzerland'
    },
    {
        code: 'CNY',
        symbol: 'CN¥',
        name: 'Chinese Yuan',
        country: 'China'
    },
    {
        code: 'SEK',
        symbol: 'kr',
        name: 'Swedish Krona',
        country: 'Sweden'
    },
    {
        code: 'NZD',
        symbol: 'NZ$',
        name: 'New Zealand Dollar',
        country: 'New Zealand'
    },
    {
        code: 'INR',
        symbol: '₹',
        name: 'Indian Rupee',
        country: 'India'
    },
    {
        code: 'BRL',
        symbol: 'R$',
        name: 'Brazilian Real',
        country: 'Brazil'
    },
    {
        code: 'ZAR',
        symbol: 'R',
        name: 'South African Rand',
        country: 'South Africa'
    },
    {
        code: 'RUB',
        symbol: '₽',
        name: 'Russian Ruble',
        country: 'Russia'
    },
    {
        code: 'MXN',
        symbol: 'MX$',
        name: 'Mexican Peso',
        country: 'Mexico'
    },
    {
        code: 'SGD',
        symbol: 'S$',
        name: 'Singapore Dollar',
        country: 'Singapore'
    },
    {
        code: 'HKD',
        symbol: 'HK$',
        name: 'Hong Kong Dollar',
        country: 'Hong Kong'
    },
    {
        code: 'NOK',
        symbol: 'kr',
        name: 'Norwegian Krone',
        country: 'Norway'
    },
    {
        code: 'TRY',
        symbol: '₺',
        name: 'Turkish Lira',
        country: 'Turkey'
    },
    {
        code: 'KRW',
        symbol: '₩',
        name: 'South Korean Won',
        country: 'South Korea'
    }
].map(item => ({
    label: `${item.name} - ${item.code}`,
    value: item.symbol
}))
