import { useNavigation, useTheme } from "@react-navigation/native";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
    ScrollView,
    StyleSheet,
    View,
} from "react-native";
import { Button, Divider, Icon, Text } from "react-native-paper";

import {
    personalEmployeeData,
} from "../../../assets/dummyData/dummyData";
import Input from "../../../component/Common/Input/Input";
import CustomButton from "../../../component/Common/button";
import CustomLineChart from "../../../component/graphs/customLineChart";
import CustomPieChart from "../../../component/graphs/customPieChart";
import PersonalEmployeePageTable from "../../../component/page/personal/personalEmployeePageTable";
import { shadowSet } from "../../../component/util/shadow";
import { useFunctions, useUserContext } from "../../../GlobalContext/useUserContext";
import { Drawer } from "react-native-drawer-layout";
import AdminSidePanel from "../../../component/page/personal/adminSidePanel";
import { employeesProps } from "../../../GlobalContext/types";



type payrollSummaryProps = {
    benefitsPayTotal: number;
    employeePayTotal: number;
    employerPayTotal: number;
    taxesPayTotal: number;
}

type upcomingSalaryAmountProps = {
    employeeCount: number;
    totalAmount: number;
}

type payrollSummaryChartProps = {
    name: string;
    y: number;
    color: string;
}

type upcomingSalaryDateProps = {
    daysUntilPayday: number;
    upcomingSalaryDate: string;
}

type fetchPaymentAccountProps = {
    status: boolean;
    message: string;
    data: {
        bank: string;
        integration: number;
        currency: string;
        domain: string;
        managed_by_integration: number;
        subaccount_code: string;
        business_name: string;
        description: string | null;
        primary_contact_name: string | null;
        primary_contact_email: string | null;
        primary_contact_phone: string | null;
        metadata: string | null;
        percentage_charge: number;
        is_verified: boolean;
        product: string;
        settlement_bank: string;
        account_number: number;
        account_name: number;
        settlement_schedule: string;
        active: boolean;
        migrate: boolean;
        id: number;
        createdAt: string;
        updatedAt: string;
    }
}

const AdminPage = (props: any) => {

    const { user, business } = useUserContext();

    const { colors } = useTheme();
    const navigation: any = useNavigation();

    const [employeesData, setEmployeesData] = useState<employeesProps[]>()
    const [showSidePanel, setShowSidePanel] = useState(false)
    const [selectedData, setSelectedData] = useState<employeesProps>()
    const [lineChartDimension, setLineChartDimension] = useState({ x: 0, y: 0 });

    const [payrollSummaryDimension, setPayrollSummaryDimension] = useState({
        x: 0,
        y: 0,
    });

    const [modifiedData, setModifiedData] = useState<employeesProps[]>();
    const [payrollSummaryData, setPayrollSummaryData] = useState<payrollSummaryChartProps[]>()
    const [upcomingSalaryData, setUpcomingSalaryData] = useState<upcomingSalaryDateProps>()
    const [paymentAccount, setPaymentAccount] = useState<fetchPaymentAccountProps>()
    const [upcomingSalaryAmount, setUpcomingSalaryAmount] = useState<upcomingSalaryAmountProps | null>()


    const pieChartData = [
        { name: 'Payment', y: 100000, color: colors.primary },
        { name: 'Paid', y: 50000, color: 'blue' },
        { name: 'Pending', y: 240500, color: '#333' }
    ]

    const addEmployee = () => {
        navigation.navigate('PersonalAddUser')
    }

    let handleEmployees = async () => {

        let get = useFunctions('fetchEmployees');
        await get({ businessId: business.id, id: user.userId })
            .then(res => {
                setEmployeesData(res.data as employeesProps[])
                setModifiedData(res.data as employeesProps[])
                console.log('employees => ', res.data)
            })
            .catch(err => console.log('fetchEmployees error: ', err.message));
    }

    let handlePayrollSummary = async () => {
        let get = useFunctions('payrollSummary')

        await get({ businessId: business.id })
            .then(({ data }: any) => {
                setPayrollSummaryData([
                    { name: 'Benefits Pay Total', y: data?.benefitsPayTotal, color: "blue" },
                    { name: 'Employee Pay Total', y: data?.employeePayTotal, color: 'green' },
                    { name: 'Employer Pay Total', y: data?.employerPayTotal, color: 'orange' },
                    { name: 'Taxes Pay Total', y: data?.taxesPayTotal, color: 'steelblue' },
                ])
            })
            .catch(err => console.log('error payrollSummary: ', err.message))
    }

    let handleUpcomingSalaryDate = async () => {
        let get = useFunctions('upcomingSalaryDate')
        await get({ businessId: business.id }).
            then(res => setUpcomingSalaryData(res.data as upcomingSalaryDateProps))
            .catch(err => console.log('upcomingSalaryDate error: ', err.message))
    }

    let handleFetchPaymentAccount = async () => {
        let get = useFunctions('fetchPaymentAccount')
        await get({ businessId: business.id }).
            then(res =>
                setPaymentAccount(res.data as fetchPaymentAccountProps)
            )
            .catch(err => console.log('fetchPaymentAccount error: ', err.message))
    }

    let handleUpcomingSalaryAmount = async () => {
        let get = useFunctions('upcomingSalaryAmount')
        await get({ businessId: business.id }).
            then(res =>
                setUpcomingSalaryAmount(res.data as upcomingSalaryAmountProps)
            )
            .catch(err => console.log('upcomingSalaryAmount error: ', err.message))
    }

    useEffect(() => {

        handleEmployees()
        handlePayrollSummary();
        handleUpcomingSalaryDate()
        handleFetchPaymentAccount();
        handleUpcomingSalaryAmount();

    }, [])

    const styles = StyleSheet.create({
        mainViewContainer: {
            flex: 1,
            marginTop: 10,
            borderRadius: 10,
            backgroundColor: "#d0d6e2",
        },
        topRightButton: {
            gap: 10,
            padding: 10,
            borderRadius: 100,
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "center",
            backgroundColor: colors.card,
        },
        searchBarView: {
            marginTop: 10,
        },
        tableMainContainer: {
            flex: 2,
            padding: 10,
            marginTop: 10,
            overflow: "hidden",
            backgroundColor: colors.card,
            borderRadius: 15,
            elevation: 10,
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
            shadowColor: "#000000",
            shadowOffset: {
                width: 0,
                height: 2,
            },
        },
        addButton: {
            flex: 1,
            ...shadowSet,
            borderRadius: 10,
            overflow: "hidden",
            backgroundColor: colors.primary,
        },
        addButtonSubView: {
            gap: 10,
            padding: 10,
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "center",
            backgroundColor: colors.card,
        },
        removeButton: {
            flex: 1,
            ...shadowSet,
            borderRadius: 10,
            overflow: "hidden",
            backgroundColor: colors.card,
        },
        removeButtonSubView: {
            gap: 10,
            padding: 10,
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "center",
            backgroundColor: colors.primary,
        },
    });

    return (
        <Drawer
            open={showSidePanel}
            onOpen={() => setShowSidePanel(true)}
            onClose={() => setShowSidePanel(false)}
            drawerPosition="right"
            drawerType="front"
            swipeEnabled={false}
            drawerStyle={{
                right: 0,
                width: '70%',
                marginVertical: 10,
                borderTopLeftRadius: 30,
                borderBottomLeftRadius: 30
            }}
            renderDrawerContent={() => {
                if (!showSidePanel) {
                    return;
                }

                return (
                    <AdminSidePanel
                        selectedData={selectedData as employeesProps}
                        setShowSidePanel={setShowSidePanel}
                    />
                )
            }}
        >
            <ScrollView style={{ flex: 1, backgroundColor: colors.background }}>
                <View style={{ padding: 10 }}>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <Text
                            variant="bodyLarge"
                            style={{ color: colors.text, flex: 1, fontWeight: "bold" }}
                        >
                            Human Resource
                        </Text>
                        <Button
                            icon="plus"
                            mode="contained"
                            onPress={addEmployee}
                            theme={{ colors: { primary: colors.primary } }}
                            textColor="black"
                            style={{ marginRight: 10 }}
                        >
                            Add User
                        </Button>
                        <Button
                            icon="cash"
                            mode="outlined"
                            onPress={() => console.log("Pressed")}
                            theme={{ colors: { outline: colors.primary } }}
                            textColor="black"
                        >
                            Process Payroll
                        </Button>
                    </View>
                </View>

                <View style={{ padding: 10 }}>
                    <View
                        style={{
                            height: 150,
                            flexDirection: "row",
                            gap: 10,
                            marginBottom: 15,
                        }}
                    >
                        {/* Upcoming Salary Date */}
                        <View
                            style={{
                                flex: 1,
                                backgroundColor: colors.primary,
                                borderRadius: 15,
                                elevation: 10,
                                shadowOpacity: 0.25,
                                shadowRadius: 3.84,
                                shadowColor: "#000000",
                                shadowOffset: {
                                    width: 0,
                                    height: 2,
                                },
                            }}
                        >
                            <View
                                style={{
                                    flex: 1,
                                    flexDirection: "row",
                                    gap: 10,
                                    padding: 10,
                                    alignItems: "center",
                                }}
                            >
                                <View
                                    style={{
                                        backgroundColor: colors.background + "90",
                                        padding: 10,
                                        borderRadius: 100,
                                    }}
                                >
                                    <Icon source="calendar-blank" color={colors.text} size={18} />
                                </View>

                                <Text variant="titleMedium" style={{ color: "white" }}>
                                    Upcoming Salary Date
                                </Text>
                            </View>


                            <View style={{ flex: 2, justifyContent: "center", padding: 10 }}>
                                <Text
                                    variant="titleLarge"
                                    style={{ color: "white", fontWeight: "bold" }}
                                >
                                    {upcomingSalaryData?.upcomingSalaryDate}
                                    {/* {moment().format("MMMM Do")},
                                    <Text variant="titleMedium" style={{ color: "white" }}>
                                        {" "}
                                        {moment().format("yyyy")}
                                    </Text> */}
                                </Text>
                                <Text
                                    variant="titleSmall"
                                    style={{ color: "white", opacity: 0.8, paddingTop: 5 }}
                                >
                                    in {upcomingSalaryData?.daysUntilPayday} days time
                                </Text>
                            </View>
                        </View>

                        {/* Upcoming Salary Amount */}
                        <View
                            style={{
                                flex: 1,
                                backgroundColor: colors.card,
                                borderRadius: 15,
                                elevation: 10,
                                shadowOpacity: 0.25,
                                shadowRadius: 3.84,
                                shadowColor: "#000000",
                                shadowOffset: {
                                    width: 0,
                                    height: 2,
                                },
                            }}
                        >
                            <View
                                style={{
                                    flex: 1,
                                    flexDirection: "row",
                                    gap: 10,
                                    padding: 10,
                                    alignItems: "center",
                                }}
                            >
                                <View
                                    style={{
                                        backgroundColor: colors.background + "90",
                                        padding: 10,
                                        borderRadius: 100,
                                    }}
                                >
                                    <Icon
                                        source="signal-cellular-3"
                                        color={colors.text}
                                        size={18}
                                    />
                                </View>

                                <Text variant="titleMedium" style={{ color: colors.text }}>
                                    Upcoming Salary Amount
                                </Text>
                            </View>

                            <Divider />

                            <View style={{ flex: 2, justifyContent: "center", padding: 10 }}>
                                <Text
                                    variant="bodyLarge"
                                    style={{ color: colors.text, fontWeight: "bold" }}
                                >
                                    $ {upcomingSalaryAmount?.totalAmount}
                                </Text>
                                <Text
                                    variant="bodyMedium"
                                    style={{ color: colors.text, opacity: 0.8 }}
                                >
                                    {upcomingSalaryAmount?.employeeCount} Employee
                                </Text>
                            </View>
                        </View>

                        {/* Payment Account */}
                        <View
                            style={{
                                flex: 1,
                                backgroundColor: colors.card,
                                borderRadius: 15,
                                elevation: 10,
                                shadowOpacity: 0.25,
                                shadowRadius: 3.84,
                                shadowColor: "#000000",
                                shadowOffset: {
                                    width: 0,
                                    height: 2,
                                },
                            }}
                        >
                            <View
                                style={{
                                    flex: 1,
                                    flexDirection: "row",
                                    gap: 10,
                                    padding: 10,
                                    alignItems: "center",
                                }}
                            >
                                <View
                                    style={{
                                        backgroundColor: colors.background + "90",
                                        padding: 10,
                                        borderRadius: 100,
                                    }}
                                >
                                    <Icon source="card-text" color={colors.text} size={18} />
                                </View>

                                <Text variant="titleMedium" style={{ color: colors.text }}>
                                    Payment Account
                                </Text>
                            </View>

                            <Divider />

                            <View style={{ flex: 2, justifyContent: "center", padding: 10 }}>
                                <View style={{ flexDirection: "row", gap: 10 }}>
                                    <View
                                        style={{
                                            backgroundColor: colors.background,
                                            padding: 10,
                                            borderRadius: 100,
                                        }}
                                    >
                                        <Icon source="bank" color={colors.text} size={18} />
                                    </View>

                                    <View>
                                        <Text
                                            variant="bodyLarge"
                                            style={{ color: colors.text, fontWeight: "bold" }}
                                        >
                                            {paymentAccount?.data.account_name}
                                        </Text>
                                        <Text
                                            variant="bodyMedium"
                                            style={{ color: colors.text, opacity: 0.8 }}
                                        >
                                            {paymentAccount?.data.is_verified ? 'Verified Account' : 'Not Verified Account'}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View
                        style={{
                            height: 350,
                            flexDirection: "row",
                            gap: 10,
                            marginTop: 10,
                        }}
                    >
                        <View
                            onLayout={(e) =>
                                setLineChartDimension({
                                    x: e.nativeEvent.layout.width,
                                    y: e.nativeEvent.layout.height,
                                })
                            }
                            style={{
                                flex: 2,
                                backgroundColor: colors.card,
                                borderRadius: 15,
                                elevation: 10,
                                shadowOpacity: 0.25,
                                shadowRadius: 3.84,
                                shadowColor: "#000000",
                                shadowOffset: {
                                    width: 0,
                                    height: 2,
                                },
                            }}
                        >
                            <CustomLineChart
                                chartDimension={lineChartDimension}
                                colors={colors}
                            />
                        </View>

                        <View
                            style={{
                                flex: 1,
                                backgroundColor: colors.card,
                                borderRadius: 15,
                                elevation: 10,
                                shadowOpacity: 0.25,
                                shadowRadius: 3.84,
                                shadowColor: "#000000",
                                shadowOffset: {
                                    width: 0,
                                    height: 2,
                                },
                            }}
                        >
                            <View style={{ flex: 1, padding: 10 }}>
                                <View
                                    style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        paddingVertical: 10,
                                    }}
                                >
                                    <Text
                                        variant="titleMedium"
                                        style={{ color: colors.text, fontWeight: "bold" }}
                                    >
                                        Payroll Summary
                                    </Text>

                                    <CustomButton value="View Report" onPress={() => { }} />
                                </View>

                                <Divider />

                                <View
                                    onLayout={(e) => {
                                        setPayrollSummaryDimension({
                                            x: e.nativeEvent.layout.width,
                                            y: e.nativeEvent.layout.height,
                                        });
                                    }}
                                    style={{ flex: 1 }}
                                >
                                    {payrollSummaryData && <CustomPieChart
                                        pieChartData={payrollSummaryData as payrollSummaryChartProps[]}
                                        chartDimension={payrollSummaryDimension}
                                        colors={colors}
                                    />}
                                </View>
                            </View>
                        </View>
                    </View>
                </View>

                <View style={{ flex: 1, padding: 10 }}>
                    <View style={styles.tableMainContainer}>

                        <Text variant="bodyLarge" style={{ color: colors.text, fontWeight: "bold", marginTop: 10 }}>Employees</Text>

                        {/* search bar, etc */}
                        <View>
                            <Input
                                label="Search..."
                                searchIcon
                                onChangeText={(text) => {
                                    if (text.length > 0) {
                                        const replaceText = text.replace(/\s/g, "");

                                        const filterData = employeesData && employeesData.filter((item) => {
                                            let found = false;
                                            Object.values(item.employeeInfo).forEach((value) => {

                                                if (
                                                    typeof value === "string" &&
                                                    value.toLowerCase().includes(replaceText.toLowerCase())
                                                ) {
                                                    found = true;
                                                } else if (
                                                    !isNaN(Number(replaceText)) &&
                                                    Number(value) === Number(replaceText)
                                                ) {
                                                    found = true;
                                                }
                                            });
                                            return found;
                                        });
                                        setModifiedData(filterData);
                                    } else {
                                        setModifiedData(employeesData);
                                    }
                                }}
                            />
                        </View>

                        <PersonalEmployeePageTable
                            data={employeesData as employeesProps[]}
                            modifiedData={modifiedData as employeesProps[]}
                            setShowSidePanel={setShowSidePanel}
                            setModifiedData={setModifiedData}
                            setSelectedData={setSelectedData}
                        />
                    </View>
                </View>
            </ScrollView>
        </Drawer>
    );
};

export default AdminPage;