import { useNavigation, useTheme } from "@react-navigation/native";
import React, { useContext, useEffect, useState } from "react";
import {
  ActivityIndicator,
  View,
  FlatList,
  StyleSheet,
  Pressable,
} from "react-native";
import { Button, Divider, Text } from "react-native-paper";

import { GlobalContext } from "../../../GlobalContext/GlobalContext";
import { useFunctions } from "../../../GlobalContext/useUserContext";
import { inventoryTableInformation } from "../../../api/inventoryApi";
import { cardStyles } from "../../theme/applicationTheme";
import { currencyFormatter, currencySymbol } from "../../util/constants";

export interface InventoryCustomrDemand {
  name: string;
  demand: string | number;
  category: string;
  description: string;
  price: number;
  quantity: number;
  total: number;
}

const Employees = () => {
  const { colors } = useTheme();
  const { business } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);
  const [inventoryData, setInventoryData] = useState({} as any);
  const navigation: any = useNavigation();

  useEffect(() => {
    if (business) {
      const { id } = business;
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const getInventoryData = useFunctions("fetchInventories");
      if (id && id !== null) {
        getInventoryData({ businessId: id })
          .then((result: any) => {
            // Read result of the Cloud Function.
            /** @type {any} */
              setIsLoading(false);

            const data: InventoryCustomrDemand[] = [];
            if (result.data && result.data.length > 0) {
              result.data.forEach((item: any) => {
                data.push({
                  name: item.itemName,
                  demand: item.itemCustomerDemand || 0,
                  category: item.itemCategory,
                  description: item.itemDescription,
                  price: item.itemUnitPrice,
                  quantity: item.itemQuantity || 0,
                  total: item.totalInventoryCost,
                });
              });

              //build a list of categories without duplicate categories
              const categories: string[] = [];
              result.data.forEach((item: inventoryTableInformation) => {
                if (!categories.includes(item.itemCategory)) {
                  categories.push(item.itemCategory);
                }
              });

              setInventoryData(data);
            } else {
              // initialize the data to an empty array
              setInventoryData([]);
              setIsLoading(false);
            }
          })
          .catch((error) => {
            // Getting the Error details.
            setIsLoading(false);
            // eslint-disable-next-line no-console
            console.error("Error retrieving sales data:", error);
          });
      }
    }
  }, [business]);

  return (
    <View
      style={{
        ...cardStyles.paper,
        flex: 1,
        padding: 10,
        margin: 0,
        borderRadius: 10,
        backgroundColor: colors.card,
      }}
    >
      {/* these are filter button that allows the user to filter based of categories in the table and also sort in ascending/descending order */}
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Text
          variant="titleLarge"
          style={{
            fontWeight: "bold",
            color: colors.text,
            marginTop: 5,
            marginLeft: 10,
            marginBottom: 10,
          }}
        >
          Current Inventory Demand
        </Text>
        <Button
          icon="dropbox"
          mode="outlined"
          theme={{ colors: { outline: colors.primary } }}
          onPress={() => {
            // go to the inventory page
            navigation.navigate("Inventory", { screen: "InventoryHome" });
          }}
        >
          <Text variant="bodyMedium" style={{ color: colors.text }}>
            View Inventory
          </Text>
        </Button>
      </View>
      <View style={{ flex: 1 }}>
        {isLoading ? (
          <View style={{ marginTop: 110 }}>
            <ActivityIndicator size="large" color={colors.primary} />
          </View>
        ) : (
          <FlatList
            data={inventoryData}
            ItemSeparatorComponent={() => (
              <Divider style={{ backgroundColor: colors.border }} />
            )}
            renderItem={({ item }) => {
              const isCompleted = item.status === "Completed";

              return (
                <Pressable
                  style={({ pressed }) => ({
                    backgroundColor: pressed
                      ? colors.background
                      : "transparent",
                    paddingHorizontal: 10,
                    paddingVertical: 10,
                  })}
                >
                  <View
                    style={[
                      styles.card,
                      isCompleted && styles.completedCard,
                      item.status === "In Progress" && {
                        borderLeftColor: colors.primary,
                        borderLeftWidth: 5,
                      },
                      item.status === "Completed" && {
                        borderLeftColor: "#23c079",
                        borderLeftWidth: 5,
                      },
                      item.status === "Due" && {
                        borderLeftColor: "#f67b2f",
                        borderLeftWidth: 5,
                      },
                    ]}
                  >
                    <Text style={styles.title}>
                      {item.name}{" "}
                      <Text variant="bodyLarge" style={styles.dueDate}>
                        Category: {item.category}
                      </Text>{" "}
                    </Text>
                    <Text style={styles.description}>{item.description}</Text>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={styles.description}>
                        Quantity: {item.quantity || 0}
                      </Text>
                      <Text style={styles.description}>
                        Price: {currencySymbol}{currencyFormatter(item.price || 0)}
                      </Text>
                      <Text style={styles.description}>
                        Total: {currencySymbol}{currencyFormatter(item.total || 0)}
                      </Text>
                      <Text style={styles.description}>
                        Demand: {item.demand || 0}
                      </Text>
                    </View>
                  </View>
                </Pressable>
              );
            }}
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    padding: 16,
    marginBottom: 16,
    elevation: 10,
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    shadowColor: "#000000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
  },
  completedCard: {
    backgroundColor: "#E5E5E5",
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 8,
  },
  description: {
    fontSize: 16,
    marginBottom: 8,
  },
  status: {
    fontSize: 16,
    color: "#23c079",
    fontWeight: "bold",
  },
  completeButton: {
    fontSize: 16,
    fontWeight: "bold",
  },
  titleContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },

  dueDate: {
    fontSize: 12,
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginLeft: 65,
  },
});

export default Employees;
