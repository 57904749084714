import { useNavigation, useTheme } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { ActivityIndicator, Icon, Text } from "react-native-paper";

import { SalesInformation } from "../../../api/salesApi";
import { isWeb } from "../../Common/isWeb";
import { Header, Row, Table } from "../../tableComponent/tableComponent";
import { currencyFormatter, currencySymbol } from "../../util/constants";
import { w } from "../../util/dimension";

const SalesTable = ({
  data,
  modifiedData,
  setModifiedData,
}: {
  data: any[];
  modifiedData: any[];
  setModifiedData: any;
}) => {
  const { colors } = useTheme();
  const navigation: any = useNavigation();

  const [noOfRows, setNoOfRows] = useState(10);

  const [width, setWidth] = useState(w);

  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const from = currentPage * noOfRows;
  const to = from + noOfRows;
  const [salesData, setSalesData] = useState([] as SalesInformation[]);

  const invoiceButton = () => {
    navigation.navigate("Sales", { screen: "SaleItem" });
  };

  const styles = StyleSheet.create({
    tableHeaderView: {
      paddingVertical: 5,
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: colors.card,
      justifyContent: "space-evenly",
    },
    tableSubTextView: {
      flex: 1,
      paddingVertical: 5,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-evenly",
    },
    tableSelectionBox: {
      borderWidth: 1,
      borderRadius: 5,
      borderColor: colors.border,
      backgroundColor: colors.background,
    },
    tableSubStatusText: {
      gap: 10,
      borderRadius: 5,
      paddingVertical: 1,
      flexDirection: "row",
      alignItems: "center",
      paddingHorizontal: 10,
      justifyContent: "center",
    },
    headerTitle: {},
    headerTitleText: {
      paddingVertical: 10,
    },
  });

  useEffect(() => {
    if (modifiedData && modifiedData.length > 0) {
      setSalesData(modifiedData);
      setIsLoading(false);
    }
  }, [modifiedData]);

  return (
    <View
      onLayout={(e) => setWidth(e.nativeEvent.layout.width)}
      style={{
        flex: 1,
        borderColor: colors.border,
        borderRadius: 10,
        borderWidth: 1,
        marginTop: 10,
      }}
    >
      <Table viewStyle={{ flex: isWeb ? 1 : 0 }}>
        <Header
          viewStyle={{ borderBottomWidth: 1, borderColor: colors.border }}
          dataState={salesData}
          setDataState={setModifiedData}
        >
          {/* <View
            style={{
              width: width * 0.05,
              alignItems: "center",
              justifyContent: "center",
            }}
          /> */}

          <Header.Title
            value="id"
            columnWidth={width * 0.15}
            viewStyle={styles.headerTitle}
            textStyle={styles.headerTitleText}
          >
            Invoice ID
          </Header.Title>

          <Header.Title
            value="saleOrderDate"
            columnWidth={width * 0.15}
            viewStyle={styles.headerTitle}
            textStyle={styles.headerTitleText}
          >
            Creation Date
          </Header.Title>

          <Header.Title
            isObject
            value="saleCustomerInformation"
            secondValue="customerName"
            columnWidth={width * 0.15}
            textStyle={{ paddingVertical: 10, textTransform: "capitalize" }}
          >
            Customer Name
          </Header.Title>

          <Header.Title
            isNumeric
            isObject
            value="saleProductInformation"
            secondValue="saleProductTotal"
            columnWidth={width * 0.15}
            viewStyle={styles.headerTitle}
            textStyle={styles.headerTitleText}
          >
            Payable Amount
          </Header.Title>

          <Header.Title
            isNumeric
            value="saleTotalAmount"
            columnWidth={width * 0.15}
            viewStyle={styles.headerTitle}
            textStyle={styles.headerTitleText}
          >
            Paid Amount
          </Header.Title>
          <Header.Title
            isObject
            value="salePaymentInformation"
            secondValue="paymentMethod"
            columnWidth={width * 0.15}
            textStyle={{ paddingVertical: 10, textTransform: "capitalize" }}
          >
            Payment Method
          </Header.Title>

          <Header.Title
            value="saleOrderStatus"
            columnWidth={width * 0.15}
            viewStyle={styles.headerTitle}
            textStyle={styles.headerTitleText}
          >
            Status
          </Header.Title>
        </Header>
        {isLoading ? (
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <ActivityIndicator size="large" color="#0000ff" />
          </View>
        ) : (
          <ScrollView>
            {salesData &&
              salesData.length > 0 &&
              salesData?.slice(from, to).map((item: any, index: number) => {
                const successIconColor = "#165e3d";
                const pendingIconColor = "#f67b2f";
                const declineIconColor = "#b83131";
                const createIconColor = "#3d42ad";

                let iconName;
                let iconColor;

                const saleProductTotal =  parseInt(item.saleTotalAmount, 10)

                if (item.saleOrderStatus === "SHIPPED") {
                  iconColor = successIconColor;
                  iconName = "check-circle";
                } else if (item.saleOrderStatus === "COMPLETED") {
                  iconColor = successIconColor;
                  iconName = "check-circle";
                } else if (item.saleOrderStatus === "SUCCESS" || item.saleOrderStatus === "success") {
                  iconColor = successIconColor;
                  iconName = "check-circle";
                } else if (item.saleOrderStatus === "DECLINED") {
                  iconColor = declineIconColor;
                  iconName = "minus-circle";
                } else if (item.saleOrderStatus === "PENDING") {
                  iconName = "clock-time-three";
                  iconColor = pendingIconColor;
                } else if (item.saleOrderStatus === "CREATED") {
                  iconColor = createIconColor;
                  iconName = "flag";
                } else if (item.saleOrderStatus === "PROCESSING") {
                  iconName = "clock-time-three";
                  iconColor = colors.primary;
                }

                return (
                  <Row
                    key={index}
                    rowHeight={40}
                    viewStyle={{
                      paddingVertical: 5,
                      maxHeight: 40,
                      alignItems: "center",
                      borderBottomWidth: StyleSheet.hairlineWidth,
                      borderColor: colors.border,
                    }}
                  >
                    {/* <View
                      style={{
                        width: width * 0.05,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                        gap: 5,
                      }}
                    >
                      <View>
                        <TouchableOpacity style={styles.tableSelectionBox}>
                          <Icon
                            source="delete"
                            size={32}
                            color={colors.primary}
                          />
                        </TouchableOpacity>
                      </View>

                      <View>
                        <TouchableOpacity>
                          <Icon
                            source="file-pdf-box"
                            size={32}
                            color={colors.primary}
                          />
                        </TouchableOpacity>
                      </View>
                    </View> */}
                    <Row.Cell
                      rowWidth={width * 0.15}
                      onPress={() => invoiceButton()}
                      value={item.saleOrderId || 0}
                    />
                    <Row.Cell
                      rowWidth={width * 0.15}
                      value={item.saleOrderDate}
                    />
                    <Row.Cell
                      rowWidth={width * 0.15}
                      textStyle={{}}
                      value={item.customerName}
                    />
                    <Row.Cell
                      rowWidth={width * 0.15}
                      value={`${currencySymbol}${currencyFormatter(saleProductTotal)}`}
                    />
                    <Row.Cell
                      rowWidth={width * 0.15}
                      value={`${currencySymbol}${currencyFormatter(item.saleTotalAmount || 0)}`}
                    />
                    <Row.Cell
                      rowWidth={width * 0.15}
                      value={
                        item.paymentMethod || "Cash"
                      }
                    />
                    <View
                      style={{
                        width: width * 0.15,
                        justifyContent: "center",
                        alignItems: "flex-start",
                      }}
                    >
                      <View
                        style={{
                          ...styles.tableSubStatusText,
                          backgroundColor: colors.background,
                        }}
                      >
                        <Icon source={iconName} size={24} color={iconColor} />
                        <Text variant="bodySmall" style={{ color: iconColor }}>
                          {item.saleOrderStatus}
                        </Text>
                      </View>
                    </View>
                  </Row>
                );
              })}
            {salesData.length === 0 && (
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  paddingVertical: 20,
                }}
              >
                <Text>No Data Found</Text>
              </View>
            )}
          </ScrollView>
        )}
      </Table>
      <View style={{ paddingVertical: 20, paddingRight: 20 }}>
        <Table.Pagination
          setRows={setNoOfRows}
          currentSelectedRows={noOfRows}
          totalPages={Table.TotalPages(modifiedData, noOfRows)}
          label={`${from + 1} - ${to} of ${modifiedData.length}`}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </View>
    </View>
  );
};

export default SalesTable;
