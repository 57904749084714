import { FlatList, Pressable, StyleSheet, View } from "react-native";
import React, { useMemo, useState } from "react";
import { Icon, Text } from "react-native-paper";
import Maps from "../../component/maps/maps";
import { useTheme } from "@react-navigation/native";
import { CustomColors } from "../../api/customColors";
import CustomButton from "../../component/Common/button";
import CustomPieChart from "../../component/graphs/customPieChart";
import CustomLineChart from "../../component/graphs/customLineChart";
import { shadowSet } from "../../component/util/shadow";

type VehicleType = {
  [key: string]: {
    carNo: string;
    driverName: string;
    from: string;
    to: string;
    location: {
      latitude: number;
      longitude: number;
    };
  }[];
};

const Trips = () => {
  const { colors }: { colors: any } = useTheme();

  const [selectedVehiclesLocation, setSelectedVehiclesLocation] = useState<any>(
    []
  );

  const [pieChartDimension, setPieChartDimension] = useState({ x: 0, y: 0 });
  const [lineChartDimension, setLineChartDimension] = useState({ x: 0, y: 0 });

  let deliveringVehicles: VehicleType = {
    container: [
      {
        carNo: "123GN23",
        driverName: "John",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.3853,
          longitude: 69.1051,
        },
      },
      {
        carNo: "123GN23",
        driverName: "Ben",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.3193,
          longitude: 69.1401,
        },
      },
      {
        carNo: "123GN23",
        driverName: "Jahanzeb",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.8713,
          longitude: 69.06421,
        },
      },
      {
        carNo: "123GN23",
        driverName: "Adam",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.17143,
          longitude: 69.94531,
        },
      },
    ],
    ship: [
      {
        carNo: "123GN23",
        driverName: "John",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.33753,
          longitude: 69.35051,
        },
      },
      {
        carNo: "123GN23",
        driverName: "Ben",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.37693,
          longitude: 69.41401,
        },
      },
      {
        carNo: "123GN23",
        driverName: "Jahanzeb",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.47143,
          longitude: 69.642211,
        },
      },
      {
        carNo: "123GN23",
        driverName: "Adam",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.143743,
          longitude: 69.964431,
        },
      },
    ],
    plane: [
      {
        carNo: "123GN23",
        driverName: "John",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.36753,
          longitude: 69.33051,
        },
      },
      {
        carNo: "123GN23",
        driverName: "Ben",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.379334,
          longitude: 69.443401,
        },
      },
      {
        carNo: "123GN23",
        driverName: "Jahanzeb",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.471433,
          longitude: 69.642321,
        },
      },
      {
        carNo: "123GN23",
        driverName: "Adam",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.174763,
          longitude: 69.943431,
        },
      },
    ],
    onfoot: [
      {
        carNo: "123GN23",
        driverName: "John",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.371253,
          longitude: 69.376051,
        },
      },
      {
        carNo: "123GN23",
        driverName: "Ben",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.377893,
          longitude: 68.440231,
        },
      },
      {
        carNo: "123GN23",
        driverName: "Jahanzeb",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.476713,
          longitude: 69.642141,
        },
      },
      {
        carNo: "123GN23",
        driverName: "Adam",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.17234343,
          longitude: 69.943341,
        },
      },
    ],
    mini_van: [
      {
        carNo: "123GN23",
        driverName: "John",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.3743453,
          longitude: 69.305341,
        },
      },
      {
        carNo: "123GN23",
        driverName: "Ben",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.373493,
          longitude: 69.443401,
        },
      },
      {
        carNo: "123GN23",
        driverName: "Jahanzeb",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.473413,
          longitude: 69.634421,
        },
      },
      {
        carNo: "123GN23",
        driverName: "Adam",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.174343,
          longitude: 69.945731,
        },
      },
    ],
    motor_cycle: [
      {
        carNo: "123GN23",
        driverName: "John",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.37576753,
          longitude: 69.354051,
        },
      },
      {
        carNo: "123GN23",
        driverName: "Ben",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.374593,
          longitude: 69.444501,
        },
      },
      {
        carNo: "123GN23",
        driverName: "Jahanzeb",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.471453,
          longitude: 69.6876421,
        },
      },
      {
        carNo: "123GN23",
        driverName: "Adam",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.134743,
          longitude: 69.91234431,
        },
      },
    ],
    car: [
      {
        carNo: "123GN23",
        driverName: "John",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.3734553,
          longitude: 69.3023451,
        },
      },
      {
        carNo: "123GN23",
        driverName: "Ben",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.3796783,
          longitude: 69.44356401,
        },
      },
      {
        carNo: "123GN23",
        driverName: "Jahanzeb",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.4734613,
          longitude: 69.6657421,
        },
      },
      {
        carNo: "123GN23",
        driverName: "Adam",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.17234643,
          longitude: 69.913245431,
        },
      },
    ],
    bike: [
      {
        carNo: "123GN23",
        driverName: "John",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.32345753,
          longitude: 69.32345051,
        },
      },
      {
        carNo: "123GN23",
        driverName: "Ben",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.37234593,
          longitude: 69.4345401,
        },
      },
      {
        carNo: "123GN23",
        driverName: "Jahanzeb",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.47234513,
          longitude: 69.613245421,
        },
      },
      {
        carNo: "123GN23",
        driverName: "Adam",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.17234543,
          longitude: 69.9423431,
        },
      },
    ],
    truck: [
      {
        carNo: "123GN23",
        driverName: "John",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.37234553,
          longitude: 69.3234051,
        },
      },
      {
        carNo: "123GN23",
        driverName: "Ben",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.37234593,
          longitude: 69.42345401,
        },
      },
      {
        carNo: "123GN23",
        driverName: "Jahanzeb",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.47123453,
          longitude: 69.64234521,
        },
      },
      {
        carNo: "123GN23",
        driverName: "Adam",
        from: "Street 2, USA",
        to: "Street 3, USA",
        location: {
          latitude: 30.17413453,
          longitude: 69.91234431,
        },
      },
    ],
  };

  // Last Delivery tab
  const [selectedDeliveryTab, setSelectedDeliveryTab] = useState(0);

  let lastDelivery = [
    { deliveryItem: "Parcel", status: "Ongoing", date: "Dec 21 23" },
    { deliveryItem: "Items", status: "Completed", date: "May 11 23" },
    { deliveryItem: "Document", status: "Failed", date: "Jan 21 23" },
    { deliveryItem: "Box", status: "Pending", date: "Jul 31 23" },
    { deliveryItem: "Parcel", status: "Ongoing", date: "Feb 29 23" },
    { deliveryItem: "Items", status: "Completed", date: "May 11 23" },
    { deliveryItem: "Document", status: "Failed", date: "Jan 21 23" },
    { deliveryItem: "Box", status: "Pending", date: "Jul 31 23" },
    { deliveryItem: "Parcel", status: "Ongoing", date: "Jun 21 23" },
    { deliveryItem: "Document", status: "Failed", date: "Jan 21 23" },
    { deliveryItem: "Parcel", status: "Ongoing", date: "Feb 29 23" },
  ];

  let totalVehicles = [
    {
      id: "container",
      name: "Container",
      icon: "truck-cargo-container",
      value: 185341,
    },
    { id: "truck", name: "Truck", icon: "truck", value: 456124 },
    { id: "mini_van", name: "Mini Van", icon: "van-passenger", value: 175354 },
    { id: "car", name: "Car", icon: "car-hatchback", value: 456123 },
    {
      id: "motor_cycle",
      name: "Motor Cycle",
      icon: "motorbike",
      value: 998138,
    },
    { id: "bike", name: "Bike", icon: "motorbike", value: 891230 },
    { id: "onfoot", name: "Onfoot", icon: "walk", value: 312392 },
    { id: "ship", name: "Ship", icon: "ferry", value: 139412 },
    { id: "plane", name: "Plane", icon: "airplane", value: 978123 },
  ];

  let pieChartData = [
    { name: "Parcel", y: 350, color: colors.primary },
    { name: "Document", y: 500, color: "#fd7" },
    { name: "Box", y: 240, color: "#f7d" },
    { name: "Other", y: 240, color: "#7d7" },
  ];

  const styles = StyleSheet.create({
    topRightButton: {
      gap: 10,
      borderRadius: 100,
      alignItems: "center",
      flexDirection: "row",
      padding: 10,
      justifyContent: "center",
      backgroundColor: colors.card,
    },
    inventoryTabs: {
      gap: 10,
      marginTop: 10,
      flexDirection: "row",
      justifyContent: "space-evenly",
    },
    tabView: {
      flex: 1,
      padding: 20,
      borderRadius: 10,
      backgroundColor: colors.card,
    },
    mapsContainer: {
      flex: 1,
      borderRadius: 15,
      elevation: 10,
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      shadowColor: "#000000",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      overflow: "hidden",
      backgroundColor: colors.card,
    },
    searchTripButton: {
      backgroundColor: colors.yellow,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 100,
      padding: 20,
    },
  });

  let PieChartMemo = useMemo(() => {
    return (
      <CustomPieChart
        pieChartData={pieChartData}
        chartDimension={pieChartDimension}
        colors={colors}
      />
    );
  }, [pieChartDimension]);

  return (
    <View style={{ flex: 1, backgroundColor: colors.background, padding: 10 }}>
      <View style={{ flex: 1, flexDirection: "row", gap: 10 }}>
        <View style={styles.mapsContainer}>
          <Maps markersLocation={selectedVehiclesLocation} />
        </View>

      </View>

      <View style={{ flex: 1, flexDirection: "row", gap: 10, paddingTop: 10 }}>

        <View
          style={{
            flex: 1,
            backgroundColor: colors.card,
            borderRadius: 15,
            elevation: 10,
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
            shadowColor: "#000000",
            shadowOffset: {
              width: 0,
              height: 2,
            },
          }}
        >
          <FlatList
            data={lastDelivery}
            style={{ borderRadius: 10 }}
            ListHeaderComponent={() => {
              return (
                <View
                  style={{
                    flexDirection: "column",
                    paddingVertical: 10,
                  }}
                >
                  {/* last delivery tab and title */}
                  <View
                    style={{
                      padding: 10,
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text
                      variant="titleMedium"
                      style={{ color: colors.text, fontWeight: "bold" }}
                    >
                      Last Delivery
                    </Text>
                    <View
                      style={{
                        flexDirection: "row",
                        borderBottomWidth: 2,
                        borderColor: colors.border,
                        gap: 5,
                      }}
                    >
                      <Pressable
                        onPress={() => setSelectedDeliveryTab(0)}
                        style={{
                          borderBottomWidth: 2,
                          borderColor:
                            selectedDeliveryTab === 0
                              ? colors.primary
                              : "transparent",
                        }}
                      >
                        <View>
                          <Text
                            style={{
                              color:
                                selectedDeliveryTab === 0
                                  ? colors.primary
                                  : colors.text,
                              fontWeight:
                                selectedDeliveryTab === 0 ? "bold" : "normal",
                              fontSize: 10,
                            }}
                            adjustsFontSizeToFit
                          >
                            All Order
                          </Text>
                        </View>
                      </Pressable>
                      <Pressable
                        onPress={() => setSelectedDeliveryTab(1)}
                        style={{
                          borderBottomWidth: 2,
                          borderColor:
                            selectedDeliveryTab === 1
                              ? colors.primary
                              : "transparent",
                        }}
                      >
                        <View>
                          <Text
                            style={{
                              color:
                                selectedDeliveryTab === 1
                                  ? colors.primary
                                  : colors.text,
                              fontWeight:
                                selectedDeliveryTab === 1 ? "bold" : "normal",
                              fontSize: 10,
                            }}
                            adjustsFontSizeToFit
                          >
                            Ongoing
                          </Text>
                        </View>
                      </Pressable>
                      <Pressable
                        onPress={() => setSelectedDeliveryTab(2)}
                        style={{
                          borderBottomWidth: 2,
                          borderColor:
                            selectedDeliveryTab === 2
                              ? colors.primary
                              : "transparent",
                        }}
                      >
                        <View>
                          <Text
                            style={{
                              color:
                                selectedDeliveryTab === 2
                                  ? colors.primary
                                  : colors.text,
                              fontWeight:
                                selectedDeliveryTab === 2 ? "bold" : "normal",
                              fontSize: 10,
                            }}
                            adjustsFontSizeToFit
                          >
                            Delivered
                          </Text>
                        </View>
                      </Pressable>
                    </View>
                  </View>

                  {/* table header */}
                  <View
                    style={{
                      flexDirection: "row",
                      paddingVertical: 10,
                      paddingHorizontal: 5,
                      backgroundColor: colors.card,
                    }}
                  >
                    {Object.keys(lastDelivery[0]).map((item, index) => (
                      <Text
                        key={index}
                        style={{
                          flex: 1,
                          color: colors.text,
                          textTransform: "capitalize",
                          fontWeight: "bold",
                          textAlign: index !== 0 ? "center" : "left",
                        }}
                        numberOfLines={1}
                      >
                        {item}
                      </Text>
                    ))}
                  </View>
                </View>
              );
            }}
            renderItem={({ item, index }) => {
              let isCompleted = item.status === "Completed";
              let isFailed = item.status === "Failed";
              return (
                <View
                  key={index}
                  style={{
                    flexDirection: "row",
                    paddingVertical: 10,
                    backgroundColor: colors.card,
                    paddingHorizontal: 5,
                    overflow: "hidden",
                  }}
                >
                  <Text
                    style={{
                      flex: 1,
                      color: isCompleted
                        ? "#65b4c7"
                        : isFailed
                          ? "#e3baca"
                          : colors.text,
                      fontWeight: isCompleted || isFailed ? "bold" : "normal",
                    }}
                  >
                    {item.deliveryItem}
                  </Text>
                  <Text
                    style={{
                      flex: 1,
                      color: isCompleted
                        ? "#65b4c7"
                        : isFailed
                          ? "#e3baca"
                          : colors.text,
                      fontWeight: isCompleted || isFailed ? "bold" : "normal",
                      textAlign: "center",
                    }}
                  >
                    {item.status}
                  </Text>
                  <Text
                    style={{
                      flex: 1,
                      color: isCompleted
                        ? "#65b4c7"
                        : isFailed
                          ? "#e3baca"
                          : colors.text,
                      fontWeight: isCompleted || isFailed ? "bold" : "normal",
                      textAlign: "center",
                    }}
                  >
                    {item.date}
                  </Text>
                </View>
              );
            }}
            stickyHeaderIndices={[0]}
          />
        </View>
        <View style={{
          flex: 1, backgroundColor: colors.card,
          borderRadius: 15,
          elevation: 10,
          shadowOpacity: 0.25,
          shadowRadius: 3.84,
          overflow: 'hidden',
          shadowColor: "#000000",
          shadowOffset: {
            width: 0,
            height: 2,
          },
        }}>

          <View style={{ padding: 10 }}>
            <Text
              variant="titleLarge"
              style={{ color: colors.text, fontWeight: "bold" }}
            >
              Delivery Items
            </Text>
          </View>

          <View
            style={{ flex: 1 }}
            onLayout={(e) => {
              setPieChartDimension({
                x: e.nativeEvent.layout.width,
                y: e.nativeEvent.layout.height,
              });
            }}
          >

            {PieChartMemo}
          </View>
        </View>

        <View style={{ flex: 1 }}>
          <View
            style={{
              flex: 1,
              borderRadius: 15,
              elevation: 10,
              shadowOpacity: 0.25,
              shadowRadius: 3.84,
              shadowColor: "#000000",
              shadowOffset: {
                width: 0,
                height: 2,
              },
              backgroundColor: colors.card,
            }}
            onLayout={(e) =>
              setLineChartDimension({
                x: e.nativeEvent.layout.width,
                y: e.nativeEvent.layout.height,
              })
            }
          >
            {" "}
            <View style={{ padding: 10 }}>
              <Text
                variant="titleLarge"
                style={{ color: colors.text, fontWeight: "bold" }}
              >
                Analytic
              </Text>
            </View>
            <CustomLineChart
              chartType="spline"
              chartDimension={lineChartDimension}
              colors={colors}
              showLegend
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default Trips;
