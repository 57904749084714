import { ScrollView, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { useTheme } from '@react-navigation/native'
import { CustomColors } from '../../../api/customColors';

const PaymentShippingItemDetail = () => {

    const { colors }: { colors: CustomColors } = useTheme();

    const styles = StyleSheet.create({
        fontBold: {
            fontWeight: 'bold',
            color:colors.text
        },
        listOfItemView: {
            marginTop: 10,
            flexDirection: 'row',
            justifyContent: 'space-between',
        }
    })
    return (
        <ScrollView contentContainerStyle={{ paddingHorizontal: 10 }}>

            <View style={styles.listOfItemView}>
                <Text style={styles.fontBold}>Bill To</Text>
                <Text style={{color:colors.subText}}>Posh Textile</Text>
            </View>
            <View style={styles.listOfItemView}>
                <Text style={styles.fontBold}>Project Name</Text>
                <Text style={{color:colors.subText}}>BELMONT TOWER</Text>
            </View>
            <View style={styles.listOfItemView}>
                <Text style={styles.fontBold}>Terms</Text>
                <Text style={{color:colors.subText}}>N30</Text>
            </View>
            <View style={styles.listOfItemView}>
                <Text style={styles.fontBold}>Ship To</Text>
                <Text style={{color:colors.subText}}>Posh Textile.Inc</Text>
            </View>
            <View style={styles.listOfItemView}>
                <Text style={styles.fontBold}>Priority</Text>
                <Text style={{color:colors.subText}}>Priority</Text>
            </View>
            <View style={styles.listOfItemView}>
                <Text style={styles.fontBold}>Specifier</Text>
                <Text style={{color:colors.subText}}>Specifier</Text>
            </View>
            <View style={styles.listOfItemView}>
                <Text style={styles.fontBold}>Order Date</Text>
                <Text style={{color:colors.subText}}>12/03/2022</Text>
            </View>
            <View style={styles.listOfItemView}>
                <Text style={styles.fontBold}>Sales Person</Text>
                <Text style={{color:colors.subText}}>House</Text>
            </View>
            <View style={styles.listOfItemView}>
                <Text style={styles.fontBold}>Customer PO</Text>
                <Text style={{color:colors.subText}}>00000</Text>
            </View>
            <View style={styles.listOfItemView}>
                <Text style={styles.fontBold}>Campaign</Text>
                <Text style={{color:colors.subText}}>Placement</Text>
            </View>
            <View style={styles.listOfItemView}>
                <Text style={styles.fontBold}>Reg Ship Date</Text>
                <Text style={{color:colors.subText}}>12/12/2002</Text>
            </View>
            <View style={styles.listOfItemView}>
                <Text style={styles.fontBold}>Comment</Text>
                <Text style={{color:colors.subText}}>No comments</Text>
            </View>

        </ScrollView>
    )
}

export default PaymentShippingItemDetail
