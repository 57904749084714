import { useTheme } from "@react-navigation/native";
import { httpsCallable } from "firebase/functions";
import React, { useContext, useEffect, useState } from "react";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import { Icon, Text} from "react-native-paper";

import { GlobalContext } from "../../GlobalContext/GlobalContext";
import { CustomColors } from "../../api/customColors";
import Input from "../../component/Common/Input/Input";
import PaymentTable from "../../component/page/payment/paymentTable";
import { functions } from "../../firebase/firebaseConfig";

const Payment = () => {
  const { colors }: { colors: CustomColors } = useTheme();
  const { business } = useContext(GlobalContext);

  const [modifiedData, setModifiedData] = useState<any[]>([]);
  const [paymentData, setPaymentData] = useState<any[]>([]); 
  const [tableSortActiveIndex, setTableSortActiveIndex] = useState<
    string | null
  >(null);

  const tableSortArray = ["success", "abandoned", "Pending", "Create"];

  const tableSort = (item: string) => {
    setTableSortActiveIndex(item);

    const filterData: any[] = [];

    paymentData.forEach((data) => {
      if (data.status === item) filterData.push(data);
    });

    setModifiedData(filterData);
  };

  const clearFilter = () => {
    setTableSortActiveIndex(null);
    setModifiedData(paymentData);
  };

  const styles = StyleSheet.create({
    topRightButton: {
      gap: 10,
      borderRadius: 100,
      alignItems: "center",
      flexDirection: "row",
      padding: 10,
      justifyContent: "center",
      backgroundColor: colors.card,
    },
    searchBarView: {
      gap: 10,
      overflow: "hidden",
      marginVertical: 10,
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    tableSortArray: {
      gap: 20,
      paddingTop: 10,
      flexWrap: "wrap",
      alignItems: "center",
      flexDirection: "row",
    },
    tableSubStatusText: {
      gap: 10,
      borderWidth: 2,
      borderRadius: 5,
      paddingVertical: 3,
      flexDirection: "row",
      alignItems: "center",
      paddingHorizontal: 20,
      justifyContent: "center",
    },
  });

    // fetch inventory data from firebase function
    const fetchPaymentReceived = () => {
      if (business) {
  
        const { subaccountCode } = business;
        const getPaymentReceivedData = httpsCallable(functions, "fetchTransactions");
        getPaymentReceivedData({ subaccountCode })
          .then((result: any) => {
            // Read result of the Cloud Function.
            /** @type {any} */
            if (result.data && result.data.length > 0) {
              //build a list of categories without duplicate categories
              setPaymentData(result.data);  
              setModifiedData(result.data);
            }
          })
          .catch((error: any) => {
            // Getting the Error details.
            console.error("Error retrieving sales data:", error);
          });
      }
    };
  
  
    useEffect(() => {
      // call function to retrieve data from api
      fetchPaymentReceived();
    }, [business]);

  return (
    <View style={{ flex: 1, backgroundColor: colors.background }}>
      <View style={{ flex: 1, padding: 10 }}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text
            variant="titleLarge"
            style={{ color: colors.text, flex: 2, fontWeight: "bold" }}
          >
            Payments Received
          </Text>

        </View>

        <View
          style={{
            flex: 1,
            marginTop: 10,
            borderRadius: 10,
            padding: 10,
            backgroundColor: colors.card,
          }}
        >
          {/* table sort array */}
          <View style={styles.tableSortArray}>
            {tableSortArray.map((item, index) => {
              const isSelected = item === tableSortActiveIndex;

              const successIconColor = "#165e3d";
              const pendingIconColor = "#b5850b";
              const declineIconColor = "#b83131";
              const createIconColor = "#3d42ad";

              let iconName;
              let iconColor;

              if (item === "success") {
                iconColor = successIconColor;
                iconName = "check-circle";
              } else if (item === "abandoned") {
                iconColor = declineIconColor;
                iconName = "minus-circle";
              } else if (item === "Pending") {
                iconName = "clock-time-three";
                iconColor = pendingIconColor;
              } else if (item === "Create") {
                iconColor = createIconColor;
                iconName = "flag";
              }

              return (
                <TouchableOpacity key={index} onPress={() => tableSort(item)}>
                  <View
                    style={{
                      ...styles.tableSubStatusText,
                      borderColor: isSelected ? iconColor : "transparent",
                      backgroundColor: colors.background,
                    }}
                  >
                    <Icon source={iconName} size={18} color={iconColor} />
                    <Text
                      variant="bodySmall"
                      style={{
                        color: iconColor,
                        fontWeight: isSelected ? "bold" : "normal",
                      }}
                    >
                      {item.toLocaleUpperCase()}
                    </Text>
                  </View>
                </TouchableOpacity>
              );
            })}

            <TouchableOpacity
              onPress={clearFilter}
              style={{
                ...styles.tableSubStatusText,
                borderWidth: 1,
                borderColor: colors.border,
              }}
            >
              <Icon source="close" size={18} color={colors.text} />
              <Text
                variant="bodySmall"
                style={{ color: colors.text }}
                selectable={false}
              >
                Clear Filter
              </Text>
            </TouchableOpacity>
          </View>

          {/* search bar, etc */}
          <View style={{ marginTop: 10 }}>
            <Input
              label="Search by invoice Id, customer name"
              onChangeText={(text) => {
                if (text.length > 0) {
                  const replaceText = text.replace(/\s/g, "");

                  const filterData = paymentData.filter((item) => {
                    let found = false;
                    Object.values(item).forEach((value) => {
                      if (
                        typeof value === "string" &&
                        value.toLowerCase().includes(replaceText.toLowerCase())
                      ) {
                        found = true;
                      } else if (
                        !isNaN(Number(replaceText)) &&
                        Number(value) === Number(replaceText)
                      ) {
                        found = true;
                      }
                    });
                    return found;
                  });

                  setModifiedData(filterData);
                } else {
                  setModifiedData(paymentData);
                }
              }}
            />
          </View>

          <PaymentTable
            modifiedData={modifiedData}
            setModifiedData={setModifiedData}
          />
        </View>
      </View>
    </View>
  );
};

export default Payment;
