const light = {
    colors: {
        primary: '#36a4e7',
        background: '#f2f2f2',
        card: '#fff',
        text: '#000',
        border: '#eeeeee',
        blueLight: '#6aa0fd',
        yellow: '#ffc145',
        subText: '#333',
        secondary: '#f2f2f2',
        info: '#36a4e7',
        success: '#01a219',
        danger: '#ca0001',
        warning: '#f67b2f',
    }
}

const dark = {
    colors: {
        primary: '#43b9fe',
        background: '#1e1e1e',
        card: '#000000',
        text: '#fcfcfc',
        subText: '#c4c4c4',
        border: '#444444',
        blueLight: '#6aa0fd',
        yellow: '#ffc145'
    }
}

export {
    light, dark
}