import { useTheme } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { set } from "react-hook-form";
import {
  StyleSheet,
  View,
  TextInput,
  FlatList,
  Image,
  Pressable,
  Modal,
  ScrollView,
  ActivityIndicator,
} from "react-native";
import { Drawer } from "react-native-drawer-layout";
import { TextInput as T, Divider, Icon, Text } from "react-native-paper";

import { InventoryProps } from "../../../GlobalContext/types";
import {
  useFunctions,
  useUserContext,
} from "../../../GlobalContext/useUserContext";
import { CustomColors } from "../../../api/customColors";
import Input from "../../../component/Common/Input/Input";
import CustomButton from "../../../component/Common/button";
import { isWeb } from "../../../component/Common/isWeb";
import CustomDropdown from "../../../component/CustomDropdown/CustomDropdown";
import InventorySidePanel from "../../../component/page/inventory/inventorySidePanel";
import {
  currencyFormatter,
  currencySymbol,
} from "../../../component/util/constants";
import { shadowSet } from "../../../component/util/shadow";

type filters = {
  category: string;
  sorting: "ascending" | "descending";
  minPrice: number;
  maxPrice: number;
};

const Inventory = (props: any) => {
  // side panel
  const [selectedItem, setSelectedItem] = useState<InventoryProps | null>(null);
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [fetchAgain, setFetchAgain] = useState(false);

  const reLoad = props.route?.params?.from === "addInventoryScreen";

  const { colors }: { colors: CustomColors } = useTheme();

  const [filteredData, setFilteredData] = useState<InventoryProps[]>([]); // filtered data
  const [totalInventories, setTotalInventories] = useState<InventoryProps[]>(
    []
  );
  const [categories, setCategories] = useState<string[]>([]);
  const [gridSelection, setGridSelection] = useState<0 | 1>(0);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [subHeaderHeight, setSubHeaderHeight] = useState(0);

  const [showAddProductModal, setShowAddProductModal] = useState(false);

  const { business } = useUserContext();

  const [filters, setFilters] = useState<filters>({
    category: "",
    sorting: "ascending",
    minPrice: 0,
    maxPrice: 99999,
  });

  const fetchInventoryData = async () => {
    setIsLoading(true);
    // grab user info from firebase function called fetchUser
    const { id } = business;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const getInventoryData = useFunctions("fetchInventories");
    if (id) {
      await getInventoryData({ businessId: id })
        .then((result: any) => {
          if (result.data && typeof result.data !== "string") {
            setTotalInventories(result.data);
            // console.log("result.data", result.data);
            setFilteredData(result.data);

            const categoriesList: any[] = [];

            result.data.forEach((item: InventoryProps) => {
              if (item.itemCategory) {
                categoriesList.push(item.itemCategory);
              }
            });

            setCategories([...new Set(categoriesList)]);

            // build a react native dropdown list we avoid adding duplicates
            const data: any[] = [];
            result.data.forEach((item: any) => {
              // check if the item name is already in the label key
              if (!data.some((i) => i.label === item.itemName)) {
                data.push({
                  label: item.itemName,
                  value: item.id,
                });
              }
            });
          } else {
            setFilteredData([]);
            setTotalInventories([]);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          // Getting the Error details.
          setIsLoading(false);
          console.error("Error retrieving inventory data:", error);
        });
    }
  };

  // fetch on screen load
  useEffect(() => {
    fetchInventoryData(); // fetch all inventory data
  }, []);

  useEffect(() => {
    fetchInventoryData(); // fetch all inventory data
  }, [fetchAgain, reLoad]);

  useEffect(() => {
    if (totalInventories.length > 0) {
      setIsLoading(false);
    }
  }, [totalInventories, filteredData]);

  const nextScreen = () => {
    setShowAddProductModal(false);
    props.navigation.navigate("AddInventoryItem");
  };

  const resetFilter = () => {
    setFilteredData(totalInventories);
    setFilters({
      category: "",
      sorting: "ascending",
      maxPrice: 9999,
      minPrice: 0,
    });
  };

  const formatData = (data: InventoryProps[], numColumns: number) => {
    const numberOfFullRows = Math.floor(data.length / numColumns);
    let numberOfElementsLastRow = data.length - numberOfFullRows * numColumns;
    while (
      numberOfElementsLastRow !== numColumns &&
      numberOfElementsLastRow !== 0
    ) {
      data.push({ empty: true } as any);
      numberOfElementsLastRow++;
    }

    return data;
  };

  if (isLoading)
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator color={colors.primary} size="large" />
      </View>
    );

  return (
    <View style={{ flex: 1, backgroundColor: colors.background, padding: 10 }}>
      {/* top bar */}
      <View
        onLayout={(e) => setSubHeaderHeight(e.nativeEvent.layout.height)}
        style={{ flexDirection: "row", gap: 10, alignItems: "center" }}
      >
        {/* Title */}
        <Text
          variant="titleLarge"
          style={{ color: colors.text, fontWeight: "bold" }}
        >
          Inventory
        </Text>

        {/* total products */}
        <View
          style={{
            backgroundColor: colors.card,
            borderRadius: 100,
            transform: [{ scale: 0.8 }],
            height: "100%",
            justifyContent: "flex-end",
            borderWidth: StyleSheet.hairlineWidth,
            borderColor: colors.border,
            flexDirection: "row",
            gap: 5,
            padding: 10,
            paddingHorizontal: 10,
            alignItems: "flex-end",
          }}
        >
          <Text
            variant="labelMedium"
            style={{ color: colors.text, fontWeight: "bold" }}
          >
            {totalInventories.length}
          </Text>
          <Text
            variant="labelSmall"
            style={{ color: colors.text, opacity: 0.8 }}
          >
            Total Products
          </Text>
        </View>

        <View
          style={{
            flex: 1,
            flexDirection: "row",
            gap: 10,
            backgroundColor: colors.card,
            borderWidth: StyleSheet.hairlineWidth,
            borderColor: colors.border,
            borderRadius: 10,
            paddingHorizontal: 10,
            paddingVertical: 5,
            height: "100%",
            alignItems: "center",
          }}
        >
          <Icon source="magnify" size={18} color={colors.text} />
          <TextInput
            placeholder="Search Product..."
            placeholderTextColor={colors.subText}
            onChangeText={(text) => {
              const tmp = totalInventories.filter((item) => {
                if (
                  item.itemName
                    .toLowerCase()
                    .trim()
                    .includes(text.toLowerCase().trim())
                ) {
                  return item;
                } else if (
                  item.itemCategory
                    .toLowerCase()
                    .trim()
                    .includes(text.toLowerCase().trim())
                ) {
                  return item;
                }
              });
              setFilteredData(tmp);
            }}
            style={{
              backgroundColor: colors.card,
              flex: 1,
              paddingHorizontal: 10,
              color: colors.text,
              height: "100%",
            }}
          />

          <Divider
            style={{ backgroundColor: colors.border, height: "100%", width: 1 }}
          />

          <CustomButton
            icon="barcode-scan"
            iconColor={colors.text}
            iconSize={18}
            onPress={() => {}}
            value="Scan"
            viewStyle={{
              backgroundColor: colors.card,
              borderRadius: 10,
              gap: 5,
            }}
            textStyle={{ color: colors.text, fontWeight: "bold" }}
          />
        </View>

        <View
          style={{
            flexDirection: "row",
            borderRadius: 10,
            height: "100%",
            alignItems: "center",
            backgroundColor: colors.card,
          }}
        >
          <CustomButton
            icon="format-list-bulleted"
            onPress={() => setGridSelection(0)}
            iconColor={gridSelection === 0 ? colors.primary : colors.border}
            iconSize={24}
            viewStyle={{
              backgroundColor: colors.card,
              borderRadius: 10,
              paddingVertical: 0,
              height: "100%",
              borderWidth: gridSelection === 0 ? 1 : 0,
              borderColor: gridSelection === 0 ? colors.primary : "transparent",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
          <CustomButton
            icon="dots-grid"
            onPress={() => setGridSelection(1)}
            iconColor={gridSelection === 1 ? colors.primary : colors.border}
            iconSize={24}
            viewStyle={{
              backgroundColor: colors.card,
              borderRadius: 10,
              borderWidth: gridSelection === 1 ? 1 : 0,
              borderColor: gridSelection === 1 ? colors.primary : "transparent",
              paddingVertical: 0,
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        </View>

        <CustomButton
          value="Add Product"
          textVariant="titleSmall"
          onPress={nextScreen}
          viewStyle={{
            backgroundColor: colors.primary,
            borderRadius: 10,
            paddingVertical: 0,
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
          textStyle={{ color: "black", fontWeight: "bold" }}
        />
      </View>

      <Drawer
        open={showSidePanel}
        onOpen={() => setShowSidePanel(true)}
        onClose={() => setShowSidePanel(false)}
        drawerPosition="right"
        drawerType="front"
        swipeEnabled={false}
        drawerStyle={{
          right: 0,
          width: "70%",
          marginVertical: 10,
          borderTopLeftRadius: 30,
          borderBottomLeftRadius: 30,
        }}
        renderDrawerContent={() => {
          if (!showSidePanel) {
            return;
          }

          return (
            <InventorySidePanel
              colors={colors}
              fetchAgain={fetchAgain}
              setFilteredData={setFilteredData}
              setFetchAgain={setFetchAgain}
              setShowSidePanel={setShowSidePanel}
              selectedItem={selectedItem as InventoryProps}
            />
          );
        }}
      >
        <View style={{ flex: 1, flexDirection: "row", gap: 10, marginTop: 10 }}>
          {/* Filter Side */}
          <View style={{ flex: 1 }}>
            <ScrollView
              style={{ backgroundColor: colors.card, borderRadius: 10 }}
              contentContainerStyle={{ padding: 10 }}
            >
              {/* sort by */}
              <View style={{ zIndex: 9999 }}>
                <View style={{ marginVertical: 10 }}>
                  <Text
                    variant="titleSmall"
                    style={{
                      color: colors.text,
                      opacity: 0.5,
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                  >
                    Sort By
                  </Text>
                </View>

                <View>
                  <CustomDropdown
                    placeholder="Sort"
                    value={filters.sorting}
                    setValue={(t: () => "ascending" | "descending") => {
                      const text = t();

                      setFilters((pre) => ({ ...pre, sorting: text }));

                      let tmp;

                      if (text === "ascending") {
                        tmp = totalInventories.sort((a, b) =>
                          a.itemName.localeCompare(b.itemName)
                        );
                      } else {
                        tmp = totalInventories.sort((a, b) =>
                          b.itemName.localeCompare(a.itemName)
                        );
                      }

                      setFilteredData(tmp);
                    }}
                    arrowStyle={{ backgroundColor: colors.card }}
                    data={[
                      { label: "Ascending", value: "ascending" },
                      { label: "Descending", value: "descending" },
                    ]}
                  />
                </View>
              </View>

              {/* category */}
              <View style={{ zIndex: 999 }}>
                <View style={{ marginVertical: 10 }}>
                  <Text
                    variant="titleSmall"
                    style={{
                      color: colors.text,
                      opacity: 0.5,
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                  >
                    Category
                  </Text>
                </View>

                <View>
                  <CustomDropdown
                    placeholder="All Product"
                    value={filters.category}
                    setValue={(t: () => string) => {
                      const text = t();

                      setFilters((pre) => ({ ...pre, category: text }));

                      const tmp = totalInventories.filter((item) => {
                        if (
                          item.itemCategory
                            .toLowerCase()
                            .trim()
                            .includes(text.toLowerCase().trim())
                        ) {
                          return item;
                        }
                      });

                      setFilteredData(tmp);
                    }}
                    arrowStyle={{ backgroundColor: colors.card }}
                    data={categories.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                  />
                </View>
              </View>

              {/* price */}
              <View>
                <View style={{ marginVertical: 10 }}>
                  <Text
                    variant="titleSmall"
                    style={{
                      color: colors.text,
                      opacity: 0.5,
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                  >
                    Price
                  </Text>
                </View>

                <View>
                  <Input
                    label="Minimum price"
                    left={
                      <T.Affix
                        text={currencySymbol}
                        textStyle={{ color: colors.text }}
                      />
                    }
                    onChangeText={(t) => {
                      if (t.length === 0) {
                        return setFilters((pre) => ({ ...pre, minPrice: 0 }));
                      }
                      setFilters((pre) => ({ ...pre, minPrice: Number(t) }));
                    }}
                  />
                  <Input
                    label="Maximum price"
                    left={
                      <T.Affix
                        text={currencySymbol}
                        textStyle={{ color: colors.text }}
                      />
                    }
                    onChangeText={(t) => {
                      if (t.length === 0) {
                        return setFilters((pre) => ({
                          ...pre,
                          maxPrice: 99999,
                        }));
                      }
                      setFilters((pre) => ({ ...pre, maxPrice: Number(t) }));
                    }}
                  />
                </View>

                <CustomButton
                  value="Filter Price"
                  onPress={() => {
                    const tmp = totalInventories.filter(
                      (item) =>
                        Number(item.itemRetailPrice ?? 0) >= filters.minPrice &&
                        Number(item.itemRetailPrice ?? 0) <= filters.maxPrice
                    );
                    setFilteredData(tmp);
                  }}
                  viewStyle={{
                    paddingVertical: 20,
                    paddingHorizontal: 20,
                    borderRadius: 10,
                    marginTop: 10,
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 10,
                  }}
                  textStyle={{
                    fontSize: 15,
                  }}
                />
              </View>

              <CustomButton
                value="Reset Filters"
                onPress={resetFilter}
                icon="restore"
                iconColor={colors.text}
                iconSize={18}
                viewStyle={{
                  paddingVertical: 20,
                  paddingHorizontal: 20,
                  borderRadius: 10,
                  marginTop: 10,
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 10,
                }}
                textStyle={{
                  fontSize: 15,
                }}
              />
            </ScrollView>
          </View>

          {/* All data */}
          <View style={{ flex: 3 }}>
            {filteredData.length === 0 ? (
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text style={{ color: "black" }}>No data found</Text>
              </View>
            ) : (
              <FlatList
                data={formatData(filteredData, gridSelection === 1 ? 3 : 1)}
                key={gridSelection}
                style={{ borderRadius: 10 }}
                numColumns={gridSelection === 1 ? 3 : 1}
                columnWrapperStyle={
                  gridSelection === 1
                    ? {
                        gap: 10,
                        marginBottom: 10,
                      }
                    : null
                }
                renderItem={({ item, index }) => {
                  if (item?.empty) {
                    return <View style={{ flex: 1, padding: 10 }} />;
                  }

                  return (
                    <Pressable
                      onPress={() => {
                        setShowSidePanel(true);
                        setSelectedItem(item as any);
                      }}
                      style={({ pressed }) => ({
                        flex: 1,
                        padding: 10,
                        opacity: pressed ? 0.5 : 1,
                        flexDirection: "row",
                        gap: 10,
                        borderRadius: 10,
                        backgroundColor: colors.card,
                        marginTop: gridSelection === 0 ? 10 : 0,
                      })}
                    >
                      {gridSelection === 0 ? (
                        <>
                          <View
                            style={{
                              backgroundColor: colors.background,
                              borderRadius: 10,
                              overflow: "hidden",
                              width: 80,
                              height: 80,
                            }}
                          >
                            <Image
                              source={{
                                uri:
                                  item.itemImage?.length > 0
                                    ? item.itemImage[0].uri
                                    : "",
                              }}
                              style={{
                                resizeMode: "cover",
                                width: 80,
                                height: 80,
                              }}
                            />
                          </View>

                          <View style={{ flex: 1 }}>
                            <View style={{ flex: 1, justifyContent: "center" }}>
                              <Text
                                variant="titleSmall"
                                style={{
                                  color: colors.text,
                                  fontWeight: "bold",
                                }}
                                ellipsizeMode="tail"
                                numberOfLines={1}
                              >
                                {item.itemName}
                              </Text>
                            </View>

                            <View
                              style={{
                                flex: 1,
                                flexDirection: "row",
                                gap: 5,
                                alignItems: "center",
                                overflow: "hidden",
                              }}
                            >
                              <View
                                style={{
                                  backgroundColor: colors.primary,
                                  paddingHorizontal: 5,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: 5,
                                }}
                              >
                                <Text
                                  variant="labelSmall"
                                  style={{ color: "black" }}
                                >
                                  {item.itemHasaVariant
                                    ? "Has"
                                    : "Does not have a"}{" "}
                                  variants
                                </Text>
                              </View>
                              <Icon
                                source="circle-small"
                                size={24}
                                color={colors.subText}
                              />
                              <Text
                                adjustsFontSizeToFit
                                style={{ color: colors.subText }}
                              >
                                {item.itemMetric || ""}
                              </Text>
                              <Icon
                                source="circle-small"
                                size={24}
                                color={colors.subText}
                              />
                              <Text
                                adjustsFontSizeToFit
                                style={{ color: colors.subText }}
                              >
                                {item.itemCategory || "Uncategorized"}
                              </Text>
                            </View>
                          </View>

                          <Divider
                            style={{
                              backgroundColor: colors.border,
                              height: "100%",
                              width: 1,
                            }}
                          />

                          <View
                            style={{
                              flex: 1,
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-around",
                            }}
                          >
                            <View
                              style={{
                                justifyContent: "space-evenly",
                                alignItems: "center",
                              }}
                            >
                              <Text
                                style={{ color: colors.subText, fontSize: 10 }}
                              >
                                RETAIL PRICE
                              </Text>
                              <Text
                                variant="titleMedium"
                                style={{ color: colors.text }}
                              >
                                {currencySymbol}
                                {
                                  // strip the currency symbol
                                  currencyFormatter(
                                    Number(item.itemRetailPrice || 0)
                                  )
                                }
                              </Text>
                            </View>
                            <View
                              style={{
                                justifyContent: "space-evenly",
                                alignItems: "center",
                              }}
                            >
                              <Text
                                style={{ color: colors.subText, fontSize: 10 }}
                              >
                                WHOLESALE PRICE
                              </Text>
                              <Text
                                variant="titleMedium"
                                style={{ color: colors.text }}
                              >
                                {currencySymbol}

                                {currencyFormatter(
                                  Number(item.itemWholesalePrice || 0)
                                )}
                              </Text>
                            </View>
                          </View>
                        </>
                      ) : (
                        <View style={{ flex: 1 }}>
                          <View
                            style={{
                              flex: 1,
                              flexDirection: "row",
                              minHeight: 80,
                              gap: 10,
                            }}
                          >
                            <View
                              style={{
                                backgroundColor: colors.background,
                                borderRadius: 10,
                                overflow: "hidden",
                                width: 80,
                                height: 80,
                              }}
                            >
                              <Image
                                source={{ uri: item.itemImage }}
                                style={{
                                  resizeMode: "cover",
                                  width: 80,
                                  height: 80,
                                }}
                              />
                            </View>

                            <View
                              style={{
                                flex: 1,
                                justifyContent: "space-evenly",
                              }}
                            >
                              <Text
                                variant="titleSmall"
                                ellipsizeMode="tail"
                                style={{
                                  color: colors.text,
                                  fontWeight: "bold",
                                  width: "100%",
                                }}
                                numberOfLines={1}
                              >
                                {item.itemName}
                              </Text>

                              <View
                                style={{
                                  backgroundColor: colors.primary,
                                  paddingHorizontal: 5,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  alignSelf: "flex-start",
                                  borderRadius: 5,
                                }}
                              >
                                <Text
                                  variant="labelSmall"
                                  style={{ color: "black" }}
                                >
                                  {item.itemHasaVariant
                                    ? "Has"
                                    : "Does not have a"}{" "}
                                  variants
                                </Text>
                              </View>

                              <View
                                style={{
                                  flexDirection: "row",
                                  gap: 3,
                                  width: "100%",
                                }}
                              >
                                <Text
                                  adjustsFontSizeToFit
                                  style={{ color: colors.subText }}
                                >
                                  {item.itemCategory || "Uncategorized"}
                                </Text>
                                <Icon
                                  source="circle-small"
                                  size={15}
                                  color={colors.subText}
                                />
                                <Text
                                  adjustsFontSizeToFit
                                  style={{ color: colors.subText }}
                                >
                                  {item.itemCategory || "Uncategorized"}
                                </Text>
                              </View>
                            </View>
                          </View>

                          <View
                            style={{
                              flex: 1,
                              flexDirection: "row",
                              marginTop: 10,
                            }}
                          >
                            <View style={{ flex: 1 }}>
                              <Text
                                variant="labelLarge"
                                style={{ color: colors.text }}
                              >
                                <Text
                                  style={{ color: colors.text, opacity: 0.8 }}
                                >
                                  RETAIL PRICE:
                                </Text>{" "}
                                {currencyFormatter(
                                  Number(item.itemRetailPrice || 0)
                                )}
                              </Text>
                              <Text
                                variant="labelLarge"
                                style={{ color: colors.text }}
                              >
                                <Text
                                  style={{ color: colors.text, opacity: 0.8 }}
                                >
                                  WHOLESALE PRICE:
                                </Text>{" "}
                                {currencyFormatter(
                                  Number(item.itemWholesalePrice || 0)
                                )}
                              </Text>
                            </View>

                            <CustomButton
                              icon="dots-horizontal"
                              iconColor={colors.text}
                              iconSize={24}
                              onPress={() => {}}
                              viewStyle={{
                                backgroundColor: colors.background,
                                borderRadius: 10,
                                justifyContent: "center",
                                alignItems: "center",
                                alignSelf: "flex-end",
                              }}
                              textStyle={{ color: "black", fontWeight: "bold" }}
                            />
                          </View>
                        </View>
                      )}
                    </Pressable>
                  );
                }}
              />
            )}
          </View>
        </View>

        <Modal
          visible={showAddProductModal}
          statusBarTranslucent
          removeClippedSubviews
          animationType="fade"
          transparent
        >
          <Pressable
            style={{ flex: 1, backgroundColor: "#00000030" }}
            onPress={() => setShowAddProductModal(false)}
          >
            <View
              style={{
                ...shadowSet,
                flex: 1,
                backgroundColor: colors.background,
                width: 350,
                borderRadius: 10,
                position: "absolute",
                top: (isWeb ? 70 : 100) + subHeaderHeight + 10,
                right: 10,
                overflow: "hidden",
              }}
            >
              <Pressable
                onPress={nextScreen}
                style={({ pressed }) => ({
                  padding: 20,
                  backgroundColor: pressed ? colors.card : "transparent",
                })}
              >
                <View style={{ flexDirection: "row", gap: 10 }}>
                  <Icon
                    source="package-variant"
                    size={18}
                    color={colors.subText}
                  />
                  <Text
                    variant="labelLarge"
                    style={{ color: colors.text, fontWeight: "bold" }}
                  >
                    Stocked Product
                  </Text>
                </View>
                <Text variant="labelSmall" style={{ color: colors.subText }}>
                  Physical object whose quantity should be tracked
                </Text>
              </Pressable>

              <Divider style={{ backgroundColor: colors.border }} />

              <Pressable
                onPress={nextScreen}
                style={({ pressed }) => ({
                  padding: 20,
                  backgroundColor: pressed ? colors.card : "transparent",
                })}
              >
                <View style={{ flexDirection: "row", gap: 10 }}>
                  <Icon source="barcode" size={18} color={colors.subText} />
                  <Text
                    variant="labelLarge"
                    style={{ color: colors.text, fontWeight: "bold" }}
                  >
                    Serialized Product
                  </Text>
                </View>
                <Text variant="labelSmall" style={{ color: colors.subText }}>
                  Physical object whose quantity should be tracked
                </Text>
              </Pressable>

              <Divider style={{ backgroundColor: colors.border }} />

              <Pressable
                onPress={nextScreen}
                style={({ pressed }) => ({
                  padding: 20,
                  backgroundColor: pressed ? colors.card : "transparent",
                })}
              >
                <View style={{ flexDirection: "row", gap: 10 }}>
                  <Icon
                    source="rotate-orbit"
                    size={18}
                    color={colors.subText}
                  />
                  <Text
                    variant="labelLarge"
                    style={{ color: colors.text, fontWeight: "bold" }}
                  >
                    Consumable
                  </Text>
                </View>
                <Text variant="labelSmall" style={{ color: colors.subText }}>
                  Physical object whose quantity should be tracked
                </Text>
              </Pressable>

              <Divider style={{ backgroundColor: colors.border }} />

              <Pressable
                onPress={nextScreen}
                style={({ pressed }) => ({
                  padding: 20,
                  backgroundColor: pressed ? colors.card : "transparent",
                })}
              >
                <View style={{ flexDirection: "row", gap: 10 }}>
                  <Icon source="cog-sync" size={18} color={colors.subText} />
                  <Text
                    variant="labelLarge"
                    style={{ color: colors.text, fontWeight: "bold" }}
                  >
                    Service
                  </Text>
                </View>
                <Text variant="labelSmall" style={{ color: colors.subText }}>
                  Physical object whose quantity should be tracked
                </Text>
              </Pressable>
            </View>
          </Pressable>
        </Modal>

        {/* Side panel */}

        {/* {selectedItem && (
          <SidePanel
            AnimatedValue={x}
            height={sidePanelHeight}
            width={sidePanelWidth * 0.9}
            style={{
              backgroundColor: colors.card,
              borderWidth: 2,
              borderRightWidth: 0,
              borderColor: colors.border,
              top: subHeaderHeight + 20,
            }}
          >
            <InventorySidePanel
              animatedValue={x}
              colors={colors}
              selectedItem={selectedItem}
              sidePanelWidth={sidePanelWidth}
            />
          </SidePanel>
        )} */}
      </Drawer>
    </View>
  );
};

export default Inventory;
