import {
  DrawerItemList,
  createDrawerNavigator,
} from "@react-navigation/drawer";
import { NavigationContainer, useTheme } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { StatusBar } from "expo-status-bar";
import React, { useEffect } from "react";
import {
  Dimensions,
  Modal,
  Platform,
  Pressable,
  StyleSheet,
  View,
  useWindowDimensions
} from "react-native";
import { Icon, Avatar, Text, Button } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import InventoryStack from "./InventoryStack";
import OnBoardingStack from "./OnboardingStack";
import PaymentStack from "./PaymentStack";
import PersonalStack from "./PersonalStack";
import SaleStack from "./SaleStack";
import { useUserContext } from "../GlobalContext/useUserContext";
import HeaderComponent from "../component/Common/Header";
import { isWeb } from "../component/Common/isWeb";
import { LoginOrSignUpForm } from "../component/form/loginorSignupForm";
import { light } from "../component/util/colors";
import { w } from "../component/util/dimension";
import { shadowSet } from "../component/util/shadow";
import { auth } from "../firebase/firebaseConfig";
import dashboard from "../src/screens/Dashboard";
// import PaymentReceived from "../src/screens/PaymentReceived";
// import ReportGenerator from "../src/screens/ReportGenerator";
// import Reports from "../src/screens/Reports";
import Settings from "../src/screens/Settings";
import Trips from "../src/screens/Trips";

const Drawer = createDrawerNavigator();

const Stack = createNativeStackNavigator();

const DrawerNavigator = () => {

  const { business, user } = useUserContext();

  const { userrole } = user;

  const { colors } = useTheme();

  const [showText, setShowText] = React.useState(false);

  const [dw, setDw] = React.useState(w);

  const dimensions = useWindowDimensions();

  const isLargeScreen = dimensions.width >= 1000;

  const isDesktop = Platform.OS === "web";

  const isOnboardingAccess = userrole.includes("onboarding");

  useEffect(() => {
    Dimensions.addEventListener("change", ({ window }) => {
      setDw(window.width);
    });
  }, []);

  return (
    <Drawer.Navigator
      screenOptions={(props) => ({
        drawerLabelStyle: {
          fontSize: 18, // Adjust the font size as desired
        },
        drawerType: isLargeScreen
          ? "permanent"
          : !isDesktop
            ? "permanent"
            : "front",
        headerShown: false,
        drawerLabel: showText ? undefined : "",
        drawerStyle: {
          width: showText ? (isWeb ? "auto" : "20%") : 60,
        },
        drawerActiveTintColor: colors.primary,
        drawerInactiveTintColor: colors.subText,
        drawerIcon: ({ color, focused, size }) => {

          let iconName;

          if (props.route.name === "Dashboard")
            iconName = focused ? "view-dashboard" : "view-dashboard-outline";
          else if (props.route.name === "Sales")
            iconName = focused ? "point-of-sale" : "point-of-sale";
          else if (props.route.name === "Payment")
            iconName = focused ? "account-cash" : "account-cash-outline";
          else if (props.route.name === "Reports")
            iconName = focused ? "bug" : "bug-outline";
          else if (props.route.name === "Inventory")
            iconName = focused ? "dropbox" : "dropbox";
          else if (props.route.name === "Trips")
            iconName = focused ? "map-marker" : "map-marker-outline";
          else if (props.route.name === "Human Resources")
            iconName = focused ? "account" : "account-outline";
          else if (props.route.name === "Settings")
            iconName = focused ? "cog" : "cog-outline";
          else if (props.route.name === "OnBoarding")
            iconName = focused ? "account-plus" : "account-plus-outline";
          else if (props.route.name === "ReportGenerator")
            iconName = focused ? "text-box" : "text-box-outline";
          else if (props.route.name === "PaymentReceived")
            iconName = focused ? "contactless-payment-circle" : "contactless-payment-circle-outline";

          return (
            <Icon
              source={iconName}
              size={24}
              color={focused ? colors.primary : colors.subText}
            />
          );
        },
      })}
      drawerContent={(props) => (
        <View style={{ flex: 1 }}>
          <DrawerItemList {...props} />

          <View
            style={{ flex: 1, justifyContent: "flex-end", paddingBottom: 20 }}
          >
            <Pressable
              onPress={() => setShowText(!showText)}
              style={({ pressed }) => ({
                opacity: pressed ? 0.5 : 1,
                justifyContent: "center",
                alignItems: showText ? "flex-end" : "center",
                paddingRight: showText ? 10 : 0,
              })}
            >
              <Icon
                source={!showText ? "arrow-expand-right" : "arrow-expand-left"}
                size={20}
                color={colors.subText}
              />
            </Pressable>
          </View>
        </View>
      )}
    >
      <Drawer.Screen name="Dashboard" component={dashboard} />
      <Drawer.Screen name="Sales" component={SaleStack} />
      {/* <Drawer.Screen name="PaymentReceived" component={PaymentReceived} /> */}

      {business?.businessProductTier !== 'Silver' &&
        <Drawer.Screen name="Inventory" component={InventoryStack} />
      }

      {/* <Drawer.Screen name="ReportGenerator" component={ReportGenerator} /> */}

      {/* {business?.businessProductTier === 'Premium' && */}
        <Drawer.Group>
          <Drawer.Screen name="Trips" component={Trips} />
          <Drawer.Screen name="Human Resources" component={PersonalStack} />
        </Drawer.Group>
      {/* } */}

      <Drawer.Screen name="Payment" component={PaymentStack} />
      {/* <Drawer.Screen name="Reports" component={Reports} /> */}

      {isOnboardingAccess ?
      <Drawer.Screen name="OnBoarding" component={OnBoardingStack} /> : null
      }
      
      <Drawer.Screen name="Settings" component={Settings} />
    </Drawer.Navigator>
  );
};

const AppNavigator = () => {
  const { user } = useUserContext();

  const [showDialog, setShowDialog] = React.useState(false);

  const [hamburgerHeight, setHamburgerHeight] = React.useState(0);

  const { colors } = light;

  const statusBarHeight = useSafeAreaInsets();

  //firebaser user signout
  const handleSignOut = async () => {
    try {
      // Additional actions after sign out (e.g., navigate to a different screen)
      auth.signOut().then(() => {
        setShowDialog(false);
      })
    } catch (error) {
      console.log("Error signing out:", error);
    }
  };

  return (
    <NavigationContainer theme={light}>
      <StatusBar style="auto" />

      {user ? (
        <HeaderComponent
          setShowDialog={setShowDialog}
          setHamburgerHeight={setHamburgerHeight}
        />
      ) : null}

      <Stack.Navigator screenOptions={{ headerShown: false }}>
        {user ? (
          <Stack.Screen name="DrawerNavigator" component={DrawerNavigator} />
        ) : (
          <Stack.Screen
            name="LoginOrSignUpForm"
            component={LoginOrSignUpForm}
          />
        )}
      </Stack.Navigator>

      <Modal
        visible={showDialog}
        transparent
        animationType="fade"
        statusBarTranslucent
        style={StyleSheet.absoluteFill}

      >
        <Pressable
          onPress={() => setShowDialog(false)}
          style={{
            flex: 1,
            backgroundColor: "#00000030",
          }}
        >
          <View
            style={{
              ...shadowSet,
              position: "absolute",
              backgroundColor: colors.card,
              width: 400,
              padding: 10,
              borderWidth: 1,
              borderColor: colors.border,
              borderRadius: 10,
              top: hamburgerHeight + statusBarHeight.top + 10,
              right: 10,
            }}
          >
            <View
              style={{
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Avatar.Image
                  size={50}
                  source={require("../assets/icons/avatar.png")}
                />
              </View>
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text variant="bodyLarge" style={{ color: colors.text }}>
                  {user ? user.userName : "User Name"}
                </Text>
              </View>
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text style={{ color: colors.text }}>
                  {user ? user.userEmail : "User Email"}
                </Text>
              </View>
              <View>
                <Button
                  icon="plus"
                  mode="contained"
                  onPress={() => {
                    //signOut from firebase and app
                    handleSignOut();
                  }}
                  theme={{ colors: { primary: colors.primary } }}
                  textColor="black"
                  style={{ marginRight: 10 }}
                >
                  Sign Out
                </Button>
              </View>

              <View>
                <Button onPress={() => setShowDialog(false)} title="close" />
              </View>
            </View>
          </View>
        </Pressable>
      </Modal>
    </NavigationContainer>
  );
};

export default AppNavigator;