import { useTheme } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { View, Pressable, ActivityIndicator, StyleSheet, ScrollView } from 'react-native'
import { Text, TextInput } from 'react-native-paper'

import { employeesProps } from '../../../GlobalContext/types'
import { useFunctions, useUserContext } from '../../../GlobalContext/useUserContext'
import { CustomColors } from '../../../api/customColors'
import CustomButton from '../../Common/button'

const Input = ({
    title,
    editMode,
    onChangeText,
    value,
    setState,
    isBoolean,
    isBooleanObject,
    ...props
}: {
    title?: string;
    editMode?: boolean;
    value?: string;
    onChangeText: string;
    isBoolean?: boolean;
    isBooleanObject?: (onPress: (text: string | boolean) => void) => React.ReactNode;
    setState: React.Dispatch<React.SetStateAction<employeesProps>>;
} & {
    info?: boolean;
    details?: boolean;
    salary?: boolean;
    benefits?: boolean;
    emergencyContact?: boolean;
    leave?: boolean;
    timeSheet?: boolean;
    terminationDetails?: boolean;
}) => {

    const { colors }: { colors: CustomColors } = useTheme();

    const styles = StyleSheet.create({
        inputTitle: {
            fontWeight: "bold",
            color: colors.text,
            marginVertical: 10
        },
        inputStyle: {
            borderRadius: 10,
            // width: 500,
            color: colors.text,
            borderColor: colors.border,
            borderWidth: editMode ? 2 : 0,
        }
    })

    const onPress = (text: string | boolean) => {
        setState(pre => ({
            ...pre,

            employeeInfo: props?.info ? {
                ...pre.employeeInfo,
                [onChangeText]: text
            } : pre.employeeInfo,

            employmentDetails: props?.details ? {
                ...pre.employmentDetails,
                [onChangeText]: text
            } : pre.employmentDetails,

            employeeSalary: props?.salary ? {
                ...pre.employeeSalary,
                [onChangeText]: text
            } : pre.employeeSalary,

            employeeBenefits: props?.benefits ? {
                ...pre.employeeBenefits,
                [onChangeText]: text
            } : pre.employeeBenefits,

            employeeEmergencyContact: props?.emergencyContact ? {
                ...pre.employeeEmergencyContact,
                [onChangeText]: text
            } : pre.employeeEmergencyContact,

            employeeLeave: props?.leave ? {
                ...pre.employeeLeave,
                [onChangeText]: text
            } : pre.employeeLeave,

            employeeTimesheet: props?.timeSheet ? {
                ...pre.employeeTimesheet,
                [onChangeText]: text
            } : pre.employeeTimesheet,

            employeeterminationDetails: props?.terminationDetails ? {
                ...pre.employeeterminationDetails,
                [onChangeText]: text
            } : pre.employeeterminationDetails,

            // employeeDocuments: props?.documents ? {
            //     ...pre.employeeDocuments,
            //     [onChangeText]: text
            // } : pre.employeeDocuments,
        }))
    }


    if (isBoolean) {
        return (
            <View style={{ width: '49%' }}>
                <Text style={styles.inputTitle}>{title}</Text>
                {isBooleanObject && isBooleanObject(onPress)}
            </View>
        )
    }

    return (
        <View style={{ width: '49%' }}>
            <Text style={styles.inputTitle}>{title}</Text>
            <TextInput
                value={value}
                editable={editMode}
                onChangeText={onPress}
                underlineColor='transparent'
                contentStyle={styles.inputStyle}
                activeUnderlineColor='transparent'
                style={{ backgroundColor: "transparent" }}
            />
        </View>
    )
}

type adminSidePanelProps = {
    selectedData: employeesProps;
    setShowSidePanel: React.Dispatch<React.SetStateAction<boolean>>;
}

const AdminSidePanel = ({ selectedData, setShowSidePanel }: adminSidePanelProps) => {
    console.log({ selectedData })
    const { business, user } = useUserContext();

    const { colors }: { colors: CustomColors } = useTheme();
    const [editMode, setEditMode] = useState(false)

    const [updatedData, setUpdatedData] = useState<employeesProps>(selectedData)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setUpdatedData(selectedData)
    }, [selectedData])


    const handleUpdateData = async () => {
        setIsLoading(true);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const update = useFunctions('updateEmployee')
        await update({
            businessId: business.id,
            employeeId: user.employeeId,
            employee: updatedData
        })
            .then(res => {
                console.log('done, ', res.data)
                setIsLoading(false);
                setShowSidePanel(false);
            })
            .catch(err => {
                console.log('error updating employee: ', err.message)
                setIsLoading(false);
            });
    }

    const styles = StyleSheet.create({
        title: {
            color: colors.text, fontWeight: 'bold', marginVertical: 15
        }
    })

    return (
        <View style={{ flex: 1, padding: 20, }}>

            <View style={{ flexDirection: 'row', gap: 10, alignItems: 'center', justifyContent: 'flex-end', }}>
                <Pressable
                    onPress={() => setEditMode(!editMode)}
                    style={({ pressed }) => ({ flexDirection: 'row', gap: 10, backgroundColor: pressed ? colors.card : colors.background, alignSelf: 'flex-end', padding: 10, paddingHorizontal: 20, borderRadius: 100 })}>
                    <Text>{!editMode ? 'Edit' : 'Cancel'}</Text>
                </Pressable>

                {editMode && <Pressable
                    onPress={handleUpdateData}
                    style={({ pressed }) => ({ flexDirection: 'row', gap: 10, justifyContent: 'center', backgroundColor: pressed ? colors.card : colors.background, alignSelf: 'flex-end', padding: 10, paddingHorizontal: 20, borderRadius: 100 })}>
                    {isLoading && <ActivityIndicator size={15} color={colors.primary} />}
                    <Text>Save</Text>
                </Pressable>}

            </View>

            <ScrollView>

                {/* info */}
                <View>
                    <Text variant='titleLarge' style={styles.title}>Employer Info</Text>
                    <View style={{ flexDirection: 'row', gap: 10, flexWrap: 'wrap' }}>
                        {updatedData && Object.keys(updatedData?.employeeInfo).map((item, index) => {
                            return (
                                <Input
                                    info
                                    key={index}
                                    title={employeeObject.employeeInfo[item as keyof typeof employeeObject.employeeInfo]}
                                    editMode={editMode}
                                    onChangeText={item}
                                    setState={setUpdatedData}
                                    value={updatedData.employeeInfo[item as keyof typeof employeeObject.employeeInfo]}
                                />
                            )
                        })}
                    </View>
                </View>

                {/* details */}
                <View style={{ marginTop: 10 }}>
                    <Text variant='titleLarge' style={styles.title}>Employer Details</Text>
                    <View style={{ flexDirection: 'row', gap: 10, flexWrap: 'wrap' }}>
                        {updatedData && Object.keys(updatedData?.employmentDetails).map((item, index) => {

                            return (
                                <Input
                                    details
                                    key={index}
                                    title={employeeObject.employmentDetails[item as keyof typeof employeeObject.employmentDetails]}
                                    editMode={editMode}
                                    onChangeText={item}
                                    setState={setUpdatedData}
                                    value={updatedData.employmentDetails[item as keyof typeof employeeObject.employmentDetails]}
                                />
                            )
                        })}
                    </View>
                </View>

                {/* salary */}
                <View style={{ marginTop: 10 }}>
                    <Text variant='titleLarge' style={styles.title}>Employer Salary</Text>
                    <View style={{ flexDirection: 'row', gap: 10, flexWrap: 'wrap' }}>
                        {updatedData && Object.keys(updatedData?.employeeSalary).map((item, index) => {
                            return (
                                <Input
                                    salary
                                    key={index}
                                    title={employeeObject.employeeSalary[item as keyof typeof employeeObject.employeeSalary]}
                                    editMode={editMode}
                                    onChangeText={item}
                                    setState={setUpdatedData}
                                    value={updatedData.employeeSalary[item as keyof typeof employeeObject.employeeSalary]}
                                />
                            )
                        })}
                    </View>
                </View>

                {/* benefits */}
                <View style={{ marginTop: 10 }}>
                    <Text variant='titleLarge' style={styles.title}>Employer Benefits</Text>
                    <View style={{ flexDirection: 'row', gap: 10, flexWrap: 'wrap' }}>
                        {updatedData && Object.keys(updatedData?.employeeBenefits).map((item, index) => {
                            const isBoolean = typeof updatedData.employeeBenefits[item as keyof typeof employeeObject.employeeBenefits] === 'boolean'

                            const valueCheck = updatedData.employeeBenefits[item as keyof typeof employeeObject.employeeBenefits] as any

                            return (
                                <Input
                                    key={index}
                                    benefits
                                    isBoolean={isBoolean}
                                    isBooleanObject={(onPress) => {
                                        return (
                                            <View style={{ justifyContent: 'space-around', flexDirection: 'row', gap: 10 }}>

                                                <CustomButton
                                                    disabled={!editMode}
                                                    value='True'
                                                    onPress={() => onPress(true)}
                                                    viewStyle={{
                                                        flex: 1,
                                                        justifyContent: 'center',
                                                        backgroundColor: valueCheck === true ? colors.primary : colors.background
                                                    }}
                                                    textStyle={{
                                                        color: valueCheck === true ? 'white' : 'black'
                                                    }}
                                                />
                                                <CustomButton
                                                    disabled={!editMode}
                                                    value='False'
                                                    onPress={() => onPress(false)}
                                                    viewStyle={{
                                                        flex: 1,
                                                        justifyContent: 'center',
                                                        backgroundColor: valueCheck === false ? colors.primary : colors.background
                                                    }}
                                                    textStyle={{
                                                        color: valueCheck === false ? 'white' : 'black'
                                                    }}
                                                />
                                            </View>
                                        )
                                    }}
                                    title={employeeObject.employeeBenefits[item as keyof typeof employeeObject.employeeBenefits]}
                                    onChangeText={item}
                                    setState={setUpdatedData}
                                // value={updatedData.employeeBenefits[item as keyof typeof employeeObject.employeeBenefits]}
                                />
                            )
                        })}
                    </View>
                </View>

                {/* emergencyContact */}
                <View style={{ marginTop: 10 }}>
                    <Text variant='titleLarge' style={styles.title}>Employer Emergency Contact</Text>
                    <View style={{ flexDirection: 'row', gap: 10, flexWrap: 'wrap' }}>
                        {updatedData && Object.keys(updatedData?.employeeEmergencyContact).map((item, index) => {
                            return (
                                <Input
                                    key={index}
                                    emergencyContact
                                    title={employeeObject.employeeEmergencyContact[item as keyof typeof employeeObject.employeeEmergencyContact]}
                                    editMode={editMode}
                                    onChangeText={item}
                                    setState={setUpdatedData}
                                    value={updatedData.employeeEmergencyContact[item as keyof typeof employeeObject.employeeEmergencyContact]}
                                />
                            )
                        })}
                    </View>
                </View>

                {/* leave */}
                <View style={{ marginTop: 10 }}>
                    <Text variant='titleLarge' style={styles.title}>Employer Leave</Text>
                    <View style={{ flexDirection: 'row', gap: 10, flexWrap: 'wrap' }}>
                        {updatedData && Object.keys(updatedData?.employeeLeave).map((item, index) => {
                            return (
                                <Input
                                    leave
                                    key={index}
                                    title={employeeObject.employeeLeave[item as keyof typeof employeeObject.employeeLeave]}
                                    editMode={editMode}
                                    onChangeText={item}
                                    setState={setUpdatedData}
                                    value={updatedData.employeeLeave[item as keyof typeof employeeObject.employeeLeave]}
                                />
                            )
                        })}
                    </View>
                </View>

                {/* timeSheet */}
                <View style={{ marginTop: 10 }}>
                    <Text variant='titleLarge' style={styles.title}>Employer Time Sheet</Text>
                    <View style={{ flexDirection: 'row', gap: 10, flexWrap: 'wrap' }}>
                        {updatedData && Object.keys(updatedData?.employeeTimesheet).map((item, index) => {
                            return (
                                <Input
                                    timeSheet
                                    key={index}
                                    title={employeeObject.employeeTimesheet[item as keyof typeof employeeObject.employeeTimesheet]}
                                    editMode={editMode}
                                    onChangeText={item}
                                    setState={setUpdatedData}
                                    value={updatedData.employeeTimesheet[item as keyof typeof employeeObject.employeeTimesheet]}
                                />
                            )
                        })}
                    </View>
                </View>

                {/* terminationDetails */}
                <View style={{ marginTop: 10 }}>
                    <Text variant='titleLarge' style={styles.title}>Employer Termination Details</Text>
                    <View style={{ flexDirection: 'row', gap: 10, flexWrap: 'wrap' }}>
                        {updatedData && Object.keys(updatedData?.employeeterminationDetails).map((item, index) => {
                            return (
                                <Input
                                    key={index}
                                    terminationDetails
                                    title={employeeObject.employeeterminationDetails[item as keyof typeof employeeObject.employeeterminationDetails]}
                                    editMode={editMode}
                                    onChangeText={item}
                                    setState={setUpdatedData}
                                    value={updatedData.employeeterminationDetails[item as keyof typeof employeeObject.employeeterminationDetails]}
                                />
                            )
                        })}
                    </View>
                </View>

            </ScrollView>

        </View>
    )
}

export default AdminSidePanel



let employeeObject = {
    employeeInfo: {
        employeeID: 'Employee ID',
        employeeName: 'Employee Name',
        employeeAddress: 'Employee Address',
        employeePhone: 'Employee Phone',
        employeeEmail: 'Employee Email',
        employeeCode: 'Employee Code',
        employeeHobby: 'Employee Hobby',
        employeeGender: 'Employee Gender',
        employeeDOB: 'Employee DOB',
        employeeDOJ: 'Employee DOJ',
    },
    employmentDetails: {
        jobTitle: 'Job Title',
        deparment: 'Deparment',
        location: 'Location',
        employeeStatus: 'Employee Status',
        employeeType: 'Employee Type',
        employeeManager: 'Employee Manager',
        employeeSupervisor: 'Employee Supervisor',
        employeeTeam: 'Employee Team',
        employeeShift: 'Employee Shift',
    },
    employeeSalary: {
        employeePayrollType: 'Employee Payroll Type',
        employeePayrollAmount: 'Employee Payroll Amount',
        employeePayrollFrequency: 'Employee Payroll Frequency',
        employeePayrollCurrency: 'Employee Payroll Currency',
        employeePayrollStartDate: 'Employee Payroll Start Date',
        employeePayrollEndDate: 'Employee Payroll End Date',
    },
    employeeBenefits: {
        employeeHealthInsurance: 'Employee Health Insurance',
        employeeDentalInsurance: 'Employee Dental Insurance',
        employeeLifeInsurance: 'Employee Life Insurance',
        employeePTO: 'Employee PTO',
        employee401K: 'Employee 401K',
        employeeStockOptions: 'Employee Stock Options',
        employeeBonus: 'Employee Bonus',
        employeeProfitSharing: 'Employee Profit Sharing',
        employeeTuitionReimbursement: 'Employee Tuition Reimbursement',
    },
    employeeEmergencyContact: {
        employeeEmergencyContactName: 'Employee Emergency Contact Name',
        employeeEmergencyContactRelation: 'Employee Emergency Contact Relation',
        employeeEmergencyContactPhone: 'Employee Emergency Contact Phone',
        employeeEmergencyContactEmail: 'Employee Emergency Contact Email',
    },
    employeeLeave: {
        employeeLeaveType: 'Employee Leave Type',
        employeeLeaveStartDate: 'Employee Leave Start Date',
        employeeLeaveEndDate: 'Employee Leave End Date',
    },
    employeeTimesheet: {
        employeeTimesheetDate: 'Employee Timesheet Date',
        employeeTimesheetHours: 'Employee Timesheet Hours',
        employeeTimesheetProject: 'Employee Timesheet Project',
        employeeTimesheetTask: 'Employee Timesheet Task',
        employeeTimesheetComments: 'Employee Timesheet Comments',
        employeeTimesheetStatus: 'Employee Timesheet Status',
        employeeTimesheetApprovedBy: 'Employee Timesheet Approved By',
        employeeTimesheetApprovedDate: 'Employee Timesheet Approved Date',
        employeeTimesheetRejectedBy: 'Employee Timesheet Rejected By',
        employeeTimesheetRejectedDate: 'Employee Timesheet Rejected Date',
        employeeTimesheetSchedule: 'Employee Timesheet Schedule',
    },
    employeeterminationDetails: {
        employeeTerminationType: 'Employee Termination Type',
        employeeTerminationReason: 'Employee Termination Reason',
        employeeTerminationDate: 'Employee Termination Date',
        employeeTerminationComments: 'Employee Termination Comments',
    },
    employeeDocuments: {
        employeeDocumentName: 'Employee Document Name',
        employeeDocumentType: 'Employee Document Type',
        employeeDocumentRef: 'Employee Document Ref',
    }
}