import { View, TouchableOpacity, StyleSheet, ScrollView } from "react-native";
import React, { useState } from "react";
import { Icon, Text } from "react-native-paper";
import { paymentData } from "../../../assets/dummyData/dummyData";
import { w } from "../../util/dimension";
import { Header, Row, Table } from "../../tableComponent/tableComponent";
import { isWeb } from "../../Common/isWeb";
import { useTheme } from "@react-navigation/native";
import { CustomColors } from "../../../api/customColors";
import { businessItemProps } from "../../../GlobalContext/types";

const OnBoardingTable = ({
  businessessData,
  modifiedData,
  setModifiedData,
  setIsLoading,
  setSelectedData,
  setShowSidePanel
}: {
  businessessData: businessItemProps[];
  modifiedData: businessItemProps[];
  setModifiedData: any;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedData: React.Dispatch<React.SetStateAction<businessItemProps>>;
  setShowSidePanel: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { colors }: { colors: CustomColors } = useTheme();
  console.log({ businessessData })
  const [width, setWidth] = useState(w);
  const [noOfRows, setNoOfRows] = useState(10);

  const [currentPage, setCurrentPage] = useState(0);
  let from = currentPage * noOfRows;
  let to = from + noOfRows;

  const styles = StyleSheet.create({
    tableSubStatusText: {
      gap: 10,
      borderRadius: 5,
      paddingVertical: 1,
      flexDirection: "row",
      alignItems: "center",
      paddingHorizontal: 10,
      justifyContent: "center",
    },
    tableSelectionBox: {
      backgroundColor: colors.background,
      borderWidth: 1,
      borderColor: colors.border,
      borderRadius: 5,
    },
  });
  return (
    <View
      onLayout={(e) => {
        setIsLoading(true);
        setWidth(e.nativeEvent.layout.width)
        setIsLoading(false);
      }}
      style={{
        flex: 1,
        borderColor: colors.border,
        borderRadius: 10,
        borderWidth: 1,
        marginTop: 10,
      }}
    >
      <Table viewStyle={{ flex: isWeb ? 1 : 0 }}>
        <Header
          dataState={businessessData}
          viewStyle={{ borderBottomWidth: 1, borderColor: colors.border }}
          setDataState={setModifiedData}
        >
          {/* <View
            style={{
              width: width * 0.05,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TouchableOpacity style={styles.tableSelectionBox}>
              <Icon source={"minus"} size={18} color="#b83131" />
            </TouchableOpacity>
          </View> */}

          <Header.Title
            value="businessName"
            columnWidth={width * 0.15}
            textStyle={{ paddingVertical: 10, textTransform: "capitalize" }}
          >
            Business Name
          </Header.Title>
          <Header.Title
            value="id"
            isID
            columnWidth={width * 0.15}
            isNumeric
            textStyle={{ paddingVertical: 10, textTransform: "capitalize" }}
          >
            Business Id
          </Header.Title>
          {/* <Header.Title
            value="businessStatus"
            isNumeric
            columnWidth={width * 0.15}
            textStyle={{ paddingVertical: 10, textTransform: "capitalize" }}
          >
            Onboarding Status
          </Header.Title> */}
          <Header.Title
            value="businessAddressLine1"
            columnWidth={width * 0.15}
            textStyle={{ paddingVertical: 10, textTransform: "capitalize" }}
          >
            Business Address
          </Header.Title>
          <Header.Title
            value="businessType"
            columnWidth={width * 0.15}
            textStyle={{ paddingVertical: 10, textTransform: "capitalize" }}
          >
            Business Type
          </Header.Title>
          <Header.Title
            value="businessPhone"
            columnWidth={width * 0.15}
            textStyle={{ paddingVertical: 10, textTransform: "capitalize" }}
          >
            Business Phone
          </Header.Title>
          <Header.Title
            value="businessEmail"
            isNumeric
            columnWidth={width * 0.15}
            textStyle={{ paddingVertical: 10, textTransform: "capitalize" }}
          >
            Business Email
          </Header.Title>
          <Header.Title
            value="businessFax"
            isNumeric
            columnWidth={width * 0.15}
            textStyle={{ paddingVertical: 10, textTransform: "capitalize" }}
          >
            Business Fax
          </Header.Title>
        </Header>

        <ScrollView>
          {modifiedData.slice(from, to).map((item, index) => {
            // let successIconColor = "#165e3d";
            // let pendingIconColor = "#b5850b";
            // let declineIconColor = "#b83131";
            // let createIconColor = "#3d42ad";

            // let iconName;
            // let iconColor;

            // if (item.status === "Succeeded") {
            //   iconColor = successIconColor;
            //   iconName = "check-circle";
            // } else if (item.status === "Declined") {
            //   iconColor = declineIconColor;
            //   iconName = "minus-circle";
            // } else if (item.status === "Pending") {
            //   iconName = "clock-time-three";
            //   iconColor = pendingIconColor;
            // } else if (item.status === "Create") {
            //   iconColor = createIconColor;
            //   iconName = "flag";
            // }

            return (
              <View style={{ flexDirection: "row" }}>
                <Row
                  key={index}
                  rowHeight={40}
                  viewStyle={{
                    paddingVertical: 5,
                    maxHeight: 40,
                    alignItems: "center",
                    borderBottomWidth: StyleSheet.hairlineWidth,
                    borderColor: colors.border,
                  }}
                >
                  {/* <View
                    key={index}
                    style={{
                      width: width * 0.05,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <TouchableOpacity style={styles.tableSelectionBox}>
                      <Icon source={"minus"} size={18} color="#b83131" />
                    </TouchableOpacity>
                  </View> */}

                  <Row.Cell
                    rowWidth={width * 0.15}
                    value={item?.businessName}
                    onPress={() => {
                      setSelectedData(item)
                      setShowSidePanel(true);
                    }}
                  />
                  <Row.Cell
                    rowWidth={width * 0.15}
                    value={item?.id}
                  />
                  {/* <Row.Cell
                    rowWidth={width * 0.15}
                    value={''}
                  /> */}
                  <Row.Cell
                    rowWidth={width * 0.15}
                    value={item.businessAddressLine1}
                  />
                  <Row.Cell
                    rowWidth={width * 0.15}
                    value={item.type}
                  />
                  <Row.Cell
                    rowWidth={width * 0.15}
                    value={item.businessPhone}
                  />
                  <Row.Cell
                    rowWidth={width * 0.15}
                    value={item.businessEmail}
                  />
                  <Row.Cell
                    rowWidth={width * 0.15}
                    value={item.businessFax}
                  />
                  {/* <Row.Cell rowWidth={width * 0.15} value={item?.invoiceID} />

                  <View
                    style={{
                      width: width * 0.15,
                      justifyContent: "center",
                      alignItems: "flex-start",
                    }}
                  >
                    <View
                      style={{
                        ...styles.tableSubStatusText,
                        paddingHorizontal: 5,
                        backgroundColor: colors.background,
                      }}
                    >
                      <Icon source={iconName} size={24} color={iconColor} />
                      <Text variant="bodySmall" style={{ color: iconColor }}>
                        {item?.status}
                      </Text>
                    </View>
                  </View>

                  <Row.Cell rowWidth={width * 0.15} value={item?.amount} />
                  <Row.Cell
                    rowWidth={width * 0.15}
                    value={item?.paymentMethod}
                  />
                  <Row.Cell
                    rowWidth={width * 0.15}
                    value={`*****${item?.cardNo}`}
                  />
                  <Row.Cell
                    rowWidth={width * 0.15}
                    value={item?.creationDate}
                  />

                  <View
                    style={{ alignItems: "center", justifyContent: "center" }}
                  >
                    <TouchableOpacity>
                      <Icon source={"file-pdf-box"} size={24} color="#b83131" />
                    </TouchableOpacity>
                  </View>

                  {["Pending", "Declined"].includes(item?.status) && (
                    <View
                      style={{ alignItems: "center", justifyContent: "center" }}
                    >
                      <TouchableOpacity>
                        <Icon
                          source={"close-circle"}
                          size={20}
                          color="#b83131"
                        />
                      </TouchableOpacity>
                    </View>
                  )} */}
                </Row>
              </View>
            );
          })}

          {modifiedData.length === 0 && (
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                paddingVertical: 20,
              }}
            >
              <Text>No Data Found</Text>
            </View>
          )}
        </ScrollView>
      </Table>
      <View style={{ width: "100%", paddingBottom: 10, paddingRight: 20 }}>
        <Table.Pagination
          currentSelectedRows={noOfRows}
          setRows={setNoOfRows}
          totalPages={Table.TotalPages(modifiedData, noOfRows)}
          label={`${from + 1} - ${to} of ${modifiedData.length}`}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </View>
    </View>
  );
};

export default OnBoardingTable;
