export const currencyValueFormatter = (value: number | string): string => {
  const numberValue = Number(value);
  const valueFormatted = Number.isNaN(numberValue)
    ? ""
    : `$${numberValue.toLocaleString("en-us", {
        minimumFractionDigits: 2,
      })}`;

  return valueFormatted;
};

export const percentFormatter = (value: number | string) => {
  const valueFormatted = Number(value).toLocaleString();

  return `${valueFormatted || 0}%`;
};
