import React from 'react'
import { TransitionPresets, createStackNavigator } from '@react-navigation/stack'
import Payment from '../src/screens/Payment'
import AddPayment from '../src/screens/AddPayment'
import PaymentShipping from '../src/screens/PaymentShipping'

const Stack = createStackNavigator()

const PaymentStack = () => {
    return (
        <Stack.Navigator screenOptions={{ headerShown: false, ...TransitionPresets.RevealFromBottomAndroid }}>
            <Stack.Screen name='PaymentHome' component={Payment} />
            <Stack.Screen name='AddPayment' component={AddPayment} />
            <Stack.Screen name='PaymentShipping' component={PaymentShipping} />
        </Stack.Navigator>
    )
}

export default PaymentStack