import { useTheme } from '@react-navigation/native';
import React from 'react'
import { Controller, useForm, InputValidationRules, RegisterOptions } from 'react-hook-form';
import { InputModeOptions, KeyboardTypeOptions, StyleSheet, Text, View, ViewStyle, useColorScheme } from 'react-native'
import { Checkbox, TextInput, TextInputProps } from 'react-native-paper';
import CustomDropdown from '../../CustomDropdown/CustomDropdown';
import { DropDownDirectionType } from 'react-native-dropdown-picker';
import { CustomColors } from '../../../api/customColors';
// import { TextInput } from 'react-native-paper';




export type InputProps = {
    name?: string;
    control?: any;
    rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    isDropdown?: boolean;
    DropdownData?: {
        label: string;
        value: string;
    }[];
    DropdownArrowStyle?: ViewStyle;
    DropdownSearchable?: boolean;
    DropdownOnChange?: (t: any) => void;
    DropdownMaxHeight?: number;
    DropdownDirection?: DropDownDirectionType;
    isCheckbox?: boolean;
    CheckboxLabel?: string;
    keyboradType?: KeyboardTypeOptions;
    inputMode?: InputModeOptions;
    isNumericValue?: boolean;

    label?: string;
    placeHolder?: string;
    hideLabel?: boolean;
    disabled?: boolean;
    numberOfLines?: number;
    multiline?: boolean;
    onBlur?: any;
    onChangeText?: (text: string) => void;
    secureTextEntry?: boolean;
    value?: any;
    style?: ViewStyle;
    searchIcon?: boolean;
    left?: any;
    error?: boolean;
}

const FormsInput = ({

    name,
    control,
    rules,
    isDropdown = false,
    DropdownData,
    DropdownArrowStyle,
    DropdownSearchable,
    DropdownOnChange,
    DropdownMaxHeight,
    DropdownDirection,
    isCheckbox,
    CheckboxLabel,
    keyboradType,
    onChangeText,
    inputMode,
    isNumericValue,


    label,
    placeHolder,
    numberOfLines,
    disabled,
    hideLabel,
    multiline = false,
    // value,
    secureTextEntry = false,
    style,
    searchIcon = false,
    left,
    error
}: InputProps) => {

    const { colors }: { colors: CustomColors } = useTheme()

    const styles = StyleSheet.create({
        textInput: {
            width: 'auto',
            marginTop: 5,
            backgroundColor: colors.card,
        },
    })

    return (
        <Controller
            control={control}
            name={name as string}
            rules={rules}
            render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                return (
                    <View>
                        {!isDropdown && !isCheckbox ?
                            <TextInput
                                style={[styles.textInput, style]}
                                underlineColor='transparent'
                                activeOutlineColor='transparent'
                                outlineColor='transparent'
                                activeUnderlineColor='transparent'
                                contentStyle={{ borderRadius: 10, borderWidth: error ? 2 : 1, borderColor: error ? 'red' : colors.border }}
                                value={value}
                                keyboardType={keyboradType}
                                onBlur={onBlur}
                                left={left}
                                secureTextEntry={secureTextEntry}
                                numberOfLines={numberOfLines}
                                multiline={multiline}
                                placeholder={placeHolder ? placeHolder : label}
                                placeholderTextColor="#99999990"
                                inputMode={inputMode}
                                onChangeText={text => {
                                    if (!isNumericValue) onChange(text);
                                    else {
                                        let t = text.replace(/[^0-9]/g, '')
                                        // t.replace(/[^0-9]/g, '')
                                        // parseInt(t)
                                        if (value === 'NaN') {
                                            // console.log('first')
                                            onChange(12)
                                        }
                                        onChange(t);
                                    }

                                    if (onChangeText) {
                                        onChangeText(text);
                                    }
                                }}
                                right={searchIcon && <TextInput.Icon icon="magnify" color={colors.text} />}
                            /> :
                            isDropdown ?
                                <CustomDropdown
                                    value={value}
                                    setValue={(t: any) => {
                                        onChange(t())
                                        if (DropdownOnChange) {
                                            DropdownOnChange(t());
                                        }
                                    }}
                                    data={DropdownData as []}
                                    placeholder={placeHolder as string}
                                    style={{ borderWidth: error ? 2 : 1, borderColor: error ? 'red' : colors.border, ...style }}
                                    arrowStyle={DropdownArrowStyle}
                                    searchable={DropdownSearchable}
                                    maxHeight={DropdownMaxHeight}
                                    direction={DropdownDirection}
                                /> :
                                isCheckbox ? (
                                    <View style={{
                                        gap: 10,
                                        alignItems: "center",
                                        flexDirection: "row",
                                    }}>
                                        <Checkbox
                                            status={value ? "checked" : "unchecked"}
                                            uncheckedColor={colors.border}
                                            color={colors.primary}
                                            onPress={() => {
                                                // if (CheckboxSetStatus) {
                                                // CheckboxSetStatus(!CheckboxStatus)

                                                onChange(!value)
                                                // }
                                            }}
                                        />
                                        <Text style={{ color: colors.subText }}>
                                            {CheckboxLabel}
                                        </Text>
                                    </View>
                                ) : null}
                        {error && <Text style={{ color: 'red', marginVertical: 5, textAlign: 'right' }}>{error.message}</Text>}
                    </View>
                )
            }}
        />
    )
}

export default FormsInput
