import React from 'react'
import { TransitionPresets, createStackNavigator } from '@react-navigation/stack'
import AddInventoryItem from '../src/screens/inventory/AddInventoryItem'
import Inventory from '../src/screens/inventory/Inventory'

const Stack = createStackNavigator()

const InventoryStack = () => {
    return (
        <Stack.Navigator screenOptions={{ headerShown: false, ...TransitionPresets.RevealFromBottomAndroid }}>
            <Stack.Screen name='InventoryHome' component={Inventory} />
            <Stack.Screen name='AddInventoryItem' component={AddInventoryItem} />
        </Stack.Navigator>
    )
}

export default InventoryStack