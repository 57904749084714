import { StyleSheet } from "react-native";
import { customDimensions, h, w } from "../util/dimension";

export const login = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: "#fff",
  },
  logoContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    width: w * .2,
    height: w * .2,
    marginTop: h * .05,
    resizeMode: "contain"
  },
  formContainer: {
    flex: 2,
    alignItems: "center",
    paddingTop: 50,
    flexBasis: "40%",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 20,
  },
  input: {
    padding: 15,
    borderWidth: 1,
    borderColor: "#43B9FE",
    borderRadius: 10,
    marginBottom: 10,
    fontSize: 16,
    fontWeight: "bold",
    color: "#000000"
  },
  button: {
    padding: 20,
    backgroundColor: "#43B9FE",
    borderRadius: 9,
    alignItems: "center",
    justifyContent: "center"
  },
  buttonText: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  imageContainer: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: "#fff",
    paddingTop: 50,
    flexBasis: "40%",
  },
  imageTextContainer: {
    flex: 1,
    alignItems: "center",
    paddingTop: 50,
  },
  imageText: {
    color: "black",
    fontSize: 50,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: 19,
    letterSpacing: 0,
    textAlign: "center",
    flexShrink: 0,
  },
  salyContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems:'center'
  },
  image: {
    width: "45%",
    height: "45%",
  },
  // salyImage: {
  //   width: customDimensions().cw*.2,
  //   height: customDimensions().ch,
  //   resizeMode:'contain'
  //   // width: "100%",
  //   // height: "45%",
  //   // marginTop: 300,
  // },
  salyTextContainer: {
    flex: 2,
    padding:10,
    justifyContent: 'center',
  },
  error: {
    color: "red",
  },
});
