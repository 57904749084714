import { Image, StyleSheet, TouchableOpacity, View } from 'react-native'
import React from 'react'
import { Icon, Text } from 'react-native-paper'
import { shadowSet } from '../../component/util/shadow'
import { w } from '../../component/util/dimension'
import PaymentShippingDropDownList from '../../component/page/payment/paymentShippingDropDownList'
import PaymentShippingItemDetail from '../../component/page/payment/paymentShippingItemDetail'
import { useTheme } from '@react-navigation/native'
import { CustomColors } from '../../api/customColors'

const PaymentShipping = (props: any) => {

    const { colors }: { colors: CustomColors } = useTheme();


    const styles = StyleSheet.create({
        fontBold: {
            fontWeight: 'bold',
            color:colors.text
        },
        mainView2: {
            flex: 1,
            marginTop: 10,
            borderRadius: 10,
            backgroundColor: colors.card,
            justifyContent: 'space-evenly'
        },
        side1: {
            gap: 10,
            flex: 2,
            padding: 10,
            ...shadowSet,
            borderRadius: 10,
            flexDirection: 'row',
            backgroundColor: colors.card,
        },
        side2: {
            gap: 20,
            marginTop: 20,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center'
        },
        actionButton: {
            gap: 10,
            borderRadius: 10,
            paddingVertical: 10,
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 10,
            justifyContent: 'center',
            backgroundColor: colors.card,
        }
    })

    return (
        <View style={{ flex: 1, backgroundColor: colors.background }}>

            <View style={{ flex: 1, padding: 10 }}>

                <View style={{ flexDirection: 'row', alignItems: 'center' }}>

                    <View style={{ flex: 2, flexDirection: 'row', gap: 10, alignItems: 'center' }}>
                        <TouchableOpacity onPress={() => props.navigation.goBack()}>
                            <Icon source={'arrow-left-circle'} size={24} color={colors.text} />
                        </TouchableOpacity>
                        <Text variant="titleLarge" style={{ color: colors.text }} >Payment and Shipping</Text>
                    </View>

                    <View style={{ flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end' }}>

                        <TouchableOpacity style={styles.actionButton}>

                            <Text style={{ color: colors.text }}>Action</Text>
                            <Icon source={'menu-down'} size={24} color={colors.text} />

                        </TouchableOpacity>

                    </View>

                </View>

                <View style={[styles.mainView2, { flexDirection: 'row', borderWidth:1, borderColor:colors.border, padding: 10, gap: 10, }]}>

                    <View style={styles.side1}>

                        <PaymentShippingDropDownList />

                        <View style={{ flex: 1, backgroundColor: colors.background, borderRadius: 10 }}>

                            <View style={{ padding: 10 }}>
                                <Text variant='bodyLarge' style={styles.fontBold}>Details</Text>
                            </View>

                            <PaymentShippingItemDetail />

                        </View>

                    </View>

                    <View style={{ flex: 1, borderRadius: 10, backgroundColor: colors.background, ...shadowSet }}>


                        <View style={styles.side2}>

                            <TouchableOpacity style={{ backgroundColor: '#008489', borderRadius: 100, padding: 10 }} >
                                <Icon source={'plus'} color='white' size={25} />
                            </TouchableOpacity>

                            <Image
                                source={require('../../assets/dummyData/demo_box.png')}
                                style={{ width: (w / 3) * .3, height: (w / 3) * .3, resizeMode: 'contain' }}
                            />

                        </View>

                        <View style={{ flex: 1 }} />

                    </View>

                </View>

            </View>
        </View>
    )
}

export default PaymentShipping
