import React, { ReactNode } from 'react';
import { View, StyleSheet } from 'react-native';
import { globalStyles } from '../../component/theme/applicationTheme';

type Props = {
  children: ReactNode;
};

export default function GlobalView({ children }: Props) {
  return <View style={globalStyles.container}>{children}</View>;
}
