import { View, TouchableOpacity, Image, ScrollView, StyleSheet, FlatList, Platform } from 'react-native'
import React, { useState } from 'react'
import { Divider, Icon, Text } from 'react-native-paper'
import { shadowSet } from '../../component/util/shadow'
import { bankNames } from '../../assets/dummyData/dummyData'
import CustomDropdown from '../../component/CustomDropdown/CustomDropdown'
import countryList from 'country-list'
import Input from '../../component/Common/Input/Input'
import { useTheme } from '@react-navigation/native'
import { CustomColors } from '../../api/customColors'

const AddPayment = (props: any) => {

  const { colors }: { colors: CustomColors } = useTheme();

  const [activePayment, setActivePayment] = useState<string | null>(null)

  const [modifiedData, setModifiedData] = useState(bankNames)

  const [cl] = useState(countryList.getNames().map((item: string) => ({
    label: item,
    value: item
  })))

  let paymentDashboard = () => {
    props.navigation.navigate('Payment', { screen: 'PaymentHome' })
  }

  let paymentShipping = () => {
    props.navigation.navigate('Payment', { screen: 'PaymentShipping' })
  }

  const [value, setValue] = useState(null);
  const [cardCountryValue, setCardCountryValue] = useState(null)

  const styles = StyleSheet.create({
    topRightButton: {
      gap: 10,
      padding: 10,
      borderRadius: 100,
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
      backgroundColor: colors.card,
    },
    mainView: {
      flex: 1,
      padding: 10,
      marginTop: 10,
      borderRadius: 10,
      backgroundColor: colors.card
    },
    textInput: {
      borderRadius: 10,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      backgroundColor: '#f8f8f8'
    },
    placeOrderButton: {
      gap: 10,
      borderRadius: 10,
      paddingVertical: 10,
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 10,
      justifyContent: 'center',
      backgroundColor: colors.primary,
    }
  })

  return (
    <View style={{ flex: 1, backgroundColor: colors.background }}>

      <View style={{ flex: 1, padding: 10 }}>

        <View style={{ flexDirection: 'row', alignItems: 'center' }}>

          <View style={{ flex: 2 }}>
            <Text variant="bodyLarge" style={{ color: colors.text, fontWeight: 'bold' }} >New Payment</Text>
          </View>

          <View style={{ flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end' }}>

            <TouchableOpacity
              onPress={paymentDashboard}
              style={styles.topRightButton}
            >
              <View style={{ backgroundColor: colors.primary, borderRadius: 100 }}>
                <Icon source={'minus'} size={24} color="white" />
              </View>
              <Text style={{ color: colors.text }}>Payment Dashboard</Text>
            </TouchableOpacity>

          </View>

        </View>

        <View style={styles.mainView}>

          {/* Inputs */}
          <ScrollView style={{ flex: 1, }}>

            <View style={{ flexDirection: 'row', padding: 10, gap: 10 }}>

              {/* Left side */}
              <View style={{ flex: 1 }}>

                <View>
                  <Text variant="titleLarge" style={{ color: colors.subText, fontWeight: 'bold', marginVertical: 5 }} >Contact info</Text>
                  <Input
                    label='Email'
                    style={{ flex: 1 }}
                  />
                </View>

                <View style={{ zIndex: 99999 }}>
                  <Text variant="titleLarge" style={{ color: colors.text, fontWeight: 'bold', marginBottom: 5, marginTop: 20 }} >Shipping</Text>

                  <View>
                    <Text variant="titleMedium" style={{ color: colors.subText, marginTop: 5 }} >Full Name</Text>
                    <Input
                      label='First and last name'
                      style={{ flex: 1 }}
                    />
                  </View>
                  <View style={{ zIndex: 99999 }}>
                    <Text variant="titleMedium" style={{ color: colors.subText, marginTop: 5 }} >Country or region</Text>
                    <CustomDropdown
                      data={cl}
                      value={value}
                      setValue={setValue}
                      placeholder='Country or region'
                      style={{ marginTop: 10 }}
                    />
                  </View>

                  <View>
                    <Text variant="titleMedium" style={{ color: colors.subText, marginTop: 5 }} >Address line 1</Text>
                    <Input
                      label='Street address'
                      style={{ flex: 1 }}
                    />
                  </View>

                </View>

                <View>

                  <Text variant="titleLarge" style={{ color: colors.text, fontWeight: 'bold', marginBottom: 5, marginTop: 20 }} >Payment</Text>

                  <View style={{ flex: 1, flexDirection: 'row', gap: 10 }}>

                    <TouchableOpacity onPress={() => setActivePayment('card')} style={{ flex: 1 }}>
                      <View style={{ backgroundColor: activePayment === 'card' ? colors.background : colors.card, borderRadius: 10, borderColor: colors.border, borderWidth: 1, padding: 10, ...shadowSet, }}>
                        <Icon source={'credit-card'} size={24} color={colors.text} />
                        <Text style={{ color: colors.text }}>Card</Text>
                      </View>
                    </TouchableOpacity>

                    <TouchableOpacity onPress={() => setActivePayment('bank')} style={{ flex: 1 }}>
                      <View style={{ backgroundColor: activePayment === 'bank' ? colors.background : colors.card, borderRadius: 10, borderColor: colors.border, borderWidth: 1, padding: 10, ...shadowSet }}>
                        <Icon source={'bank'} size={24} color={colors.text} />
                        <Text style={{ color: colors.text }}>Bank</Text>
                      </View>
                    </TouchableOpacity>

                  </View>

                  <View>

                    {activePayment === 'bank' && (
                      <>
                        <Input label='Search Bank' searchIcon
                          onChangeText={(t) => {

                            if (t.length > 0) {

                              let a: any = []
                              bankNames.forEach(item => {
                                if (item.bankName.toLocaleLowerCase().includes(t.toLocaleLowerCase())) {
                                  a.push(item)
                                }

                                setModifiedData(a)
                              })
                            } else {
                              setModifiedData(bankNames)
                            }
                          }}
                        />
                        <FlatList
                          data={modifiedData}
                          numColumns={3}
                          renderItem={({ item, index }) => {
                            return (
                              <TouchableOpacity style={{ backgroundColor: colors.card, flex: 1, padding: 10, }}>
                                <View style={{ backgroundColor: colors.background, width: '100%', height: '100%', flex: Platform.OS === 'web' ? null : 1, borderRadius: 10, justifyContent: 'center', alignItems: 'center', ...shadowSet }}>
                                  <Image source={item.bankImage} style={{ width: '80%', flex: Platform.OS === 'web' ? null : 1, height: 100, resizeMode: 'contain' }} />
                                </View>
                              </TouchableOpacity>
                            )
                          }}
                        />
                      </>
                    )}

                    {activePayment === 'card' && (
                      <View style={{ marginTop: 10 }} >
                        <Input
                          label='Card Number'
                          style={{ flex: 1 }}
                        />

                        <View style={{ flexDirection: 'row', gap: 10, }}>
                          <Input
                            label='Expiration'
                            style={{ flex: 1 }}
                          />
                          <Input
                            label='CVC'
                            style={{ flex: 1 }}
                          />
                        </View>

                        <CustomDropdown
                          data={cl}
                          placeholder='Country'
                          value={cardCountryValue}
                          setValue={setCardCountryValue}
                          style={{ marginTop: 10, borderRadius: 10 }}
                        />

                        <Input
                          label='ZIp'
                          style={{ flex: 1 }}
                        />

                      </View>
                    )}
                  </View>
                </View>
              </View>

              {/* Right side */}
              <View style={{ flex: 1, }}>

                <View style={{ margin: 20, padding: 20, borderRadius: 10, ...shadowSet }}>

                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                    <Text style={{ color: colors.text }}>Subtotal ( 1 item )</Text>
                    <Text style={{ color: colors.text }}>$ 1.00</Text>
                  </View>

                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                    <Text variant='bodyMedium' style={{ color: colors.text }} >Shipping</Text>
                    <Text variant='bodyMedium' style={{ color: colors.text }} >Free</Text>
                  </View>

                  <Divider style={{ backgroundColor: colors.border, marginVertical: 20 }} />

                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20 }}>
                    <Text variant='bodyLarge' style={{ color: colors.text, fontWeight: 'bold' }}>Total</Text>
                    <Text variant='bodyLarge' style={{ color: colors.text, fontWeight: 'bold' }}>$ 1.00</Text>
                  </View>

                  <TouchableOpacity
                    onPress={paymentShipping}
                    style={styles.placeOrderButton}
                  >
                    <Text style={{ color: '#fff', fontWeight: 'bold' }}>Place order</Text>
                  </TouchableOpacity>

                </View>

              </View>

            </View>
          </ScrollView>

        </View>
      </View >

    </View >
  )
}

export default AddPayment
