import { useTheme } from '@react-navigation/native';
import { BarCodeScanner } from 'expo-barcode-scanner';
import React, { useEffect, useState } from 'react'
import { View, Button, StyleSheet, TouchableOpacity, ScrollView, Image, Platform, Dimensions, Modal } from 'react-native'
import { Icon, Text } from 'react-native-paper';

import { CustomColors } from '../../../api/customColors';
import Input from '../../Common/Input/Input';
import { h, w } from '../../util/dimension';

type props = {
    modalVisible: boolean;
    setVisibility: any;
    editRowData: {
        itemID: string;
        dateOfEnter: string;
        itemName: string;
        quantity: string;
        unitPrice: string;
    };
    setEditRowData: any;
    handleEdit: () => void;
}

const EmployeeEditModal = ({ modalVisible, setVisibility, editRowData, setEditRowData, handleEdit }: props) => {

    const { colors }: { colors: CustomColors } = useTheme();

    const [dimen, setDimen] = useState({
        w,
        h
    })

    const isDeskTop = Platform.OS === 'web'

    const [currentModal, setCurrentModal] = useState<number | null>(null)
    const [hasPermission, setHasPermission] = useState<boolean | null>(null);
    const [scanned, setScanned] = useState(false);

    const title = () => {
        if (currentModal === null) return 'Choose Editing Method';
        else if (currentModal === 0) return 'Scan the QR Code';
        else if (currentModal === 1) return 'Edit data';
    }

    useEffect(() => {

        if (!isDeskTop) {

            const getBarCodeScannerPermissions = async () => {
                const { status } = await BarCodeScanner.requestPermissionsAsync();
                setHasPermission(status === 'granted');
            };

            getBarCodeScannerPermissions();
        }

        const sub = Dimensions.addEventListener('change', ({ window }) => {
            setDimen({
                w: window.width,
                h: window.height
            })
        })


    }, []);

    const handleBarCodeScanned = ({ type, data }: any) => {
        setScanned(true);
        alert(`Bar code with type ${type} and data ${data} has been scanned!`);
    };

    return (
        <Modal
            transparent
            visible={modalVisible}
            animationType="fade"
            statusBarTranslucent
        >
            <View style={{ flex: 1, backgroundColor: '#000000a3', justifyContent: 'center', alignItems: 'center' }}>
                <View
                    style={{
                        // width: "50%",
                        width: 700,
                        height: "80%",
                        backgroundColor: colors.background,
                        borderRadius: 10,
                        padding: 10
                    }}
                >
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <TouchableOpacity onPress={() => setCurrentModal(null)} style={{ flexDirection: 'row', gap: 10, alignItems: 'center' }}>
                            {currentModal !== null && <Icon source="arrow-left-circle" color={colors.text} size={24} />}
                            <Text variant='headlineMedium' style={{ color: colors.text, fontWeight: 'bold' }}>{title()}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => {
                            setCurrentModal(null);
                            setVisibility(false)
                        }}>
                            <Icon source="close-thick" size={30} color={colors.text} />
                        </TouchableOpacity>
                    </View>

                    {currentModal === null && <View style={{ flex: 1, flexDirection: 'row', gap: 10, justifyContent: 'center', alignItems: 'center', }}>

                        <TouchableOpacity
                            onPress={() => setCurrentModal(0)}
                            style={{ backgroundColor: colors.primary, padding: 10, paddingHorizontal: 20, borderRadius: 10 }}>
                            <Text variant='bodyMedium' style={{ fontWeight: 'bold', color: colors.text }}>Use Scanner</Text>
                        </TouchableOpacity>

                        <TouchableOpacity
                            onPress={() => setCurrentModal(1)}
                            style={{ backgroundColor: colors.primary, padding: 10, paddingHorizontal: 20, borderRadius: 10 }}>
                            <Text variant='bodyMedium' style={{ fontWeight: 'bold', color: colors.text }}>Edit Manually</Text>
                        </TouchableOpacity>

                    </View>}

                    {currentModal === 1 && <ScrollView style={{ flex: 1 }}>

                        <TouchableOpacity style={{ marginTop: 10 }}>

                            <Image source={editRowData?.userImage} style={{ resizeMode: 'cover', width: 80, height: 80, borderRadius: 10 }} />

                        </TouchableOpacity>

                        <View style={{ marginTop: 10 }}>

                            <Input
                                label='Name'
                                value={editRowData?.name}
                                onChangeText={text => setEditRowData((pre: any) => ({ ...pre, name: text }))}
                            />
                        </View>

                        <View style={{ marginTop: 10 }}>

                            <Input
                                label="Role"
                                value={editRowData?.role}
                                onChangeText={text => setEditRowData((pre: any) => ({ ...pre, role: text }))}
                            />
                        </View>

                        <View style={{ marginTop: 10 }}>
                            <Input
                                label="Status"
                                value={editRowData?.status}
                                onChangeText={text => setEditRowData((pre: any) => ({ ...pre, status: text }))}
                            />
                        </View>

                        <View style={{ marginTop: 10 }}>
                            <Input
                                label="Time In"
                                value={editRowData?.timeIn}
                                onChangeText={text => setEditRowData((pre: any) => ({ ...pre, timeIn: text }))}
                            />
                        </View>

                        <View style={{ marginTop: 10 }}>
                            <Input
                                label="Quantity"
                                value={editRowData?.timeOut}
                                onChangeText={text => setEditRowData((pre: any) => ({ ...pre, timeOut: text }))}
                            />
                        </View>

                        <View style={{ justifyContent: 'center', alignItems: 'center', marginTop: 50 }}>
                            <TouchableOpacity
                                onPress={handleEdit}
                                style={{ width: '50%', borderRadius: 10, backgroundColor: colors.primary, padding: 10, justifyContent: 'center', alignItems: 'center' }}>
                                <Text variant='bodyMedium' style={{ fontWeight: 'bold', color: 'white' }}>Update Changes</Text>
                            </TouchableOpacity>
                        </View>

                    </ScrollView>}

                    {
                        currentModal === 0 && <View style={{ flex: 1 }}>

                            {!isDeskTop ? <>
                                <BarCodeScanner
                                    onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
                                    style={StyleSheet.absoluteFillObject}
                                />

                                {scanned && <Button title="Tap to Scan Again" onPress={() => setScanned(false)} />}
                            </> : <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                                <Text>This component is not support for web.</Text>
                            </View>}

                        </View>
                    }

                </View>

            </View>
        </Modal >
    )
}

export default EmployeeEditModal;

const styles = StyleSheet.create({
    textInput: {
        borderRadius: 10,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        backgroundColor: '#f8f8f8'
    }
})