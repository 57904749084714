import { View, Text, StyleSheet, ScrollView, Pressable, ActivityIndicator } from 'react-native'
import React, { useEffect, useState } from 'react'
import { businessItemProps } from '../../../GlobalContext/types';
import { useTheme } from '@react-navigation/native';
import { CustomColors } from '../../../api/customColors';
import { Button, TextInput } from 'react-native-paper';
import { useFunctions } from '../../../GlobalContext/useUserContext';


type onBoardingsidepanelProps = {
    setShowSidePanel: React.Dispatch<React.SetStateAction<boolean>>;
    selectedData: businessItemProps;
    fetchAgain: boolean;
    setFetchAgain: React.Dispatch<React.SetStateAction<boolean>>;
}

const OnboardingSidePanel = ({ setShowSidePanel, selectedData, fetchAgain, setFetchAgain }: onBoardingsidepanelProps) => {

    const { colors }: { colors: CustomColors } = useTheme();
    const [editMode, setEditMode] = useState(false)

    const [updatedData, setUpdatedData] = useState<businessItemProps>(selectedData)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setUpdatedData(selectedData)
    }, [selectedData])

    const handleInputChange = (key: keyof businessItemProps, value: string) => {
        setUpdatedData((prevData) => ({ ...prevData, [key]: value }));
    };

    let handleUpdateData = async () => {
        setIsLoading(true);
        let update = useFunctions('updateBusiness');
        await update({
            business: updatedData,
            businessId: updatedData?.id
        }).then(res => {
            setIsLoading(false);
            setEditMode(false);
            setShowSidePanel(false);
            setFetchAgain(!fetchAgain);
        })
            .catch(err => {
                console.log('error update', err.message);
                setIsLoading(false);
                setEditMode(false);
            })
    }

    const styles = StyleSheet.create({
        inputTitle: {
            fontWeight: "bold",
            color: colors.text,
            marginVertical: 10
        },
        inputStyle: {
            color: colors.text, borderRadius: 10, borderWidth: editMode ? 2 : 0, borderColor: colors.border
        }
    })

    return (
        <ScrollView contentContainerStyle={{ padding: 20 }}>
            <View style={{ flexDirection: 'row', gap: 10, alignItems: 'center', justifyContent: 'center', marginBottom: 10 }}>

                {/* title */}
                <View style={{ flex: 1 }}>
                    <TextInput
                        editable={editMode}
                        value={updatedData?.businessName}
                        underlineColor='transparent'
                        activeUnderlineColor='transparent'
                        onChangeText={text => {
                            handleInputChange('businessName', text)
                        }}
                        contentStyle={{ flex: 1, fontSize: 30, color: colors.text, fontWeight: 'bold', borderRadius: 10, borderWidth: editMode ? 2 : 0, borderColor: colors.border }}
                        style={{ backgroundColor: "transparent" }}
                    />
                </View>

                <View style={{ flexDirection: 'row', gap: 10, alignItems: 'center', justifyContent: 'flex-end', }}>
                    <Pressable
                        onPress={() => setEditMode(!editMode)}
                        style={({ pressed }) => ({ flexDirection: 'row', gap: 10, backgroundColor: pressed ? colors.card : colors.background, alignSelf: 'flex-end', padding: 10, paddingHorizontal: 20, borderRadius: 100 })}>
                        <Text>{!editMode ? 'Edit' : 'Cancel'}</Text>
                    </Pressable>
                    {editMode && <Pressable
                        onPress={handleUpdateData}
                        style={({ pressed }) => ({ flexDirection: 'row', gap: 10, justifyContent: 'center', backgroundColor: pressed ? colors.card : colors.background, alignSelf: 'flex-end', padding: 10, paddingHorizontal: 20, borderRadius: 100 })}>
                        {isLoading && <ActivityIndicator size={15} color={colors.primary} />}
                        <Text>Save</Text>
                    </Pressable>}
                </View>
            </View>

            <View>
                <Text style={styles.inputTitle}>Business Id</Text>
                <Text style={styles.inputTitle} >{updatedData?.id}</Text>
            </View>

            <View style={{ flexDirection: 'row', gap: 10, marginTop: 10 }}>
                <View style={{ flex: 1 }}>
                    <Text style={styles.inputTitle}>Email</Text>
                    <TextInput
                        editable={editMode}
                        underlineColor='transparent'
                        value={updatedData?.businessEmail}
                        activeUnderlineColor='transparent'
                        style={{ backgroundColor: "transparent" }}
                        onChangeText={text => handleInputChange('businessEmail', text)}
                        contentStyle={styles.inputStyle}
                    />
                </View>

                <View style={{ flex: 1 }}>
                    <Text style={styles.inputTitle}>Address Line 2</Text>
                    <TextInput
                        editable={editMode}
                        underlineColor='transparent'
                        value={updatedData?.businessAddressLine1}
                        activeUnderlineColor='transparent'
                        style={{ backgroundColor: "transparent" }}
                        onChangeText={text => handleInputChange('businessAddressLine1', text)}
                        contentStyle={styles.inputStyle}
                    />
                </View>
            </View>

            <View style={{ flexDirection: 'row', gap: 10, marginTop: 10 }}>
                <View style={{ flex: 1 }}>
                    <Text style={styles.inputTitle}>Address Line 2</Text>
                    <TextInput
                        editable={editMode}
                        underlineColor='transparent'
                        value={updatedData?.businessAddressLine2}
                        activeUnderlineColor='transparent'
                        style={{ backgroundColor: "transparent" }}
                        onChangeText={text => handleInputChange('businessAddressLine2', text)}
                        contentStyle={styles.inputStyle}
                    />
                </View>

                <View style={{ flex: 1 }}>
                    <Text style={styles.inputTitle}>Address Line 3</Text>
                    <TextInput
                        editable={editMode}
                        underlineColor='transparent'
                        value={updatedData?.businessAddressLine3}
                        activeUnderlineColor='transparent'
                        style={{ backgroundColor: "transparent" }}
                        onChangeText={text => handleInputChange('businessAddressLine3', text)}
                        contentStyle={styles.inputStyle}
                    />
                </View>
            </View>

            <View style={{ flexDirection: 'row', gap: 10, marginTop: 10 }}>
                <View style={{ flex: 1 }}>
                    <Text style={styles.inputTitle}>Bonus Allocated</Text>
                    <TextInput
                        editable={editMode}
                        underlineColor='transparent'
                        value={String(updatedData?.bonusAllocated)}
                        activeUnderlineColor='transparent'
                        style={{ backgroundColor: "transparent" }}
                        onChangeText={text => handleInputChange('bonusAllocated', text)}
                        contentStyle={styles.inputStyle}
                    />
                </View>

                <View style={{ flex: 1 }}>
                    <Text style={styles.inputTitle}>Business City</Text>
                    <TextInput
                        editable={editMode}
                        underlineColor='transparent'
                        value={updatedData?.businessCity}
                        activeUnderlineColor='transparent'
                        style={{ backgroundColor: "transparent" }}
                        onChangeText={text => handleInputChange('businessCity', text)}
                        contentStyle={styles.inputStyle}
                    />
                </View>
            </View>

            <View style={{ flexDirection: 'row', gap: 10, marginTop: 10 }}>
                <View style={{ flex: 1 }}>
                    <Text style={styles.inputTitle}>Business Country</Text>
                    <TextInput
                        editable={editMode}
                        underlineColor='transparent'
                        value={updatedData?.businessCountry}
                        activeUnderlineColor='transparent'
                        style={{ backgroundColor: "transparent" }}
                        onChangeText={text => handleInputChange('businessCountry', text)}
                        contentStyle={styles.inputStyle}
                    />
                </View>

                <View style={{ flex: 1 }}>
                    <Text style={styles.inputTitle}>Business Fax</Text>
                    <TextInput
                        editable={editMode}
                        underlineColor='transparent'
                        value={updatedData?.businessFax}
                        activeUnderlineColor='transparent'
                        style={{ backgroundColor: "transparent" }}
                        onChangeText={text => handleInputChange('businessFax', text)}
                        contentStyle={styles.inputStyle}
                    />
                </View>
            </View>

            <View style={{ flexDirection: 'row', gap: 10, marginTop: 10 }}>
                <View style={{ flex: 1 }}>
                    <Text style={styles.inputTitle}>Business Phone</Text>
                    <TextInput
                        editable={editMode}
                        underlineColor='transparent'
                        value={updatedData?.businessPhone}
                        activeUnderlineColor='transparent'
                        style={{ backgroundColor: "transparent" }}
                        onChangeText={text => handleInputChange('businessPhone', text)}
                        contentStyle={styles.inputStyle}
                    />
                </View>

                <View style={{ flex: 1 }}>
                    <Text style={styles.inputTitle}>Business Product Tier</Text>
                    <TextInput
                        editable={editMode}
                        underlineColor='transparent'
                        value={updatedData?.businessProductTier}
                        activeUnderlineColor='transparent'
                        style={{ backgroundColor: "transparent" }}
                        onChangeText={text => handleInputChange('businessProductTier', text)}
                        contentStyle={styles.inputStyle}
                    />
                </View>
            </View>

            <View style={{ flexDirection: 'row', gap: 10, marginTop: 10 }}>
                <View style={{ flex: 1 }}>
                    <Text style={styles.inputTitle}>Business State</Text>
                    <TextInput
                        editable={editMode}
                        underlineColor='transparent'
                        value={updatedData?.businessState}
                        activeUnderlineColor='transparent'
                        style={{ backgroundColor: "transparent" }}
                        onChangeText={text => handleInputChange('businessState', text)}
                        contentStyle={styles.inputStyle}
                    />
                </View>

                <View style={{ flex: 1 }}>
                    <Text style={styles.inputTitle}>Business Type</Text>
                    <TextInput
                        editable={editMode}
                        underlineColor='transparent'
                        value={updatedData?.businessType}
                        activeUnderlineColor='transparent'
                        style={{ backgroundColor: "transparent" }}
                        onChangeText={text => handleInputChange('businessType', text)}
                        contentStyle={styles.inputStyle}
                    />
                </View>
            </View>

            <View style={{ flexDirection: 'row', gap: 10, marginTop: 10 }}>
                <View style={{ flex: 1 }}>
                    <Text style={styles.inputTitle}>Business Website</Text>
                    <TextInput
                        editable={editMode}
                        underlineColor='transparent'
                        value={updatedData?.businessWebsite}
                        activeUnderlineColor='transparent'
                        style={{ backgroundColor: "transparent" }}
                        onChangeText={text => handleInputChange('businessWebsite', text)}
                        contentStyle={styles.inputStyle}
                    />
                </View>

                <View style={{ flex: 1 }}>
                    <Text style={styles.inputTitle}>Business Zip Code</Text>
                    <TextInput
                        editable={editMode}
                        underlineColor='transparent'
                        value={updatedData?.businessZipCode}
                        activeUnderlineColor='transparent'
                        style={{ backgroundColor: "transparent" }}
                        onChangeText={text => handleInputChange('businessZipCode', text)}
                        contentStyle={styles.inputStyle}
                    />
                </View>
            </View>
            <View style={{ flexDirection: 'row', gap: 10, marginTop: 10 }}>
                <View style={{ flex: 1 }}>
                    <Text style={styles.inputTitle}>Profit Sharing Allocated</Text>
                    <TextInput
                        editable={editMode}
                        underlineColor='transparent'
                        value={String(updatedData?.profitSharingAllocated)}
                        activeUnderlineColor='transparent'
                        style={{ backgroundColor: "transparent" }}
                        onChangeText={text => handleInputChange('profitSharingAllocated', text)}
                        contentStyle={styles.inputStyle}
                    />
                </View>

                <View style={{ flex: 1 }}>
                    <Text style={styles.inputTitle}>Sub Account Code</Text>
                    <TextInput
                        editable={editMode}
                        underlineColor='transparent'
                        value={updatedData?.subaccountCode}
                        activeUnderlineColor='transparent'
                        style={{ backgroundColor: "transparent" }}
                        onChangeText={text => handleInputChange('subaccountCode', text)}
                        contentStyle={styles.inputStyle}
                    />
                </View>
            </View>

            <View style={{ flex: 1 }}>
                <Text style={styles.inputTitle}>Type</Text>
                <TextInput
                    editable={editMode}
                    underlineColor='transparent'
                    value={updatedData?.type}
                    activeUnderlineColor='transparent'
                    style={{ backgroundColor: "transparent" }}
                    onChangeText={text => handleInputChange('type', text)}
                    contentStyle={styles.inputStyle}
                />
            </View>

        </ScrollView >
    )
}

export default OnboardingSidePanel

