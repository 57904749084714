import React, { useState } from 'react'
import { APIProvider, AdvancedMarker, InfoWindow, Map, Marker, Pin } from '@vis.gl/react-google-maps';
import { useTheme } from '@react-navigation/native';
import { Text } from 'react-native-paper';
import { View } from 'react-native';

type mapsProps = {
    markersLocation: {
        carNo: string;
        driverName: string;
        from: string;
        to: string;
        location: {
            latitude: number;
            longitude: number;
        }
    }[];
}

let center = {
    lat: 30.3753,
    lng: 69.3451,
}

const Maps = ({ markersLocation }: mapsProps) => {

    let MAP_ID = '583074a812285da6'
    let API_KEY = 'AIzaSyDKKR9qFpecJ9BhDsw_U1l8A4nfwqTsuLg'

    const { colors } = useTheme();

    const [selectedMarkerIndex, setSelectedMarkerIndex] = useState<number | null>(null);

    return (
        <APIProvider apiKey={API_KEY}>
            <Map
                zoom={3}
                zoomControl
                mapId={MAP_ID}
                center={center}
                fullscreenControl
                style={{ flex: 1 }}
                backgroundColor={'black'}
                gestureHandling={'greedy'}
            >
                {markersLocation &&
                    markersLocation.map((item, index) => {
                        return (
                            <>
                                <AdvancedMarker
                                    key={index}
                                    onClick={() => setSelectedMarkerIndex(index)}
                                    position={{
                                        lat: item?.location?.latitude,
                                        lng: item?.location?.longitude
                                    }}
                                />

                                {selectedMarkerIndex === index && <InfoWindow
                                    key={index}
                                    onCloseClick={() => setSelectedMarkerIndex(null)}
                                    position={{
                                        lat: item.location?.latitude,
                                        lng: item.location?.longitude
                                    }}
                                >
                                    <View>
                                        <Text variant='bodySmall' style={{ color: colors.text, fontWeight: 'bold' }}>Driver Name: <Text style={{ color: colors.text }}>{item.driverName}</Text></Text>
                                        <Text variant='bodySmall' style={{ color: colors.text, fontWeight: 'bold' }}>Car #: <Text style={{ color: colors.text }}>{item.carNo}</Text></Text>
                                        <Text variant='bodySmall' style={{ color: colors.text, fontWeight: 'bold' }}>From: <Text style={{ color: colors.text }}>{item.from}</Text></Text>
                                        <Text variant='bodySmall' style={{ color: colors.text, fontWeight: 'bold' }}>To: <Text style={{ color: colors.text }}>{item.to}</Text></Text>
                                    </View>
                                </InfoWindow>}
                            </>
                        )
                    })}
            </Map>
        </APIProvider>
    )
}

export default Maps