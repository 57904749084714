import { useTheme } from "@react-navigation/native";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  FlatList,
  Image,
  Pressable,
  TouchableOpacity,
  View,
  StyleSheet,
  ActivityIndicator,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { BarChart } from "react-native-gifted-charts";
import { Button, Divider, Icon, Text } from "react-native-paper";

import { employerDetail } from "../../../GlobalContext/types";
import {
  useFunctions,
  useUserContext,
} from "../../../GlobalContext/useUserContext";
import { tasks } from "../../../assets/dummyData/dummyData";
import CustomPieChart from "../../../component/graphs/customPieChart";




const EmployeePage = () => {
  const { user, business } = useUserContext();

  const [employeeData, setEmployeeData] = useState<
    employerDetail | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);

  const { colors } = useTheme();

  const handleFetchEmployee = async () => {
    setIsLoading(true);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const get = useFunctions("fetchEmployee");

    await get({ businessId: business.id, employeeId: user.employeeId })
      .then((res) => {
        setEmployeeData(res.data as employerDetail);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("error fetchEmployee", err.message);
      });
  };

  const additionalInformation = [
    { label: "Address", value: employeeData?.employeeAddress },
    { label: "Phone", value: employeeData?.employeePhone },
    { label: "Email", value: employeeData?.employeeEmail },
    { label: "Code", value: employeeData?.employeeCode },
    { label: "Hobby", value: employeeData?.employeeHobby },
    { label: "Gender", value: employeeData?.employeeGender },
    { label: "Date of Birth", value: employeeData?.employeeDOB },
    { label: "Date of Job", value: employeeData?.employeeDOJ },
    {
      label: "Emergency Contact Name",
      value: employeeData?.employeeEmergencyContactName,
    },
    {
      label: "Emergency Contact Relation",
      value: employeeData?.employeeEmergencyContactRelation,
    },
    {
      label: "Emergency Contact Phone",
      value: employeeData?.employeeEmergencyContactPhone,
    },
    {
      label: "Emergency Contact Email",
      value: employeeData?.employeeEmergencyContactEmail,
    },
  ];

  const benefitCards = [
    { label: "Health Insurance", value: employeeData?.employeeHealthInsurance },
    {
      label: "Health Insurance Amount",
      value: employeeData?.employeeHealthInsuranceAmount,
    },
    { label: "Dental Insurance", value: employeeData?.employeeDentalInsurance },
    {
      label: "Dental Insurance Amount",
      value: employeeData?.employeeDentalInsuranceAmount,
    },
    { label: "Life Insurance", value: employeeData?.employeeLifeInsurance },
    {
      label: "Life Insurance Amount",
      value: employeeData?.employeeLifeInsuranceAmount,
    },
    { label: "PTO", value: employeeData?.employeePTO },
    { label: "PTO Amount", value: employeeData?.employeePTOAmount },
    { label: "401k", value: employeeData?.employee401K },
    { label: "401k Amount", value: employeeData?.employee401KAmount },
    { label: "Stock Options", value: employeeData?.employeeStockOptions },
    {
      label: "Stock Options Amount",
      value: employeeData?.employeeStockOptionsAmount,
    },
    { label: "Bonus", value: employeeData?.employeeBonus },
    { label: "Bonus Amount", value: employeeData?.employeeBonusAmount },
    { label: "Profit Sharing", value: employeeData?.employeeProfitSharing },
  ];

  const employmentDetails = [
    { label: "Job Title", value: employeeData?.jobTitle },
    { label: "Department", value: employeeData?.department },
    { label: "Location", value: employeeData?.location },
    { label: "Employment Status", value: employeeData?.employeeStatus },
    { label: "Employment Type", value: employeeData?.employeeType },
    { label: "Employee Manager", value: employeeData?.employeeManager },
  ];

  const handleEmployeeTaskCompletion = async () => {
    // let getTask = useFunctions('fetchTasks')
    // await getTask({
    //   businessId: business.id,
    //   // employeeId: user.employeeId,
    //   // employeeTaskdata: {
    //   //   createdBy: 'jahanzeb',
    //   //   createdDate: Date.now(),
    //   //   description: 'this is a description',
    //   //   dueDate: Date.now(),
    //   //   name: 'hurry up',
    //   //   notes: 'this is a note',
    //   //   taskStatus: 'in progress'
    //   // }
    // })
    //   .then(res => console.log('fetchTasks ', res.data))
    //   .catch(err => console.log('err fetchTasks', err.message))
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const getTask = useFunctions("employeeTaskCompletion");
    await getTask({
      businessId: business.id,
    })
      .then((res) => console.log("employeeTaskCompletion ", res.data))
      .catch((err) => console.log("err", err.message));
  };

  useEffect(() => {
    handleFetchEmployee();
    handleEmployeeTaskCompletion();
  }, []);

  const [barChartDimension, setBarChartDimension] = useState({ x: 0, y: 0 });
  const [pieChartDimension, setPieChartDimension] = useState({ x: 0, y: 0 });
  const pieChartData = [
    { name: "Payment", y: 100000, color: colors.primary },
    { name: "Paid", y: 50000, color: "blue" },
    { name: "Pending", y: 240500, color: "#333" },
  ];

  if (isLoading) {
    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <ActivityIndicator color={colors.primary} size="large" />
      </View>
    );
  }

  return (
    <View style={{ flex: 1, backgroundColor: colors.background, padding: 10 }}>
      {/* Top bar */}
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          marginBottom: 10,
        }}
      >
        <View
          style={{
            width: "35%",
            alignItems: "center",
            justifyContent: "space-evenly",
            borderTopLeftRadius: 100,
            borderBottomLeftRadius: 100,
            backgroundColor: colors.card,
            flexDirection: "row",
            gap: 30,
            padding: 10,
            elevation: 10,
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
            shadowColor: "#000000",
            shadowOffset: {
              width: 0,
              height: 2,
            },
          }}
        >
          <View>
            <Image
              source={require("../../../assets/picture.png")}
              style={{
                marginLeft: 10,
                resizeMode: "contain",
                width: 40,
                height: 40,
                borderRadius: 1000,
              }}
            />
          </View>

          <View>
            <Text variant="bodyLarge" style={{ color: colors.text }}>
              {employeeData?.employeeName}
            </Text>
            <Text
              variant="bodyMedium"
              style={{ color: colors.text, opacity: 0.8 }}
            >
              {employeeData?.jobTitle}
            </Text>
          </View>

          <View>
            <Text variant="bodyLarge" style={{ color: colors.text }}>
              {employeeData?.employeeTimesheetHours}
            </Text>
            <Text
              variant="bodyMedium"
              style={{ color: colors.text, opacity: 0.8 }}
            >
              Work Hours
            </Text>
          </View>
        </View>

        <Divider
          style={{ height: "100%", width: 2, backgroundColor: colors.border }}
        />

        <View
          style={{
            width: "35%",
            alignItems: "center",
            justifyContent: "space-evenly",
            borderTopRightRadius: 100,
            borderBottomRightRadius: 100,
            backgroundColor: colors.card,
            flexDirection: "row",
            gap: 30,
            padding: 10,
            elevation: 10,
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
            shadowColor: "#000000",
            shadowOffset: {
              width: 0,
              height: 2,
            },
          }}
        >
          <View
            style={{
              backgroundColor: colors.background,
              borderRadius: 100,
              padding: 10,
            }}
          >
            <Icon source="currency-usd" color={colors.text} size={24} />
          </View>

          <View>
            <Text variant="bodyLarge" style={{ color: colors.text }}>
              {employeeData?.employeePayrollAmount}
            </Text>
            <Text
              variant="bodyMedium"
              style={{ color: colors.text, opacity: 0.8 }}
            >
              Earned
            </Text>
          </View>

          <View>
            <Text variant="bodyLarge" style={{ color: colors.text }}>
              {employeeData?.employeeBonusAmount}
            </Text>
            <Text
              variant="bodyMedium"
              style={{ color: colors.text, opacity: 0.8 }}
            >
              Bonus
            </Text>
          </View>
        </View>
      </View>

      <View style={{ flex: 1, flexDirection: "row", gap: 10 }}>
        <View style={{ flex: 4, gap: 10, borderRadius: 10 }}>
          <View style={{ flex: 1, flexDirection: "row", gap: 10 }}>
            <View style={{ flex: 1 }}>
              {/* Time Clock */}
              <View style={{ flex: 1.25 }}>
                <View
                  style={{
                    flex: 1,
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 10,
                    paddingHorizontal: 10,
                    backgroundColor: colors.card,
                    borderRadius: 15,
                    elevation: 10,
                    shadowOpacity: 0.25,
                    shadowRadius: 3.84,
                    shadowColor: "#000000",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    <Image
                      source={require("../../../assets/picture.png")}
                      style={{
                        width: 30,
                        height: 30,
                        resizeMode: "contain",
                        marginTop: 10,
                      }}
                    />

                    <View>
                      <Text variant="bodyLarge" style={{ color: colors.text }}>
                        {employeeData?.employeeName}
                      </Text>
                      <Text
                        variant="bodySmall"
                        style={{ color: colors.text, opacity: 0.8 }}
                      >
                        {employeeData?.jobTitle}
                      </Text>
                    </View>

                    <View>
                      <Text
                        variant="bodySmall"
                        style={{
                          color: colors.text,
                          opacity: 0.8,
                          paddingTop: 7,
                        }}
                      >
                        Time Remaining
                      </Text>
                      <Text
                        variant="bodyMedium"
                        style={{ color: colors.text, paddingTop: 1 }}
                      >
                        {}
                        <Text
                          variant="labelLarge"
                          style={{ color: colors.text, opacity: 0.8 }}
                        >
                          mins
                        </Text>
                      </Text>
                    </View>

                    <View>
                      <Text
                        variant="bodySmall"
                        style={{
                          color: colors.text,
                          opacity: 0.8,
                          paddingTop: 7,
                        }}
                      >
                        Time Spent
                      </Text>
                      <Text
                        variant="bodyMedium"
                        style={{ color: colors.text, paddingTop: 1 }}
                      >
                        <Text
                          variant="labelLarge"
                          style={{ color: colors.text, opacity: 0.8 }}
                        >
                          hrs
                        </Text>
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: 10,
                    }}
                  >
                    <View>
                      <Button
                        icon="clock-in"
                        mode="outlined"
                        onPress={() => console.log("Pressed")}
                        theme={{ colors: { outline: colors.primary } }}
                        textColor="black"
                      >
                        Clock In
                      </Button>
                    </View>
                    <View>
                      <Button
                        mode="outlined"
                        onPress={() => console.log("Pressed")}
                        theme={{ colors: { outline: colors.primary } }}
                        textColor="black"
                      >
                        Request a Day off
                      </Button>
                    </View>
                    <View>
                      <Button
                        icon="clock-out"
                        mode="outlined"
                        onPress={() => console.log("Pressed")}
                        theme={{ colors: { outline: colors.primary } }}
                        textColor="black"
                      >
                        Clock Out
                      </Button>
                    </View>
                  </View>
                </View>
              </View>

              {/* Works Hours */}
              <View style={{ flex: 1, marginTop: 10 }}>
                <View
                  onLayout={(e) =>
                    setBarChartDimension({
                      x: e.nativeEvent.layout.width,
                      y: e.nativeEvent.layout.height,
                    })
                  }
                  style={{
                    flex: 1,
                    backgroundColor: colors.card,
                    marginTop: 10,
                    borderRadius: 15,
                    elevation: 10,
                    shadowOpacity: 0.25,
                    shadowRadius: 3.84,
                    shadowColor: "#000000",
                    shadowOffset: {
                      width: 0,
                      height: 3,
                    },
                    height: barChartDimension.y,
                  }}
                >
                  <View>
                    <Text
                      variant="titleLarge"
                      style={{
                        color: colors.text,
                        fontWeight: "bold",
                        padding: 10,
                        opacity: 0.8,
                      }}
                    >
                      Work Hours
                    </Text>
                  </View>
                  {/* <View style={{ width: barChartDimension.x, height: barChartDimension.y, backgroundColor: 'green' }}> */}
                  <ScrollView horizontal>
                    <BarChart
                      barWidth={10}
                      noOfSections={5}
                      barBorderRadius={40}
                      frontColor={colors.primary}
                      // backgroundColor={'blue'}
                      width={barChartDimension.x * 0.9}
                      data={[
                        { value: 8, label: "M" },
                        { value: 4, label: "T" },
                        { value: 12, label: "W" },
                        { value: 8, label: "Th" },
                        { value: 12, label: "F" },
                        { value: 8, label: "S" },
                        { value: 6, label: "Su" },
                        { value: 8, label: "M" },
                        { value: 4, label: "T" },
                        { value: 12, label: "W" },
                        { value: 8, label: "Th" },
                        { value: 12, label: "F" },
                        { value: 8, label: "S" },
                        { value: 6, label: "Su" },
                        { value: 8, label: "M" },
                        { value: 4, label: "T" },
                        { value: 12, label: "W" },
                        { value: 8, label: "Th" },
                        { value: 12, label: "F" },
                        { value: 8, label: "S" },
                        { value: 6, label: "Su" },
                      ]}
                      yAxisThickness={-10}
                      // xAxisLabelsVerticalShift={10}
                      // xAxisThickness={0}

                      // xAxisThickness={10}
                      hideRules
                      // hideAxesAndRules
                      initialSpacing={10}
                      // barMarginBottom={0}
                      // hideOrigin
                      hideYAxisText={false}
                      // stepValue={10}
                      // showXAxisIndices
                      // xAxisLabelsHeight={0}
                      xAxisLabelTextStyle={{ color: colors.text }}
                      noOfSectionsBelowXAxis={0}
                      height={barChartDimension.y * 0.5}
                      barStyle={{}}
                      // showReferenceLine1
                      // referenceLine1Position={420}
                      // referenceLine1Config={{
                      //     color: 'gray',
                      //     dashWidth: 2,
                      //     dashGap: 3,
                      // }}
                    />
                  </ScrollView>
                </View>
              </View>
            </View>

            {/* Pie Chart Graph */}
            <View
              id="pieChart"
              style={{
                flex: 1,
                backgroundColor: colors.card,
                borderRadius: 15,
                elevation: 10,
                shadowOpacity: 0.25,
                shadowRadius: 3.84,
                shadowColor: "#000000",
                shadowOffset: {
                  width: 0,
                  height: 3,
                },
              }}
              onLayout={(e) => {
                setPieChartDimension({
                  x: e.nativeEvent.layout.width,
                  y: e.nativeEvent.layout.height,
                });
              }}
            >
              <CustomPieChart
                pieChartData={pieChartData}
                chartDimension={pieChartDimension}
                colors={colors}
              />
            </View>

            {/* Employment Details */}
            <View style={{ flex: 1 }}>
              <View
                style={{
                  flex: 1,
                  backgroundColor: colors.card,
                  borderRadius: 15,
                  paddingVertical: 5,
                  elevation: 10,
                  shadowOpacity: 0.25,
                  shadowRadius: 3.84,
                  shadowColor: "#000000",
                  shadowOffset: {
                    width: 0,
                    height: 3,
                  },
                }}
              >
                <View>
                  <Text
                    variant="titleLarge"
                    style={{
                      color: colors.text,
                      fontWeight: "bold",
                      padding: 10,
                      opacity: 0.8,
                    }}
                  >
                    Additional Information
                  </Text>
                </View>

                <ScrollView>
                  {additionalInformation.map((item) => (
                    <>
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          gap: 10,
                          paddingVertical: 10,
                          paddingHorizontal: 10,
                          flexWrap: "wrap", // Add flexWrap property
                        }}
                      >
                        <View>
                          <Text
                            variant="labelLarge"
                            style={{ color: colors.text }}
                          >
                            {item.label} :
                          </Text>
                        </View>

                        <View>
                          <Text
                            variant="bodyMedium"
                            numberOfLines={1}
                            ellipsizeMode="tail"
                            style={{ color: colors.text }}
                          >
                            {item.value}
                          </Text>
                        </View>
                      </View>
                      <Divider style={{ backgroundColor: colors.border }} />
                    </>
                  ))}
                </ScrollView>
              </View>
            </View>
          </View>

          {/* Bottom Row, benefit card, information, etc */}
          <View style={{ flex: 1, flexDirection: "row", gap: 10 }}>
            <View style={{ flex: 1, flexDirection: "row", gap: 10 }}>
              <View style={{ flex: 1 }}>
                <View
                  style={{
                    flex: 1,
                    backgroundColor: colors.card,
                    borderRadius: 15,
                    paddingVertical: 5,
                    marginTop: 10,
                    elevation: 10,
                    shadowOpacity: 0.25,
                    shadowRadius: 3.84,
                    shadowColor: "#000000",
                    shadowOffset: {
                      width: 0,
                      height: 3,
                    },
                    // flexWrap: "wrap", // Add flexWrap property
                  }}
                >
                  <View>
                    <Text
                      variant="titleLarge"
                      style={{
                        color: colors.text,
                        fontWeight: "bold",
                        padding: 10,
                        opacity: 0.8,
                      }}
                    >
                      Benefit Cards
                    </Text>
                  </View>
                  <ScrollView>
                    {benefitCards.map((item) => (
                      <>
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            gap: 10,
                            paddingVertical: 10,
                            paddingHorizontal: 10,
                            flexWrap: "wrap", // Add flexWrap property
                          }}
                        >
                          <View>
                            <Text
                              variant="labelLarge"
                              style={{ color: colors.text }}
                            >
                              {item.label} :
                            </Text>
                          </View>

                          <View>
                            <Text
                              variant="bodyMedium"
                              style={{ color: colors.text }}
                            >
                              {item.value?.toString()}
                            </Text>
                          </View>
                        </View>
                        <Divider style={{ backgroundColor: colors.border }} />
                      </>
                    ))}
                  </ScrollView>
                </View>
              </View>
              {/* Employment Details */}
              <View style={{ flex: 1 }}>
                <View
                  style={{
                    flex: 1,
                    backgroundColor: colors.card,
                    borderRadius: 15,
                    paddingVertical: 5,
                    marginTop: 10,
                    elevation: 10,
                    shadowOpacity: 0.25,
                    shadowRadius: 3.84,
                    shadowColor: "#000000",
                    shadowOffset: {
                      width: 0,
                      height: 3,
                    },
                  }}
                >
                  <View>
                    <Text
                      variant="titleLarge"
                      style={{
                        color: colors.text,
                        fontWeight: "bold",
                        padding: 10,
                        opacity: 0.8,
                      }}
                    >
                      Employment Details
                    </Text>
                  </View>
                  {employmentDetails.map((item) => (
                    <>
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          gap: 10,
                          paddingVertical: 10,
                          paddingHorizontal: 10,
                          flexWrap: "wrap", // Add flexWrap property
                        }}
                      >
                        <View>
                          <Text
                            variant="labelLarge"
                            style={{ color: colors.text }}
                          >
                            {item.label} :
                          </Text>
                        </View>

                        <View>
                          <Text
                            variant="bodyMedium"
                            style={{ color: colors.text }}
                          >
                            {item.value}
                          </Text>
                        </View>
                      </View>
                      <Divider style={{ backgroundColor: colors.border }} />
                    </>
                  ))}
                </View>
              </View>
            </View>

            <View style={{ flex: 1 }}>
              {/* Document */}

              <FlatList
                ListHeaderComponent={
                  <Text
                    variant="titleLarge"
                    style={{
                      color: colors.text,
                      fontWeight: "bold",
                      padding: 10,
                      opacity: 0.8,
                    }}
                  >
                    Tasks
                  </Text>
                }
                data={tasks}
                ItemSeparatorComponent={() => (
                  <Divider style={{ backgroundColor: colors.border }} />
                )}
                style={{
                  backgroundColor: colors.card,
                  borderRadius: 10,
                  paddingVertical: 5,
                  marginTop: 10,
                  elevation: 10,
                  shadowOpacity: 0.25,
                  shadowRadius: 3.84,
                  shadowColor: "#000000",
                  shadowOffset: {
                    width: 0,
                    height: 3,
                  },
                }}
                renderItem={({ item }) => {
                  const isCompleted = item.status === "Completed";

                  return (
                    <Pressable
                      style={({ pressed }) => ({
                        backgroundColor: pressed
                          ? colors.background
                          : "transparent",
                        paddingHorizontal: 10,
                        paddingVertical: 10,
                      })}
                    >
                      <View
                        style={[
                          styles.card,
                          isCompleted && styles.completedCard,
                          item.status === "In Progress" && {
                            borderLeftColor: colors.primary,
                            borderLeftWidth: 5,
                          },
                          item.status === "Completed" && {
                            borderLeftColor: "#23c079",
                            borderLeftWidth: 5,
                          },
                          item.status === "Due" && {
                            borderLeftColor: "#f67b2f",
                            borderLeftWidth: 5,
                          },
                        ]}
                      >
                        <Text style={styles.description}>
                          {item.description}
                        </Text>
                        {isCompleted ? (
                          <Text style={styles.status}>Completed</Text>
                        ) : (
                          <TouchableOpacity onPress={() => {}}>
                            <Text
                              style={{
                                ...styles.completeButton,
                                color: colors.primary,
                              }}
                            >
                              Mark Complete
                            </Text>
                          </TouchableOpacity>
                        )}
                      </View>
                    </Pressable>
                  );
                }}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  card: {
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    padding: 16,
    marginBottom: 16,
    elevation: 10,
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    shadowColor: "#000000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
  },
  completedCard: {
    backgroundColor: "#E5E5E5",
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 8,
  },
  description: {
    fontSize: 16,
    marginBottom: 8,
  },
  status: {
    fontSize: 16,
    color: "#23c079",
    fontWeight: "bold",
  },
  completeButton: {
    fontSize: 16,
    fontWeight: "bold",
  },
  titleContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },

  dueDate: {
    fontSize: 12,
    color: "gray",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginLeft: 65,
  },
});
export default EmployeePage;
