import { useTheme } from "@react-navigation/native";
import React, { useState } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { ActivityIndicator, Icon, Text } from "react-native-paper";

import { CustomColors } from "../../../api/customColors";
import { Payment } from "../../../api/paymentsApi";
import { paymentData } from "../../../assets/dummyData/dummyData";
import { currencyValueFormatter } from "../../../utils/currencyPercentFormatter";
import { isWeb } from "../../Common/isWeb";
import { Header, Row, Table } from "../../tableComponent/tableComponent";
import { w } from "../../util/dimension";

const PaymentTable = ({
  modifiedData,
  setModifiedData,
}: {
  modifiedData: any[];
  setModifiedData: any;
}) => {
  const { colors }: { colors: CustomColors } = useTheme();

  const [width, setWidth] = useState(w);
  const [noOfRows, setNoOfRows] = useState(10);

  const [currentPage, setCurrentPage] = useState(0);
  const from = currentPage * noOfRows;
  const to = from + noOfRows;

  const styles = StyleSheet.create({
    tableSubStatusText: {
      gap: 10,
      borderRadius: 5,
      paddingVertical: 1,
      flexDirection: "row",
      alignItems: "center",
      paddingHorizontal: 10,
      justifyContent: "center",
    },
    tableSelectionBox: {
      backgroundColor: colors.background,
      borderWidth: 1,
      borderColor: colors.border,
      borderRadius: 5,
    },
  });

  return (
    <View
      onLayout={(e) => setWidth(e.nativeEvent.layout.width)}
      style={{
        flex: 1,
        borderColor: colors.border,
        borderRadius: 10,
        borderWidth: 1,
        marginTop: 10,
      }}
    >
      <Table viewStyle={{ flex: isWeb ? 1 : 0 }}>
        <Header
          dataState={paymentData}
          viewStyle={{ borderBottomWidth: 1, borderColor: colors.border }}
          setDataState={setModifiedData}
        >
          <Header.Title
            value="paymentID"
            columnWidth={width * 0.15}
            textStyle={{ paddingVertical: 10, textTransform: "capitalize" }}
          >
            Payment ID
          </Header.Title>
          <Header.Title
            value="paymentDate"
            isID
            columnWidth={width * 0.15}
            isNumeric
            textStyle={{ paddingVertical: 10, textTransform: "capitalize" }}
          >
            Payment Date
          </Header.Title>
          <Header.Title
            value="paymentAmount"
            isID
            columnWidth={width * 0.15}
            isNumeric
            textStyle={{ paddingVertical: 10, textTransform: "capitalize" }}
          >
            Payment Amount
          </Header.Title>{" "}
          <Header.Title
            value="paidAmount"
            isID
            columnWidth={width * 0.15}
            isNumeric
            textStyle={{ paddingVertical: 10, textTransform: "capitalize" }}
          >
            Paid Amount
          </Header.Title>
          <Header.Title
            value="status"
            columnWidth={width * 0.15}
            textStyle={{ paddingVertical: 10, textTransform: "capitalize" }}
          >
            Status
          </Header.Title>
          <Header.Title
            value="paymentMethod"
            columnWidth={width * 0.15}
            textStyle={{ paddingVertical: 10, textTransform: "capitalize" }}
          >
            Payment Method
          </Header.Title>
          <Header.Title
            value="cardNo"
            isNumeric
            columnWidth={width * 0.15}
            textStyle={{ paddingVertical: 10, textTransform: "capitalize" }}
          >
            Card No
          </Header.Title>
          <Header.Title
            value="creationDate"
            columnWidth={width * 0.15}
            textStyle={{ paddingVertical: 10, textTransform: "capitalize" }}
          >
            Creation Date
          </Header.Title>
        </Header>

        <ScrollView>
          {/*  activity notifier that will spin till data is back */}

          {modifiedData.length > 0 ? (
            modifiedData.slice(from, to).map((item: Payment, index: any) => {
              const successIconColor = "#165e3d";
              const pendingIconColor = "#b5850b";
              const declineIconColor = "#b83131";
              const createIconColor = "#3d42ad";

              let iconName;
              let iconColor;

              if (item.status === "success") {
                iconColor = successIconColor;
                iconName = "check-circle";
              } else if (item.status === "abandoned") {
                iconColor = declineIconColor;
                iconName = "minus-circle";
              } else if (item.status === "Pending") {
                iconName = "clock-time-three";
                iconColor = pendingIconColor;
              } else if (item.status === "Create") {
                iconColor = createIconColor;
                iconName = "flag";
              }

              return (
                <View style={{ flexDirection: "row" }}>
                  <Row
                    key={index}
                    rowHeight={40}
                    viewStyle={{
                      paddingVertical: 5,
                      maxHeight: 40,
                      alignItems: "center",
                      borderBottomWidth: StyleSheet.hairlineWidth,
                      borderColor: colors.border,
                    }}
                  >
                    <Row.Cell
                      rowWidth={width * 0.15}
                      onPress={() => {}}
                      value={item?.id}
                    />
                    <Row.Cell
                      rowWidth={width * 0.15}
                      value={new Date(item.paid_at).toLocaleDateString()}
                    />
                    <Row.Cell
                      rowWidth={width * 0.15}
                      value={currencyValueFormatter(item?.requested_amount)}
                    />
                    <Row.Cell
                      rowWidth={width * 0.15}
                      value={currencyValueFormatter(item?.amount)}
                    />{" "}
                    <View
                      style={{
                        width: width * 0.15,
                        justifyContent: "center",
                        alignItems: "flex-start",
                      }}
                    >
                      <View
                        style={{
                          ...styles.tableSubStatusText,
                          paddingHorizontal: 5,
                          backgroundColor: colors.background,
                        }}
                      >
                        <Icon source={iconName} size={24} color={iconColor} />
                        <Text variant="bodySmall" style={{ color: iconColor }}>
                          {item?.status.toLocaleUpperCase()}
                        </Text>
                      </View>
                    </View>
                    <Row.Cell
                      rowWidth={width * 0.15}
                      value={
                        item?.authorization?.channel
                          ? item?.authorization?.channel.toUpperCase()
                          : "N/A"
                      }
                    />
                    <Row.Cell
                      rowWidth={width * 0.15}
                      value={
                        item.authorization?.last4
                          ? `*****${item.authorization?.last4}`
                          : "N/A"
                      }
                    />
                    <Row.Cell
                      rowWidth={width * 0.15}
                      value={new Date(item.created_at).toLocaleDateString()}
                    />
                    {/* <View
                    style={{ alignItems: "center", justifyContent: "center" }}
                  >
                    <TouchableOpacity>
                      <Icon source="file-pdf-box" size={24} color="#b83131" />
                    </TouchableOpacity>
                  </View>

                  {["Pending", "Declined"].includes(item?.status) && (
                    <View
                      style={{ alignItems: "center", justifyContent: "center" }}
                    >
                      <TouchableOpacity>
                        <Icon
                          source="close-circle"
                          size={20}
                          color="#b83131"
                        />
                      </TouchableOpacity>
                    </View>
                  )} */}
                  </Row>
                </View>
              );
            })
          ) : (
            <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <ActivityIndicator size="large" color={colors.primary} />
          </View>
          )}
        </ScrollView>
      </Table>
      <View style={{ width: "100%", paddingBottom: 10, paddingRight: 20 }}>
        <Table.Pagination
          currentSelectedRows={noOfRows}
          setRows={setNoOfRows}
          totalPages={Table.TotalPages(modifiedData, noOfRows)}
          label={`${from + 1} - ${to} of ${modifiedData.length}`}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </View>
    </View>
  );
};

export default PaymentTable;
