import React from 'react'
import { TransitionPresets, createStackNavigator } from '@react-navigation/stack'
import OnBoarding from '../src/screens/OnBoarding'
import AddOnBoarding from '../src/screens/AddOnBoarding'
import OnBoardingAddBusiness from '../src/screens/OnBoardingAddBusiness'

const Stack = createStackNavigator()

const OnBoardingStack = () => {
    return (
        <Stack.Navigator screenOptions={{ headerShown: false, ...TransitionPresets.RevealFromBottomAndroid }}>
            <Stack.Screen name='OnBoarding' component={OnBoarding} />
            <Stack.Screen name='AddOnboarding' component={AddOnBoarding} />
            <Stack.Screen name='AddBusiness' component={OnBoardingAddBusiness} />
        </Stack.Navigator>
    )
}

export default OnBoardingStack