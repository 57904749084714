import { View } from "react-native";
import React from "react";
import Input from "../../Common/Input/Input";
import { Text } from "react-native-paper";
import AddressInput from "./AddressFields";
import { useForm, Controller } from "react-hook-form";

let BusinessInformation = ({ editMode }: { editMode: boolean }) => {
  const { control, handleSubmit } = useForm();

  return (
    <View
      style={{
        gap: 10,
        flexWrap: "wrap",
        flexDirection: "row",
      }}
    >
      <Input
        disabled={!editMode}
        label={"Business Name"}
        style={{ width: "48%" }}
      />
      <Input
        disabled={!editMode}
        label={"Business Description"}
        style={{ width: "48%",height:"25%"}}
        multiline
      />
      <Controller
        control={control}
        render={({ field, fieldState }) => (
          <View style={{ width: '48%' }}>
            <AddressInput
              label="Address Line1"
              style={{ width: '100%' }}
              onChange={(value: any) => field.onChange(value)}
              value={field.value}
              disabled={!editMode}

            />
            {fieldState.error && (
              <Text style={{ color: "red" }}>
                {fieldState.error.message}
              </Text>
            )}
          </View>
        )}
        name="addressLine1"
        rules={{ required: "Address Line 1 is required" }}
        defaultValue=""
      />
      <Controller
        control={control}
        render={({ field, fieldState }) => (
          <View style={{ width: '48%' }}>
            <AddressInput
              label="Address Line2"
              style={{ width: '100%' }}
              onChange={(value: any) => field.onChange(value)}
              value={field.value}
              disabled={!editMode}

            />
            {fieldState.error && (
              <Text style={{ color: "red" }}>
                {fieldState.error.message}
              </Text>
            )}
          </View>
        )}
        name="addressLine2"
        rules={{ required: "Address Line 2 is required" }}
        defaultValue=""
      />

      <Controller
        control={control}
        render={({ field, fieldState }) => (
          <View style={{ width: '48%' }}>
            <AddressInput
              label="City"
              style={{ width: '100%' }}
              onChange={(value: any) => field.onChange(value)}
              value={field.value}
              disabled={!editMode}

            />
            {fieldState.error && (
              <Text style={{ color: "red" }}>
                {fieldState.error.message}
              </Text>
            )}
          </View>
        )}
        name="city"
        rules={{ required: "City is required" }}
        defaultValue=""
      />
      <Controller
        control={control}
        render={({ field, fieldState }) => (
          <View style={{ width: '48%' }}>
            <AddressInput
              label="State"
              style={{ width: '100%' }}
              onChange={(value: any) => field.onChange(value)}
              value={field.value}
              disabled={!editMode}

            />
            {fieldState.error && (
              <Text style={{ color: "red" }}>
                {fieldState.error.message}
              </Text>
            )}
          </View>
        )}
        name="state"
        rules={{ required: "State is required" }}
        defaultValue=""
      />
      <Controller
        control={control}
        render={({ field, fieldState }) => (
          <View style={{ width: '48%' }}>
            <AddressInput
              label="ZipCode"
              style={{ width: '100%' }}
              onChange={(value: any) => field.onChange(value)}
              value={field.value}
              disabled={!editMode}

            />
            {fieldState.error && (
              <Text style={{ color: "red" }}>
                {fieldState.error.message}
              </Text>
            )}
          </View>
        )}
        name="zipCode"
        rules={{ required: "ZipCode is required" }}
        defaultValue=""
      />

      <Input
        disabled={!editMode}
        label={"Phone Number"}
        style={{ width: '48%' }}
      />
      <Input
        disabled={!editMode}
        label={"Email Address"}
        style={{ width: '48%' }}
      />

      <Input
        disabled={!editMode}
        label={"Ownership & Legal Structure"}
        style={{ width: '48%' }}

      />
      <Input
        disabled={!editMode}
        label={"Website"}
        style={{ width: '48%' }}

      />

    </View>
  );
};
export default BusinessInformation;
