import { StyleSheet, View } from 'react-native'
import React from 'react'
import { Button, Checkbox, Text } from 'react-native-paper'
import Input from '../../Common/Input/Input'
import { useTheme } from '@react-navigation/native'

let ManageNotifications = ({ setUserNotificationConfig, userNotificationConfig, editMode }: {
  setUserNotificationConfig: any;
  userNotificationConfig: any;
  editMode: boolean;
}) => {

  const {colors} = useTheme();
  const styles = StyleSheet.create({
    button: {
      borderRadius: 10,
      backgroundColor: colors.card,
    }
  })
  
  return (
    <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 10 }}>

      {/* manage notification */}
      <View style={{ flex: 1 }}>
        <Text variant='bodyLarge' style={{color:colors.text, fontWeight: 'bold' }}>Manege Notifications</Text>

        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Checkbox color={colors.primary} status='checked' />
          <Text variant='bodyMedium' style={{color:colors.text}}>Order request</Text>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Checkbox color={colors.primary} status='checked' />
          <Text variant='bodyMedium' style={{color:colors.text}}>Inventory shortage</Text>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Checkbox color={colors.primary} status='checked' />
          <Text variant='bodyMedium' style={{color:colors.text}}>Order approval</Text>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Checkbox color={colors.primary} status='checked' />
          <Text variant='bodyMedium' style={{color:colors.text}}>Timesheet approval</Text>
        </View>
      </View>

      {/* configure e-mail and sms */}
      <View style={{ flex: 1 }}>
        <Text variant='bodyLarge' style={{color:colors.text, fontWeight: 'bold' }}>Configure e-mail and sms notification</Text>

        <View style={{ flexDirection: 'row', gap: 10, marginTop: 10 }}>

          <Button theme={{colors:{ onSurfaceDisabled:colors.border}}} disabled={!editMode} mode='contained' onPress={() => setUserNotificationConfig('email')} style={[styles.button, { backgroundColor: userNotificationConfig === 'email' ? colors.primary : colors.card }]} textColor={colors.text}>Email</Button>
          <Button theme={{colors:{ onSurfaceDisabled:colors.border}}} disabled={!editMode} mode='contained' onPress={() => setUserNotificationConfig('sms')} style={[styles.button, { backgroundColor: userNotificationConfig === 'sms' ? colors.primary : colors.card }]} textColor={colors.text}>SMS</Button>
          <Button theme={{colors:{ onSurfaceDisabled:colors.border}}} disabled={!editMode} mode='contained' onPress={() => setUserNotificationConfig('both')} style={[styles.button, { backgroundColor: userNotificationConfig === 'both' ? colors.primary : colors.card }]} textColor={colors.text}>Both</Button>

        </View>

        {['sms', 'both'].includes(userNotificationConfig) && <Input disabled={!editMode} label='Enter phone number' />}
        {['email', 'both'].includes(userNotificationConfig) && <Input disabled={!editMode} label='Enter email address' value={'example@example.com'} />}

      </View>

    </View>
  )
}

export default ManageNotifications
