import { StyleSheet } from "react-native";

export const globalStyles = StyleSheet.create({
  title: {
    fontWeight: "bold",
    marginTop: 5,
    marginBottom: 10,
  },

  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  navigationContainer: {
    flex: 1,
    padding: 20,
  },
  navigationItem: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#000",
    padding: 10,
  },
  navigationHeader: {
    height: 80,
    backgroundColor: "#f4511e",
    justifyContent: "center",
    alignItems: "center",
  },
  navigationLogo: {
    width: 50,
    height: 50,
  },
  header: {
    backgroundColor: "#333",
    borderBottomWidth: 0,
  },
});

export const formStyles = StyleSheet.create({
  title: {
    fontFamily: "Poppins",
    fontSize: 30,
    fontWeight: "500",
    lineHeight: 45,
    letterSpacing: 0,
    textAlign: "left",
  },
  form: {
    width: "100%",
  },
  input: {
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
    padding: 10,
    marginBottom: 10,
  },
  halfInput: {
    width: "48%",
  },
});

export const rowTheme = StyleSheet.create({
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    flex: 1,
    backgroundColor: "#F8F8F8",
    alignItems: "center",
  },
});

export const colTheme = StyleSheet.create({
  col: {
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: 10,
  },
});

export const textStyles = StyleSheet.create({
  heading: {
    fontSize: 50,
    fontWeight: "bold",
    color: "#212121",
    fontStyle: "normal",
  },
  subheading: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#323a45",
  },
  body: {
    fontSize: 16,
    color: "#5b616b",
  },
  caption: {
    fontSize: 12,
    color: "#494440",
  },
});

export const iconStyles = StyleSheet.create({
  image: {
    width: "50%",
    height: "50%",
    resizeMode: "contain",
  },
});

export const cardStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#EFEFEF",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    borderRadius: 10,
    padding: 20,
    shadowColor: "#000000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    flex: 1,
    flexGrow: 1,
    margin: 10,
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: 10,
    padding: 20,
    marginVertical: 10,
    flex: 1,
    flexGrow: 1,
    shadowColor: "#000000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  elevated: {
    shadowColor: "#000000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  cardTitle: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 10,
  },
  innerCard: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  innerCardButton: {
    backgroundColor: "#FFFFFF",
    borderRadius: 5,
    padding: 10,
    marginHorizontal: 5,
  },
  innerCardButtonText: {
    fontSize: 16,
    fontWeight: "bold",
  },
});

export const fontStyles = StyleSheet.create({
  h1: {
    fontFamily: "Lato",
    fontSize: 24,
    fontWeight: "500",
    lineHeight: 17,
    letterSpacing: 0,
    textAlign: "left",
    color: "#000000D1",
  },
  h2: {
    fontFamily: "Lato",
    fontSize: 20,
    fontWeight: "400",
    lineHeight: 17,
    letterSpacing: 0,
    textAlign: "left",
    color: "#000000D1",
  },
  h3: {
    margin: 0,
    fontFamily: "Lato",
    fontWeight: "600",
    fontSize: 16,
    lineHeight: 1.5,
  },
  body: {
    fontFamily: "Lato",
    fontSize: 18,
    fontWeight: "400",
    lineHeight: 17,
    letterSpacing: 0,
    textAlign: "left",
    color: "#000000D1",
  },
  caption: {
    fontFamily: "Lato",
    fontSize: 24,
    fontWeight: "600",
    lineHeight: 17,
    letterSpacing: 0,
    textAlign: "left",
    color: "#000000D1",
  },
  tableAmount: {
    fontFamily: "Lato",
    fontSize: 18,
    fontWeight: "700",
    lineHeight: 17,
    letterSpacing: 0,
    textAlign: "right",
  },
  body2: {
    margin: 0,
    fontFamily: "Lato",
    fontWeight: "400",
    fontSize: 18,
    lineHeight: 1.5,
  },
});
