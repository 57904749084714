import { httpsCallable } from "firebase/functions";
import React, { useContext, useEffect, useState } from "react";
import { View, ImageBackground } from "react-native";
import { Text } from "react-native-paper";

import { GlobalContext } from "../../../GlobalContext/GlobalContext";
import { functions } from "../../../firebase/firebaseConfig";
import { light } from "../../util/colors";
import { currencyFormatter, currencySymbol } from "../../util/constants";
export interface RevenueDisplay {
  title: string;
  value: string;
  color: string;
}

export interface RevenueData {
  profit: number;
  loss: number;
  revenue: number;
}

const Revenue = () => {
  const { business } = useContext(GlobalContext);
  const [revenueData, setRevenueData] = useState({} as any);
  const [dashboard_Status, setDashboard_Status] = useState(
    [] as RevenueDisplay[]
  );

  useEffect(() => {
    if (business) {
      const { id } = business;
      const getRevenueData = httpsCallable(functions, "fetchRevenue");
      if (id) {
        getRevenueData({ businessId: id })
          .then((result: any) => {
            const data: any = [];
            const profit = result.data.profit || 0;
            const loss = result.data.loss || 0;
            const revenue = result.data.revenue || 0;
            // Read result of the Cloud Function.
            /** @type {any} */

            data.push(
              {
                title: "Profit",
                value: `${currencySymbol}${currencyFormatter(profit)}`,
                color: light.colors.success,
              },
              {
                title: "Loss",
                value: `${currencySymbol}${currencyFormatter(loss)}`,
                color: light.colors.danger,
              },
              {
                title: "Revenue",
                value: `${currencySymbol}${currencyFormatter(revenue)}`,
                color: light.colors.primary,
              }
            );
            setRevenueData(data);
          })
          .catch((error) => {
            console.error("Error retrieving sales data:", error);
          });
      }
    }
  }, [business]);

  useEffect(() => {
    if (revenueData.length > 0) {
      setDashboard_Status(revenueData);
    }
  }, [revenueData]);

  return (
    <ImageBackground
      source={require("../../../assets/icons/dashboard_image.png")}
      style={{
        flex: 1,
        margin: 0,
        flexGrow: 1,
        padding: 10,
        width: "auto",
        height: "auto",
        borderRadius: 10,
        overflow: "hidden",
        backgroundColor: light.colors.primary,
      }}
      resizeMode="cover"
    >
      {/* use a blur view to show screening is loading */}
      <View style={{ flex: 1, flexDirection: "row" }}>
        {dashboard_Status.slice(0, -1).map((item, index) => {
          return (
            <View
              key={index}
              style={{
                width: "50%",
                padding: 20,
                justifyContent: "center",
              }}
            >
              <View
                style={{
                  backgroundColor: light.colors.background,
                  borderRadius: 20,
                  padding: 20,
                  alignItems: "center",
                }}
              >
                <Text
                  variant="titleLarge"
                  style={{ color: item.color, fontWeight: "bold" }}
                  adjustsFontSizeToFit
                  numberOfLines={1}
                >
                  {item.value}
                </Text>
                <Text
                  variant="titleMedium"
                  style={{ color: light.colors.text }}
                >
                  {item.title}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
      <View style={{ flex: 1, flexDirection: "row", justifyContent: "center" }}>
        {dashboard_Status.slice(2).map((item, index) => {
          return (
            <View key={index} style={{ width: "50%", padding: 20 }}>
              <View
                style={{
                  backgroundColor: light.colors.background,
                  borderRadius: 20,
                  padding: 20,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  variant="titleLarge"
                  style={{ color: item.color, fontWeight: "bold" }}
                  adjustsFontSizeToFit
                  numberOfLines={1}
                >
                  {item.value}
                </Text>
                <Text
                  variant="titleMedium"
                  style={{ color: light.colors.text }}
                >
                  {item.title}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
    </ImageBackground>
  );
};

export default Revenue;
