import { useTheme } from "@react-navigation/native";
import * as DocumentPicker from "expo-document-picker";
import React, { useContext, useState } from "react";
import { FlatList, Pressable, ScrollView, View } from "react-native";
import { Divider, Icon, Text } from "react-native-paper";

import { GlobalContext } from "../../../GlobalContext/GlobalContext";
import { useFunctions, useUserContext } from "../../../GlobalContext/useUserContext";
import { CustomColors } from "../../../api/customColors";
import Input from "../../../component/Common/Input/Input";
import CustomButton from "../../../component/Common/button";
import { isWeb } from "../../../component/Common/isWeb";
import CustomDropdown from "../../../component/CustomDropdown/CustomDropdown";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase/firebaseConfig";

let FileUploader: any;
if (isWeb) FileUploader = require("react-drag-drop-files").FileUploader;

const PersonalAddUser = (props: any) => {
  const { colors }: { colors: CustomColors } = useTheme();
  const {business} = useContext(GlobalContext);
  const [currentStep, setCurrentStep] = useState(1);
  const [genderDropdownValue, setGenderDropdownValue] = useState(null);
  const [employeeStatusValue, setEmployeeStatusValue] = useState(null);
  const [selectedFilesName, setSelectedFilesName] = useState<string[]>([]);
  const [selectedFileTypes, setSelectedFileTypes] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedFileTypesWeb, setSelectedFileTypesWeb] = useState<string[]>(
    []
  );
  const [employee, setEmployee] = useState({} as any);
  const [employeeValidation, setEmployeeValidation] = useState({
    name: false,
    address: false,
    phone: false,
    email: false,
  } as any);

  const addEmployeeFunction = async ()=>{
    const addSale = httpsCallable(functions, "addSale");
    await addSale({ businessId: business.id, employee })
      .then(() => {
        // Read result of the Cloud Function.
        /** @type {any} */
        // transaction save was successful
        props.navigation.navigate("PersonalEmployeePageHome");
    })
      .catch((error) => {
        // Getting the Error details.
        // eslint-disable-next-line no-console
        console.error("Error adding sale data:", error);
      });

  }

  const gotoNextStep = () => {
    if (currentStep < 5) setCurrentStep(currentStep + 1);
    else if (currentStep === 5){

        addEmployeeFunction();
    }
  };
  const goPreviousStep = () => {
    console.log(currentStep > 1);
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  const handelCancel = () => {
    props.navigation.goBack();
  };

  const stepText = (stepNumber: number) => {
    if (stepNumber === 1) return "Employee Information";
    else if (stepNumber === 2) return "Employment Details";
    else if (stepNumber === 3) return "Employee Emergency Contact";
    else if (stepNumber === 4) return "Employee Benefits";
    else if (stepNumber === 5) return "Upload Employee Documents";
  };

  const StepComponent = ({
    title,
    icon,
    size,
    stepNumber,
  }: {
    title: string;
    icon: string;
    size: number;
    stepNumber: number;
  }) => {
    return (
      <View
        style={{
          flexDirection: "row",
          gap: 10,
          padding: 10,
          alignItems: "center",
          overflow: "hidden",
          borderRadius: 10,
          backgroundColor:
            stepNumber === currentStep ? colors.card : "transparent",
        }}
      >
        <Icon
          source={icon}
          size={size}
          color={stepNumber === currentStep ? colors.primary : colors.subText}
        />
        <View>
          <Text style={{ color: colors.subText, opacity: 0.8 }}>{title}</Text>
          <Text
            style={{
              color: stepNumber === currentStep ? colors.primary : colors.text,
              fontWeight: stepNumber === currentStep ? "bold" : "normal",
              marginTop: 5,
            }}
          >
            {stepText(stepNumber)}
          </Text>
        </View>
      </View>
    );
  };

  const employeeGenderData = [
    { label: "Male", value: "MALE" },
    { label: "Female", value: "FEMALE" },
    { label: "Other", value: "OTHER" },
    { label: "Don't Disclose", value: "DONT_DISCLOSE" },
  ];

  const employeeStatus = [
    { label: "Active", value: "ACTIVE" },
    { label: "Inactive", value: "INACTIVE" },
    { label: "Terminated", value: "TERMINATED" },
  ];

  // handleDelete selected files
  const handleDelete = (data: any, index: number) => {
    const filteredData: string[] = [];

    selectedFilesName.map((item) => {
      if (item !== data) {
        filteredData.push(item);
      }
    });

    setSelectedFilesName(filteredData);
  };

  const handleChange = async (file: any) => {
    const names: string[] = [];

    const fileReadPromises: Promise<void>[] = Object.keys(file).map(
      (keyValue: string) => {
        return new Promise<void>((resolve) => {
          const f = new FileReader();

          f.onload = () => {
            names.push(file[keyValue].name);
            resolve();
          };

          f.readAsDataURL(file[keyValue]);
        });
      }
    );

    await Promise.all(fileReadPromises);
    setSelectedFilesName(names);
  };

  const pickDocument = async () => {
    try {
      const result = await DocumentPicker.getDocumentAsync({
        multiple: true,
        type: selectedFileTypes,
      });

      if (result.assets) {
        const names = result.assets.map((item) => item.name);
        setSelectedFilesName(names);
      }
    } catch (error) {
      console.error("Error picking documents: ", error);
    }
  };

  // for dropdown
  const fileUploadTypes = [
    { label: "Excel", value: "excel" },
    { label: "Word", value: "word" },
    { label: "Power Point", value: "powerpoint" },
    { label: "PDF", value: "pdf" },
  ];

  return (
    <View style={{ flex: 1, backgroundColor: colors.background }}>
      <View style={{ flex: 1, padding: 10 }}>
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Text
            variant="bodyLarge"
            style={{ color: colors.subText, opacity: 0.8 }}
          >
            Add User
          </Text>
          <Text
            variant="titleLarge"
            style={{ color: colors.text, fontWeight: "bold" }}
          >
            {stepText(currentStep)}
          </Text>
        </View>

        <View style={{ flex: 8, flexDirection: "row", gap: 10 }}>
          {/* left side  */}
          <View style={{ flex: 1 }}>
            <StepComponent
              title="Step 1"
              icon="information-outline"
              size={18}
              stepNumber={1}
            />
            <StepComponent
              title="Step 2"
              icon="account-details-outline"
              size={18}
              stepNumber={2}
            />
            <StepComponent
              title="Step 3"
              icon="account-box-outline"
              size={18}
              stepNumber={3}
            />
            <StepComponent
              title="Step 4"
              icon="account-heart-outline"
              size={18}
              stepNumber={4}
            />
            <StepComponent
              title="Step 5"
              icon="update"
              size={18}
              stepNumber={5}
            />
          </View>

          {/* right side */}
          <View style={{ flex: 3, flexDirection: "row" }}>
            <View
              style={{
                flex: 2,
                backgroundColor: colors.card,
                borderRadius: 10,
                overflow: "hidden",
              }}
            >
              <ScrollView contentContainerStyle={{ padding: 10 }}>
                {/* employee information */}
                {currentStep === 1 && (
                  <View style={{ flex: 1 }}>
                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>ID</Text>
                        <Input label="ID" style={{ flex: 1 }} />
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>Name</Text>
                        <Input
                          label="Name"
                          style={{ flex: 1 }}
                          value={employee.name}
                          error={employeeValidation.name}
                          errorText="Name is required"
                          onChangeText={(text) => {
                            setEmployee({ ...employee, name: text });
                          }}
                        />
                      </View>
                    </View>

                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>Address</Text>
                        <Input
                          label="Address"
                          style={{ flex: 1 }}
                          value={employee.address}
                          error={employeeValidation.address}
                          errorText="Address is required"
                          onChangeText={(text) => {
                            setEmployee({ ...employee, address: text });
                          }}
                        />
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>Phone</Text>
                        <Input
                          label="Phone"
                          style={{ flex: 1 }}
                          error={employeeValidation.phone}
                          errorText="Phone is required"
                          onChangeText={(text) => {
                            setEmployee({ ...employee, phone: text });
                          }}
                        />
                      </View>
                    </View>

                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>Email</Text>
                        <Input
                          label="Email"
                          style={{ flex: 1 }}
                          error={employeeValidation.email}
                          errorText="Email is required"
                          onChangeText={(text) => {
                            setEmployee({ ...employee, email: text });
                          }}
                        />
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>Code</Text>
                        <Input
                          label="Code"
                          style={{ flex: 1 }}
                          value={employee.code}
                          onChangeText={(text) => {
                            setEmployee({ ...employee, code: text });
                          }}
                        />
                      </View>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        flexWrap: "wrap",
                        gap: 10,
                        marginTop: 10,
                      }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>Gender</Text>
                        <CustomDropdown
                          data={employeeGenderData}
                          direction="TOP"
                          placeholder="Gender"
                          placeholderStyle={{ color: "#99999990" }}
                          style={{
                            backgroundColor: colors.card,
                            borderWidth: 1,
                            height: "100%",
                            marginTop: 5,
                          }}
                          value={genderDropdownValue}
                          setValue={setGenderDropdownValue}
                        />
                      </View>
                    </View>
                  </View>
                )}

                {currentStep === 2 && (
                  <View style={{ flex: 1 }}>
                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>Job Title</Text>
                        <Input
                          label="Job Title"
                          style={{ flex: 1 }}
                          value={employee.jobTitle}
                          onChangeText={(text) => {
                            setEmployee({ ...employee, jobTitle: text });
                          }}
                        />
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Department
                        </Text>
                        <Input
                          label="Department"
                          style={{ flex: 1 }}
                          value={employee.department}
                          onChangeText={(text) => {
                            setEmployee({ ...employee, department: text });
                          }}
                        />
                      </View>
                    </View>

                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>Location</Text>
                        <Input
                          label="Location"
                          style={{ flex: 1 }}
                          value={employee.location}
                          onChangeText={(text) => {
                            setEmployee({ ...employee, location: text });
                          }}
                        />
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Employee Supervisor
                        </Text>
                        <Input
                          label="Employee Supervisor"
                          style={{ flex: 1 }}
                          value={employee.employeeSupervisor}
                          onChangeText={(text) => {
                            setEmployee({
                              ...employee,
                              employeeSupervisor: text,
                            });
                          }}
                        />
                      </View>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        gap: 10,
                        marginTop: 10,
                        zIndex: 999,
                      }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Employee Supervisor
                        </Text>
                        <CustomDropdown
                          data={employeeStatus}
                          placeholder="Employee Status"
                          value={employeeStatusValue}
                          setValue={setEmployeeStatusValue}
                          placeholderStyle={{ color: "#99999990" }}
                          style={{
                            backgroundColor: colors.card,
                            borderWidth: 1,
                            marginTop: 5,
                            height: "100%",
                          }}
                        />
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Employee Type
                        </Text>
                        <Input
                          label="Employee Type"
                          style={{ flex: 1 }}
                          value={employee.employeeType}
                          onChangeText={(text) => {
                            setEmployee({ ...employee, employeeType: text });
                          }}
                        />
                      </View>
                    </View>

                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Employee Team
                        </Text>
                        <Input
                          label="Employee Team"
                          style={{ flex: 1 }}
                          value={employee.employeeTeam}
                          onChangeText={(text) => {
                            setEmployee({ ...employee, employeeTeam: text });
                          }}
                        />
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Employee Shift
                        </Text>
                        <Input
                          label="Employee Shift"
                          style={{ flex: 1 }}
                          value={employee.employeeShift}
                          onChangeText={(text) => {
                            setEmployee({ ...employee, employeeShift: text });
                          }}
                        />
                      </View>
                    </View>

                    <View style={{ flex: 1, marginTop: 10 }}>
                      <Text style={{ color: colors.subText }}>
                        Employee Manager
                      </Text>
                      <Input
                        label="Employee Manager"
                        style={{ flex: 1 }}
                        value={employee.employeeManager}
                        onChangeText={(text) => {
                          setEmployee({ ...employee, employeeManager: text });
                        }}
                      />
                    </View>
                  </View>
                )}

                {currentStep === 3 && (
                  <View style={{ flex: 1 }}>
                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Emergency Contact Name
                        </Text>
                        <Input
                          label="Emergency Contact Name"
                          style={{ flex: 1 }}
                          value={employee.emergencyContactName}
                          onChangeText={(text) => {
                            setEmployee({
                              ...employee,
                              employeeEmergencyContactName: text,
                            });
                          }}
                        />
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Emergency Contact Relation
                        </Text>
                        <Input
                          label="Emergency Contact Relation"
                          style={{ flex: 1 }}
                          value={employee.emergencyContactRelation}
                          onChangeText={(text) => {
                            setEmployee({
                              ...employee,
                              employeeEmergencyContactRelation: text,
                            });
                          }}
                        />
                      </View>
                    </View>

                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Emergency Contact Phone
                        </Text>
                        <Input
                          label="Emergency Contact Phone"
                          style={{ flex: 1 }}
                          value={employee.emplyeeEmergencyContactPhone}
                          onChangeText={(text) => {
                            setEmployee({
                              ...employee,
                              emplyeeEmergencyContactPhone: text,
                            });
                          }}
                        />
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Emergency Contact Email
                        </Text>
                        <Input
                          label="Emergency Contact Email"
                          style={{ flex: 1 }}
                          value={employee.employeeEmergencyContactEmail}
                          onChangeText={(text) => {
                            setEmployee({
                              ...employee,
                              employeeEmergencyContactEmail: text,
                            });
                          }}
                        />
                      </View>
                    </View>
                  </View>
                )}

                {/* employee benefit */}
                {currentStep === 4 && (
                  <View style={{ flex: 1 }}>
                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Health Insurance
                        </Text>
                        <Input label="Health Insurance" style={{ flex: 1 }} 
                            value={employee.employeeHealthInsurance}
                            onChangeText={(text) => {
                              setEmployee({
                                ...employee,
                                employeeHealthInsurance: text,
                              });
                            }}
                        />
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Dental Insurance
                        </Text>
                        <Input label="Dental Insurance" style={{ flex: 1 }} 
                            value={employee.employeeDentalInsurance}
                            onChangeText={(text) => {
                              setEmployee({
                                ...employee,
                                employeeDentalInsurance: text,
                              });
                            }}
                        />
                      </View>
                    </View>
                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Life Insurance
                        </Text>
                        <Input label="Life Insurance" style={{ flex: 1 }}
                            value={employee.employeeLifeInsurance}
                            onChangeText={(text) => {
                              setEmployee({
                                ...employee,
                                employeeLifeInsurance: text,
                              });
                            }}
                        />
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>PTO</Text>
                        <Input label="PTO" style={{ flex: 1 }} 
                            value={employee.employeePTO}
                            onChangeText={(text) => {
                              setEmployee({
                                ...employee,
                                employeePTO: text,
                              });
                            }}
                        />
                      </View>
                    </View>
                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>401k</Text>
                        <Input label="401k" style={{ flex: 1 }} 
                            value={employee.employee401k}
                            onChangeText={(text) => {
                                setEmployee({
                                    ...employee,
                                    employee401k: text,
                                });
                            }  }          
                        />
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Stock Options
                        </Text>
                        <Input label="Stock Options" style={{ flex: 1 }} 
                            value={employee.employeetockOptions}
                            onChangeText={(text) => {
                                setEmployee({
                                    ...employee,
                                    employeeStockOptions: text,
                                });
                            } 
                        }
                        />
                      </View>
                    </View>
                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>Bonus</Text>
                        <Input label="Bonus" style={{ flex: 1 }} 
                            value={employee.employeeBonus}
                            onChangeText={(text) => {
                                setEmployee({
                                    ...employee,
                                    employeeBonus: text,
                                });
                            }
                        }
                        />
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Profit Sharing
                        </Text>
                        <Input label="Profit Sharing" style={{ flex: 1 }} 
                            value={employee.employeeProfitSharing}
                            onChangeText={(text) => {
                                setEmployee({
                                    ...employee,
                                    employeeProfitSharing: text,
                                });
                            }
                        }
                        />
                      </View>
                    </View>
                    <View style={{ flex: 1 }}>
                      <Text style={{ color: colors.subText }}>
                        Tuition Reimbursement
                      </Text>
                      <Input label="Tuition Reimbursement"
                        value={employee.employeeTuitionReimbursement}
                        onChangeText={(text) => {
                            setEmployee({
                                ...employee,
                                employeeTuitionReimbursement: text,
                            });
                        }}
                      />
                    </View>
                  </View>
                )}

                {currentStep === 5 && (
                  <View style={{ flex: 1 }}>
                    <View
                      style={{
                        flexDirection: "row",
                        gap: 10,
                        marginBottom: 10,
                        zIndex: 999,
                      }}
                    >
                      <View style={{ flex: 1 }}>
                        <CustomDropdown
                          data={fileUploadTypes}
                          placeholder="Select File Type"
                          setValue={(t: any) => {
                            setSelectedFile(t());

                            const selectedValue = t();

                            if (selectedValue === "pdf") {
                              if (isWeb) setSelectedFileTypesWeb(["PDF"]);
                              else setSelectedFileTypes(["application/pdf"]);
                            } else if (selectedValue === "word") {
                              if (isWeb) setSelectedFileTypesWeb(["DOCX"]);
                              else
                                setSelectedFileTypes([
                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                ]);
                            } else if (selectedValue === "excel") {
                              if (isWeb) setSelectedFileTypesWeb(["XLSX"]);
                              else
                                setSelectedFileTypes([
                                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                ]);
                            } else if (selectedValue === "powerpoint") {
                              if (isWeb) setSelectedFileTypesWeb(["PPTX"]);
                              else
                                setSelectedFileTypes([
                                  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                                ]);
                            }
                          }}
                          value={selectedFile}
                          style={{
                            backgroundColor: colors.card,
                          }}
                        />
                      </View>

                      {!isWeb && selectedFile.length !== 0 && (
                        <CustomButton
                          value="Pick Document"
                          onPress={pickDocument}
                          viewStyle={{
                            borderRadius: 10,
                            backgroundColor: colors.card,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        />
                      )}
                    </View>

                    {FileUploader && selectedFile.length !== 0 && (
                      <FileUploader
                        multiple
                        name="file"
                        hoverTitle="Upload"
                        handleChange={handleChange}
                        types={selectedFileTypesWeb}
                      />
                    )}

                    {selectedFilesName.length > 0 && (
                      <View style={{ marginTop: 10 }}>
                        <Text
                          variant="bodyLarge"
                          style={{ color: colors.text, fontWeight: "bold" }}
                        >
                          Selected Files
                        </Text>
                        <FlatList
                          data={selectedFilesName}
                          ListHeaderComponent={() => {
                            return (
                              <View style={{ flexDirection: "row" }}>
                                <Text
                                  style={{
                                    flex: 1,
                                    color: colors.text,
                                    fontWeight: "bold",
                                    textAlign: "center",
                                  }}
                                >
                                  Sr #
                                </Text>
                                <Text
                                  style={{
                                    flex: 8,
                                    color: colors.text,
                                    fontWeight: "bold",
                                  }}
                                >
                                  File Name
                                </Text>
                                <Text
                                  style={{
                                    flex: 1,
                                    color: colors.text,
                                    fontWeight: "bold",
                                    textAlign: "center",
                                  }}
                                >
                                  Delete
                                </Text>
                              </View>
                            );
                          }}
                          renderItem={({ item, index }) => {
                            return (
                              <View
                                style={{
                                  flex: 1,
                                  flexDirection: "row",
                                  gap: 10,
                                  paddingHorizontal: 10,
                                  paddingVertical: 5,
                                }}
                              >
                                <Text
                                  style={{
                                    flex: 1,
                                    color: colors.text,
                                    opacity: 0.8,
                                    textAlign: "center",
                                  }}
                                >
                                  {index + 1}.
                                </Text>
                                <Text style={{ flex: 8, color: colors.text }}>
                                  {item}
                                </Text>
                                <Pressable
                                  onPress={() => handleDelete(item, index)}
                                  style={({ pressed }) => ({
                                    flex: 1,
                                    opacity: pressed ? 0.5 : 1,
                                    justifyContent: "center",
                                    alignItems: "center",
                                  })}
                                >
                                  <Icon
                                    source="close"
                                    size={18}
                                    color={colors.text}
                                  />
                                </Pressable>
                              </View>
                            );
                          }}
                        />
                      </View>
                    )}
                  </View>
                )}
              </ScrollView>
            </View>

            <View style={{ flex: 1 }} />
          </View>
        </View>
      </View>

      <View
        style={{
          flexDirection: "row",
          gap: 10,
          backgroundColor: colors.card,
          justifyContent: "flex-end",
          padding: 20,
        }}
      >
        <CustomButton
          value="Cancel"
          onPress={handelCancel}
          viewStyle={{
            paddingVertical: 15,
            paddingHorizontal: 20,
            backgroundColor: "transparent",
            borderWidth: 1,
            borderColor: colors.primary,
            borderRadius: 10,
          }}
          textStyle={{ color: colors.primary, fontSize: 18 }}
        />

        <CustomButton
          value="Save as Draft"
          onPress={addEmployeeFunction}
          viewStyle={{
            paddingVertical: 15,
            paddingHorizontal: 20,
            backgroundColor: "transparent",
            borderWidth: 1,
            borderColor: colors.primary,
            borderRadius: 10,
          }}
          textStyle={{ color: colors.primary, fontSize: 18 }}
        />

        <Divider
          style={{ height: "100%", width: 1, backgroundColor: colors.border }}
        />

        <CustomButton
          value="Previous"
          onPress={goPreviousStep}
          viewStyle={{
            paddingVertical: 15,
            paddingHorizontal: 20,
            backgroundColor: "transparent",
            borderWidth: 1,
            borderColor: colors.primary,
            borderRadius: 10,
          }}
          textStyle={{ color: colors.primary, fontSize: 18 }}
        />

        <CustomButton
          value={currentStep === 5 ? "Save" : "Next"}
          onPress={gotoNextStep}
          viewStyle={{
            paddingVertical: 15,
            paddingHorizontal: 20,
            backgroundColor: colors.primary,
            borderRadius: 10,
          }}
          textStyle={{ color: "black", fontSize: 18 }}
        />
      </View>
    </View>
  );
};

export default PersonalAddUser;
