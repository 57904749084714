import { TransitionPresets, createStackNavigator } from '@react-navigation/stack'
import React from 'react'

import Sales from '../src/screens/sales/Sales'
import SalesItem from '../src/screens/sales/SalesItem'

const Stack = createStackNavigator()

const SaleStack = () => {
    return (
        <Stack.Navigator screenOptions={{ headerShown: false, ...TransitionPresets.RevealFromBottomAndroid }}>
            <Stack.Screen name='SaleHome' component={Sales} />
            <Stack.Screen name='SaleItem' component={SalesItem} />
        </Stack.Navigator>
    )
}

export default SaleStack