import { StyleSheet, TouchableOpacity, View } from 'react-native'
import React from 'react'
import { Icon, Text } from 'react-native-paper'
import PersonalCenterView from '../../../component/page/personal/centerView'
import { useTheme } from '@react-navigation/native'
import { CustomColors } from '../../../api/customColors'

const PersonalClockPage = (props: any) => {

    const { colors }: { colors: CustomColors } = useTheme();

    let onClick = () => { }


    const styles = StyleSheet.create({
        mainViewContainer: {
            flex: 1,
            marginTop: 10,
            borderRadius: 10,
            backgroundColor: colors.card
        },
        bottomButton: {
            borderRadius: 10,
            alignItems: 'center',
            paddingVertical: 10,
            paddingHorizontal: 20,
            justifyContent: 'center',
            backgroundColor: colors.primary,
        }
    })

    return (
        <View style={{ flex: 1, backgroundColor: colors.background }}>

            <View style={{ flex: 1, padding: 10 }}>

                <View style={{ flexDirection: 'row', gap: 10, alignItems: 'center' }}>
                    <TouchableOpacity onPress={() => props.navigation.goBack()}>
                        <Icon source={'arrow-left-circle'} size={24} />
                    </TouchableOpacity>
                    <Text variant="titleLarge" style={{ fontWeight: 'bold' }} >Human Resource / Attendance</Text>
                </View>

                <View style={styles.mainViewContainer}>

                    <PersonalCenterView />

                    <View style={{ flexDirection: 'row', gap: 20, alignItems: 'center', justifyContent: 'center', marginBottom: 20 }}>

                        <TouchableOpacity onPress={onClick} style={styles.bottomButton}>
                            <Text variant='bodyMedium' style={{ fontWeight: 'bold', color: 'white' }} numberOfLines={1}>Report</Text>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={onClick} style={styles.bottomButton}>
                            <Text variant='bodyMedium' style={{ fontWeight: 'bold', color: 'white' }} numberOfLines={1}>Clock In</Text>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={onClick} style={styles.bottomButton}>
                            <Text variant='bodyMedium' style={{ fontWeight: 'bold', color: 'white' }} numberOfLines={1}>Clock Out</Text>
                        </TouchableOpacity>

                    </View>

                </View>
            </View>
        </View>
    )
}

export default PersonalClockPage