import { StyleSheet, View } from 'react-native'
import React from 'react'
import { Text } from 'react-native-paper'
import { shadowSet } from '../../util/shadow'
import { useTheme } from '@react-navigation/native'
import { CustomColors } from '../../../api/customColors'

const PersonalCenterView = () => {

    const { colors }: { colors: CustomColors } = useTheme();

    const styles = StyleSheet.create({

        centerMainView: {
            width: '30%',
            ...shadowSet,
            aspectRatio: 1,
            alignItems: 'center',
            borderRadius: 1000,
            justifyContent: 'center',
            backgroundColor: '#ffc145',
        },
        centerSubView: {
            width: '90%',
            height: '20%',
            borderWidth: 1,
            borderRadius: 10,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: colors.card,
        }
    })

    return (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <View style={styles.centerMainView}>
                <View style={styles.centerSubView}>
                    <Text variant='displayLarge'>7:30 am</Text>
                </View>
            </View>
        </View>
    )
}

export default PersonalCenterView