import { useTheme } from "@react-navigation/native";
import { randomUUID } from "expo-crypto";
import * as DocumentPicker from "expo-document-picker";
import * as ImagePicker from "expo-image-picker";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Pressable,
  ScrollView,
  View,
  FlatList,
  ActivityIndicator,
} from "react-native";
import { Divider, Icon, TextInput, Text } from "react-native-paper";

import { InventoryProps } from "../../../GlobalContext/types";
import {
  useFunctions,
  useUserContext,
} from "../../../GlobalContext/useUserContext";
import { CustomColors } from "../../../api/customColors";
import FormsInput from "../../../component/Common/Input/FormsInput";
import { inputFieldSchema } from "../../../component/Common/Input/inputSchema";
import CustomButton from "../../../component/Common/button";
import { isWeb } from "../../../component/Common/isWeb";
import CustomDropdown from "../../../component/CustomDropdown/CustomDropdown";
import {
  currencyFormatter,
  currencySymbol,
  getMetricOrImperialSymbol,
} from "../../../component/util/constants";
import { percentFormatter } from "../../../utils/currencyPercentFormatter";
let FileUploader: any;
if (isWeb) FileUploader = require("react-drag-drop-files").FileUploader;

const AddInventoryItem = (props: any) => {
  const { control, handleSubmit, watch } = useForm<InventoryProps>({
    defaultValues: {
      itemIsReturnable: false,
      itemHasaVariant: false,
      itemID: randomUUID(),
    },
  });

  const unitOfMeasurement = watch("itemSaleByWeight");
  const itemQauntity = watch("itemQuantity");
  const itemUnitPrice = watch("itemUnitPrice");

  const { colors }: { colors: CustomColors } = useTheme();
  const { business } = useUserContext();

  // retail margind and  profit
  const [retailMargin, setRetailMargin] = useState("0");
  const [retailProfit, setRetailProfit] = useState(0);

  // wholesale margin and profit
  const [wholesaleMargin, setWholesaleMargin] = useState<number>(0);
  const [wholesaleProfit, setWholesaleProfit] = useState<number>(0);

  // step 1 - product type dropdown state
  const [showProductCategoryDropdown, setShowProductCategoryDropdown] =
    useState(false);

  // step 3
  const [showLocationDropdown, setShowLocationDropdown] = useState(false);

  // step 4

  // const [unitOfMeasurement, setUnitOfMeasurement] = useState<any>("");
  const [measurementType, setMeasurementType] = useState<any>("");

  // inventory state declaration
  const [inventoryData, setInventoryData] = useState<any>({}); // inventory data
  const [inventoryCategory, setInventoryCategory] = useState<any>([]); // inventory category
  const [inventoryLocation, setInventoryLocation] = useState<any>([]); // inventory location
  const [currentInventoryNames, setCurrentInventoryNames] = useState<any>([]); // inventory names
  const [currentInventorynameError, setCurrentInventorynameError] =
    useState<boolean>(false); // inventory names

  const [isLoading, setIsLoading] = useState(false);
  const [newCategory, setNewCategory] = useState<boolean>(false);

  const [currentStep, setCurrentStep] = useState(1);
  const [inventoryCost, setInventoryCost] = useState<any>("0");
  const [selectedFilesName, setSelectedFilesName] = useState<
    { name: string; uri: string | ArrayBuffer | null }[]
  >([]);
  const [selectedFileTypes, setSelectedFileTypes] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedFileTypesWeb, setSelectedFileTypesWeb] = useState<string[]>(
    []
  );
  const [newLocation, setNewLocation] = useState<boolean>(false);

  const addInventoryDataFunction = async (formsData: InventoryProps) => {
    setIsLoading(true);
    const mergedInventoryData = {
      ...formsData,
      itemID: randomUUID(),
      itemImage: selectedFilesName,
      itemRetailMargin: retailMargin,
      itemRetailProfit: retailProfit,
      itemWholesaleMargin: wholesaleMargin,
      itemWholesaleProfit: wholesaleProfit,
      itemLocation: formsData.itemLocation,
      itemSaleByWeight: unitOfMeasurement === "weight",
      itemMetric: measurementType,
      totalInventoryCost: inventoryCost,
    };

    const { id } = business;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const addInventoryData = useFunctions("addInventory");
    addInventoryData({ businessId: id, inventory: mergedInventoryData })
      .then((result: any) => {
        if (result.data) {
          // navigate to inventory home screen
          props.navigation.navigate("InventoryHome", {
            from: "addInventoryScreen",
          });
          console.log("sucessfully navigated to inventory home", result.data);
          setIsLoading(false);
        }
      })
      .catch((error: any) => {
        // Getting the Error details.
        setIsLoading(false);
        console.error("Error retrieving sales data:", error);
      });
  };

  const gotoNextStep = (data: InventoryProps) => {
    if (currentStep < 3) {
      const nextStep = currentStep + 1;
      setCurrentStep(nextStep);
    } else {
      if (currentStep === 3) {
        // calculate the cost of the product

        // SEND DATA TO INVENTORY DATA
        addInventoryDataFunction(data);
      }
    }
  };

  const fileUploadTypes = [
    { label: "Image", value: "image" },
    { label: "PDF", value: "pdf" },
  ];

  const handleDelete = (
    data: { name: string; uri: string | ArrayBuffer | null },
    index: number
  ) => {
    const filteredData: { name: string; uri: string | ArrayBuffer | null }[] =
      [];

    selectedFilesName.map((item) => {
      if (item.name !== data.name) {
        filteredData.push(item);
      }
    });

    setSelectedFilesName(filteredData);
  };

  // drag n drop handle change
  const handleChange = async (file: any) => {
    const names: { name: string; uri: string | ArrayBuffer | null }[] = [];

    const fn = [...selectedFilesName];

    const fileReadPromises: Promise<void>[] = Object.keys(file).map(
      (keyValue: string) => {
        return new Promise<void>((resolve) => {
          const f = new FileReader();

          f.onload = () => {
            names.push({ name: file[keyValue].name, uri: f.result });
            resolve();
          };

          f.readAsDataURL(file[keyValue]);
        });
      }
    );

    await Promise.all(fileReadPromises);

    setSelectedFilesName([...fn, ...names]);
  };

  const pickDocument = async () => {
    const fn = [...selectedFilesName];
    try {
      const result = await DocumentPicker.getDocumentAsync({
        multiple: true,
        type: selectedFileTypes,
      });

      if (result.assets) {
        const names = result.assets.map((item) => ({
          name: item.name,
          uri: item.uri,
        }));
        setSelectedFilesName([...fn, ...names]);
      }
    } catch (error) {
      console.error("Error picking documents: ", error);
    }
  };

  const goPreviousStep = () => {
    console.log(currentStep > 1);
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  const stepText = (stepNumber: number) => {
    if (stepNumber === 1) return "General Information";
    else if (stepNumber === 3) return "Sales Information";
    else if (stepNumber === 2) return "Quantity & Reorder";
  };

  const StepComponent = ({
    title,
    icon,
    size,
    stepNumber,
  }: {
    title: string;
    icon: string;
    size: number;
    stepNumber: number;
  }) => {
    return (
      <View
        style={{
          flexDirection: "row",
          gap: 10,
          padding: 10,
          alignItems: "center",
          borderRadius: 10,
          backgroundColor:
            stepNumber === currentStep ? colors.card : "transparent",
        }}
      >
        <Icon
          source={icon}
          size={size}
          color={stepNumber === currentStep ? colors.primary : colors.subText}
        />
        <View>
          <Text style={{ color: colors.subText, opacity: 0.8 }}>{title}</Text>
          <Text
            style={{
              color: stepNumber === currentStep ? colors.primary : colors.text,
              fontWeight: stepNumber === currentStep ? "bold" : "normal",
              marginTop: 5,
            }}
          >
            {stepText(stepNumber)}
          </Text>
        </View>
      </View>
    );
  };

  const pickImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      base64: true,
    });

    if (!result.canceled) {
      // add image to the inventory data state and pass it to firebase add inventory function
      setInventoryData({
        ...inventoryData,
        itemImage: result.assets[0].uri,
      });
    }
  };

  const handelCancel = () => {
    props.navigation.navigate("InventoryHome");
  };

  // a debounce function to prevent checking product name on every key stroke
  const debounce = (func: any, timeout: number) => {
    let timer: any;
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  };

  // this is a function to calculate the margin and profit of the product
  const handleMarginAndProfitCalculation = (value: string, type: string) => {
    if (type === "unit") {
      // calculate overall item cost unit price * quantity
      const unitPrice = parseFloat(value);
      const quantity = parseFloat(itemQauntity || ("1" as any));
      const costPrice = unitPrice * quantity;
      // prevent state from mutating
      setInventoryCost(costPrice.toString());
    } else if (type === "retail") {
      const retailPrice = parseFloat(value);
      const costPrice = parseFloat(itemUnitPrice as any);
      const marginSubtract = retailPrice - costPrice;
      const marginPercentage = (marginSubtract / retailPrice) * 100;
      const margin = marginPercentage;
      const profit = retailPrice - costPrice;
      setRetailMargin(margin.toFixed(2));
      setRetailProfit(profit);
    } else if (type === "wholesale") {
      const wholesalePrice = parseFloat(value);
      const costPrice = parseFloat(itemUnitPrice as any);
      const margin = ((wholesalePrice - costPrice) / wholesalePrice) * 100;
      const profit = wholesalePrice - costPrice;
      setWholesaleMargin(parseFloat(margin.toFixed(2)));
      setWholesaleProfit(profit);
    }
  };

  // fetch inventory data from firebase function
  const fetchInventoryData = () => {
    if (business) {
      const { id } = business;
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const getInventoryData = useFunctions("fetchInventories");
      getInventoryData({ businessId: id })
        .then((result: any) => {
          if (result.data && typeof result.data !== "string") {
            //build a list of categories without duplicate categories
            const categories: any[] = [
              { label: "Add New Category", value: "New" },
            ];
            const seenLabels = new Set();

            result.data.forEach((item: any) => {
              // if (item.label !== null && !seen Labels.has(item.label)) {

              if (
                !categories.includes(item.itemCategory) &&
                item.itemCategory !== null &&
                !seenLabels.has(item.label)
              ) {
                // if category.label already exists in the dropdown do not add it again
                if (
                  categories.some(
                    (category) => category.label === item.itemCategory
                  )
                ) {
                  return;
                }
                categories.push({
                  label: item.itemCategory,
                  value: item.itemCategory,
                });

                seenLabels.add(item.itemCategory);
              }
            });

            // set inventory location dropdown and remove duplicates
            const locations: any[] = [
              { label: "Add New Location", value: "New" },
            ];
            result.data.forEach((item: any) => {
              if (
                !locations.some(
                  (location) => location.label === item.itemLocation
                )
              ) {
                locations.push({
                  label: item.itemLocation,
                  value: item.itemLocation,
                });
              }
            });

            setInventoryLocation(
              locations.filter((item) => item.label !== null)
            );

            setInventoryCategory(categories);

            //build a list of current inventory names without duplicate products
            const currentInventoryNames: any[] = [];
            result.data.forEach((item: any) => {
              if (!currentInventoryNames.includes(item.itemName)) {
                currentInventoryNames.push(item.itemName);
              }
            });
            setCurrentInventoryNames(currentInventoryNames);
          } else {
            // initial state of inventory data
            setInventoryLocation([]);
            setInventoryCategory([]);
            setCurrentInventoryNames([]);
            setIsLoading(false);
          }
        })
        .catch((error: any) => {
          // Getting the Error details.
          console.error("Error retrieving sales data:", error);
        });
    }
  };

  // fetch current inventory data on screen load
  useEffect(() => {
    fetchInventoryData();
  }, []);

  // when category is selectedfrom the dropdown check to see if new category/locatio is selected
  useEffect(() => {
    const category = watch("itemCategory");
    const location = watch("itemLocation");
    if (category === "New") {
      setNewCategory(true);
    }

    if (location === "New") {
      setNewLocation(true);
    }

    if (category !== "New") {
      setNewCategory(false);
    }

    if (location !== "New") {
      setNewLocation(false);
    }
  }, [watch("itemCategory"), watch("itemLocation")]);

  if (isLoading)
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator color={colors.primary} size="large" />
      </View>
    );

  return (
    <View style={{ flex: 1, backgroundColor: colors.background }}>
      <View style={{ flex: 1, padding: 10 }}>
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Text
            variant="bodyLarge"
            style={{ color: colors.subText, opacity: 0.8 }}
          >
            Add New Product
          </Text>
          <Text
            variant="titleLarge"
            style={{ color: colors.text, fontWeight: "bold" }}
          >
            {stepText(currentStep)}
          </Text>
        </View>

        <View style={{ flex: 8, flexDirection: "row", gap: 10 }}>
          {/* left side  */}
          <View style={{ flex: 1 }}>
            {/* Step 1 */}
            <StepComponent
              title="Step 1"
              icon="file-document"
              size={18}
              stepNumber={1}
            />
            {/* Step 2 */}
            <StepComponent
              title="Step 2"
              icon="multiplication-box"
              size={18}
              stepNumber={2}
            />
            {/* Step 3 */}
            <StepComponent title="Step 3" icon="tag" size={18} stepNumber={3} />
          </View>

          {/* right side */}
          <View style={{ flex: 3, flexDirection: "row" }}>
            <View style={{ flex: 2 }}>
              <ScrollView
                contentContainerStyle={{
                  padding: 10,
                  backgroundColor: colors.card,
                  borderRadius: 10,
                }}
              >
                {/* step 1 */}
                {currentStep === 1 && (
                  <View style={{ flex: 1 }}>
                    {/* checkbox */}
                    <View style={{ flexDirection: "row", gap: 20 }}>
                      <FormsInput
                        isCheckbox
                        control={control}
                        name="itemIsReturnable"
                        style={{ marginTop: 5 }}
                        CheckboxLabel="Returnable product"
                      />

                      <FormsInput
                        isCheckbox
                        control={control}
                        name="itemHasaVariant"
                        CheckboxLabel="Has a variant"
                        style={{ marginTop: 5 }}
                      />
                    </View>

                    <View
                      style={{
                        marginTop: 10,
                        flexDirection: "row",
                        gap: 10,
                        zIndex: 9999,
                      }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Product Name
                        </Text>
                        <FormsInput
                          control={control}
                          name="itemName"
                          placeHolder="e.g. Cheese Burger"
                          style={{ marginTop: 5 }}
                          rules={inputFieldSchema["itemName"]}
                        />
                      </View>

                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Product Category
                        </Text>

                        {!newCategory ? (
                          <FormsInput
                            control={control}
                            name="itemCategory"
                            placeHolder="Select Category"
                            style={{
                              marginTop: 5,
                              height: "100%",
                              backgroundColor: "transparent",
                            }}
                            rules={inputFieldSchema["itemCategory"]}
                            isDropdown
                            DropdownData={inventoryCategory}
                            DropdownArrowStyle={{
                              backgroundColor: colors.border,
                            }}
                            DropdownSearchable
                          />
                        ) : (
                          // if new category is selected show input field to add new category and a button to cancel it with just a close icon

                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <FormsInput
                              control={control}
                              name="itemCategory"
                              placeHolder="e.g. Burger"
                              style={{ marginTop: 5 }}
                              rules={inputFieldSchema["itemCategory"]}
                            />

                            <Pressable
                              onPress={() => {
                                // initialize to blank category dropdown
                                setNewCategory(false);
                              }}
                            >
                              <Icon
                                source="close"
                                size={18}
                                color={colors.text}
                              />
                            </Pressable>
                          </View>
                        )}
                      </View>
                    </View>

                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Product SKU
                        </Text>
                        <FormsInput
                          control={control}
                          name="itemSKU"
                          placeHolder="XXX-XXX-XX"
                          style={{ marginTop: 5 }}
                        />
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>Barcode</Text>
                        <FormsInput
                          control={control}
                          name="itemBarcode"
                          placeHolder="XX-XXXX"
                          style={{ marginTop: 5 }}
                        />
                      </View>
                    </View>

                    <View style={{ marginTop: 10 }}>
                      <Text style={{ color: colors.subText }}>
                        Product Description
                      </Text>
                      <FormsInput
                        control={control}
                        name="itemDescription"
                        placeHolder="Product Description"
                        style={{ marginTop: 5 }}
                      />
                    </View>

                    <View
                      style={{ marginTop: 10, flexDirection: "row", gap: 10 }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Supplier Id
                        </Text>
                        <FormsInput
                          control={control}
                          name="supplierID"
                          placeHolder="Supplier Id"
                          style={{ marginTop: 5 }}
                        />
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Supplier Name
                        </Text>
                        <FormsInput
                          control={control}
                          name="supplierName"
                          placeHolder="Supplier Name"
                          style={{ marginTop: 5 }}
                        />
                      </View>
                    </View>

                    <View style={{ flex: 1, marginTop: 10 }}>
                      <Text style={{ color: colors.subText }}>
                        Customer Demand
                      </Text>
                      <FormsInput
                        control={control}
                        name="itemCustomerDemand"
                        placeHolder="Customer Demand"
                        style={{ marginTop: 5 }}
                      />
                    </View>

                    <View style={{ marginTop: 10, justifyContent: "center" }}>
                      <Text style={{ color: colors.subText }}>Image</Text>

                      <View
                        style={{
                          flexDirection: "row",
                          gap: 10,
                          marginVertical: 10,
                          zIndex: 999,
                        }}
                      >
                        <View style={{ flex: 1 }}>
                          <FormsInput
                            control={control}
                            name="itemImage"
                            placeHolder="Select File Type"
                            style={{
                              marginTop: 5,
                              height: "100%",
                              backgroundColor: "transparent",
                            }}
                            isDropdown
                            DropdownData={fileUploadTypes}
                            DropdownArrowStyle={{
                              backgroundColor: colors.border,
                            }}
                            DropdownSearchable
                            DropdownOnChange={(t) => {
                              setSelectedFile(t);

                              const selectedValue = t;
                              console.log(selectedValue);

                              if (selectedValue === "pdf") {
                                if (isWeb) setSelectedFileTypesWeb(["PDF"]);
                                else setSelectedFileTypes(["application/pdf"]);
                              } else if (selectedValue === "image") {
                                if (isWeb)
                                  setSelectedFileTypesWeb(["jpeg", "png"]);
                                else
                                  setSelectedFileTypes([
                                    "image/jpeg",
                                    "image/png",
                                  ]);
                              }
                            }}
                          />

                          {/* <CustomDropdown
                            data={fileUploadTypes}
                            placeholder="Select File Type"
                            setValue={(t: any) => {
                              setSelectedFile(t())

                              let selectedValue = t();

                              if (selectedValue === 'pdf') {

                                if (isWeb) setSelectedFileTypesWeb(['PDF'])
                                else setSelectedFileTypes(['application/pdf'])

                              } else if (selectedValue === 'word') {

                                if (isWeb) setSelectedFileTypesWeb(['DOCX'])
                                else setSelectedFileTypes(['application/vnd.openxmlformats-officedocument.wordprocessingml.document'])

                              } else if (selectedValue === 'excel') {

                                if (isWeb) setSelectedFileTypesWeb(['XLSX'])
                                else setSelectedFileTypes(['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'])

                              } else if (selectedValue === 'powerpoint') {

                                if (isWeb) setSelectedFileTypesWeb(['PPTX'])
                                else setSelectedFileTypes(['application/vnd.openxmlformats-officedocument.presentationml.presentation'])

                              }
                            }}

                            value={selectedFile}
                            style={{
                              backgroundColor: colors.card,
                            }}
                          /> */}
                        </View>

                        {!isWeb && selectedFile.length !== 0 && (
                          <CustomButton
                            value="Pick Document"
                            onPress={pickDocument}
                            viewStyle={{
                              borderRadius: 10,
                              backgroundColor: colors.card,
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          />
                        )}
                      </View>

                      {FileUploader && selectedFile.length !== 0 && (
                        <FileUploader
                          multiple
                          name="file"
                          hoverTitle="Upload"
                          handleChange={handleChange}
                          types={selectedFileTypesWeb}
                        />
                      )}

                      {selectedFilesName.length > 0 && (
                        <View style={{ marginTop: 10 }}>
                          <Text
                            variant="bodyLarge"
                            style={{ color: colors.text, fontWeight: "bold" }}
                          >
                            Selected Files
                          </Text>
                          <FlatList
                            data={selectedFilesName}
                            ListHeaderComponent={() => {
                              return (
                                <View style={{ flexDirection: "row" }}>
                                  <Text
                                    style={{
                                      flex: 1,
                                      color: colors.text,
                                      fontWeight: "bold",
                                      textAlign: "center",
                                    }}
                                  >
                                    Sr #
                                  </Text>
                                  <Text
                                    style={{
                                      flex: 8,
                                      color: colors.text,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    File Name
                                  </Text>
                                  <Text
                                    style={{
                                      flex: 1,
                                      color: colors.text,
                                      fontWeight: "bold",
                                      textAlign: "center",
                                    }}
                                  >
                                    Delete
                                  </Text>
                                </View>
                              );
                            }}
                            renderItem={({ item, index }) => {
                              console.log({ files: item });
                              return (
                                <View
                                  style={{
                                    flex: 1,
                                    flexDirection: "row",
                                    gap: 10,
                                    paddingHorizontal: 10,
                                    paddingVertical: 5,
                                  }}
                                >
                                  <Text
                                    style={{
                                      flex: 1,
                                      color: colors.text,
                                      opacity: 0.8,
                                      textAlign: "center",
                                    }}
                                  >
                                    {index + 1}.
                                  </Text>
                                  <Text style={{ flex: 8, color: colors.text }}>
                                    {item.name}
                                  </Text>
                                  <Pressable
                                    onPress={() => handleDelete(item, index)}
                                    style={({ pressed }) => ({
                                      flex: 1,
                                      opacity: pressed ? 0.5 : 1,
                                      justifyContent: "center",
                                      alignItems: "center",
                                    })}
                                  >
                                    <Icon
                                      source="close"
                                      size={18}
                                      color={colors.text}
                                    />
                                  </Pressable>
                                </View>
                              );
                            }}
                          />
                        </View>
                      )}
                    </View>
                  </View>
                )}
                {currentStep === 2 && (
                  <View style={{ flex: 1 }}>
                    {/* dropdown selection for user to select if being sold by weight or number count */}
                    <View
                      style={{
                        flexDirection: "row",
                        gap: 10,
                        marginTop: 10,
                        zIndex: 9999,
                      }}
                    >
                      <View style={{ marginTop: 10, flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Sale by weight or count
                        </Text>

                        <FormsInput
                          control={control}
                          name="itemSaleByWeight"
                          placeHolder="Select File Type"
                          style={{
                            marginTop: 5,
                            height: "100%",
                            backgroundColor: "transparent",
                          }}
                          rules={inputFieldSchema["itemSaleByWeight"]}
                          isDropdown
                          DropdownData={[
                            { label: "Weight", value: "weight" },
                            { label: "Count", value: "count" },
                          ]}
                          DropdownArrowStyle={{
                            backgroundColor: colors.border,
                          }}
                          DropdownSearchable
                          DropdownMaxHeight={150}
                          DropdownDirection="BOTTOM"
                        />
                      </View>
                      {/* when we select weight for the dropdown we need to select metric or  */}
                      {unitOfMeasurement === "weight" ? (
                        <View style={{ marginTop: 10, flex: 1 }}>
                          <Text style={{ color: colors.subText }}>
                            Metric or Imperial
                          </Text>
                          <CustomDropdown
                            placeholder="Select"
                            value={measurementType}
                            setValue={setMeasurementType}
                            arrowStyle={{ backgroundColor: colors.border }}
                            style={{
                              marginTop: 10,
                              backgroundColor: "transparent",
                            }}
                            data={[
                              { label: "Metric", value: "metric" },
                              { label: "Imperial", value: "imperial" },
                            ]}
                          />
                        </View>
                      ) : null}
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        gap: 10,
                        marginTop: 10,
                      }}
                    >
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "row",
                          backgroundColor: colors.card,
                          marginTop: 10,
                          // borderWidth: StyleSheet.hairlineWidth,
                          // borderColor: colors.border,
                          borderRadius: 10,
                          alignItems: "flex-start",
                          gap: 10,
                        }}
                      >
                        <View style={{ flex: 1 }}>
                          <Text style={{ color: colors.subText }}>
                            Total Quantity
                          </Text>
                          <FormsInput
                            control={control}
                            name="itemQuantity"
                            placeHolder="Quantity"
                            keyboradType="numeric"
                            inputMode="numeric"
                            isNumericValue
                            style={{ marginTop: 5 }}
                            rules={inputFieldSchema["itemQuantity"]}
                            left={
                              unitOfMeasurement === "weight" ? (
                                <TextInput.Affix
                                  text={getMetricOrImperialSymbol(
                                    measurementType === "metric"
                                  )}
                                />
                              ) : null
                            }
                          />
                        </View>
                        <View style={{ flex: 1 }}>
                          <Text style={{ color: colors.subText }}>
                            inventory Reorder Point
                          </Text>
                          <FormsInput
                            control={control}
                            name="itemReorderPoint"
                            isNumericValue
                            keyboradType="numeric"
                            inputMode="numeric"
                            style={{ marginTop: 5 }}
                            rules={inputFieldSchema["itemReorderPoint"]}
                          />
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        gap: 10,
                        marginTop: 10,
                        zIndex: 999,
                      }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Available Locations
                        </Text>

                        <FormsInput
                          control={control}
                          name="itemSafetyStock"
                          placeHolder="Select Category"
                          style={{
                            marginTop: 5,
                            height: "100%",
                            backgroundColor: "transparent",
                          }}
                          isDropdown
                          DropdownData={inventoryLocation}
                        />
                      </View>

                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Enter Location
                        </Text>
                        <FormsInput
                          control={control}
                          name="itemLocation"
                          placeHolder="Location"
                          style={{ marginTop: 5 }}
                          rules={inputFieldSchema["itemLocation"]}
                        />
                      </View>
                    </View>
                  </View>
                )}
                {/* Sales Information */}
                {currentStep === 3 && (
                  <View style={{ flex: 1 }}>
                    <View style={{ marginTop: 10, zIndex: 999 }}>
                      <Text style={{ color: colors.subText }}>
                        Price Per{" "}
                        {unitOfMeasurement === "weight"
                          ? getMetricOrImperialSymbol(
                              measurementType
                            ).toUpperCase()
                          : "Unit"}
                      </Text>
                      <FormsInput
                        control={control}
                        name="itemUnitPrice"
                        keyboradType="numeric"
                        inputMode="numeric"
                        isNumericValue
                        style={{ marginTop: 5 }}
                        rules={inputFieldSchema["itemUnitPrice"]}
                        onChangeText={(text) => {
                          handleMarginAndProfitCalculation(text, "unit");
                        }}
                        left={<TextInput.Affix text={currencySymbol} />}
                      />
                    </View>
                    <View style={{ marginTop: 10, zIndex: 999 }}>
                      <Text style={{ color: colors.subText }}>
                        Retail Sale Price Per{" "}
                        {unitOfMeasurement === "weight"
                          ? getMetricOrImperialSymbol(
                              measurementType
                            ).toUpperCase()
                          : "Unit"}
                      </Text>

                      <FormsInput
                        control={control}
                        name="itemRetailPrice"
                        keyboradType="numeric"
                        inputMode="numeric"
                        isNumericValue
                        style={{ marginTop: 5 }}
                        rules={inputFieldSchema["itemRetailPrice"]}
                        onChangeText={(text) => {
                          handleMarginAndProfitCalculation(text, "retail");
                        }}
                        left={<TextInput.Affix text={currencySymbol} />}
                      />
                    </View>

                    <View style={{ marginTop: 10, zIndex: 99 }}>
                      <Text style={{ color: colors.subText }}>
                        Wholesale Price Per{" "}
                        {unitOfMeasurement === "weight"
                          ? getMetricOrImperialSymbol(
                              measurementType
                            ).toUpperCase()
                          : "Unit"}
                      </Text>

                      <FormsInput
                        control={control}
                        name="itemWholesalePrice"
                        style={{ marginTop: 5 }}
                        keyboradType="numeric"
                        inputMode="numeric"
                        isNumericValue
                        rules={inputFieldSchema["itemWholesalePrice"]}
                        left={<TextInput.Affix text={currencySymbol} />}
                        onChangeText={(text) => {
                          handleMarginAndProfitCalculation(text, "wholesale");
                        }}
                      />
                    </View>

                    <View style={{ marginTop: 10, zIndex: 98 }}>
                      <Text style={{ color: colors.subText }}>
                        Total Cost of Inventory
                      </Text>
                      <FormsInput
                        control={control}
                        name="totalInventoryCost"
                        placeHolder="Total Inventory Cost"
                        style={{ marginTop: 5 }}
                        keyboradType="numeric"
                        inputMode="numeric"
                        isNumericValue
                        left={<TextInput.Affix text={currencySymbol} />}
                        rules={inputFieldSchema["totalInventoryCost"]}
                        // value={currencyFormatter(inventoryCost)}
                      />
                    </View>

                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Retail margin & profit per{" "}
                          {unitOfMeasurement === "weight"
                            ? getMetricOrImperialSymbol(
                                measurementType
                              ).toUpperCase()
                            : "Unit"}
                        </Text>
                        <View
                          style={{
                            flex: 1,
                            marginTop: 10,
                            borderRadius: 10,
                            justifyContent: "space-evenly",
                            backgroundColor: colors.background,
                            padding: 10,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              paddingHorizontal: 10,
                            }}
                          >
                            <Text
                              style={{ color: colors.subText, opacity: 0.8 }}
                            >
                              Margin
                            </Text>
                            <Text
                              style={{
                                color: colors.subText,
                                fontWeight: "bold",
                              }}
                            >
                              {percentFormatter(retailMargin)}
                            </Text>
                          </View>

                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              paddingHorizontal: 10,
                              marginTop: 10,
                            }}
                          >
                            <Text
                              style={{ color: colors.subText, opacity: 0.8 }}
                            >
                              Profit
                            </Text>
                            <Text
                              style={{
                                color: colors.subText,
                                fontWeight: "bold",
                              }}
                            >
                              {currencyFormatter(retailProfit)}
                            </Text>
                          </View>
                        </View>
                      </View>

                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Wholesale margin & profit per{" "}
                          {unitOfMeasurement === "weight"
                            ? getMetricOrImperialSymbol(
                                measurementType
                              ).toUpperCase()
                            : "Unit"}
                        </Text>
                        <View
                          style={{
                            flex: 1,
                            marginTop: 10,
                            borderRadius: 10,
                            justifyContent: "space-evenly",
                            backgroundColor: colors.background,
                            padding: 10,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              paddingHorizontal: 10,
                            }}
                          >
                            <Text
                              style={{ color: colors.subText, opacity: 0.8 }}
                            >
                              Margin
                            </Text>
                            <Text
                              style={{
                                color: colors.subText,
                                fontWeight: "bold",
                              }}
                            >
                              {percentFormatter(wholesaleMargin)}
                            </Text>
                          </View>

                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              paddingHorizontal: 10,
                              marginTop: 10,
                            }}
                          >
                            <Text
                              style={{ color: colors.subText, opacity: 0.8 }}
                            >
                              Profit
                            </Text>
                            <Text
                              style={{
                                color: colors.subText,
                                fontWeight: "bold",
                              }}
                            >
                              {currencyFormatter(wholesaleProfit)}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                )}
              </ScrollView>
            </View>

            <View style={{ flex: 1 }} />
          </View>
        </View>
      </View>

      <View
        style={{
          flexDirection: "row",
          gap: 10,
          backgroundColor: colors.card,
          justifyContent: "flex-end",
          padding: 20,
        }}
      >
        <CustomButton
          value="Cancel"
          onPress={handelCancel}
          viewStyle={{
            paddingVertical: 15,
            paddingHorizontal: 20,
            backgroundColor: "transparent",
            borderWidth: 1,
            borderColor: colors.primary,
            borderRadius: 10,
          }}
          textStyle={{ color: colors.primary, fontSize: 18 }}
        />

        <CustomButton
          value="Save as Draft"
          onPress={() => {}}
          viewStyle={{
            paddingVertical: 15,
            paddingHorizontal: 20,
            backgroundColor: "transparent",
            borderWidth: 1,
            borderColor: colors.primary,
            borderRadius: 10,
          }}
          textStyle={{ color: colors.primary, fontSize: 18 }}
        />

        <Divider
          style={{ height: "100%", width: 1, backgroundColor: colors.border }}
        />

        <CustomButton
          value="Previous"
          onPress={goPreviousStep}
          viewStyle={{
            paddingVertical: 15,
            paddingHorizontal: 20,
            backgroundColor: "transparent",
            borderWidth: 1,
            borderColor: colors.primary,
            borderRadius: 10,
          }}
          textStyle={{ color: colors.primary, fontSize: 18 }}
        />

        <CustomButton
          value={currentStep === 3 ? "Save" : "Next"}
          onPress={handleSubmit(gotoNextStep)}
          viewStyle={{
            paddingVertical: 15,
            paddingHorizontal: 20,
            backgroundColor: colors.primary,
            borderRadius: 10,
          }}
          textStyle={{ color: "black", fontSize: 18 }}
        />
      </View>
    </View>
  );
};

export default AddInventoryItem;
