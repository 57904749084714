import React from "react";
import { View, Text, TextInput, TextInputProps } from "react-native";
import Input from "../../Common/Input/Input";

export interface AddressInputProps {
  label: string;
  value: string;
  style?: any;
  onChange: any;
  disabled?:boolean
}
const AddressInput = (props: AddressInputProps) => {
  const { label, value, onChange, style, disabled } = props;

  return (
    <View>
      <Input
        onChangeText={onChange}
        label={label}
        style={{
          ...style,
        }}
        disabled={disabled}
      />
    </View>
  );
};

export default AddressInput;
