import { View, TouchableOpacity, StyleSheet, Image, ScrollView, ActivityIndicator } from 'react-native'
import React, { useState } from 'react'
import { Icon, Text } from 'react-native-paper'
import { useNavigation, useTheme } from '@react-navigation/native'
import { w } from '../../util/dimension'
import { Header, Row, Table } from '../../tableComponent/tableComponent'
import EmployeeEditModal from './employeeEditModal'
import { CustomColors } from '../../../api/customColors'
import { employeesProps } from '../../../GlobalContext/types'


const PersonalEmployeePageTable = ({
    data,
    modifiedData,
    setModifiedData,
    setShowSidePanel,
    setSelectedData
}: {
    data: employeesProps[];
    modifiedData: employeesProps[];
    setModifiedData: React.Dispatch<React.SetStateAction<employeesProps[] | undefined>>;
    setShowSidePanel: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedData: React.Dispatch<React.SetStateAction<employeesProps>>;
}) => {

    const { colors }: { colors: CustomColors } = useTheme();

    const navigation = useNavigation();

    const [width, setWidth] = useState(w)
    const [noOfRows, setNoOfRows] = useState(10)

    const [currentPage, setCurrentPage] = useState(0)
    let from = currentPage * noOfRows;
    let to = from + noOfRows;

    const [showModal, setShowModal] = useState<boolean>(false)
    const [editRowData, setEditRowData] = useState<any>()
    const [editRowIndex, setEditRowIndex] = useState<number>(0)

    let handleDelete = (currentIndex: number) => {

        let newData: any = []

        modifiedData.forEach((item, index) => {
            if (currentIndex !== index) {
                newData.push(item)
            }
        })
        setModifiedData(newData)

    }

    let showEditModal = (item: object, index: number) => {
        setShowModal(true);
        setEditRowData(item);
        setEditRowIndex(index);
    }

    let handleEdit = () => {
        let newData: any = []

        modifiedData.forEach((item, index) => {
            if (index !== editRowIndex) {
                newData.push(item)
            } else {
                newData.push(editRowData)
            }
        })

        setModifiedData(newData)
        setShowModal(false)

    }

    if (!modifiedData) {
        return <ActivityIndicator />
    }

    return (
        <View
            onLayout={e => setWidth(e.nativeEvent.layout.width)}
            style={{ flex: 1, borderRadius: 10, borderWidth: 1, borderColor: colors.border, marginTop: 10 }}
        >
            <Table>

                <Header viewStyle={{ borderBottomWidth: 1, borderColor: colors.border }} dataState={data} setDataState={setModifiedData}>
                    <Header.Title columnWidth={width * .15} isObject value='employeeInfo' secondValue='employeeName' textStyle={{ paddingVertical: 10 }} >Employee Name</Header.Title>
                    <Header.Title columnWidth={width * .15} isObject value='employeeInfo' secondValue='employeeID' textStyle={{ paddingVertical: 10 }} >Employee Id</Header.Title>
                    <Header.Title columnWidth={width * .15} isObject value='employeeInfo' secondValue='employeeCode' textStyle={{ paddingVertical: 10 }} >Employee Code</Header.Title>
                    <Header.Title columnWidth={width * .15} isObject value='employeeInfo' secondValue='employeeEmail' textStyle={{ paddingVertical: 10 }}  >Employee Email</Header.Title>
                    <Header.Title columnWidth={width * .15} isObject value='employeeInfo' secondValue='employeePhone' textStyle={{ paddingVertical: 10 }} isNumeric >Employee Phone</Header.Title>
                    <Header.Title columnWidth={width * .15} isObject value='employeeInfo' secondValue='employeeAddress' textStyle={{ paddingVertical: 10 }} isID isNumeric >Employee Address</Header.Title>
                </Header>

                <ScrollView>
                    {modifiedData.slice(from, to).map((item, index) => {

                        return (
                            <View key={index} style={{ flexDirection: 'row' }}>

                                <Row
                                    key={index}
                                    rowHeight={40}
                                    viewStyle={{ paddingVertical: 5, maxHeight: 40, alignItems: 'center', borderBottomWidth: StyleSheet.hairlineWidth, borderColor: colors.border }}
                                >
                                    <Row.Cell rowWidth={width * .15} onPress={() => {
                                        setSelectedData(item)
                                        setShowSidePanel(true);
                                    }} value={item?.employeeInfo?.employeeName} />
                                    <Row.Cell rowWidth={width * .15} value={item?.employeeInfo?.employeeID} />
                                    <Row.Cell rowWidth={width * .15} value={item?.employeeInfo?.employeeCode} />
                                    <Row.Cell rowWidth={width * .15} value={item?.employeeInfo?.employeeEmail} />
                                    <Row.Cell rowWidth={width * .15} value={item?.employeeInfo?.employeePhone} />
                                    <Row.Cell rowWidth={width * .15} value={item?.employeeInfo?.employeeAddress} />

                                    <TouchableOpacity
                                        onPress={() => navigation.navigate('Human Resources', { screen: 'PersonalClockPage' })}
                                        style={{ width: width * .15, alignItems: 'center' }}>
                                        <Icon source={'dots-horizontal'} size={20} color={colors.text} />
                                    </TouchableOpacity>

                                </Row>

                            </View>
                        )
                    })}

                    {
                        modifiedData.length === 0 && <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', paddingVertical: 20 }} >
                            <Text style={{ color: colors.text }}>No Data Found</Text>
                        </View>
                    }

                </ScrollView>
            </Table >
            <View style={{ paddingBottom: 20, paddingRight: 20 }}>
                <Table.Pagination
                    currentSelectedRows={noOfRows}
                    setRows={setNoOfRows}
                    totalPages={Table.TotalPages(modifiedData, noOfRows)}
                    label={`${from + 1} - ${to}, Current Page - ${currentPage + 1}, Total Pages - ${Table.TotalPages(modifiedData, noOfRows)}`}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            </View>
            <EmployeeEditModal
                modalVisible={showModal}
                setVisibility={setShowModal}
                editRowData={editRowData}
                setEditRowData={setEditRowData}
                handleEdit={handleEdit}
            />

        </View>
    )
}

export default PersonalEmployeePageTable