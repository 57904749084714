// import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getFunctions } from 'firebase/functions';
import { Platform } from "react-native";


const firebaseConfig = {
  // prod-zimmerlogistics
  apiKey: "AIzaSyAeWPycenUx_4_tEwU_-d-rBBR7Kw0Efb4",
  authDomain: "prod-zimmerlogistics.firebaseapp.com",
  databaseURL: "https://prod-zimmerlogistics-default-rtdb.firebaseio.com",
  projectId: "prod-zimmerlogistics",
  storageBucket: "prod-zimmerlogistics.appspot.com",
  messagingSenderId: "618653681213",
  appId: "1:618653681213:web:3340103b03e52ab537cc54",
  measurementId: "G-0MSPWJFRFM"

} 

// const firebaseConfig = {
//   // dev-swiftlogistic-app
//   apiKey: "AIzaSyB_Ui6IaPXLXaTpPBMUembw4Y1BLEgyjO8",
//   authDomain: "swiftlogistic-app.firebaseapp.com",
//   databaseURL: "https://swiftlogistic-app-default-rtdb.firebaseio.com",
//   projectId: "swiftlogistic-app",
//   storageBucket: "swiftlogistic-app.appspot.com",
//   messagingSenderId: "976696819736",
//   appId: "1:976696819736:web:6685dad8fccfd21415436d",
//   measurementId: "G-95GYR75J34"

// };

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const functions = getFunctions(app);
// export const analytics = getAnalytics(app);
// export const log = (name: string, params?: any) => logEvent(analytics, name, params);

if (Platform.OS === 'web') {
  setPersistence(auth, browserLocalPersistence);
}

// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaEnterpriseProvider("6LdYwAkpAAAAADntq-4SziaAFSXYOgTP2n7Z0Wmy"),
//   isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
// });
