import { useTheme } from "@react-navigation/native";
import React from "react";
import { TextStyle, ViewStyle } from "react-native";
import { Text, TouchableRipple, Icon } from "react-native-paper";

type buttonProps = {
  value?: string;
  textStyle?: TextStyle;
  viewStyle?: ViewStyle;
  onPress?: () => void;
  disabled?: boolean;
  icon?: string;
  iconSize?: number;
  iconColor?: string;
  hideText?: boolean;
  textVariant?:
    | "displayLarge"
    | "displayMedium"
    | "displaySmall"
    | "headlineLarge"
    | "headlineMedium"
    | "headlineSmall"
    | "titleLarge"
    | "titleMedium"
    | "titleSmall"
    | "labelLarge"
    | "labelMedium"
    | "labelSmall"
    | "bodyLarge"
    | "bodyMedium"
    | "bodySmall";
};

const CustomButton = ({
  value,
  textStyle,
  icon,
  iconSize = 20,
  viewStyle,
  iconColor,
  hideText = true,
  textVariant,
  onPress,
  disabled,
}: buttonProps) => {
  const { colors } = useTheme();

  return (
    <TouchableRipple
      borderless
      disabled={disabled}
      onPress={onPress}
      rippleColor="#60606070"
      style={{
        borderRadius: 10,
        backgroundColor: colors.background,
        paddingVertical: 5,
        paddingHorizontal: 10,
        flexDirection: "row",
        ...viewStyle,
      }}
    >
      <>
        {icon && <Icon source={icon} size={iconSize} color={iconColor} />}
        {hideText && (
          <Text
            variant={textVariant ?? "labelSmall"}
            style={{ color: colors.text, ...textStyle }}
          >
            {value}
          </Text>
        )}
      </>
    </TouchableRipple>
  );
};

export default CustomButton;
