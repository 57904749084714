import { useTheme } from "@react-navigation/native";
import React from "react";
import { View } from "react-native";

import Employees from "../../component/page/dashboard/Employees";
import Inventory from "../../component/page/dashboard/Inventory";
import Revenue from "../../component/page/dashboard/Revenue";
import TransactionHistory from "../../component/page/dashboard/TransactionHistory";

const Dashboard = () => {

  const { colors } = useTheme()

  return (
    <View style={{ flex: 1, backgroundColor: colors?.background }}>

      <View style={{ flex: 1, margin: 10 }}>

        <View style={{ flex: 2, flexDirection: 'row', gap: 20 }}>
          <TransactionHistory />
          <Employees />
        </View>

        <View style={{ flex: 3, flexDirection: 'row', gap: 20, marginTop: 20 }}>
          <Revenue />
          <Inventory />
        </View>

      </View>

    </View>
  );
};

export default Dashboard;
