import React, { useState } from 'react'
import { useUserContext } from '../../../GlobalContext/useUserContext';
import AdminPage from './AdminPage';
import EmployeePage from './EmployeePage';

const HomeHR = () => {

    const { user: { userrole } } = useUserContext();

    const [isAdmin] = useState(userrole.some(item => item === 'admin'))

    return !isAdmin ?
        <AdminPage /> :
        <EmployeePage />;
}

export default HomeHR