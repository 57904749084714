import { StyleSheet, TouchableOpacity, View } from 'react-native'
import React, { useState } from 'react'
import { Button, Text } from 'react-native-paper';
import { useTheme } from '@react-navigation/native';
import { CustomColors } from '../../../api/customColors';

let HelpSupport = ({ editMode }: {
  editMode: boolean;
}) => {

  const { colors }: { colors: CustomColors } = useTheme();

  const [isLiveSupportAvailable, setIsLiveSupportAvailable] = useState(true)

  const styles = StyleSheet.create({
    button: {
      borderRadius: 10,
      backgroundColor: colors.card,
    }
  })
  return (
    <View>

      <View>
        <Text variant='bodyLarge' style={{ color: colors.text, fontWeight: 'bold' }}>Help Center</Text>

        <View style={{ flexDirection: 'row', justifyContent: 'flex-start', gap: 10, marginVertical: 10 }}>

          <Button theme={{ colors: { onSurfaceDisabled: colors.border } }} disabled={!editMode} mode='contained' onPress={() => { }} style={styles.button} textColor={colors.text}>User guides</Button>
          <Button theme={{ colors: { onSurfaceDisabled: colors.border } }} disabled={!editMode} mode='contained' onPress={() => { }} style={styles.button} textColor={colors.text}>FAQs</Button>
        </View>

      </View>

      <View>

        <Text variant='bodyLarge' style={{ color: colors.text, fontWeight: 'bold' }}>Contact Customer Support</Text>

        <View style={{ flexDirection: 'row', justifyContent: 'flex-start', gap: 10, marginVertical: 10 }}>
          <Button theme={{ colors: { onSurfaceDisabled: colors.border } }} disabled={!editMode} mode='contained' onPress={() => { }} style={styles.button} textColor={colors.text}>Email</Button>
          <Button theme={{ colors: { onSurfaceDisabled: colors.border } }} disabled={!editMode} mode='contained' onPress={() => { }} style={[styles.button, { backgroundColor: isLiveSupportAvailable ? colors.primary : colors.card }]} textColor={isLiveSupportAvailable ? 'white' : colors.text}>Live Chat</Button>
          <Button theme={{ colors: { onSurfaceDisabled: colors.border } }} disabled={!editMode} mode='contained' onPress={() => { }} style={styles.button} textColor={colors.text}>Phone</Button>
        </View>

      </View>

      <View>

        <Text variant='bodyLarge' style={{ color: colors.text, fontWeight: 'bold' }}>App Version</Text>

        <View style={{ flexDirection: 'row', gap: 10, marginVertical: 10 }}>
          <Text variant='bodyLarge' style={{ color: colors.subText }}>Currently installed: v 1.0</Text>
          <TouchableOpacity>
            <Text variant='bodyMedium' style={{ textDecorationLine: 'underline', color: colors.primary, fontWeight: 'bold' }}>release notes</Text>
          </TouchableOpacity>
        </View>

      </View>

      <View>
        <Text variant='bodyLarge' style={{ color: colors.text, fontWeight: 'bold' }}>Account Management</Text>

        <View style={{ flexDirection: 'row', justifyContent: 'flex-start', gap: 10, marginVertical: 10 }}>
          <Button theme={{ colors: { onSurfaceDisabled: colors.border } }} disabled={!editMode} mode='contained' onPress={() => { }} style={styles.button} textColor={colors.text}>Logout</Button>
          <Button theme={{ colors: { onSurfaceDisabled: colors.border } }} disabled={!editMode} mode='contained' onPress={() => { }} style={[styles.button, { backgroundColor: '#FF7F7F' }]} textColor={colors.text}>Delete Account</Button>
          <Button theme={{ colors: { onSurfaceDisabled: colors.border } }} disabled={!editMode} mode='contained' onPress={() => { }} style={styles.button} textColor={colors.text}>De-active Account</Button>
        </View>

      </View>

    </View>
  )
}
export default HelpSupport
