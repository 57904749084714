import { View, TouchableOpacity, Image, ScrollView, StyleSheet, FlatList, Platform } from 'react-native'
import React, { useState } from 'react'
import { Divider, Icon, Text } from 'react-native-paper'
import { shadowSet } from '../../component/util/shadow'
import { bankNames } from '../../assets/dummyData/dummyData'
import CustomDropdown from '../../component/CustomDropdown/CustomDropdown'
import countryList from 'country-list'
import Input from '../../component/Common/Input/Input'
import { useTheme } from '@react-navigation/native'
import { CustomColors } from '../../api/customColors'
import BusinessInformation from '../../component/page/settings/businessInformation'

const AddOnBoarding = (props: any) => {

  const { colors }: { colors: CustomColors } = useTheme();

  const [activePayment, setActivePayment] = useState<string | null>(null)

  const [modifiedData, setModifiedData] = useState(bankNames)

  const [cl] = useState(countryList.getNames().map((item: string) => ({
    label: item,
    value: item
  })))

  let paymentDashboard = () => {
    props.navigation.navigate('Payment', { screen: 'PaymentHome' })
  }

  const [value, setValue] = useState(null);
  const [cardCountryValue, setCardCountryValue] = useState(null)

  const styles = StyleSheet.create({
    topRightButton: {
      gap: 10,
      padding: 10,
      borderRadius: 100,
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
      backgroundColor: colors.card,
    },
    mainView: {
      flex: 1,
      padding: 10,
      marginTop: 10,
      borderRadius: 10,
      backgroundColor: colors.card
    },
    textInput: {
      borderRadius: 10,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      backgroundColor: '#f8f8f8'
    },
    placeOrderButton: {
      gap: 10,
      borderRadius: 10,
      paddingVertical: 10,
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 10,
      justifyContent: 'center',
      backgroundColor: colors.primary,
    }
  })

  return (
    <View style={{ flex: 1, backgroundColor: colors.background }}>

      <View style={{ flex: 1, padding: 10 }}>

        <View style={{ flexDirection: 'row', alignItems: 'center' }}>

          <View style={{ flex: 2 }}>
            <Text variant="bodyLarge" style={{ color: colors.text, fontWeight: 'bold' }} >New Business</Text>
          </View>

          <View style={{ flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end' }}>

            <TouchableOpacity
              onPress={paymentDashboard}
              style={styles.topRightButton}
            >
              <View style={{ backgroundColor: colors.primary, borderRadius: 100 }}>
                <Icon source={'minus'} size={24} color="white" />
              </View>
              <Text style={{ color: colors.text }}>Payment Dashboard</Text>
            </TouchableOpacity>

          </View>

        </View>

        <View style={styles.mainView}>

          {/* Inputs */}
          <ScrollView style={{ flex: 1, }}>

            <View style={{ flexDirection: 'row', padding: 10, gap: 10 }}>

              {/* Left side */}
              <View style={{ flex: 1 }}>

              <BusinessInformation editMode />

              </View>

              {/* Right side */}
              <View style={{ flex: 1, }}>

                <View style={{ margin: 20, padding: 20, borderRadius: 10, ...shadowSet }}>

                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                    <Text style={{ color: colors.text }}>Subtotal ( 1 item )</Text>
                    <Text style={{ color: colors.text }}>$ 1.00</Text>
                  </View>

                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                    <Text variant='bodyMedium' style={{ color: colors.text }} >Shipping</Text>
                    <Text variant='bodyMedium' style={{ color: colors.text }} >Free</Text>
                  </View>

                  <Divider style={{ backgroundColor: colors.border, marginVertical: 20 }} />

                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20 }}>
                    <Text variant='bodyLarge' style={{ color: colors.text, fontWeight: 'bold' }}>Total</Text>
                    <Text variant='bodyLarge' style={{ color: colors.text, fontWeight: 'bold' }}>$ 1.00</Text>
                  </View>

                </View>

              </View>

            </View>
          </ScrollView>

        </View>
      </View >

    </View >
  )
}

export default AddOnBoarding
