import { useTheme } from "@react-navigation/native";
// import { logEvent } from "firebase/analytics";
import { httpsCallable } from "firebase/functions";
import React, { useContext, useEffect, useState } from "react";
import { ActivityIndicator, View } from "react-native";
import { Text } from "react-native-paper";

import { GlobalContext } from "../../../GlobalContext/GlobalContext";
import { SalesInformation } from "../../../api/salesApi";
import {
  //  analytics,
   log } from "../../../firebase/firebaseConfig";
import CustomLineChart from "../../graphs/customLineChart";
import { cardStyles } from "../../theme/applicationTheme";
import { useFunctions, useUserContext } from "../../../GlobalContext/useUserContext";
import { isWeb } from "../../Common/isWeb";

const TransactionHistory = () => {
  const { colors } = useTheme();
  const { business } = useUserContext();
  const [transactionHistoryData, setTransactionHistoryData] = useState(
    [] as SalesInformation[]
  );
  const [data, setData] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);
  const [lineChartDimension, setLineChartDimension] = useState({ x: 0, y: 0 });

  let getDashboardSales = async () => {
    setIsLoading(true);
    if (business) {
      const { id } = business;
      const getDashboardSales = useFunctions("fetchDashboardSales");
      if (id && id !== null) {
        await getDashboardSales({ businessId: id })
          .then((result: any) => {
            if (result.data.length > 0) {
              setIsLoading(false);
              setTransactionHistoryData(result.data);
              
              // if (isWeb) {
              //   logEvent(analytics, "data_loaded", {
              //     name: "transaction_dashboard_history"
              //   });
              // }
            }
          })
          .catch((error) => {
            // Getting the Error details.
            setIsLoading(false);
            log("exceptionDashboardTransaction", {
              description: error.message,
            });
          });
      }
    }
  }

  useEffect(() => {
    getDashboardSales()
  }, [business]);

  useEffect(() => {
    if (transactionHistoryData.length > 0) {
      setData(transactionHistoryData);
    }
  }, [transactionHistoryData]);
  return (
    <View
      style={{
        ...cardStyles.paper,
        margin: 0,
        padding: 10,
        backgroundColor: colors.card,
      }}
    >
      <View>
        <Text
          variant="titleLarge"
          style={{
            fontWeight: "bold",
            marginTop: 5,
            marginBottom: 10,
            color: colors.text,
          }}
        >
          Transaction History
        </Text>
      </View>

      {isLoading ? (
        <View style={{ marginTop: 110 }}>
          <ActivityIndicator size="large" color={colors.primary} />
        </View>
      ) : (
        <View
          onLayout={(e) =>
            setLineChartDimension({
              x: e.nativeEvent.layout.width,
              y: e.nativeEvent.layout.height,
            })
          }
          style={{ flexDirection: "row", flex: 1 }}
        >
          <CustomLineChart
            chartDimension={lineChartDimension}
            colors={colors}
            lineChartData={data}
            lineChartSeriesName="Sales"
          />
        </View>
      )}
    </View>
  );
};

export default TransactionHistory;
