import { ScrollView, StyleSheet, View, TouchableOpacity, Appearance, ColorSchemeName } from "react-native";
import React, { useState } from "react";
import { Icon, Text } from "react-native-paper";
import Accordion from "react-native-collapsible/Accordion";
import BusinessInformation from "../../component/page/settings/businessInformation";
import HelpSupport from "../../component/page/settings/helpSupport";
import ManageNotifications from "../../component/page/settings/manageNotifications";
import AppTheme from "../../component/page/settings/appTheme";
import LanguageRegionSetting from "../../component/page/settings/languageRegionSetting";
import { cardStyles } from "../../component/theme/applicationTheme";
import { useTheme } from "@react-navigation/native";
import { CustomColors } from "../../api/customColors";

const Settings = () => {

  const { colors }: { colors: CustomColors } = useTheme();

  const [businessCardSelectedIndex, setBusinessCardSelectedIndex] = useState<number[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number[]>([]);
  const [showTaxID, setShowTaxID] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [currentAppTheme, setCurrentAppTheme] = useState<ColorSchemeName>("light");
  const [userNotificationConfig, setUserNotificationConfig] = useState<string | null>(null);

  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const [selectedCurrency, setSelectedCurrency] = useState<string | null>(null);

  let accordionData = [
    {
      id: 0,
      title: "Business Information",
    },
    {
      id: 1,
      title: "Notification Settings",
    },
    {
      id: 3,
      title: "Language and Region Settings",
    },
    {
      id: 4,
      title: "Help and Support",
    },
  ];

  const styles = StyleSheet.create({
    mainViewContainer: {
      flex: 1,
      margin: 10,
      borderRadius: 10,
      overflow: "hidden",
      backgroundColor: colors.card,
    },
    cardRenderHeader: {
      flex: 1,
      padding: 10,
      borderRadius: 10,
      alignItems: "center",
      flexDirection: "row",
    },
    renderHeader: {
      padding: 10,
      alignItems: "center",
      flexDirection: "row",
      color: colors.text,
      borderRadius: 10,
      backgroundColor: colors.card,
    },
    cardRenderContent: {
      flex: 1,
      padding: 10,
    },
    renderContent: {
      padding: 10,
      margin: 10,
      backgroundColor: colors.card,
    },
    editButtonContainer: {
      gap: 10,
      marginTop: 10,
      paddingTop: 10,
      borderTopWidth: 1,
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "flex-start",
      borderColor: colors.border,
    },
    editButtonView: {
      gap: 5,
      padding: 10,
      borderRadius: 10,
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: colors.card,
    },
    button: {
      borderRadius: 10,
      backgroundColor: colors.card,
    },
  });

  return (
    <View style={{ flex: 1, backgroundColor: colors.background }}>
      <Text
        variant="bodyLarge"
        style={{ color: colors.text, margin: 10, fontWeight: "bold" }}
      >
        Settings
      </Text>

      <ScrollView style={styles.mainViewContainer} contentContainerStyle={{ padding: 10, paddingBottom: 20 }}>
        <View style={{ marginTop: 10 }}>

          <View
            style={{
              ...cardStyles.paper,
              marginTop: 5,
              borderRadius: 10,
              backgroundColor: "#0a1a36",
            }}
          >
            <View style={{ padding: 10 }}>
              <Text variant="bodySmall" style={{ color: "white" }}>
                Name
              </Text>
              <Text
                variant="displaySmall"
                style={{ fontWeight: "bold", color: colors.primary }}
              >
                Reefa Foods
              </Text>
            </View>

            <View
              style={{
                padding: 10,
                flexDirection: "row",
                gap: 10,
                justifyContent: "space-evenly",
              }}
            >
              <View>
                <Text variant="bodySmall" style={{ color: colors.primary }}>
                  Legal Name
                </Text>
                <Text style={{ color: "white" }}>Reefa Foods</Text>
              </View>

              <View>
                <Text variant="bodySmall" style={{ color: colors.primary }}>
                  Federal Tax ID
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    gap: 10,
                    alignItems: "center",
                  }}
                >
                  <Text style={{ color: "white" }}>
                    {showTaxID ? "2234230840" : "*****0840"}
                  </Text>

                  <TouchableOpacity onPress={() => setShowTaxID(!showTaxID)}>
                    <Icon
                      source={showTaxID ? "eye" : "eye-off"}
                      color="#fff"
                      size={15}
                    />
                  </TouchableOpacity>
                </View>
              </View>

              <View>
                <Text variant="bodySmall" style={{ color: colors.primary }}>
                  Org Number
                </Text>
                <Text style={{ color: "white" }}>4</Text>
              </View>
            </View>

            {/* business card */}
            <Accordion
              activeSections={businessCardSelectedIndex}
              sections={[{ id: 0, title: "More Information" }]}
              underlayColor="transparent"
              renderHeader={(item) => {
                return (
                  <View
                    style={{
                      ...styles.cardRenderHeader,
                      borderBottomRightRadius:
                        businessCardSelectedIndex[0] === item.id ? 0 : 10,
                      borderBottomLeftRadius:
                        businessCardSelectedIndex[0] === item.id ? 0 : 10,
                    }}
                  >
                    <Text
                      variant="bodyLarge"
                      style={{
                        flex: 1,
                        color: colors.primary,
                        fontWeight: "bold",
                      }}
                    >
                      {item.title}
                    </Text>
                    <Icon
                      source={
                        businessCardSelectedIndex[0] === item.id
                          ? "menu-up"
                          : "menu-down"
                      }
                      color={colors.primary}
                      size={24}
                    />
                  </View>
                )
              }}
              renderContent={(item) => (
                <View
                  style={{
                    ...styles.cardRenderContent,
                    borderBottomRightRadius:
                      businessCardSelectedIndex[0] === item.id ? 10 : 0,
                    borderBottomLeftRadius:
                      businessCardSelectedIndex[0] === item.id ? 10 : 0,
                  }}
                >
                  <View
                    style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                  >
                    <View style={{ flex: 1 }}>
                      <Text
                        variant="bodyMedium"
                        style={{ fontWeight: "bold", color: colors.primary }}
                      >
                        Business Address
                      </Text>
                      <Text variant="bodyMedium" style={{ color: "#fff" }}>
                        House # 10, Street 5, UK, USAGhana
                      </Text>
                    </View>
                    <View style={{ flex: 1 }}>
                      <Text
                        variant="bodyMedium"
                        style={{ fontWeight: "bold", color: colors.primary }}
                      >
                        Phone Number
                      </Text>
                      <Text variant="bodyMedium" style={{ color: "#fff" }}>
                        +92 305 123 456 789
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                  >
                    <View style={{ flex: 1 }}>
                      <Text
                        variant="bodyMedium"
                        style={{ fontWeight: "bold", color: colors.primary }}
                      >
                        Email
                      </Text>
                      <Text variant="bodyMedium" style={{ color: "#fff" }}>
                        example@example.com
                      </Text>
                    </View>
                    <View style={{ flex: 1 }}>
                      <Text
                        variant="bodyMedium"
                        style={{ fontWeight: "bold", color: colors.primary }}
                      >
                        Established Date
                      </Text>
                      <Text variant="bodyMedium" style={{ color: "#fff" }}>
                        12-05-2021
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                  >
                    <View style={{ flex: 1 }}>
                      <Text
                        variant="bodyMedium"
                        style={{ fontWeight: "bold", color: colors.primary }}
                      >
                        Doing Business As
                      </Text>
                      <Text variant="bodyMedium" style={{ color: "#fff" }}>
                        House # 10, Street 5, UK, USA
                      </Text>
                    </View>
                    <View style={{ flex: 1 }}>
                      <Text
                        variant="bodyMedium"
                        style={{ fontWeight: "bold", color: colors.primary }}
                      >
                        Reefa Foods
                      </Text>
                      <Text variant="bodyMedium" style={{ color: "#fff" }}>
                        House # 10, Street 5, UK, USA
                      </Text>
                    </View>
                  </View>
                </View>
              )}
              onChange={(index) => {
                setBusinessCardSelectedIndex(index)
              }}
            />
          </View>
        </View>

        <Accordion
          containerStyle={{ paddingHorizontal: 10 }}
          sectionContainerStyle={{ marginTop: 10, backgroundColor: colors.card, borderRadius: 10,borderWidth:StyleSheet.hairlineWidth, borderColor:'grey', ...cardStyles.elevated }}
          activeSections={selectedIndex}
          sections={accordionData}
          underlayColor="transparent"
          renderHeader={(item) => {
            return (
              <View
                style={{
                  ...styles.renderHeader,
                  borderRadius: 9
                }}
              >
                <Text style={{ color: colors.text, flex: 1 }}>{item.title}</Text>

                <Icon
                  source={
                    selectedIndex[0] === item.id ? "menu-up" : "menu-down"
                  }
                  size={24}
                  color={colors.text}
                />
              </View>
            );
          }}
          renderContent={(item) => (
            <View
              style={{
                ...styles.renderContent,
              }}
            >
              {item.id === 0 ? (
                <BusinessInformation editMode={editMode} />
              ) : item.id === 1 ? (
                <ManageNotifications
                  setUserNotificationConfig={setUserNotificationConfig}
                  userNotificationConfig={userNotificationConfig}
                  editMode={editMode}
                />
              ) : item.id === 3 ? (
                <LanguageRegionSetting
                  selectedCountry={selectedCountry}
                  selectedCurrency={selectedCurrency}
                  setSelectedCountry={setSelectedCountry}
                  setSelectedCurrency={setSelectedCurrency}
                  editMode={editMode}
                />
              ) : item.id === 4 ? (
                <HelpSupport editMode={editMode} />
              ) : null}

              <View style={styles.editButtonContainer}>
                <TouchableOpacity
                  onPress={() => setEditMode(!editMode)}
                  style={styles.editButtonView}
                >
                  <Icon source={"pencil"} size={18} color={colors.text} />
                  <Text style={{ color: colors.text }}>{editMode ? "Save Changes" : "Edit Details"}</Text>
                </TouchableOpacity>
                {editMode && (
                  <TouchableOpacity
                    onPress={() => setEditMode(false)}
                    style={styles.editButtonView}
                  >
                    <Icon source={"cancel"} size={18} color={colors.text} />
                    <Text style={{ color: colors.text }}>Cancel</Text>
                  </TouchableOpacity>
                )}
              </View>
            </View>
          )}
          onChange={(index: []) => {
            setSelectedIndex(index);
          }}
        />
      </ScrollView>
    </View>
  );
};

export default Settings;