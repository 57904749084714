import * as ScreenOrientation from "expo-screen-orientation";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import React, { useEffect, } from "react";
import { Animated, LogBox, Platform, StyleSheet } from "react-native";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
import { GlobalProvider } from "./GlobalContext/GlobalContext";
import AppNavigator  from "./Routes/AppNavigator";
import { app } from "./firebase/firebaseConfig";
import GlobalView from "./global/pages/mainView";

export default function App() {
  const isWeb = Platform.OS === "web";

  LogBox.ignoreLogs(["new NativeEventEmitter()"]);

  const [initializing, setInitializing] = React.useState(true);
  
  useEffect(() => {
    if (!isWeb) {
      ScreenOrientation.lockAsync(
        ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT
      );
    }

    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Set a timeout to log the user out after a specified time (e.g., 1 hour)
        const logoutTimer = setTimeout(() => {
          signOut(auth).then(() => {
            // CLEAR OUT LOCAL STORAGE HERE OF FIREBASE AUTH TOKEN
            localStorage.removeItem('firebase:auth');
            console.log('User has been logged out due to inactivity');
            // Here you can also clear any saved login information if necessary
          }).catch((error) => {
            console.error('Error logging out:', error);
          });
        }, 3600000); // 3600000 milliseconds = 1 hour

        setInitializing(false);

        // Clear the logout timer if the user logs out before the timer expires
        return () => {
          clearTimeout(logoutTimer);
        };
      } else {
        setInitializing(true);
      }
    });

    // Unsubscribe from the auth listener on component unmount
    return () => unsubscribe();
  }, []);

  const rotateAnim = React.useRef(new Animated.Value(0)).current;

  useEffect(() => {
    const rotateLoop = () => {
      Animated.loop(
        Animated.timing(rotateAnim, {
          toValue: 1,
          duration: 2000,
          useNativeDriver: true,
        })
      ).start();
    };

    rotateLoop();
  }, [initializing]);

  useEffect(() => {
    setTimeout(() => {
      setInitializing(false);
      rotateAnim.setValue(0);
    }, 2000);
  }, [initializing]);

  const rotate = rotateAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ["0deg", "360deg"],
  });

  if (initializing) {
    return (
      <GlobalView>
        <Animated.Image
          source={require("./assets/Final.png")}
          style={[styles.image, { transform: [{ rotate }] }]}
        />
      </GlobalView>
    );
  }

  return (
      <GestureHandlerRootView style={{ flex: 1, backgroundColor: "black" }}>
        <SafeAreaProvider style={{ flex: 1 }}>
          <SafeAreaView style={{ flex: 1 }}>
            <GlobalProvider>
              <AppNavigator />
            </GlobalProvider>
          </SafeAreaView>
        </SafeAreaProvider>
      </GestureHandlerRootView>
  );
}

const styles = StyleSheet.create({
  image: {
    width: "50%",
    height: "50%",
    resizeMode: "contain",
  },
});
