import React, { useEffect, useState } from 'react'
import { View, Text, Dimensions, Platform } from 'react-native'

const { height: h, width: w } = Dimensions.get('screen')

const maxWidth = 1024;
const maxHeight = 961;

let isDesktop = Platform.OS === 'web'

const customDimensions = () => {
    const [cw, setCw] = useState(w)
    const [ch, setCh] = useState(h)

    useEffect(() => {
        Dimensions.addEventListener('change', ({ screen, window }) => {
            setCw(window.width)
            setCh(window.height)
        })
    }, [])

    return {
        cw,
        ch
    }
}

const fs = () => ({
    title: isDesktop ? customDimensions().cw * 0.015 : customDimensions().cw * 0.02,
    subTitle: isDesktop ? customDimensions().cw * 0.015 : customDimensions().cw * 0.025,
    body: isDesktop ? customDimensions().cw * 0.01 : customDimensions().cw * 0.02,
    iconSize: isDesktop ? (isDesktop ? customDimensions().cw * 0.01 : customDimensions().cw * 0.02) * 1.25 : (isDesktop ? customDimensions().cw * 0.01 : customDimensions().cw * 0.02) * 1.5

})

export {
    h,
    w,
    isDesktop,
    maxWidth,
    maxHeight,
    fs,
    customDimensions
}