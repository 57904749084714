import { RegisterOptions } from "react-hook-form";



export const inputFieldSchema: inventoryItemSchema = {
    itemName: { required: { value: true, message: 'Name is required' } },
    itemCategory: { required: { value: true, message: 'Category is required' } },
    itemSKU: { required: { value: true, message: 'SKU is required' } },
    itemBarcode: { required: { value: true, message: 'Barcode is required' } },
    itemSafetyStock: { required: { value: true, message: 'Safety Stock is required' } },
    itemDescription: { required: { value: true, message: 'Description is required' } },
    itemSaleByWeight: { required: { value: true, message: 'This field is required' } },
    itemQuantity: { required: { value: true, message: 'Quantity is required' } },
    itemReorderPoint: { required: { value: true, message: 'Reorder Point is required' } },
    itemLocation: { required: { value: true, message: 'Location is required' } },
    itemUnitPrice: { required: { value: true, message: 'Unit Price is required' } },
    itemRetailPrice: { required: { value: true, message: 'Retail Price is required' } },
    itemWholesalePrice: { required: { value: true, message: 'Whole Sale Price is required' } },
    itemImage: { required: { value: true, message: 'Image is required' } },
    supplierID: { required: { value: true, message: 'Supplier Id is required' } },
    supplierName: { required: { value: true, message: 'Supplier Name is required' } },
    itemCustomerDemand: { required: { value: true, message: 'Customer # of demand is required' } },
    itemMetric: { required: { value: true, message: 'Metric is required' } },
    itemExpirationDate: { required: { value: true, message: 'Expiration Date is required' } },
    itemHasaVariant: { required: { value: true, message: 'Variant is required' } },
    itemIsReturnable: { required: { value: true, message: 'Returnable is required' } },
    itemWholesaleMargin: { required: { value: true, message: 'Whole Sale Margin is required' } },
}

export const businessItemSchema: businessItemProps = {
    bonusAllocated: { required: { value: true, message: 'Bonus Allocated is required' } },
    businessAddressLine1: { required: { value: true, message: 'Address Line 1 is required' } },
    businessAddressLine2: { required: { value: true, message: 'Address Line 2 is required' } },
    businessAddressLine3: { required: { value: true, message: 'Address Line 3 is required' } },
    businessCity: { required: { value: true, message: 'City is required' } },
    businessCountry: { required: { value: true, message: 'Country is required' } },
    businessEmail: { required: { value: true, message: 'Email is required' } },
    businessFax: { required: { value: true, message: 'Fax is required' } },
    businessName: { required: { value: true, message: 'Name is required' } },
    businessPhone: { required: { value: true, message: 'Phone is required' } },
    businessProductTier: { required: { value: true, message: 'Prodcut Tier is required' } },
    businessState: { required: { value: true, message: 'State is required' } },
    businessType: { required: { value: true, message: 'Business Type is required' } },
    businessWebsite: { required: { value: true, message: 'Webiste is required' } },
    businessZipCode: { required: { value: true, message: 'Zip Code is required' } },
    id: { required: { value: true, message: 'Id is required' } },
    profitSharingAllocated: { required: { value: true, message: 'Profile Sharing is required' } },
    subaccountCode: { required: { value: true, message: 'Account Code is required' } },
    type: { required: { value: true, message: 'Type is required' } },
}

type businessItemProps = {
    bonusAllocated: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;//15
    businessAddressLine1: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;//"Cloud Avenue 789"
    businessAddressLine2: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;//"Data Center 204"
    businessAddressLine3: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;//""
    businessCity: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;//"Techtopolis"
    businessCountry: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;//"Techlandia"
    businessEmail: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;//"info@skylinetechsolutions.com"
    businessFax: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;//"+9876543211"
    businessName: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;//"Skyline Tech Solutions"
    businessPhone: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;//"+9876543210"
    businessProductTier: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;//"Gold"
    businessState: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;//"InnoSphere"
    businessType: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;//"Information Technology"
    businessWebsite: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;//"www.skylinetechsolutions.com"
    businessZipCode: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;//"56789"
    id: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;//"41Vu7T00IzQ6pjRT9L1U"
    profitSharingAllocated: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;//0
    subaccountCode: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;//"ACCT_q4fqtdod437t1y9"
    type: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;//"percentage"
}

type inventoryItemSchema = {
    id?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>; //id,
    itemID?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>; //inventory.itemID,
    itemName?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>; //inventory.itemName,
    itemDescription?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>; //inventory.itemDescription,
    itemQuantity?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>; //inventory.itemQuantity,
    itemUnitPrice?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>; //inventory.itemUnitPrice,
    itemRetailPrice?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>; //inventory.itemRetailPrice,
    itemWholesalePrice?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>; //inventory.itemWholesalePrice,
    itemWholesaleMargin?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>; //inventory.itemWholesaleMargin,
    itemWholesaleProfit?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>; //inventory.itemWholesaleProfit,
    itemCategory?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>; //inventory.itemCategory,
    itemLocation?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>; //inventory.itemLocation,
    itemReorderPoint?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>; //inventory.itemReorderPoint,
    itemRetailMargin?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>; //inventory.itemRetailMargin,
    itemRetailProfit?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>; //inventory.itemRetailProfit,
    totalInventoryCost?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>; //inventory.totalInventoryCost,
    itemHasaVariant?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>; //inventory.itemHasaVariant,
    itemIsReturnable?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>; //inventory.itemIsReturnable,
    itemMetric?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>; //inventory.itemMetric,
    itemSKU?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>; //inventory.itemSKU,
    itemSaleByWeight?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>; //inventory.itemSaleByWeight,
    supplierID?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>; //inventory.supplierID,
    supplierName?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>; //inventory.supplierName,
    dateOfLastSale?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>; //inventory.dateOfLastSale,
    dateofLastReplishment?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>; //inventory.dateofLastReplishment,
    itemBarcode?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>; //inventory.itemBarcode,
    itemExpirationDate?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>; //inventory.itemExpirationDate,
    itemCustomerDemand?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>; //inventory.itemCustomerDemand,
    itemSafetyStock?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>; //inventory.itemSafetyStock,
    itemImage?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>; //
}