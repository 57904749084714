/* eslint-disable no-console */
import { useTheme } from "@react-navigation/native";
import { httpsCallable } from "firebase/functions";
import React, { useContext, useEffect, useState } from "react";
import { View } from "react-native";
import { Button, Text } from "react-native-paper";

import { GlobalContext } from "../../../GlobalContext/GlobalContext";
import { functions } from "../../../firebase/firebaseConfig";
import CustomPieChart from "../../graphs/customPieChart";

const Employees = () => {
  const { colors } = useTheme();
  const { business } = useContext(GlobalContext);

  const [payrollSummaryDimension, setPayrollSummaryDimension] = React.useState({
    x: 0,
    y: 0,
  });

    const [payrollSummaryData, setPayrollSummaryData] = useState([] as any[]);

  async function fetchPayrollSummaryData() {
    // grab user info from firebase function called fetchUser
    const { id } = business;

    const getPayrollSummaryData = httpsCallable(functions, "payrollSummary");
    if(id && id !== null){await getPayrollSummaryData({ businessId: id })
      .then((result: any) => {
        // Read result of the Cloud Function.
        /** @type {any} */
        const data = result.data;
        console.log(data);
        setPayrollSummaryData([{
          name: "Employer Payment",
          y:data.employerPayTotal,
          color: colors.primary,
        },
        {
          name: "Employee Payment",
          y: data.employeePayTotal,
          color: "blue"
        },
        {
          name: "Benefit Payment",
          y: data.benefitsPayTotal,
          color: "#333"
        },
      ])
      })
      .catch((error) => {
        // Getting the Error details.
        console.error("Error retrieving sales data:", error);
      });}
  }

  useEffect(() => {
      // fetch sales data with a firebase function call
      // setTransactionHistoryData(transactionHistory)
      if (business){
      fetchPayrollSummaryData();
      }
  }, [business]);

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: colors.card,
        borderRadius: 15,
        elevation: 10,
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        shadowColor: "#000000",
        shadowOffset: {
          width: 0,
          height: 2,
        },
      }}
    >
      <View style={{ flex: 1 }}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            paddingVertical: 10,
          }}
        >
          <Text
            variant="titleLarge"
            style={{
              fontWeight: "bold",
              color: colors.text,
              marginLeft: 10,
            }}
          >
            Payroll Summary
          </Text>

          <Button
            icon="eye"
            mode="outlined"
            theme={{ colors: { outline: colors.primary } }}
            textColor="black"
            style={{ marginRight: 10 }}
          >
            View Payroll Report
          </Button>
        </View>

        <View
          onLayout={(e) => {
            setPayrollSummaryDimension({
              x: e.nativeEvent.layout.width,
              y: e.nativeEvent.layout.height,
            });
          }}
          style={{ flex: 1 }}
        >
          <CustomPieChart
            pieChartData={payrollSummaryData}
            chartDimension={payrollSummaryDimension}
            colors={colors}
          />
        </View>
      </View>
    </View>
  );
};

export default Employees;
