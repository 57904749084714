import currencySymbolMap from "currency-symbol-map";
import * as RNLocalize from 'react-native-localize';


export const currencyFormatter = (value: number) => {
    const userLocale = RNLocalize.getLocales()[0].languageTag;
    const currency = currencySymbolMap('GHS');

    // set the format to Ghana cedis currency
    const formatter = new Intl.NumberFormat(userLocale, {
        style: 'currency',
        currency: 'GHS',
    });

    return formatter.format(value)?.substring(3);
};


export const currencySymbol = currencySymbolMap('GHS');

// number formating %
export const percentageFormatter = (value: number) => {
    return `${value.toFixed(2)}%`;
};

// set metric or imperial symbol
export const getMetricOrImperialSymbol = (metric: boolean) => {
    return metric ? 'kg' : 'lb';
};