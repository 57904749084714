/* eslint-disable react-hooks/rules-of-hooks */
import { useTheme } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { View, Switch, Pressable, Image, ScrollView, FlatList, StyleSheet, TextInput, Animated, ActivityIndicator } from 'react-native'
import * as RNLocalize from "react-native-localize";
import { Divider, Icon, Text, TextInput as TInput } from 'react-native-paper'


import { InventoryProps } from '../../../GlobalContext/types'
import { useFunctions, useUserContext } from '../../../GlobalContext/useUserContext'
import { CustomColors } from '../../../api/customColors'
import { percentFormatter } from '../../../utils/currencyPercentFormatter'
import CustomButton from '../../Common/button'
import { isWeb } from '../../Common/isWeb'
import { currencyFormatter } from '../../util/constants'
import { shadowSet } from '../../util/shadow'


type sidePanelProps = {
    colors: CustomColors;
    selectedItem: InventoryProps;
    setShowSidePanel: React.Dispatch<React.SetStateAction<boolean>>;
    fetchAgain: boolean;
    setFetchAgain: React.Dispatch<React.SetStateAction<boolean>>;
    setFilteredData: React.Dispatch<React.SetStateAction<InventoryProps[]>>;
}


const Input = ({
    value,
    textValue,
    multiline,
    editMode,
    setState,
    placeholder
}: {
    value?: string | number;
    textValue: keyof InventoryProps;
    setState: React.Dispatch<React.SetStateAction<InventoryProps>>;
    editMode: boolean;
    multiline?: boolean;
    placeholder: string;
}) => {

    const { colors } = useTheme();

    const styles = StyleSheet.create({
        inputStyle: {
            borderRadius: 10,
            color: colors.text,
            borderColor: colors.border,
            borderWidth: editMode ? 2 : 0,
        }
    })

    return (
        <TInput
            value={String(value)}
            multiline={multiline}
            placeholder={placeholder}
            placeholderTextColor="#999"
            editable={editMode}
            onChangeText={text => {
                setState(pre => ({ ...pre, [textValue]: text }) as InventoryProps)
            }}
            underlineColor='transparent'
            contentStyle={styles.inputStyle}
            activeUnderlineColor='transparent'
            style={{
                height: 'auto',
                paddingHorizontal: 5,
                backgroundColor: "transparent",
            }}
        />
    )
}


const InventorySidePanel = ({
    fetchAgain,
    setFetchAgain,
    setShowSidePanel,
    colors,
    setFilteredData,
    selectedItem
}: sidePanelProps) => {

    console.log(selectedItem )
    const { business } = useUserContext();
    const currency = RNLocalize.getCurrencies()[0];
    const currencyCode = currency;
    const currencySymbol = currencyCode;
    
    const [isActive, setIsActive] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [editMode, setEditMode] = useState(false)

    const [updatedData, setUpdatedData] = useState<InventoryProps | undefined>(selectedItem)

    // segment control button
    const [selectedButton, setSelectedButton] = useState(0)

    const [selectedImage, setSelectedImage] = useState(updatedData?.itemImage)
    const displayImageHeight = isWeb ? 300 : 200

    const checkIndex = (index: number) => selectedButton === index

    useEffect(() => {
        setSelectedImage(updatedData?.itemImage)
    }, [updatedData])

    const updateInventory = async (updatedData: InventoryProps) => {

        setIsLoading(true);
        const getData = useFunctions('updateInventory')
        await getData({
            businessId: business.id,
            inventory: updatedData
        })
            .then(result => {
                console.log('updateInventory successfully', result.data)
                setIsLoading(false);
                setShowSidePanel(false);
                setFilteredData([]);
                setFetchAgain(!fetchAgain);
            })
            .catch(err => {
                console.log('error updating inventory', err.message);
                setIsLoading(false);
            })

    }

    const deleteInventory = async (id: string) => {
        setIsLoading(true);
        const getData = useFunctions('deleteInventory')
        await getData({
            businessId: business.id,
            inventoryId: id
        })
            .then(data => {
                setIsLoading(false);
                setShowSidePanel(false);
                setFilteredData([]);
                setFetchAgain(!fetchAgain);
            })
            .catch(err => {
                setIsLoading(false);
                console.log('error', err.message)
            })
    }



    // console.log({ updatedData })

    return (
        <View style={{ flex: 1, padding: 30 }}>

            {/* top bar */}
            <View style={{ flexDirection: 'row' }}>

                <CustomButton
                    icon="arrow-expand-right"
                    iconSize={18}
                    hideText
                    iconColor={colors.subText}
                    viewStyle={{ justifyContent: 'center', alignItems: 'center' }}
                    onPress={() => {
                        setSelectedButton(0)
                        setShowSidePanel(false);
                    }}
                />

                <View style={{ flexDirection: 'row', gap: 10, flex: 1, justifyContent: 'flex-end', }}>

                    <View style={{ flexDirection: 'row', gap: 10, alignItems: 'center' }}>
                        <Switch value={isActive} onValueChange={() => setIsActive(!isActive)} />
                        <Text style={{ color: colors.text, fontWeight: 'bold' }}>Active</Text>
                    </View>

                    <CustomButton
                        value='Delete'
                        textVariant='labelLarge'
                        onPress={() => deleteInventory(updatedData?.id as string)}
                        viewStyle={{ borderWidth: 1, borderColor: colors.primary, padding: 15, backgroundColor: 'transparent' }}
                        textStyle={{ fontWeight: 'bold', color: colors.primary }}
                    />

                    <CustomButton
                        value={!editMode ? 'Edit' : 'Cancel'}
                        textVariant='labelLarge'
                        onPress={() => setEditMode(!editMode)}
                        viewStyle={{ borderWidth: 1, borderColor: colors.primary, padding: 15, backgroundColor: 'transparent' }}
                        textStyle={{ fontWeight: 'bold', color: colors.primary }}
                    />

                    {editMode && <CustomButton
                        value='Save'
                        textVariant='labelLarge'
                        onPress={() => updateInventory(updatedData as InventoryProps)}
                        viewStyle={{ borderWidth: 1, borderColor: colors.primary, padding: 15, backgroundColor: 'transparent' }}
                        textStyle={{ fontWeight: 'bold', color: colors.primary }}
                    />}

                    <CustomButton
                        value='Clone'
                        textVariant='labelLarge'
                        onPress={() => { }}
                        viewStyle={{ borderWidth: 1, borderColor: colors.primary, padding: 15, backgroundColor: 'transparent' }}
                        textStyle={{ fontWeight: 'bold', color: colors.primary }}
                    />

                    <Divider style={{ height: '100%', width: 2, backgroundColor: colors.border }} />

                    <CustomButton
                        icon="dots-horizontal"
                        iconSize={24}
                        iconColor={colors.subText}
                        onPress={() => { }}
                    />

                </View>

            </View>

            {/* data */}
            <View style={{ flex: 1, marginTop: 10 }}>

                <Text variant='headlineSmall' style={{ color: colors.text, fontWeight: 'bold', textTransform: 'capitalize' }} numberOfLines={2}>{updatedData?.itemName}</Text>
                <Text variant='titleSmall' style={{ color: colors.text }} numberOfLines={2}># {updatedData?.id}</Text>

                <View style={{ flexDirection: 'row', gap: 10, opacity: 0.6, marginTop: 10, alignItems: 'center' }}>
                    <Text adjustsFontSizeToFit style={{ color: colors.subText }}>{updatedData?.itemCategory}</Text>
                    <Icon source="circle-small" size={24} color={colors.subText} />
                    <Text adjustsFontSizeToFit style={{ color: colors.subText }}>{updatedData?.itemCategory}</Text>
                </View>

                {/* segment control button */}
                <View style={{ flexDirection: 'row', justifyContent: 'flex-start', backgroundColor: colors.background, alignSelf: 'flex-start', borderRadius: 10, gap: 5, padding: 2, marginTop: 20 }}>

                    <Pressable onPress={() => setSelectedButton(0)} style={({ pressed }) => ({
                        padding: 10, borderRadius: 10, opacity: pressed ? 0.5 : 1,
                        backgroundColor: checkIndex(0) ? colors.card : 'transparent',
                    })}>
                        <Text style={{ color: checkIndex(0) ? colors.primary : colors.subText, fontWeight: checkIndex(0) ? 'bold' : 'normal' }}>General Information</Text>
                    </Pressable>

                    <Pressable onPress={() => setSelectedButton(1)} style={({ pressed }) => ({
                        padding: 10, borderRadius: 10, opacity: pressed ? 0.5 : 1,
                        backgroundColor: checkIndex(1) ? colors.card : 'transparent',
                    })}>
                        <Text style={{ color: checkIndex(1) ? colors.primary : colors.subText, fontWeight: checkIndex(1) ? 'bold' : 'normal' }}>History</Text>
                    </Pressable>

                    <Pressable onPress={() => setSelectedButton(2)} style={({ pressed }) => ({
                        padding: 10, borderRadius: 10, opacity: pressed ? 0.5 : 1,
                        backgroundColor: checkIndex(2) ? colors.card : 'transparent',
                    })}>
                        <Text style={{ color: checkIndex(2) ? colors.primary : colors.subText, fontWeight: checkIndex(2) ? 'bold' : 'normal' }}>Notes</Text>
                    </Pressable>

                </View>

                {/* general information */}
                {selectedButton === 0 && <ScrollView style={{ flex: 1, marginTop: 10 }}>

                    <View style={{ flexDirection: 'row', gap: 10 }}>

                        {/* left */}
                        <View style={{ flex: 2 }}>

                            <View style={{ flex: 1, flexDirection: 'row', gap: 5, height: displayImageHeight, marginVertical: 10 }}>

                                <View style={{ flex: 1, width: 'auto', backgroundColor: 'white', overflow: 'hidden', borderRadius: 10, margin: 5, ...shadowSet }}>
                                    <Image source={{ uri: selectedImage }} style={{ height: displayImageHeight, resizeMode: 'contain' }} />
                                </View>

                                <View style={{ height: displayImageHeight, marginRight: 5 }}>
                                    <FlatList
                                        data={updatedData?.itemImage}
                                        renderItem={({ item, index }) => {
                                            return (
                                                <Pressable onPress={() => setSelectedImage(item as any)} style={({ pressed }) => ({ opacity: pressed ? 0.5 : 1, borderRadius: 10, marginTop: 5, overflow: 'hidden', backgroundColor: '#fff' })}>
                                                    <Image source={{ uri: item }} style={{ width: 80, height: 80, resizeMode: 'contain' }} />
                                                </Pressable>
                                            )
                                        }}
                                    />
                                </View>

                            </View>

                            {/* basic information */}
                            <Text variant='titleLarge' style={{ color: colors.text }}>Basic Information</Text>

                            <View style={{ padding: 10, width: '60%' }}>

                                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingTop: 10, }}>
                                    <Text style={{ flex: 1, color: colors.subText, textTransform: 'uppercase', opacity: 0.5, fontWeight: 'bold' }}>total be packed</Text>
                                    <Input
                                        editMode={editMode}
                                        placeholder={editMode ? 'Item Quantity' : ''}
                                        setState={setUpdatedData as any}
                                        value={updatedData?.itemQuantity ?? ''}
                                        textValue='itemQuantity'
                                    />
                                </View>
                                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingTop: 10 }}>
                                    <Text style={{ flex: 1, color: colors.subText, textTransform: 'uppercase', opacity: 0.5, fontWeight: 'bold' }}>category</Text>
                                    <Input
                                        editMode={editMode}
                                        placeholder={editMode ? 'Item Category' : ''}
                                        setState={setUpdatedData as any}
                                        value={updatedData?.itemCategory ?? ''}
                                        textValue='itemCategory'
                                    />
                                </View>
                                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingTop: 10 }}>
                                    <Text style={{ flex: 1, color: colors.subText, textTransform: 'uppercase', opacity: 0.5, fontWeight: 'bold' }}>barcode</Text>
                                    <Input
                                        editMode={editMode}
                                        placeholder={editMode ? 'Barcode' : ''}
                                        setState={setUpdatedData as any}
                                        value={updatedData?.barcode ?? ''}
                                        textValue='barcode'
                                    />
                                </View>
                                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingTop: 10 }}>
                                    <Text style={{ flex: 1, color: colors.subText, textTransform: 'uppercase', opacity: 0.5, fontWeight: 'bold' }}>unit</Text>
                                    <Input
                                        editMode={editMode}
                                        placeholder={editMode ? 'Item Unit' : ''}
                                        setState={setUpdatedData as any}
                                        value={updatedData?.itemPrice ?? ''}
                                        textValue='itemPrice'
                                    />
                                </View>
                                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingTop: 10 }}>
                                    <Text style={{ flex: 1, color: colors.subText, textTransform: 'uppercase', opacity: 0.5, fontWeight: 'bold' }}>Create by</Text>
                                    <Input
                                        editMode={editMode}
                                        placeholder={editMode ? 'Created By' : ''}
                                        setState={setUpdatedData as any}
                                        value={updatedData?.itemCreatedBy ?? ''}
                                        textValue='itemCreatedBy'
                                    />
                                </View>
                                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingTop: 10 }}>
                                    <Text style={{ flex: 1, color: colors.subText, textTransform: 'uppercase', opacity: 0.5, fontWeight: 'bold' }}>description</Text>
                                    <Input
                                        editMode={editMode}
                                        placeholder={editMode ? 'Item Description' : ''}
                                        setState={setUpdatedData as any}
                                        value={updatedData?.itemDescription ?? ''}
                                        textValue='itemDescription'
                                    />
                                </View>
                            </View>

                            {/* measurement */}
                            <Text variant='titleLarge' style={{ color: colors.text }}>Measurement</Text>

                            <View style={{ padding: 10, width: '60%' }}>

                                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingTop: 10 }}>
                                    <Text style={{ flex: 1, color: colors.subText, textTransform: 'uppercase', opacity: 0.5, fontWeight: 'bold' }}>Measurement Type</Text>
                                    <Input
                                        editMode={editMode}
                                        placeholder={editMode ? 'Item metric' : ''}
                                        setState={setUpdatedData as any}
                                        value={updatedData?.itemMetric ?? ''}
                                        textValue='itemMetric'
                                    />
                                </View>
                                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingTop: 10 }}>
                                    <Text style={{ flex: 1, color: colors.subText, textTransform: 'uppercase', opacity: 0.5, fontWeight: 'bold' }}>Sale By</Text>

                                    <Input
                                        editMode={editMode}
                                        placeholder={editMode ? 'Item sale by weight' : ''}
                                        setState={setUpdatedData as any}
                                        value={updatedData?.itemSaleByWeight ?? ''}
                                        textValue='itemSaleByWeight'
                                    />
                                </View>
                            </View>

                            {/* purchase information */}
                            <Text variant='titleLarge' style={{ color: colors.text }}>Purchase Information</Text>

                            <View style={{ padding: 10, width: '60%' }}>

                                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', paddingTop: 10 }}>
                                    <Text style={{ flex: 1, color: colors.subText, textTransform: 'uppercase', opacity: 0.5, fontWeight: 'bold' }}>Price per Unit</Text>
                                    <Text style={{ flex: 1, fontWeight: 'bold', color: colors.text }}>{currencySymbol} {updatedData?.itemPrice as number}</Text>
                                </View>

                            </View>

                            {/* sale information */}
                            <Text variant='titleLarge' style={{ color: colors.text }}>Sale Information</Text>

                            <View style={{ flexDirection: 'row', padding: 10, gap: 10 }}>
                                <View style={{ flex: 1 }}>
                                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', paddingTop: 10, borderBottomWidth: 1, paddingBottom: 10, borderColor: colors.border }}>
                                        <Text style={{ flex: 1, color: colors.subText, textTransform: 'uppercase', opacity: 0.5, fontWeight: 'bold' }}>Retail price</Text>
                                        <Text style={{ fontWeight: 'bold', color: colors.text }}>{currencySymbol} {updatedData?.itemRetailPrice || 0}</Text>
                                    </View>
                                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', paddingTop: 10 }}>
                                        <Text style={{ flex: 1, color: colors.subText, textTransform: 'uppercase', opacity: 0.5, fontWeight: 'bold' }}>margin</Text>
                                        <Text style={{ fontWeight: 'bold', color: colors.text }}>{currencySymbol} {updatedData?.itemRetailMargin || 0}</Text>
                                    </View>
                                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', paddingTop: 10 }}>
                                        <Text style={{ flex: 1, color: colors.subText, textTransform: 'uppercase', opacity: 0.5, fontWeight: 'bold' }}>Profit (%)</Text>
                                        <Text style={{ fontWeight: 'bold', color: colors.text }}>{percentFormatter(updatedData?.itemRetailProfit || 0)}</Text>
                                    </View>
                                </View>

                                <View style={{ flex: 1 }}>
                                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', paddingTop: 10, borderBottomWidth: 1, paddingBottom: 10, borderColor: colors.border }}>
                                        <Text style={{ flex: 1, color: colors.subText, textTransform: 'uppercase', opacity: 0.5, fontWeight: 'bold' }}>WholeSale price</Text>
                                        <Text style={{ fontWeight: 'bold', color: colors.text }}>{currencySymbol} {updatedData?.itemWholesalePrice || 0}</Text>
                                    </View>
                                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', paddingTop: 10 }}>
                                        <Text style={{ flex: 1, color: colors.subText, textTransform: 'uppercase', opacity: 0.5, fontWeight: 'bold' }}>margin</Text>
                                        <Text style={{ fontWeight: 'bold', color: colors.text }}>{currencySymbol} {updatedData?.itemWholesaleMargin || 0}</Text>
                                    </View>
                                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', paddingTop: 10 }}>
                                        <Text style={{ flex: 1, color: colors.subText, textTransform: 'uppercase', opacity: 0.5, fontWeight: 'bold' }}>profit (%)</Text>
                                        <Text style={{ fontWeight: 'bold', color: colors.text }}>{percentFormatter(updatedData?.itemWholesaleProfit || 0)}</Text>
                                    </View>
                                </View>

                            </View>

                        </View>

                        {/* right */}
                        <View style={{ flex: 1 }}>

                            <View style={{ backgroundColor: colors.background, padding: 10, borderRadius: 10 }}>

                                <Text variant='titleLarge' style={{ color: colors.text }}>Stock</Text>

                                <View style={{ backgroundColor: colors.card, padding: 10, borderRadius: 10, marginTop: 10 }}>

                                    <Text style={{ flex: 1, color: colors.subText, textTransform: 'uppercase', fontWeight: 'bold', textAlign: 'center', opacity: 0.7, }}>Qauntity on hand</Text>

                                    <Text style={{ fontWeight: 'bold', color: colors.text, marginTop: 10, textAlign: 'center' }}>{updatedData?.itemQuantity}</Text>

                                    <CustomButton
                                        value='Adjust Stock'
                                        textVariant='labelLarge'
                                        onPress={() => { }}
                                        viewStyle={{ borderWidth: 1, borderColor: colors.primary, padding: 15, marginTop: 10, alignSelf: 'center', backgroundColor: 'transparent' }}
                                        textStyle={{ fontWeight: 'bold', color: colors.primary }}
                                    />

                                </View>

                                <Text variant='titleLarge' style={{ color: colors.text, marginVertical: 10 }}>Reorder Points</Text>

                                <View style={{ backgroundColor: colors.card, padding: 10, borderRadius: 10 }}>

                                    <View style={{ flexDirection: 'row', gap: 10 }}>
                                        <View style={{ flex: 1, justifyContent: 'space-between', paddingTop: 10 }}>
                                            <Text variant='labelMedium' style={{ flex: 1, color: colors.subText, textTransform: 'uppercase', opacity: 0.5, fontWeight: 'bold' }}>Reorder point</Text>
                                            <Text variant='labelMedium' style={{ flex: 1, marginTop: 5, fontWeight: 'bold', color: colors.text }}>{updatedData?.itemReorderPoint}</Text>
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: 'row', gap: 5, marginVertical: 10 }}>
                                        <Icon source="map-marker-radius" size={18} color={colors.subText} />
                                        <Text style={{ color: colors.text }}>updatedData Location</Text>
                                        <Text style={{ color: '#1fe', fontWeight: 'bold' }}>{updatedData?.itemLocation}</Text>
                                    </View>

                                    <View style={{ flexDirection: 'row', gap: 10 }}>
                                        <View style={{ flex: 1, justifyContent: 'space-between', paddingTop: 10 }}>
                                            <Text variant='labelMedium' style={{ flex: 1, color: colors.subText, textTransform: 'uppercase', opacity: 0.5, fontWeight: 'bold' }}>Supplier Name</Text>
                                            <Text variant='labelMedium' style={{ flex: 1, marginTop: 5, fontWeight: 'bold', color: colors.text }}>{updatedData?.supplierName}</Text>
                                        </View>
                                        <View style={{ flex: 1, justifyContent: 'space-between', paddingTop: 10 }}>
                                            <Text variant='labelMedium' style={{ flex: 1, color: colors.subText, textTransform: 'uppercase', opacity: 0.5, fontWeight: 'bold' }}>Supplier Id</Text>
                                            <Text variant='labelMedium' style={{ flex: 1, marginTop: 5, fontWeight: 'bold', color: colors.text }}>{updatedData?.supplierID}</Text>
                                        </View>
                                    </View>


                                </View>


                            </View>



                        </View>

                    </View>

                </ScrollView>}

                {/* history */}
                {selectedButton === 1 && <FlatList
                    data={updatedData?.itemHistory}
                    style={{ marginTop: 10 }}
                    renderItem={({ item, index }) => {
                        return (
                            <View style={{ flexDirection: 'row' }}>

                                {/* line */}
                                <View style={{ width: 50, height: '100%', alignItems: 'center' }}>
                                    <View style={{ position: 'absolute', top: 0, alignSelf: 'center', backgroundColor: colors.card, borderRadius: 100, zIndex: 99 }}>
                                        <Icon source="record-circle" size={18} color={colors.subText} />
                                    </View>
                                    <View style={{ flex: 1, borderStyle: 'dashed', borderWidth: StyleSheet.hairlineWidth, borderColor: colors.border }} />
                                </View>

                                {/* other */}
                                <View style={{ flex: 1, padding: 10 }}>

                                    <View style={{ paddingHorizontal: 10, alignSelf: 'flex-start', borderRadius: 100, borderWidth: 1, borderColor: colors.border }}>
                                        <Text variant='labelSmall' style={{ color: colors.text }}>{item?.timeStamp}</Text>
                                    </View>
                                    <Text style={{ color: colors.text, fontWeight: 'bold', marginVertical: 10 }}>{item?.activity}</Text>

                                    <View style={{ flexDirection: 'row', gap: 10, alignItems: 'center' }}>
                                        <Image source={{ uri: item?.users_image }} style={{ width: 30, height: 30, resizeMode: 'contain', borderRadius: 100 }} />
                                        <Text style={{ color: colors.subText }}>{item?.users_Name}</Text>
                                    </View>

                                </View>


                            </View>
                        )
                    }}
                />}

                {/* notes */}
                {selectedButton === 2 && <ScrollView style={{ marginTop: 10 }}>

                    <Text variant='titleLarge' style={{ color: colors.text }}>Add new notes</Text>

                    <TextInput
                        placeholder='Add notes here'
                        placeholderTextColor={colors.border}
                        style={{ width: '60%', height: 200, borderWidth: 1, color: colors.text, borderColor: colors.border, borderRadius: 10, padding: 10, marginVertical: 10 }}
                        multiline
                    />


                    <CustomButton
                        value='Add Notes'
                        textVariant='bodyLarge'
                        viewStyle={{ backgroundColor: colors.primary, borderRadius: 10, alignSelf: 'flex-start' }}
                        textStyle={{ color: 'black', fontWeight: 'bold' }}
                        onPress={() => { }}
                    />

                    {/* when button is clicked add item notes to the inventor as an update to it */}

                    {/* {updatedData?.notes?.map((item, index) => {
                        return (
                            <View style={{ padding: 10, borderRadius: 10, backgroundColor: colors.background, marginTop: 10 }}>

                                <View style={{ flexDirection: 'row', gap: 10, alignItems: 'center' }}>
                                    <Image source={{ uri: item?.user?.imageURI }} style={{ width: 30, height: 30, resizeMode: 'contain', borderRadius: 100 }} />
                                    <View>
                                        <Text style={{ color: colors.text, fontWeight: 'bold' }}>{item?.user?.name}</Text>
                                        <Text style={{ color: colors.subText }}>{item?.time}</Text>
                                    </View>
                                </View>

                                <Divider style={{ marginVertical: 10, backgroundColor: colors.border }} />

                                <View style={{ padding: 10 }}>
                                    <Text style={{ fontWeight: 'bold', opacity: 0.7, color: colors.text }}>{item?.note}</Text>
                                </View>


                            </View>
                        )
                    })} */}

                </ScrollView>}

            </View>

            {
                isLoading && (
                    <View style={{
                        ...StyleSheet.absoluteFillObject, justifyContent: 'center', alignItems: 'center', borderTopLeftRadius: 30,
                        borderBottomLeftRadius: 30, backgroundColor: '#00000050'
                    }}>
                        <ActivityIndicator color={colors.primary} size="large" />
                    </View>
                )
            }

        </View>
    )
}

export default InventorySidePanel