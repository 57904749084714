import moment from 'moment'

let transactionHistoryHeader = [
    'Order Id',
    'Customer Name',
    'Date',
    'Amount'
]
let transactionHistory = [
    {
        title: "Tesco Market",
        type: 'Shopping',
        date: moment().format('DD MMM YYYY'),
        amount: '$100.80'
    },
    {
        title: "ElectroMen Market",
        type: 'Food',
        date: moment().format('DD MMM YYYY'),
        amount: '$700.80'
    },
    {
        title: "FLorgio Restuarant",
        type: 'Shopping',
        date: moment().format('DD MMM YYYY'),
        amount: '$610.80'
    },
    {
        title: "John Mathew",
        type: 'Sport',
        date: moment().format('DD MMM YYYY'),
        amount: '$920.80'
    },
    {
        title: "Ann Mrlin",
        type: 'Shopping',
        date: moment().format('DD MMM YYYY'),
        amount: '$150.80'
    }

]

let employeesData = [
    {
        image: require('../icons/user_picture.png'),
        name: "Ann",
    },
    {
        image: require('../icons/user_picture.png'),
        name: "jullie",
    },
    {
        image: require('../icons/user_picture.png'),
        name: "Ben",
    },
    {
        image: require('../icons/user_picture.png'),
        name: "Atom",
    },
    {
        image: require('../icons/user_picture.png'),
        name: "Ann",
    },
];

let dashboard_Status = [
    {
        title: 'Loss',
        value: '$ 350.60',
        color: '#bb4430'
    },
    {
        title: 'Profit',
        value: '$ 1500.50',
        color: '#439a86'
    },
    {
        title: 'Revenue',
        value: '$ 2850.75',
        color: '#368bc5'
    },
]

let goalsData = [
    {
        title: 'Holidays',
        value: '$550',
        date: '12/20/23',
        icon: 'tent'
    },
    {
        title: 'Renovation',
        value: '$930',
        date: '12/20/23',
        icon: 'brush-variant'
    },
    {
        title: 'Texes',
        value: '$510',
        date: '12/20/23',
        icon: 'hail'
    },
    {
        title: 'Holidays',
        value: '$550',
        date: '12/20/23',
        icon: 'tent'
    },
    {
        title: 'Renovation',
        value: '$930',
        date: '12/20/23',
        icon: 'brush-variant'
    },
    {
        title: 'Texes',
        value: '$510',
        date: '12/20/23',
        icon: 'hail'
    },
]

let goalsProgress = [
    {
        title: 'Inventory',
        value: 52,
        color: '#f89950',
        icon: 'cart-outline'
    },
    {
        title: 'Budget',
        value: 21,
        color: '#25ad4b',
        icon: 'ios-car-outline'
    },
    {
        title: 'Customer Requests',
        value: 74,
        color: '#70a6e8',
        icon: 'airplane-outline'
    },
]

let salesInformationData = [
    {
        invoiceID: '#AHG2d4',
        date: '12/20/23',
        customer: 'Mark',
        payableAmount: 560,
        status: 'Succeeded',
        paidAmount: 60,
        dueAmount: 500
    },
    {
        invoiceID: '#BHG334',
        date: '12/20/23',
        customer: 'Alivin',
        payableAmount: 2300,
        status: 'Succeeded',
        paidAmount: 320,
        dueAmount: 1500
    },
    {
        invoiceID: '#cHG234',
        date: '12/20/20',
        customer: 'Tom',
        payableAmount: 1100,
        status: 'Succeeded',
        paidAmount: 900,
        dueAmount: 200
    },
    {
        invoiceID: '#1HG234',
        date: '12/20/23',
        customer: 'Jerry',
        payableAmount: 90,
        status: 'Succeeded',
        paidAmount: 10,
        dueAmount: 80
    },
    {
        invoiceID: '#aHG234',
        date: '12/20/23',
        customer: 'Mark',
        payableAmount: 100,
        status: 'Succeeded',
        paidAmount: 0,
        dueAmount: 100
    },
    {
        invoiceID: '#tAHG2d4',
        date: '12/20/23',
        customer: 'Mark',
        payableAmount: 560,
        status: 'Succeeded',
        paidAmount: 60,
        dueAmount: 500
    },
    {
        invoiceID: '#jAHG334',
        date: '12/20/23',
        customer: 'Alivin',
        payableAmount: 2300,
        status: 'Succeeded',
        paidAmount: 320,
        dueAmount: 1500
    },
    {
        invoiceID: '#kAHG234',
        date: '12/20/20',
        customer: 'Tom',
        payableAmount: 1100,
        status: 'Succeeded',
        paidAmount: 900,
        dueAmount: 200
    },
    {
        invoiceID: '#rAHG234',
        date: '12/20/23',
        customer: 'Jerry',
        payableAmount: 90,
        status: 'Succeeded',
        paidAmount: 10,
        dueAmount: 80
    },
    {
        invoiceID: '#nAHG234',
        date: '12/20/23',
        customer: 'Mark',
        payableAmount: 100,
        status: 'Succeeded',
        paidAmount: 0,
        dueAmount: 100
    },
    {
        invoiceID: '#vAHG334',
        date: '12/20/23',
        customer: 'Alivin',
        payableAmount: 2300,
        status: 'Succeeded',
        paidAmount: 320,
        dueAmount: 1500
    },
    {
        invoiceID: '#AHG234',
        date: '12/20/20',
        customer: 'Tom',
        payableAmount: 1100,
        status: 'Succeeded',
        paidAmount: 900,
        dueAmount: 200
    },
    {
        invoiceID: '#AHG234',
        date: '12/20/23',
        customer: 'Jerry',
        payableAmount: 90,
        status: 'Succeeded',
        paidAmount: 10,
        dueAmount: 80
    },
    {
        invoiceID: '#AHG234',
        date: '12/20/23',
        customer: 'Mark',
        payableAmount: 100,
        status: 'Succeeded',
        paidAmount: 0,
        dueAmount: 100
    },
    {
        invoiceID: '#AHG334',
        date: '12/20/23',
        customer: 'Alivin',
        payableAmount: 2300,
        status: 'Succeeded',
        paidAmount: 320,
        dueAmount: 1500
    },
    {
        invoiceID: '#AHG234',
        date: '12/20/20',
        customer: 'Tom',
        payableAmount: 1100,
        status: 'Succeeded',
        paidAmount: 900,
        dueAmount: 200
    },
    {
        invoiceID: '#AHG234',
        date: '12/20/23',
        customer: 'Jerry',
        payableAmount: 90,
        status: 'Succeeded',
        paidAmount: 10,
        dueAmount: 80
    },
    {
        invoiceID: '#AHG234',
        date: '12/20/23',
        customer: 'Mark',
        payableAmount: 100,
        status: 'Succeeded',
        paidAmount: 0,
        dueAmount: 100
    },
    {
        invoiceID: '#AHG334',
        date: '12/20/23',
        customer: 'Alivin',
        payableAmount: 2300,
        status: 'Succeeded',
        paidAmount: 320,
        dueAmount: 1500
    },
    {
        invoiceID: '#AHG234',
        date: '12/20/20',
        customer: 'Tom',
        payableAmount: 1100,
        status: 'Succeeded',
        paidAmount: 900,
        dueAmount: 200
    },
    {
        invoiceID: '#AHG234',
        date: '12/20/23',
        customer: 'Jerry',
        payableAmount: 90,
        status: 'Succeeded',
        paidAmount: 10,
        dueAmount: 80
    },
    {
        invoiceID: '#AHG234',
        date: '12/20/23',
        customer: 'Mark',
        payableAmount: 100,
        status: 'Succeeded',
        paidAmount: 0,
        dueAmount: 100
    },
    {
        invoiceID: '#AHG2d4',
        date: '12/20/23',
        customer: 'Mark',
        payableAmount: 560,
        status: 'Succeeded',
        paidAmount: 60,
        dueAmount: 500
    },
    {
        invoiceID: '#AHG334',
        date: '12/20/23',
        customer: 'Alivin',
        payableAmount: 2300,
        status: 'Succeeded',
        paidAmount: 320,
        dueAmount: 1500
    },
    {
        invoiceID: '#AHG234',
        date: '12/20/20',
        customer: 'Tom',
        payableAmount: 1100,
        status: 'Succeeded',
        paidAmount: 900,
        dueAmount: 200
    },
    {
        invoiceID: '#AHG234',
        date: '12/20/23',
        customer: 'Jerry',
        payableAmount: 90,
        status: 'Succeeded',
        paidAmount: 10,
        dueAmount: 80
    },
    {
        invoiceID: '#AHG234',
        date: '12/20/23',
        customer: 'Mark',
        payableAmount: 100,
        status: 'Succeeded',
        paidAmount: 0,
        dueAmount: 100
    },
]

let inventoryData = [
    {
        itemID: '#1200670',
        dateOfEnter: '20/12/20',
        itemName: 'Fish',
        quantity: '100',
        unitPrice: 1250.00
    },
    {
        itemID: '#123500670',
        dateOfEnter: '29/12/20',
        itemName: 'Milk',
        quantity: '48',
        unitPrice: 1990.00
    },
    {
        itemID: '#123567012312',
        dateOfEnter: '12/12/20',
        itemName: 'Meat',
        quantity: '10',
        unitPrice: 150.00
    },
    {
        itemID: '#091235522',
        dateOfEnter: '19/12/22',
        itemName: 'Eggs',
        quantity: '36',
        unitPrice: 1000.00
    },
    {
        itemID: '#1200670',
        dateOfEnter: '20/12/20',
        itemName: 'Fish',
        quantity: '100',
        unitPrice: 1250.00
    },
    {
        itemID: '#123500670',
        dateOfEnter: '29/12/20',
        itemName: 'Milk',
        quantity: '48',
        unitPrice: 1990.00
    },
    {
        itemID: '#1235621370',
        dateOfEnter: '12/12/20',
        itemName: 'Meat',
        quantity: '10',
        unitPrice: 150.00
    },
    {
        itemID: '#091235522',
        dateOfEnter: '19/12/22',
        itemName: 'Eggs',
        quantity: '36',
        unitPrice: 1000.00
    },
    {
        itemID: '#1200670',
        dateOfEnter: '20/12/20',
        itemName: 'Fish',
        quantity: '100',
        unitPrice: 1250.00
    },
    {
        itemID: '#123500670',
        dateOfEnter: '29/12/20',
        itemName: 'Milk',
        quantity: '48',
        unitPrice: 1990.00
    },
    {
        itemID: '#123500670',
        dateOfEnter: '29/12/20',
        itemName: 'Milk',
        quantity: '48',
        unitPrice: 1990.00
    },
    {
        itemID: '#1asdas235670',
        dateOfEnter: '12/12/20',
        itemName: 'Meat',
        quantity: '10',
        unitPrice: 150.00
    },
    {
        itemID: '#091235522',
        dateOfEnter: '19/12/22',
        itemName: 'Eggs',
        quantity: '36',
        unitPrice: 1000.00
    },
    {
        itemID: '#1200670',
        dateOfEnter: '20/12/20',
        itemName: 'Fish',
        quantity: '100',
        unitPrice: 1250.00
    },
    {
        itemID: '#123500670',
        dateOfEnter: '29/12/20',
        itemName: 'Milk',
        quantity: '48',
        unitPrice: 1990.00
    },
]

let paymentData = [
    {
        paymentID: 'cx9fasdf90d8-ds9912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Succeeded',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: 'df9f90d8-ds991sdf2-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Pending',
        amount: 700.00,
        paymentMethod: 'VISA',
        cardNo: '0003',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: 'yu9yuf90d8-ds9912-sdfdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Declined',
        amount: 1500.00,
        paymentMethod: 'MASTERCARD',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: 'jk9f90d8-ds9912-sdfc9s-asddfff',
        invoiceID: '#AHG2d4',
        status: 'Create',
        amount: 1500.00,
        paymentMethod: 'NAYAPAY',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: 'zc9f90asd8-ds9912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Succeeded',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: '219f90d8-ds9asd912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Pending',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: 'as9fas90d8-ds9912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Declined',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: '9f90d8-ds9912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Succeeded',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: '9f90d8-ds9912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Declined',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: '9f90d8-ds9912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Succeeded',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: '9f90d8-ds9912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Succeeded',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: '9f90d8-ds9912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Declined',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: '9f90d8-ds9912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Succeeded',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: '9f90d8-ds9912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Succeeded',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: '9f90d8-ds9912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Declined',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: '9f90d8-ds9912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Succeeded',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: '9f90d8-ds9912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Succeeded',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: '9f90d8-ds9912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Declined',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: '9f90d8-ds9912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Succeeded',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: '9f90d8-ds9912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Succeeded',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: '9f90d8-ds9912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Declined',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: '9f90d8-ds9912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Succeeded',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: '9f90d8-ds9912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Succeeded',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: '9f90d8-ds9912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Declined',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: '9f90d8-ds9912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Succeeded',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: '9f90d8-ds9912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Succeeded',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: '9f90d8-ds9912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Declined',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: '9f90d8-ds9912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Succeeded',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: '9f90d8-ds9912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Succeeded',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: '9f90d8-ds9912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Declined',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: '9f90d8-ds9912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Succeeded',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: '9f90d8-ds9912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Succeeded',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: '9f90d8-ds9912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Declined',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
    {
        paymentID: '9f90d8-ds9912-sdfc9s-asdff',
        invoiceID: '#AHG2d4',
        status: 'Succeeded',
        amount: 1500.00,
        paymentMethod: 'VISA',
        cardNo: '8403',
        creationDate: 'Mar 23, 2023, 11:00 am'
    },
]

let addInventoryItemHeader = [
    'LOT No.',
    'Item No.',
    'Description',
    'Current Quantity',
    'Physical Quantity'
]

let addInventoryItemData = [
    {
        lotNo: '#LOT23445634',
        itemNo: 'JEROME-ASDKJ',
        description: '100% ploester',
        currentQuantity: 29,
        physicalQuantity: 10
    },
    {
        lotNo: '#LOT23445634',
        itemNo: 'JEROME-ASDKJ',
        description: '100% ploester',
        currentQuantity: 29,
        physicalQuantity: 10
    },
    {
        lotNo: '#LOT23445634',
        itemNo: 'JEROME-ASDKJ',
        description: '100% ploester',
        currentQuantity: 29,
        physicalQuantity: 10
    },
    {
        lotNo: '#LOT23445634',
        itemNo: 'JEROME-ASDKJ',
        description: '100% ploester',
        currentQuantity: 29,
        physicalQuantity: 10
    },
    {
        lotNo: '#LOT23445634',
        itemNo: 'JEROME-ASDKJ',
        description: '100% ploester',
        currentQuantity: 29,
        physicalQuantity: 10
    },
    {
        lotNo: '#LOT23445634',
        itemNo: 'JEROME-ASDKJ',
        description: '100% ploester',
        currentQuantity: 29,
        physicalQuantity: 10
    },
    {
        lotNo: '#LOT23445634',
        itemNo: 'JEROME-ASDKJ',
        description: '100% ploester',
        currentQuantity: 29,
        physicalQuantity: 10
    },
]

let personalEmployeeHeaders = [
    'Name',
    'Role',
    'Status',
    'Time In',
    'Time Out',
    'View More'
]

let personalEmployeeData = [
    {
        name: 'Kelvin',
        userImage: require('../icons/user.png'),
        role: 'Front Dev',
        status: 'Active',
        timeIn: '7:30 am',
        timeOut: '5:30 pm'
    },
    {
        name: 'AKelvin',
        userImage: require('../icons/user.png'),
        role: 'Front Dev',
        status: 'Active',
        timeIn: '7:30 am',
        timeOut: '5:30 pm'
    },
    {
        name: 'BKelvin',
        userImage: require('../icons/user.png'),
        role: 'Front Dev',
        status: 'Active',
        timeIn: '7:30 am',
        timeOut: '5:30 pm'
    },
    {
        name: 'CKelvin',
        userImage: require('../icons/user.png'),
        role: 'Front Dev',
        status: 'Active',
        timeIn: '7:30 am',
        timeOut: '5:30 pm'
    },
    {
        name: 'DKelvin',
        userImage: require('../icons/user.png'),
        role: 'Front Dev',
        status: 'Active',
        timeIn: '7:30 am',
        timeOut: '5:30 pm'
    },
    {
        name: 'EKelvin',
        userImage: require('../icons/user.png'),
        role: 'Front Dev',
        status: 'Active',
        timeIn: '7:30 am',
        timeOut: '5:30 pm'
    },
    {
        name: 'FKelvin',
        userImage: require('../icons/user.png'),
        role: 'Front Dev',
        status: 'Active',
        timeIn: '7:30 am',
        timeOut: '5:30 pm'
    },
    {
        name: 'GKelvin',
        userImage: require('../icons/user.png'),
        role: 'Front Dev',
        status: 'Active',
        timeIn: '7:30 am',
        timeOut: '5:30 pm'
    },
    {
        name: 'Kelvin',
        userImage: require('../icons/user.png'),
        role: 'Front Dev',
        status: 'Active',
        timeIn: '7:30 am',
        timeOut: '5:30 pm'
    },
    {
        name: 'Kelvin',
        userImage: require('../icons/user.png'),
        role: 'Front Dev',
        status: 'Active',
        timeIn: '7:30 am',
        timeOut: '5:30 pm'
    },
    {
        name: 'Kelvin',
        userImage: require('../icons/user.png'),
        role: 'Front Dev',
        status: 'Active',
        timeIn: '7:30 am',
        timeOut: '5:30 pm'
    },
    {
        name: 'Kelvin',
        userImage: require('../icons/user.png'),
        role: 'Front Dev',
        status: 'Active',
        timeIn: '7:30 am',
        timeOut: '5:30 pm'
    },
    {
        name: 'Kelvin',
        userImage: require('../icons/user.png'),
        role: 'Front Dev',
        status: 'Active',
        timeIn: '7:30 am',
        timeOut: '5:30 pm'
    },
    {
        name: 'Kelvin',
        userImage: require('../icons/user.png'),
        role: 'Front Dev',
        status: 'Active',
        timeIn: '7:30 am',
        timeOut: '5:30 pm'
    },
    {
        name: 'Kelvin',
        userImage: require('../icons/user.png'),
        role: 'Front Dev',
        status: 'Active',
        timeIn: '7:30 am',
        timeOut: '5:30 pm'
    },
]

let salePurchaseData = [
    {
        title: 'Jerome Sahara',
        moq: 50,
        qty: 150,
        image: require('./demo_1.png')
    },
    {
        title: 'Jerome Sahara',
        moq: 50,
        qty: 150,
        image: require('./demo_2.png')
    },
    {
        title: 'Jerome Sahara',
        moq: 50,
        qty: 150,
        image: require('./demo_3.png')
    },
]

let bankNames = [
    {
        bankName: 'Bank of America',
        bankImage: require('../bank_icons/bank_of_america.png')
    },
    {
        bankName: 'Chase',
        bankImage: require('../bank_icons/chase.png')
    },
    {
        bankName: 'Citi Bank',
        bankImage: require('../bank_icons/citi_bank.png')
    },
    {
        bankName: 'US Bank',
        bankImage: require('../bank_icons/us-bank-logo.png')
    },
    {
        bankName: 'Wells Fargo',
        bankImage: require('../bank_icons/wells_fargo.png')
    },
    {
        bankName: 'Wings Financial',
        bankImage: require('../bank_icons/wings_financial.png')
    },
]

let benefitCards = [
    { label: 'Health Insurance', value: 'Ammana' },
    { label: 'Dental Insurance', value: 'Ammana' },
    { label: 'Lift Insurance', value: 'Ammana' },
    { label: 'PTO', value: '15 days' },
    { label: '401k' , value: 'Ammana'},
    { label: 'Stock Options', value: 'Ammana' },
    { label: 'Bonus', value: 'Ammana' },
    { label: 'Profit Sharing', value: 'Ammana' }
]

let information = [
    { label: 'Address', value: '154 malam' },
    { label: 'Phone', value: '123456789' },
    { label: 'Email', value: 'testaccount@email.com' },
    { label: 'Code', value: '123456789' },
    { label: 'Hobby', value: 'Cricket' },
    { label: 'Gender', value: 'male' },
    { label: 'Date of Birth', value: '12/12/2020' },
    { label: 'Date of Job', value: '12/12/2020' },    
    { label: 'Emergency Contact Name', value: 'Mark' },
    { label: 'Emergency Contact Relation', value: 'Father' },
    { label: 'Emergency Contact Phone', value: '123456789' },
]

let document = [
    { name: 'Letter', type: 'cover_letter' },
    { name: 'Handbook', type: 'employment' },
    { name: 'Agreement', type: 'employment' },
]

let employmentDetails = [
    { label: 'Job Title', value: 'Software Engineer' },
    { label: 'Department', value: 'Software' },
    { label: 'Location', value: 'USA' },
    { label: 'Employment Status', value: 'Active' },
    { label: 'Employment Type', value: 'Full Time' },
    { label: 'Employee Manager', value: 'Mark' },
]


let tasks = [
    { title: 'HomePage Design', status: 'Completed', date: '12/20/23', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
    { title: 'Product Listing Page', status: 'In Progress', date: '12/20/23', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
    { title: 'Product Design Page', status: 'Completed', date: '12/20/23', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
    { title: 'Shopping Cart', status: 'In Progress', date: '12/20/23', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
    { title: 'Checkout Process', status: 'Due', date: '12/20/23', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
    { title: 'User Account Dashboard', status: 'Due', date: '12/20/23', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
]

export {
    transactionHistory,
    transactionHistoryHeader,
    employeesData,
    goalsProgress,
    dashboard_Status,
    goalsData,
    paymentData,
    salesInformationData,
    inventoryData,
    addInventoryItemHeader,
    addInventoryItemData,
    personalEmployeeHeaders,
    personalEmployeeData,
    salePurchaseData,
    bankNames,
    benefitCards,
    information,
    document,
    employmentDetails,
    tasks
}