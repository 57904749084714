/* eslint-disable react-hooks/rules-of-hooks */
import { useTheme } from "@react-navigation/native";
import countryList from "country-list";
import { BlurView } from "expo-blur";
import { httpsCallable } from "firebase/functions";
import React, { useContext, useEffect, useState } from "react";
import {
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";
import { GooglePlacesAutocomplete } from "react-native-google-places-autocomplete";
import * as RNLocalize from "react-native-localize";
import {
  ActivityIndicator,
  Checkbox,
  Divider,
  Icon,
  Text,
  TextInput,
} from "react-native-paper";
import { Paystack } from "react-native-paystack-webview";
import { PaystackButton } from "react-paystack";

import { GlobalContext } from "../../../GlobalContext/GlobalContext";
import { useFunctions } from "../../../GlobalContext/useUserContext";
import { CustomColors } from "../../../api/customColors";
import { inventoryTableInformation } from "../../../api/inventoryApi";
import Input from "../../../component/Common/Input/Input";
import CustomButton from "../../../component/Common/button";
import CustomDropdown from "../../../component/CustomDropdown/CustomDropdown";
import { currencyFormatter } from "../../../component/util/constants";
import { shadowSet } from "../../../component/util/shadow";
import { functions } from "../../../firebase/firebaseConfig";
import "./saleswebButton.css";
import { set } from "react-hook-form";

export enum ShippingMethod {
  // eslint-disable-next-line no-unused-vars
  default = "",

  // eslint-disable-next-line no-unused-vars
  PICKUP = "PICKUP",
  // eslint-disable-next-line no-unused-vars
  DELIVER = "DELIVER",
  // eslint-disable-next-line no-unused-vars
  SHIP = "SHIP",
}

const SalesItem = (props: any) => {
  const currency = RNLocalize.getCurrencies()[0];
  const currencyCode = currency;
  const currencySymbol = currencyCode;
  const { colors }: { colors: CustomColors } = useTheme();
  const { business, user } = useContext(GlobalContext);
  const [currentStep, setCurrentStep] = useState(1);
  const [value, setValue] = useState(null);
  const [selectedValue, setSelectedValue] = useState(" ");
  const [inventoryData, setInventoryData] = useState<
    inventoryTableInformation[]
  >([]);
  const [inventoryDataDropdown, setInventoryDataDropdown] = useState<any[]>([]);
  const [overallOrderTotal, setOverallOrderTotal] = useState("0");

  //store list of items in this state and display on far right side of the screen when current step is 2
  const [itemList, setItemList] = useState<any[]>([]);

  // save change to state and when click process payment then save to database
  // initialize all field state
  const shippingDescriptionValues = {
    [ShippingMethod.default]: "",
    [ShippingMethod.PICKUP]: "Pickup Order",
    [ShippingMethod.DELIVER]: "Deliver Order",
    [ShippingMethod.SHIP]: "Ship Order",
  };
  const [customerInfo, setCustomerInfo] = useState({
    businessName: "",
    phoneNumber: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode: "",
    emailAddress: "",
  });
  const [shippingValue, setShippingValue] = useState<ShippingMethod>(
    ShippingMethod.default
  );
  const [sameAddress, setSameAddress] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  // initialize all field state and date value to the current date and time of the user
  const [orderInfo, setOrderInfo] = useState({
    productName: "",
    saleOrderNumber: "",
    orderDate: new Date().toLocaleDateString(),
    orderStatus: "",
    unitPrice: "0",
    subtotalForItem: "",
    totalAmount: "0",
    skuOrItemCode: "",
    orderedQuantity: "0",
    notesAndComment: "",
    orderShippingType: "",
    orderDeliveryDate: new Date().toLocaleDateString(),
  });

  const [isCash, setIsCash] = useState<boolean>(false);
  const [isPaymentRequest, setIsPaymentRequest] = useState<boolean>(false);
  const [isPaymentOnline, setIsPaymentOnline] = useState<boolean>(false);
  const [orderError, setOrderError] = useState(false);
  const [deliveryError, setDeliveryError] = useState(false);
  const [customerError, setCustomerError] = useState(false);

  const [paymentInfo, setPaymentInfo] = useState({
    contactType: "",
    fullName: "",
    countryOrRegion: "",
    sameAsShippingAddress: false,
    addressLine1: "",
    addressLine2: "",
    emailAddress: "",
    city: "",
    state: "",
    zipCode: "",
    email: "",
    cardNumber: "",
    expiration: "",
    cvc: "",
    deliveryAmount: "0",
  });

  const [cl] = useState(
    // sort the country list alphabetically
    countryList
      .getNames()
      .map((item: string) => ({
        label: item,
        value: item,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  );

  const { orderDate, totalAmount, skuOrItemCode, orderedQuantity } = orderInfo;

  const fetchInventoryData = async () => {
    // grab user info from firebase function called fetchUser
    const { id } = business;
    const getInventoryData = useFunctions("fetchInventories");
    await getInventoryData({ businessId: id })
      .then((result: any) => {
        // Read result of the Cloud Function.
        /** @type {any} */

        
        if (result.data) {
          setInventoryData(result.data);
        // build a react native dropdown list we avoid adding duplicates
        const data: any[] = [];
        result.data.forEach((item: inventoryTableInformation) => {
          // check if the item name is already in the label key
          if (!data.some((i) => i.label === item.itemName)) {
            data.push({
              label: item.itemName,
              value: item.id,
            });
          }
        });
        setInventoryDataDropdown(data);
      }else{
        setInventoryData([]);
        setInventoryDataDropdown([]);
        setIsLoading(false);
      }
      })
      .catch((error) => {
        // Getting the Error details.
        console.error("Error retrieving inventory data:", error);
      });
  };

  const config = {
    email: customerInfo.emailAddress,
    amount:
      (parseInt(overallOrderTotal, 10) +
        parseInt(paymentInfo.deliveryAmount, 10)) *
      100,
    publicKey: "pk_test_082fb69484f4b7e4512b1df41ad24c87a731672c",
    subaccount: business.subaccountCode,
    currency:
      process.env.NODE_ENV === "test" || process.env.NODE_ENV === "development"
        ? "GHS"
        : currencyCode,
  };

  const handleSaveSale = async (reference: any) => {
    // call firebase function to save data to database

    const payload: any = {
      saleOrderDate: orderInfo.orderDate,
      saleOrderStatus: reference.status.toUpperCase() || "CREATED",
      saleTotalAmount:
        parseInt(orderInfo.totalAmount, 10) +
        parseInt(paymentInfo.deliveryAmount, 10),
      salesNotes: orderInfo.notesAndComment,
      customerName: customerInfo.businessName,
      customerPhone: customerInfo.phoneNumber,
      saleCustomerAddressLine1: customerInfo.addressLine1,
      saleCustomerAddressLine2: customerInfo.addressLine2,
      saleCustomerCity: customerInfo.city,
      saleCustomerState: customerInfo.state,
      saleCustomerZipCode: customerInfo.zipCode,
      saleCustomerCountry: selectedValue,
      saleCustomerAddressLine3: "",
      customerEmail: customerInfo.emailAddress,
      // map itemlist to saleProductInformation
      saleProductInformation: itemList.map((item) => ({
        saleProduct: item.productName,
        saleProductPrice: item.unitPrice,
        saleProductSKU: item.skuOrItemCode,
        saleProductQuantity: item.orderedQuantity,
        saleProductTotal: item.totalAmount,
        saleProductNotes: item.notesAndComment,
      })),
      saleShippingMethod: shippingDescriptionValues[shippingValue],
      saleShippingAmount: paymentInfo.deliveryAmount,
      saleShippingAddressLine1: paymentInfo.addressLine1,
      saleShippingAddressLine2: paymentInfo.addressLine2,
      saleShippingCity: paymentInfo.city,
      saleShippingState: paymentInfo.state,
      saleShippingZipCode: paymentInfo.zipCode,
      saleShippingCountry: selectedValue,
      saleShippingAddressLine3: "",
      saleShippingEmail: paymentInfo.emailAddress,
      salesRepName: user.displayName,
      salesRepEmail: user.email,
      salesRepPhone: user.phoneNumber,
      paymentStatus: reference.status,
      paymentReference: reference.reference,
      paymentTransactionId: reference.transaction,
      paymentTREXref: reference.trxref,
      paymentAmount:
        parseInt(orderInfo.totalAmount, 10) +
        parseInt(paymentInfo.deliveryAmount, 10),
      paymentCurrency: currencyCode,
      saleType: isCash
        ? "CASH"
        : isPaymentRequest
          ? "PAYMENT_REQUEST"
          : "ONLINE",
    };

    const addSale = httpsCallable(functions, "addSale");
    await addSale({ businessId: business.id, sale: payload })
      .then(() => {
        // Read result of the Cloud Function.
        /** @type {any} */
        setIsLoading(false);
        // transaction save was successful
        props.navigation.navigate("SaleHome");
      })
      .catch((error) => {
        // Getting the Error details.
        setIsLoading(false);
        // eslint-disable-next-line no-console
        console.error("Error adding sale data:", error);
      });
  };

  useEffect(() => {
    fetchInventoryData();
  }, []);

  const shippingMethods = [
    { label: "Pickup Order", value: "PICKUP" },
    { label: "Deliver Order", value: "DELIVER" },
    { label: "Ship Order", value: "SHIP" },
  ];

  const handleCheckboxChange = () => {
    setSameAddress(!sameAddress);
    if (!sameAddress) {
      setPaymentInfo((prev) => ({
        ...prev,
        fullName: customerInfo.businessName,
        addressLine1: customerInfo.addressLine1,
        addressLine2: customerInfo.addressLine2,
        city: customerInfo.city,
        state: customerInfo.state,
        zipCode: customerInfo.zipCode,
        emailAddress: customerInfo.emailAddress,
      }));
    }
  };

  // wait for user to be done typing then save to state
  const debounce = (func: any, wait: number, immediate: boolean) => {
    let timeout: any;
    return function (this: any) {
      const context = this,
        // eslint-disable-next-line prefer-rest-params
        args = arguments;
      const later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      // @ts-ignore
      if (callNow) func.apply(context, args);
    };
  };

  const gotoNextStep = () => {
    if (currentStep < 3) {
      const stepCount = currentStep + 1;
      setCurrentStep(stepCount);
      if (stepCount === 3) {
        // we are on the second step so we need to add the item to the list
        // add all order totals in itemlist to get the overall total before adding delivery cost
        const orderTotal = itemList.reduce(
          (acc, item) => acc + parseInt(item.totalAmount, 10),
          0
        );

        const orderedQuantity = itemList.reduce(
          (acc, item) => acc + parseInt(item.orderedQuantity, 10),
          0
        );

        setOrderInfo((prev) => ({
          ...prev,
          orderedQuantity: orderedQuantity.toString(),
        }));

        setOverallOrderTotal(orderTotal);
      }
    }
  };

  const buttonToDisplay = () => {
    if (isCash || isPaymentRequest) {
      return (
        <CustomButton
          onPress={() => {
            setIsLoading(true);
            handleSaveSale({ status: "success" });
          }}
          viewStyle={{
            paddingVertical: 15,
            paddingHorizontal: 20,
            backgroundColor: colors.primary,
            borderRadius: 10,
          }}
          textStyle={{ color: "black", fontSize: 18 }}
          value="Process Payment"
        />
      );
    } else if (isPaymentOnline) {
      if (Platform.OS === "web") {
        return (
          <PaystackButton className="paystack-button" {...componentProps} />
        );
      } else {
        return (
          <Paystack
            paystackKey="pk_test_082fb69484f4b7e4512b1df41ad24c87a731672c"
            amount={
              parseInt(orderInfo.totalAmount, 10) +
              parseInt(paymentInfo.deliveryAmount, 10)
            }
            billingEmail={customerInfo.emailAddress}
            activityIndicatorColor={colors.primary}
            onCancel={() => {
              // handle response here
              handlePaystackCloseAction();
            }}
            onSuccess={(res) => {
              // handle response here
              handlePaystackSuccessAction(res);
            }}
            autoStart
          />
        );
      }
    }
  };

  const goPreviousStep = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  const handelCancel = () => {
    props.navigation.navigate("SaleHome");
  };

  const stepText = (stepNumber: number) => {
    if (stepNumber === 1) return "Customer Information";
    else if (stepNumber === 2) return "Order Information";
    else if (stepNumber === 3) return "Payment Process";
  };

  const StepComponent = ({
    title,
    icon,
    size,
    stepNumber,
  }: {
    title: string;
    icon: string;
    size: number;
    stepNumber: number;
  }) => {
    return (
      <View
        style={{
          flexDirection: "row",
          gap: 10,
          padding: 10,
          alignItems: "center",
          overflow: "hidden",
          borderRadius: 10,
          backgroundColor:
            stepNumber === currentStep ? colors.card : "transparent",
          shadowColor: stepNumber === currentStep ? "#000000" : "#000000",
          shadowOffset: {
            width: 0,
            height: stepNumber === currentStep ? 2 : 0,
          },
          shadowOpacity: stepNumber === currentStep ? 0.25 : 0,
          shadowRadius: stepNumber === currentStep ? 3.84 : 0,
          elevation: stepNumber === currentStep ? 5 : 0,
        }}
      >
        <Icon
          source={icon}
          size={size}
          color={stepNumber === currentStep ? colors.primary : colors.subText}
        />
        <View>
          <Text
            variant="bodyLarge"
            style={{ color: colors.subText, opacity: 0.8 }}
          >
            {title}
          </Text>
          <Text
            variant="bodyMedium"
            style={{
              color: stepNumber === currentStep ? colors.primary : colors.text,
              fontWeight: stepNumber === currentStep ? "bold" : "normal",
              marginTop: 5,
            }}
          >
            {stepText(stepNumber)}
          </Text>
        </View>
      </View>
    );
  };

  const [errorOrderQauntity, setErrorOrderQauntity] = useState(false);
  const [qaumtityErrorText, setQaumtityErrorText] = useState("");
  const handleOrderQauntityCheck = (value: string) => {
    const item = inventoryData.find(
      (item: inventoryTableInformation) => item.id === selectedValue
    ) as inventoryTableInformation;

    // subtract selected qautity from the current item qauntity if the remainder is negative then set the order qauntity error to true.
    // also set next button to disabled

    const { itemQuantity } = item;
    const remainder = itemQuantity - parseInt(value, 10);

    if (remainder < 0) {
      setErrorOrderQauntity(true);
      setOrderError(true);
      setQaumtityErrorText(
        `You can only order ${itemQuantity} of this item. You have ${remainder} left`
      );
    } else {
      setErrorOrderQauntity(false);
      setOrderError(false);
      handleOrderTotalCalculation(value);
    }
  };
  const handleOrderTotalCalculation = (value: string) => {
    const item = inventoryData.find(
      (item: inventoryTableInformation) => item.id === selectedValue
    ) as inventoryTableInformation;

    const t = value === "" || value === "0" ? "1" : value;

    const formattedTotalAmount =
      value === "" || value === "0"
        ? parseFloat("0").toFixed(2)
        : parseFloat(String(item.itemPrice * parseInt(t, 10))).toFixed(2);

    setOrderInfo((prev) => ({
      ...prev,
      totalAmount: formattedTotalAmount || "0",
    }));
  };

  const handleDeliveryAmountCalculation = (value: string) => {
    const val = value;

    setPaymentInfo((prev) => ({
      ...prev,
      deliveryAmount: val,
    }));
  };

  const handleCustomOrderTotalCalculation = (value: string) => {
    setOrderInfo((prev) => ({
      ...prev,
      totalAmount: value,
    }));
  };

  const handleOverallTotal = () => {
    const orderTotal = parseInt(overallOrderTotal, 10) || 0;
    const deliveryTotal = parseInt(paymentInfo.deliveryAmount, 10) || 0;
    const total = orderTotal + deliveryTotal;
    return currencyFormatter(total);
  };

  const handlePaystackSuccessAction = (reference: any) => {
    // Implementation for whatever you want to do with reference and after success call.
    if (reference.status === "success") {
      // load the screen now
      setIsLoading(true);
      handleSaveSale(reference);
    }
  };

  // you can call this function anything
  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    // eslint-disable-next-line no-console
    console.log("closed");
  };

  const componentProps = {
    ...config,
    text: "Process Payment",
    onSuccess: (reference: any) => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  const handleITemAdd = () => {
    // an array every time the user clicks the add item button so we add it to the setItemList state
    const itemId = itemList.length + 1;
    const item = {
      id: itemId,
      productName: orderInfo.productName,
      unitPrice: orderInfo.unitPrice,
      skuOrItemCode: orderInfo.skuOrItemCode,
      orderedQuantity: orderInfo.orderedQuantity,
      totalAmount: orderInfo.totalAmount,
      notesAndComment: orderInfo.notesAndComment,
      orderShippingType: shippingDescriptionValues[shippingValue],
    };

    setItemList((prev) => [...prev, item]);

    // reset the order info
    setOrderInfo((prev) => ({
      ...prev,
      productName: "",
      unitPrice: "0",
      skuOrItemCode: "",
      orderedQuantity: "0",
      totalAmount: "0",
      notesAndComment: "",
    }));

    // reset the selected value
    setSelectedValue(" ");
  };

  useEffect(() => {
    if (selectedValue !== " " && selectedValue !== null) {
      //find the item id in the inventoryData array and set order info
      const item = inventoryData.find(
        (item: inventoryTableInformation) => item.id === selectedValue
      ) as inventoryTableInformation;
      const formattedTotalAmount =
        item.itemPrice * parseInt(orderInfo.orderedQuantity, 10);
      const { itemName, itemPrice, id } = item;
      setOrderInfo((prev) => ({
        ...prev,
        productName: itemName,
        unitPrice: itemPrice.toString(),
        skuOrItemCode: id,
        totalAmount: formattedTotalAmount.toString(),
        orderDate: new Date().toLocaleDateString(),
      }));
    }
  }, [selectedValue]);

  return (
    <View style={{ flex: 1, backgroundColor: colors.background }}>
      <View style={{ flex: 1, padding: 10 }}>
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Text
            variant="bodyLarge"
            style={{ color: colors.subText, opacity: 0.8 }}
          >
            Add Sale
          </Text>
          <Text
            variant="titleLarge"
            style={{ color: colors.text, fontWeight: "bold" }}
          >
            {stepText(currentStep)}
          </Text>
        </View>

        <View style={{ flex: 8, flexDirection: "row", gap: 10 }}>
          {/* left side  */}
          <View style={{ flex: 1 }}>
            <StepComponent
              title="Step 1"
              icon="comment-account-outline"
              size={18}
              stepNumber={1}
            />
            <StepComponent
              title="Step 2"
              icon="account-details-outline"
              size={18}
              stepNumber={2}
            />
            <StepComponent
              title="Step 3"
              icon="currency-usd"
              size={18}
              stepNumber={3}
            />
          </View>

          {/* right side */}
          <View style={{ flex: 3, flexDirection: "row" }}>
            <View
              style={{
                overflow: "hidden",
                backgroundColor: "#fff",
                borderRadius: 10,
                padding: 20,
                marginVertical: 10,
                flex: 2,
                flexGrow: 1,
                shadowColor: "#000000",
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.25,
                shadowRadius: 3.84,
                elevation: 5,
              }}
            >
              <ScrollView contentContainerStyle={{ padding: 10 }}>
                {/* customer information */}
                {currentStep === 1 && (
                  <View style={{ flex: 1 }}>
                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text
                          variant="bodyMedium"
                          style={{ color: colors.subText }}
                        >
                          Business Name
                        </Text>
                        <Input
                          value={customerInfo.businessName}
                          label="Business Name"
                          style={{ flex: 1 }}
                          onChangeText={(t: string) => {
                            setCustomerInfo((prev) => ({
                              ...prev,
                              businessName: t,
                            }));
                          }}
                        />
                      </View>
                    </View>
                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >
                      {" "}
                      <View style={{ flex: 1 }}>
                        <Text
                          variant="bodyMedium"
                          style={{ color: colors.subText }}
                        >
                          Phone Number
                        </Text>
                        <Input
                          value={customerInfo.phoneNumber}
                          label="Phone Number"
                          style={{ flex: 1 }}
                          onChangeText={(t: string) => {
                            setCustomerInfo((prev) => ({
                              ...prev,
                              phoneNumber: t,
                            }));
                          }}
                        />
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text
                          variant="bodyMedium"
                          style={{ color: colors.subText }}
                        >
                          Email Address
                        </Text>
                        <Input
                          label="Email Address"
                          style={{ flex: 1 }}
                          value={customerInfo.emailAddress}
                          onChangeText={(t: string) => {
                            setCustomerInfo((prev) => ({
                              ...prev,
                              emailAddress: t,
                            }));
                          }}
                        />
                      </View>
                    </View>
                    {/* 
                    this will be activated in production after api-key or it not responding has been resolved
                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >
                      <View style={{ flex: 1 }}>
                        <GooglePlacesAutocomplete
                          placeholder="Enter Location"
                          onPress={(data, details = null) => {
                            // 'details' is provided when fetchDetails = true
                            console.log(data, details);
                          }}
                          query={{
                            key: "AIzaSyB_Ui6IaPXLXaTpPBMUembw4Y1BLEgyjO8 ",
                            language: "en",
                          }}
                          fetchDetails
                          styles={{
                            textInput: {
                              backgroundColor: "transparent",
                              borderRadius: 10,
                              padding: 10,
                              ...shadowSet,
                            },
                            listView: {
                              backgroundColor: "white",
                              borderRadius: 10,
                              ...shadowSet,
                            },
                            row: {
                              padding: 10,
                              borderBottomWidth: 1,
                              borderBottomColor: colors.border,
                            },
                          }}
                        />
                      </View>
                    </View> */}
                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text
                          variant="bodyMedium"
                          style={{ color: colors.subText }}
                        >
                          Address Line 1
                        </Text>
                        <Input
                          value={customerInfo.addressLine1}
                          label="Address Line 1"
                          style={{ flex: 1 }}
                          onChangeText={(t: string) => {
                            setCustomerInfo((prev) => ({
                              ...prev,
                              addressLine1: t,
                            }));
                          }}
                        />
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text
                          variant="bodyMedium"
                          style={{ color: colors.subText }}
                        >
                          Address Line 2
                        </Text>
                        <Input
                          value={customerInfo.addressLine2}
                          label="Address Line 2"
                          style={{ flex: 1 }}
                          onChangeText={(t: string) => {
                            setCustomerInfo((prev) => ({
                              ...prev,
                              addressLine2: t,
                            }));
                          }}
                        />
                      </View>
                    </View>
                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text
                          variant="bodyMedium"
                          style={{ color: colors.subText }}
                        >
                          City
                        </Text>
                        <Input
                          value={customerInfo.city}
                          label="City"
                          style={{ flex: 1 }}
                          onChangeText={(t: string) => {
                            setCustomerInfo((prev) => ({
                              ...prev,
                              city: t,
                            }));
                          }}
                        />
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text
                          variant="bodyMedium"
                          style={{ color: colors.subText }}
                        >
                          State
                        </Text>
                        <Input
                          value={customerInfo.state}
                          onChangeText={(t: string) => {
                            setCustomerInfo((prev) => ({
                              ...prev,
                              state: t,
                            }));
                          }}
                          label="State"
                          style={{ flex: 1 }}
                        />
                      </View>
                    </View>
                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >
                      <View style={{ flex: 1, zIndex: 99999 }}>
                        <Text
                          variant="bodyMedium"
                          style={{ color: colors.subText }}
                        >
                          Country or region{" "}
                        </Text>
                        <CustomDropdown
                          data={cl}
                          value={value}
                          setValue={setValue}
                          placeholder="Country or region"
                          style={{ marginTop: 10 }}
                        />
                      </View>{" "}
                      <View style={{ flex: 1 }}>
                        <Text
                          variant="bodyMedium"
                          style={{ color: colors.subText }}
                        >
                          Zip Code
                        </Text>
                        <Input
                          label="Zip Code"
                          style={{ flex: 1 }}
                          value={customerInfo.zipCode}
                          onChangeText={(t: string) => {
                            setCustomerInfo((prev) => ({
                              ...prev,
                              zipCode: t,
                            }));
                          }}
                        />
                      </View>
                    </View>
                  </View>
                )}

                {/* order information */}
                {currentStep === 2 && (
                  <View style={{ flex: 1 }}>
                    <View
                      style={{
                        flexDirection: "row",
                        gap: 10,
                        marginTop: 10,
                        zIndex: 999,
                      }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text
                          variant="bodyMedium"
                          style={{ color: colors.subText }}
                        >
                          Product Name or Description
                        </Text>
                        <CustomDropdown
                          placeholder="Select a Product"
                          data={inventoryDataDropdown}
                          setValue={setSelectedValue}
                          value={selectedValue}
                          direction="BOTTOM"
                          zIndex={999}
                          maxHeight={150}
                        />
                      </View>
                    </View>

                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text
                          variant="bodyMedium"
                          style={{ color: colors.subText }}
                        >
                          Order Date
                        </Text>
                        {/* 
                        we will be initializing the date to the current date locale
                        */}
                        <Input
                          value={orderDate}
                          label="Order Date"
                          style={{ flex: 1 }}
                          disabled
                        />
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text
                          variant="bodyMedium"
                          style={{ color: colors.subText }}
                        >
                          Unit Price
                        </Text>
                        <Input
                          left={<TextInput.Affix text={currencySymbol} />}
                          value={orderInfo.unitPrice}
                          label="Unit Price"
                          style={{ flex: 1 }}
                        />
                      </View>
                    </View>

                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text
                          variant="bodyMedium"
                          style={{ color: colors.subText }}
                        >
                          Ordered Quantity
                        </Text>
                        <Input
                          value={orderedQuantity}
                          label="Qauntity"
                          style={{ flex: 1 }}
                          onChangeText={(t: string) => {
                            handleOrderQauntityCheck(t);
                            setOrderInfo((prev) => ({
                              ...prev,
                              orderedQuantity: t,
                            }));
                          }}
                          error={errorOrderQauntity}
                          errorText={qaumtityErrorText}
                        />
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text
                          variant="bodyMedium"
                          style={{ color: colors.subText }}
                        >
                          Total Amount
                        </Text>
                        <Input
                          left={<TextInput.Affix text={currencySymbol} />}
                          value={totalAmount}
                          label="Total Amount"
                          style={{ flex: 1 }}
                          onChangeText={(t: string) => {
                            handleCustomOrderTotalCalculation(t);
                          }}
                        />
                      </View>
                    </View>

                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text
                          variant="bodyMedium"
                          style={{ color: colors.subText }}
                        >
                          SKU or item code
                        </Text>
                        <Input
                          value={skuOrItemCode}
                          label="SKU or item code"
                          style={{ flex: 1 }}
                          onChangeText={(t: string) => {
                            setOrderInfo((prev) => ({
                              ...prev,
                              skuOrItemCode: t,
                            }));
                          }}
                        />
                      </View>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        gap: 10,
                        marginTop: 10,
                        zIndex: 999,
                      }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text
                          variant="bodyMedium"
                          style={{ color: colors.subText }}
                        >
                          Order Shipping Method
                        </Text>
                        <CustomDropdown
                          placeholder="Select a Shipping Method"
                          data={shippingMethods}
                          setValue={setShippingValue}
                          value={shippingValue}
                          direction="BOTTOM"
                          zIndex={999}
                          maxHeight={150}
                        />
                      </View>
                    </View>

                    <View style={{ flex: 1, marginTop: 10 }}>
                      <Text
                        variant="bodyMedium"
                        style={{ color: colors.subText }}
                      >
                        Notes & Comment
                      </Text>
                      <Input
                        label="Notes & Comment"
                        style={{ flex: 1, height: 200 }}
                        multiline
                        onChangeText={(t: string) => {
                          setOrderInfo((prev) => ({
                            ...prev,
                            notesAndComment: t,
                          }));
                        }}
                        value={orderInfo.notesAndComment}
                      />
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        gap: 10,
                        backgroundColor: colors.card,
                        justifyContent: "center",
                        padding: 20,
                      }}
                    >
                      <CustomButton
                        onPress={handleITemAdd}
                        viewStyle={{
                          paddingVertical: 15,
                          paddingHorizontal: 20,
                          backgroundColor: colors.primary,
                          borderRadius: 10,
                        }}
                        textStyle={{ color: "black", fontSize: 18 }}
                        value="Add Item"
                        disabled={errorOrderQauntity}
                      />
                    </View>
                  </View>
                )}

                {currentStep === 3 && (
                  <View style={{ flex: 3 }}>
                    <ScrollView style={{ flex: 3 }}>
                      <View
                        style={{ flexDirection: "row", padding: 10, gap: 10 }}
                      >
                        {/* Left side */}
                        <View style={{ flex: 1 }}>
                          <View>
                            <Text variant="bodyMedium">
                              <Checkbox
                                status={sameAddress ? "checked" : "unchecked"}
                                onPress={handleCheckboxChange}
                                testID="sameAddress"
                              />
                              Same as Customer Information
                            </Text>
                          </View>

                          <View style={{ zIndex: 99999, marginTop: 10 }}>
                            <View>
                              <Text
                                variant="bodyMedium"
                                style={{ color: colors.subText, marginTop: 5 }}
                              >
                                Recievers Full Name
                              </Text>
                              <Input
                                value={paymentInfo.fullName}
                                label="First and last name"
                                style={{ flex: 1 }}
                                onChangeText={(t: string) => {
                                  setPaymentInfo((prev) => ({
                                    ...prev,
                                    fullName: t,
                                  }));
                                }}
                              />
                            </View>
                            <View style={{ zIndex: 99999 }}>
                              <Text
                                variant="bodyMedium"
                                style={{ color: colors.subText, marginTop: 5 }}
                              >
                                Country or region
                              </Text>
                              <CustomDropdown
                                data={cl}
                                value={value}
                                setValue={setValue}
                                placeholder="Country or region"
                                style={{ marginTop: 10 }}
                              />
                            </View>

                            <View
                              style={{
                                flexDirection: "row",
                                gap: 10,
                                marginTop: 10,
                              }}
                            >
                              <View style={{ flex: 1 }}>
                                <Text
                                  variant="bodyMedium"
                                  style={{ color: colors.subText }}
                                >
                                  Address Line 1
                                </Text>
                                <Input
                                  value={paymentInfo.addressLine1}
                                  label="Address Line 1"
                                  style={{ flex: 1 }}
                                  onChangeText={(t: string) => {
                                    setPaymentInfo((prev) => ({
                                      ...prev,
                                      addressLine1: t,
                                    }));
                                  }}
                                />
                              </View>
                              <View style={{ flex: 1 }}>
                                <Text
                                  variant="bodyMedium"
                                  style={{ color: colors.subText }}
                                >
                                  Address Line 2
                                </Text>
                                <Input
                                  value={paymentInfo.addressLine2}
                                  label="Address Line 2"
                                  style={{ flex: 1 }}
                                  onChangeText={(t: string) => {
                                    setPaymentInfo((prev) => ({
                                      ...prev,
                                      addressLine2: t,
                                    }));
                                  }}
                                />
                              </View>
                            </View>

                            <View
                              style={{
                                flexDirection: "row",
                                gap: 10,
                                marginTop: 10,
                              }}
                            >
                              <View style={{ flex: 1 }}>
                                <Text
                                  variant="bodyMedium"
                                  style={{ color: colors.subText }}
                                >
                                  City
                                </Text>
                                <Input
                                  value={paymentInfo.city}
                                  label="City"
                                  style={{ flex: 1 }}
                                  onChangeText={(t: string) => {
                                    setPaymentInfo((prev) => ({
                                      ...prev,
                                      city: t,
                                    }));
                                  }}
                                />
                              </View>
                              <View style={{ flex: 1 }}>
                                <Text
                                  variant="bodyMedium"
                                  style={{ color: colors.subText }}
                                >
                                  State
                                </Text>
                                <Input
                                  value={paymentInfo.state}
                                  onChangeText={(t: string) => {
                                    setPaymentInfo((prev) => ({
                                      ...prev,
                                      state: t,
                                    }));
                                  }}
                                  label="State"
                                  style={{ flex: 1 }}
                                />
                              </View>
                            </View>

                            <View
                              style={{
                                flexDirection: "row",
                                gap: 10,
                                marginTop: 10,
                              }}
                            >
                              <View style={{ flex: 1 }}>
                                <Text
                                  variant="bodyMedium"
                                  style={{ color: colors.subText }}
                                >
                                  Zip Code
                                </Text>
                                <Input
                                  label="Zip Code"
                                  style={{ flex: 1 }}
                                  value={paymentInfo.zipCode}
                                  onChangeText={(t: string) => {
                                    setPaymentInfo((prev) => ({
                                      ...prev,
                                      zipCode: t,
                                    }));
                                  }}
                                />
                              </View>
                              <View style={{ flex: 1 }}>
                                <Text
                                  variant="bodyMedium"
                                  style={{ color: colors.subText }}
                                >
                                  Email Address
                                </Text>
                                <Input
                                  label="Email Address"
                                  style={{ flex: 1 }}
                                  value={paymentInfo.emailAddress}
                                  onChangeText={(t: string) => {
                                    setPaymentInfo((prev) => ({
                                      ...prev,
                                      emailAddress: t,
                                    }));
                                  }}
                                />
                              </View>
                            </View>
                            <View
                              style={{
                                flexDirection: "row",
                                gap: 10,
                                marginTop: 10,
                              }}
                            >
                              <View style={{ flex: 1 }}>
                                <Text
                                  variant="bodyMedium"
                                  style={{ color: colors.subText }}
                                >
                                  Delivery Amount
                                </Text>
                                <Input
                                  left={
                                    <TextInput.Affix text={currencySymbol} />
                                  }
                                  value={paymentInfo.deliveryAmount}
                                  label="Delivery Amount"
                                  style={{ flex: 1 }}
                                  onChangeText={debounce(
                                    (t: string) => {
                                      handleDeliveryAmountCalculation(t);
                                    },
                                    100,
                                    false
                                  )}
                                />
                              </View>
                            </View>
                            <View
                              style={{
                                flexDirection: "row",
                                gap: 20,
                                marginTop: 10,
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  gap: 10,
                                  alignItems: "center",
                                }}
                              >
                                <Checkbox
                                  status={isCash ? "checked" : "unchecked"}
                                  uncheckedColor={colors.border}
                                  color={colors.primary}
                                  onPress={() => setIsCash(!isCash)}
                                />
                                <Text style={{ color: colors.subText }}>
                                  Cash
                                </Text>
                              </View>

                              <View
                                style={{
                                  flexDirection: "row",
                                  gap: 10,
                                  alignItems: "center",
                                }}
                              >
                                <Checkbox
                                  status={
                                    isPaymentRequest ? "checked" : "unchecked"
                                  }
                                  uncheckedColor={colors.border}
                                  color={colors.primary}
                                  onPress={() =>
                                    setIsPaymentRequest(!isPaymentRequest)
                                  }
                                />
                                <Text style={{ color: colors.subText }}>
                                  Request Payment
                                </Text>
                              </View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  gap: 10,
                                  alignItems: "center",
                                }}
                              >
                                <Checkbox
                                  status={
                                    isPaymentOnline ? "checked" : "unchecked"
                                  }
                                  uncheckedColor={colors.border}
                                  color={colors.primary}
                                  onPress={() =>
                                    setIsPaymentOnline(!isPaymentOnline)
                                  }
                                />
                                <Text style={{ color: colors.subText }}>
                                  Payment Online
                                </Text>
                              </View>
                            </View>
                          </View>
                        </View>

                        {/* Right side */}
                        <View style={{ flex: 1 }}>
                          <View
                            style={{
                              margin: 20,
                              padding: 20,
                              borderRadius: 10,
                              ...shadowSet,
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: 10,
                              }}
                            >
                              <Text
                                variant="bodyMedium"
                                style={{ color: colors.text }}
                              >
                                Subtotal ( {orderInfo.orderedQuantity} item )
                              </Text>
                              <Text
                                variant="bodyMedium"
                                style={{ color: colors.text }}
                              >
                                {currencyFormatter(
                                  parseInt(overallOrderTotal, 10)
                                )}
                              </Text>
                            </View>
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: 10,
                              }}
                            >
                              <Text
                                variant="bodyMedium"
                                style={{ color: colors.text }}
                              >
                                Taxes
                              </Text>
                              <Text
                                variant="bodyMedium"
                                style={{
                                  fontStyle: "italic",
                                  color: colors.text,
                                }}
                              >
                                Calculating Taxes...
                              </Text>
                            </View>
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: 10,
                              }}
                            >
                              <Text
                                variant="bodyMedium"
                                style={{ color: colors.text }}
                              >
                                {shippingDescriptionValues[shippingValue]}
                              </Text>
                              <Text
                                variant="bodyMedium"
                                style={{ color: colors.text }}
                              >
                                {shippingDescriptionValues[shippingValue] !== ""
                                  ? [
                                      ShippingMethod.DELIVER,
                                      ShippingMethod.SHIP,
                                    ].includes(shippingValue)
                                    ? currencyFormatter(
                                        parseInt(
                                          paymentInfo.deliveryAmount || "0",
                                          10
                                        )
                                      )
                                    : "Free"
                                  : null}
                              </Text>
                            </View>

                            <Divider
                              style={{
                                backgroundColor: colors.border,
                                marginVertical: 20,
                              }}
                            />

                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginBottom: 20,
                              }}
                            >
                              <Text
                                variant="bodyMedium"
                                style={{
                                  color: colors.text,
                                  fontWeight: "bold",
                                }}
                              >
                                Total
                              </Text>
                              <Text
                                variant="bodyMedium"
                                style={{
                                  color: colors.text,
                                  fontWeight: "bold",
                                }}
                              >
                                {handleOverallTotal()}
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                    </ScrollView>
                  </View>
                )}
              </ScrollView>
            </View>

            {/* item list */}

            {currentStep === 2 && (
              <View
                style={{
                  overflow: "hidden",
                  backgroundColor: "#fff",
                  borderRadius: 10,
                  padding: 20,
                  margin: 10,
                  flex: 1,
                  flexGrow: 1,
                  shadowColor: "#000000",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.25,
                  shadowRadius: 3.84,
                  elevation: 5,
                }}
              >
                <ScrollView contentContainerStyle={{ padding: 10 }}>
                  {itemList.map((item, index) => (
                    <View
                      key={item.id}
                      style={{
                        flex: 1,
                        flexDirection: "column",
                        margin: 20,
                        padding: 20,
                        borderRadius: 10,
                        ...shadowSet,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          marginRight: 10,
                        }}
                      >
                        <Pressable
                          onPress={() => {
                            const filtered =
                              itemList.length > 1
                                ? itemList.filter((item) => item.id !== index)
                                : [];
                            setItemList(filtered);
                          }}
                        >
                          {/* click to remove item from list */}
                          <Icon source="delete-outline" size={20} />
                        </Pressable>
                      </View>
                      <View>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginTop: 10,
                          }}
                        >
                          <Text
                            variant="bodyMedium"
                            style={{ color: colors.text }}
                          >
                            Item Name
                          </Text>
                          <Text
                            variant="bodyMedium"
                            style={{ color: colors.text }}
                          >
                            {item.productName}
                          </Text>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginTop: 10,
                          }}
                        >
                          <Text
                            variant="bodyMedium"
                            style={{ color: colors.text }}
                          >
                            Item Qauntity
                          </Text>
                          <Text
                            variant="bodyMedium"
                            style={{
                              fontStyle: "italic",
                              color: colors.text,
                            }}
                          >
                            {item.orderedQuantity}
                          </Text>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginBottom: 20,
                          }}
                        >
                          <Text
                            variant="bodyMedium"
                            style={{
                              color: colors.text,
                              fontWeight: "bold",
                            }}
                          >
                            Unit Price
                          </Text>
                          <Text
                            variant="bodyMedium"
                            style={{
                              color: colors.text,
                            }}
                          >
                            {currencyFormatter(parseInt(item.unitPrice, 10))}
                          </Text>
                        </View>

                        <Divider
                          style={{
                            backgroundColor: colors.border,
                            marginVertical: 20,
                          }}
                        />

                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginBottom: 20,
                          }}
                        >
                          <Text
                            variant="bodyMedium"
                            style={{
                              color: colors.text,
                              fontWeight: "bold",
                            }}
                          >
                            Total
                          </Text>
                          <Text
                            variant="bodyMedium"
                            style={{
                              color: colors.text,
                              fontWeight: "bold",
                            }}
                          >
                            {currencyFormatter(parseInt(item.totalAmount, 10))}
                          </Text>
                        </View>
                      </View>
                    </View>
                  ))}
                </ScrollView>
              </View>
            )}
          </View>
        </View>
      </View>

      <View
        style={{
          flexDirection: "row",
          gap: 10,
          backgroundColor: colors.card,
          justifyContent: "flex-end",
          padding: 20,
        }}
      >
        <CustomButton
          value="Cancel"
          onPress={handelCancel}
          viewStyle={{
            paddingVertical: 15,
            paddingHorizontal: 20,
            backgroundColor: "transparent",
            borderWidth: 1,
            borderColor: colors.primary,
            borderRadius: 10,
          }}
          textStyle={{ color: colors.primary, fontSize: 18 }}
        />

        <CustomButton
          value="Save as Draft"
          onPress={() => {}}
          viewStyle={{
            paddingVertical: 15,
            paddingHorizontal: 20,
            backgroundColor: "transparent",
            borderWidth: 1,
            borderColor: colors.primary,
            borderRadius: 10,
          }}
          textStyle={{ color: colors.primary, fontSize: 18 }}
        />

        <Divider
          style={{ height: "100%", width: 1, backgroundColor: colors.border }}
        />

        <CustomButton
          value="Previous"
          onPress={goPreviousStep}
          viewStyle={{
            paddingVertical: 15,
            paddingHorizontal: 20,
            backgroundColor: "transparent",
            borderWidth: 1,
            borderColor: colors.primary,
            borderRadius: 10,
          }}
          textStyle={{ color: colors.primary, fontSize: 18 }}
        />
        {currentStep === 3 ? (
          buttonToDisplay()
        ) : (
          <CustomButton
            value="Next"
            onPress={gotoNextStep}
            viewStyle={{
              paddingVertical: 15,
              paddingHorizontal: 20,
              backgroundColor: colors.primary,
              borderRadius: 10,
            }}
            textStyle={{ color: "black", fontSize: 18 }}
            disabled={orderError || customerError || deliveryError}
          />
        )}
      </View>

      {isLoading && (
        <BlurView intensity={100} style={styles.blurView}>
          <ActivityIndicator size="large" color="white" />
        </BlurView>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  blurView: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default SalesItem;
