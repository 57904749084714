import { StyleSheet, View } from 'react-native'
import React from 'react'
import CustomDropdown from '../../CustomDropdown/CustomDropdown'
import { CurrencyList } from './currencyList'
import { Text } from 'react-native-paper'
import Input from '../../Common/Input/Input'
import { useTheme } from '@react-navigation/native'
import { CustomColors } from '../../../api/customColors'

let LanguageRegionSetting = ({ editMode, setSelectedCountry, selectedCountry, setSelectedCurrency, selectedCurrency }: {
  editMode: boolean;
  setSelectedCountry: any;
  selectedCountry: any;
  setSelectedCurrency: any;
  selectedCurrency: any;

}) => {

  const { colors }: { colors: CustomColors } = useTheme();

  let languageList = [
    { value: 'English', label: 'English' },
    { value: 'Spanish', label: 'Spanish' },
    { value: 'French', label: 'French' },
    { value: 'German', label: 'German' },
    { value: 'Italian', label: 'Italian' },
    { value: 'Portuguese', label: 'Portuguese' },
    { value: 'Russian', label: 'Russian' },
    { value: 'Chinese', label: 'Chinese' },
    { value: 'Japanese', label: 'Japanese' },
    { value: 'Korean', label: 'Korean' },
  ]

  return (
    <View style={{ zIndex: 999 }}>

      <Text variant='bodyLarge' style={{ color:colors.text, fontWeight: 'bold' }}>Select app language</Text>

      <View style={{ width: '50%', zIndex: 999 }}>
        <CustomDropdown
          disabled={!editMode}
          placeholder='Select app language'
          style={{ marginVertical: 10 }}
          data={languageList}
          setValue={setSelectedCountry}
          value={selectedCountry}
          direction='BOTTOM'
          zIndex={999}
          maxHeight={150}
        />
      </View>

      <Text variant='bodyLarge' style={{color:colors.text, fontWeight: 'bold' }}>Select app language</Text>

      <View style={{ width: '50%', zIndex: 998 }}>
        <CustomDropdown
          disabled={!editMode}
          placeholder='Select currency'
          data={CurrencyList}
          style={{ marginTop: 10 }}
          setValue={setSelectedCurrency}
          value={selectedCurrency}
          direction='BOTTOM'
          maxHeight={130}
        />
      </View>

      <Input disabled={!editMode} label='Date Format' />

    </View>
  )
}

export default LanguageRegionSetting

const styles = StyleSheet.create({})