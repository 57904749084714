import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "@firebase/auth";
import { useTheme } from "@react-navigation/native";
import { BlurView } from "expo-blur";
import React, { useEffect, useState } from "react";
import { Controller, set, useForm } from "react-hook-form";
import {
  View,
  Image,
  TouchableOpacity,
  Linking,
  StyleSheet,
  Dimensions,
  Platform,
} from "react-native";
import {
  ActivityIndicator,
  HelperText,
  Text,
  TextInput,
} from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import { Path, Svg } from "react-native-svg";

import { CustomColors } from "../../api/customColors";
import { auth } from "../../firebase/firebaseConfig";
import { login } from "../theme/loginTheme";
import { w } from "../util/dimension";

export const LoginOrSignUpForm = () => {
  const { colors }: { colors: CustomColors } = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false); // State variable to track reset success
  const isDesktop = Platform.OS === "web";
  const [isLoading, setIsLoading] = useState(false);

  // let   = Dimensions.get('screen')
  const hardwares = isDesktop
    ? Dimensions.get("window")
    : Dimensions.get("screen");
  const [w, setW] = useState<number>(hardwares.width);
  const [h, setH] = useState<number>(hardwares.height);

  useEffect(() => {
    Dimensions.addEventListener("change", ({ screen, window }) => {
      setW(screen.width);
      setH(screen.height);
    });
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      emailOrUsername: "",
      password: "",
    },
  });

  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = async (data: Record<string, string>) => {
    setIsLoading(true);
    try {
      const { emailOrUsername, password } = data;
      await signInWithEmailAndPassword(auth, emailOrUsername, password);
    } catch (error: any) {
      const errorMessage = error.message.split("/")[1].replace(/[-)]/g, " ");
      const capitalizedErrorMessage =
        errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
      // split the error message and set the error message to the second part of the error message
      setErrorMessage(capitalizedErrorMessage);
    }
  };

  const handleForgotPassword = async () => {
    const email = watch("emailOrUsername"); // Get the email address from the form
    try {
      await sendPasswordResetEmail(auth, email);
      setResetSuccess(true); // Set reset success to true after form submission
      setForgotPassword(false); // Set forgotPassword to false to show the login form again
      // You can show a success message to the user or navigate to a different screen
    } catch (error) {
      setErrorMessage(`Error sending password reset email:${error}`);
      // You can show an error message to the user or handle the error in any other way
    }
  };

  const handleEmailPress = () => {
    Linking.openURL(`mailto:info@zimmertechnology.org`);
  };

  const c1 = w * 1;
  const c2 = w * 0.9;
  const c3 = w * 0.8;
  const c4 = w * 0.7;
  const c5 = w * 0.6;
  const c6 = w * 0.5;
  const c7 = w * 0.4;
  const c8 = w * 0.3;
  const c9 = w * 0.2;

  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: colors.card,
        justifyContent: "center",
        height: h,
        width: w,
        overflow: "hidden",
      }}
    >
      <View
        style={{
          ...StyleSheet.absoluteFillObject,
          height: h,
          width: w,
          overflow: "hidden",
        }}
      >
        <View
          style={{
            position: "absolute",
            top: -c1 / 3,
            right: -c1 / 3,
            width: c1,
            height: c1,
            borderRadius: 1000,
            backgroundColor: "#e6e6e6",
          }}
        />
        <View
          style={{
            position: "absolute",
            top: -c2 / 3,
            right: -c2 / 3,
            width: c2,
            height: c2,
            borderRadius: 1000,
            backgroundColor: "#d9e0e5",
          }}
        />
        <View
          style={{
            position: "absolute",
            top: -c3 / 3,
            right: -c3 / 3,
            width: c3,
            height: c3,
            borderRadius: 1000,
            backgroundColor: "#cbdbe5",
          }}
        />
        <View
          style={{
            position: "absolute",
            top: -c4 / 3,
            right: -c4 / 3,
            width: c4,
            height: c4,
            borderRadius: 1000,
            backgroundColor: "#b0cfe5",
          }}
        />
        <View
          style={{
            position: "absolute",
            top: -c5 / 3,
            right: -c5 / 3,
            width: c5,
            height: c5,
            borderRadius: 1000,
            backgroundColor: "#a2cae4",
          }}
        />
        <View
          style={{
            position: "absolute",
            top: -c6 / 3,
            right: -c6 / 3,
            width: c6,
            height: c6,
            borderRadius: 1000,
            backgroundColor: "#94c5e4",
          }}
        />
        <View
          style={{
            position: "absolute",
            top: -c7 / 3,
            right: -c7 / 3,
            width: c7,
            height: c7,
            borderRadius: 1000,
            backgroundColor: "#85c0e4",
          }}
        />
        <View
          style={{
            position: "absolute",
            top: -c8 / 3,
            right: -c8 / 3,
            width: c8,
            height: c8,
            borderRadius: 1000,
            backgroundColor: "#7ebde4",
          }}
        />
        <View
          style={{
            position: "absolute",
            top: -c9 / 3,
            right: -c9 / 3,
            width: c9,
            height: c9,
            borderRadius: 1000,
            backgroundColor: "#6eb9e4",
          }}
        />
      </View>

      <View
        style={{
          height: h,
          position: "absolute",
          width: 600,
          justifyContent: "center",
          alignItems: "center",
          left: w * 0.1,
        }}
      >
        <BlurView
          intensity={100}
          tint="default"
          style={{
            padding: 20,
            width: 600,
            overflow: "hidden",
            borderRadius: 20,
            paddingHorizontal: 40,
            justifyContent: "center",
          }}
        >
          <View style={{ justifyContent: "center", alignItems: "center" }}>
            <Image
              source={require("../../assets/zimmerLogistics.png")}
              style={{ height: 200, resizeMode: "contain" }}
            />
          </View>

          <View style={{ paddingTop: 20, paddingHorizontal: 20 }}>
            <Text
              variant="headlineLarge"
              style={{
                color: colors.text,
                fontWeight: "bold",
                marginVertical: 10,
                textAlign: "center", // Added textAlign property
              }}
            >
              Welcome
            </Text>

            <Controller
              control={control}
              rules={{
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  label="Email"
                  onBlur={onBlur}
                  value={value}
                  style={{
                    backgroundColor: colors.background,
                    marginBottom: 15,
                  }}
                  onChangeText={(text) => {
                    onChange(text);
                    setErrorMessage(""); // Reset the error message
                  }}
                  outlineStyle={{ borderRadius: 5 }}
                  mode="outlined"
                />
              )}
              name="emailOrUsername"
            />
            {errors.emailOrUsername &&
              errors.emailOrUsername.type === "required" && (
                <Text style={login.error}>Username is a required field</Text>
              )}
            {errors.emailOrUsername &&
              errors.emailOrUsername.type === "pattern" && (
                <Text style={login.error}>Invalid email address</Text>
              )}

            {!forgotPassword ? (
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    label="Password"
                    secureTextEntry={!showPassword}
                    onBlur={onBlur}
                    style={{ backgroundColor: colors.background }}
                    onChangeText={(text) => {
                      onChange(text);
                      setErrorMessage(""); // Reset the error message
                    }}
                    value={value}
                    outlineStyle={{ borderRadius: 5 }}
                    mode="outlined"
                    right={
                      showPassword ? (
                        <TextInput.Icon
                          icon="eye" // Use eye or eye-slash icon based on showPassword state
                          color={colors.subText}
                          onPress={() => setShowPassword(!showPassword)}
                        />
                      ) : (
                        <TextInput.Icon
                          icon="eye-off" // Use eye or eye-slash icon based on showPassword state
                          color={colors.subText}
                          onPress={() => setShowPassword(!showPassword)}
                        />
                      )
                    }
                  />
                )}
                name="password"
              />
            ) : null}
            <HelperText
              type="error"
              visible={errorMessage !== ""}
              style={{
                ...login.error,
                fontSize: 16,
                fontWeight: "bold",
              }}
            >
              {errorMessage}
            </HelperText>

            {errors.password && (
              <Text style={login.error}>Password is a required field.</Text>
            )}

            {/* success message for password reset email */}
            {resetSuccess && (
              <Text
                variant="bodyLarge"
                style={{
                  color: "#4BB543",
                  textAlign: "center", // Added textAlign property
                  fontStyle: "italic",
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Password reset email sent successfully.
              </Text>
            )}

            <View
              style={{
                alignItems: "flex-end",
                paddingRight: 10,
                paddingTop: 8,
                paddingBottom: 10,
              }}
            >
              <TouchableOpacity onPress={() => setForgotPassword(true)}>
                <Text
                  variant="bodyLarge"
                  style={{
                    fontWeight: "bold",
                    color: "#0b63f8",
                    textDecorationLine: "underline",
                  }}
                >
                  Forget Password ?
                </Text>
              </TouchableOpacity>
            </View>

            <View style={{ flexDirection: "row", gap: 10 }}>
              {forgotPassword ? (
                <>
                  <TouchableOpacity
                    style={styles.signInButton}
                    onPress={() => {
                      handleForgotPassword();
                    }}
                  >
                    <Text
                      variant="bodyLarge"
                      style={{
                        color: colors.subText,
                        fontWeight: "bold",
                        padding: 10,
                      }}
                    >
                      {forgotPassword ? "Reset Password" : "Sign In"}
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styles.signInButton}
                    onPress={() => setForgotPassword(false)}
                  >
                    <Text
                      variant="bodyLarge"
                      style={{
                        color: colors.subText,
                        fontWeight: "bold",
                        padding: 10,
                      }}
                    >
                      Cancel
                    </Text>
                  </TouchableOpacity>
                </>
              ) : (
                <View style={{ ...styles.signInButton, marginLeft: 105 }}>
                  <TouchableOpacity onPress={handleSubmit(onSubmit)}>
                    <Text
                      variant="bodyLarge"
                      style={{
                        color: colors.subText,
                        fontWeight: "bold",
                        padding: 10,
                      }}
                    >
                      Sign In{" "}
                      {isLoading && (
                        <ActivityIndicator size="small" color="white" />
                      )}
                    </Text>
                  </TouchableOpacity>
                </View>
              )}
            </View>

            {errorMessage !== "" && (
              <Text style={login.error}>{errorMessage}</Text>
            )}
          </View>
          <View
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "center",
              paddingTop: 5,
            }}
          >
            <Text variant="bodyLarge" style={{ color: colors.subText }}>
              Don't have an account? Contact{" "}
              <TouchableOpacity onPress={handleEmailPress}>
                <Text
                  variant="bodyLarge"
                  style={{
                    fontWeight: "bold",
                    color: "#0b63f8",
                    textDecorationLine: "underline",
                  }}
                >
                  {" "}
                  ZimmerLogistics{" "}
                </Text>
              </TouchableOpacity>
              or your company admin for login information
            </Text>
          </View>
        </BlurView>
      </View>
      <View
        style={{
          position: "absolute",
          left: w * 0.75,
          top: h * 0.5,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Svg width="42" height="68" viewBox="0 0 42 68" fill="none">
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 0C32.598 0 42 9.40202 42 21C42 31.9234 33.6599 40.8989 23 41.906V64C23 65.1046 22.1046 66 21 66C19.8954 66 19 65.1046 19 64V41.906C8.34014 40.8989 0 31.9234 0 21C0 9.40202 9.40202 0 21 0Z"
            fill={colors.primary}
          />
          <Path
            d="M28 21C28 17.134 24.866 14 21 14C17.134 14 14 17.134 14 21C14 24.866 17.134 28 21 28C24.866 28 28 24.866 28 21Z"
            fill={colors.background}
          />
          <Path
            opacity="0.6"
            d="M21 68C23.7614 68 26 66.8807 26 65.5C26 64.1193 23.7614 63 21 63C18.2386 63 16 64.1193 16 65.5C16 66.8807 18.2386 68 21 68Z"
            fill={colors.primary}
          />
        </Svg>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  logoImg: {
    width: w * 0.1,
    height: w * 0.1,
  },
  input: {
    padding: 20,
    borderWidth: 1,
    borderRadius: 10,
    borderColor: "#9747ff",
    backgroundColor: "#f0efff",
  },
  signInButton: {
    flex: 0.5,
    backgroundColor: "#43b9fe",
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  otherButtonView: {
    flex: 1,
    backgroundColor: "#43b9fe",
    paddingVertical: 10,
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  illustrationImg: {
    height: w / 4,
    width: (w / 4) * 0.9,
    resizeMode: "contain",
  },
});
