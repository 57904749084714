import { useTheme } from "@react-navigation/native";
import { httpsCallable } from "firebase/functions";
import React, { useContext, useEffect, useState } from "react";
import { View, SafeAreaView, Pressable } from "react-native";
import { Button, Text } from "react-native-paper";

import { GlobalContext } from "../../../GlobalContext/GlobalContext";
import { CustomColors } from "../../../api/customColors";
import { SalesInformation } from "../../../api/salesApi";
import Input from "../../../component/Common/Input/Input";
import SalesTable from "../../../component/page/sales/salesTable";
import { cardStyles } from "../../../component/theme/applicationTheme";
import { functions } from "../../../firebase/firebaseConfig";
import { salesInformationData } from "../../../assets/dummyData/dummyData";

const Sales = (props: any) => {
  const { colors }: { colors: CustomColors } = useTheme();

  const [modifiedData, setModifiedData] = useState([] as any);
  const { business } = useContext(GlobalContext);

  const [salesData, setSalesData] = useState([] as any);

  const [successfulSales, setSuccessfulSales] = useState([] as any[]);
  const [pendingSales, setPendingSales] = useState([] as any[]);
  const [newlyCreatedSales, setNewlyCreatedSales] = useState([] as any[]);

  const getData = httpsCallable(functions, "fetchSales");

  // console.log('aaa',getData())

  async function getSalesData() {
    if (business) {
      const { id } = business;
      getData({ businessId: id })
        .then((result: any) => {
          // Read result of the Cloud Function.
          /** @type {any} */
          const { data } = result;
          if (data.length > 0) {
            const salesData: SalesInformation[] = data;

            // construct a new table data that contains id,date,customer name, total amount, paid amount, status
            const constructedData = salesData.map((item) => {
              const {
                id,
                saleCustomerInformation,
                saleOrderStatus,
                saleTotalAmount,
                salePaymentInformation,
              } = item;
              const { customerName } = saleCustomerInformation;
              const time = new Date(item.saleOrderDate).toLocaleDateString();

              return {
                saleOrderId:id,
                saleOrderDate: time,
                customerName,
                saleOrderStatus,
                saleTotalAmount,
                paidAmount: saleTotalAmount,
                paymentMethod: salePaymentInformation?.paymentMethod || "Cash",
              };
            });

            // filter out successful sales status
            const successfulSales = salesData.filter(
              (item) =>
              item.saleOrderStatus === "SUCCESS" || item.saleOrderStatus === "success" || 
                item.saleOrderStatus === "DELIVERED" ||
                item.saleOrderStatus === "COMPLETED" ||
                item.saleOrderStatus === "SHIPPED"
            );

            setSuccessfulSales(successfulSales);

            // filter out pending sales status
            const pendingSales = salesData.filter(
              (item) => item.saleOrderStatus === "PENDING"
            );

            setPendingSales(pendingSales);

            // filter out newly created sales status
            const processingSales = salesData.filter(
              (item) =>
                item.saleOrderStatus === "PROCESSING" ||
                item.saleOrderStatus === "CREATED" ||
                item.saleOrderStatus === " " ||
                item.saleOrderStatus === null
            );

            setNewlyCreatedSales(processingSales);
            setSalesData(constructedData);
            setModifiedData(constructedData);
          }
        })
        .catch((error) => {
          // Getting the Error details.
          console.error("Error retrieving sales data:", error);
        });
    }
  }

  // need to fetch sales data from firebase when the screen is loaded or the navigation is focused
  
  useEffect(() => {
    const unsubscribe = props.navigation.addListener("focus", () => {
      getSalesData();
    });

    return unsubscribe;
  }, [props.navigation]);

  
  useEffect(() => {
    getSalesData();
  }, []);

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.background }}>
      <View style={{ flex: 1, padding: 10 }}>
        <View
          style={{
            flexDirection: "row",
            gap: 10,
            marginHorizontal: 10,
            alignItems: "center",
          }}
        >
          <Text
            variant="titleLarge"
            style={{ flex: 1, fontWeight: "bold", color: colors.text }}
            numberOfLines={1}
          >
            Sales Information
          </Text>

          <Button
            icon="plus"
            mode="outlined"
            onPress={() => props.navigation.navigate("SaleItem")}
            theme={{ colors: { outline: colors.primary } }}
            textColor="black"
            style={{ marginRight: 10 }}
          >
            New Sale
          </Button>
        </View>

        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-evenly",
            gap: 10,
            paddingHorizontal: 10,
          }}
        >
          <View style={{ flex: 1 }}>
            <Pressable
              style={({ pressed }) => ({
                ...cardStyles.card,
                backgroundColor: pressed ? colors.background : colors.card,
                flex: 0,
              })}
            >
              <Text
                variant="bodyMedium"
                style={{ fontWeight: "500", color: colors.text }}
              >
                Total Successful Sales
              </Text>
              <Text
                variant="displaySmall"
                style={{
                  fontWeight: "bold",
                  marginTop: 20,
                  color: colors.success,
                }}
              >
                {successfulSales.length}
              </Text>
            </Pressable>
          </View>
          <View style={{ flex: 1 }}>
            <Pressable
              style={({ pressed }) => ({
                ...cardStyles.card,
                backgroundColor: pressed ? colors.background : colors.card,
                flex: 0,
              })}
            >
              <Text
                variant="bodyMedium"
                style={{ fontWeight: "500", color: colors.text }}
              >
                Pending Sales
              </Text>
              <Text
                variant="displaySmall"
                style={{
                  fontWeight: "bold",
                  marginTop: 20,
                  color: colors.warning,
                }}
              >
                {pendingSales.length}
              </Text>
            </Pressable>
          </View>
          <View style={{ flex: 1 }}>
            <Pressable
              style={({ pressed }) => ({
                ...cardStyles.card,
                backgroundColor: pressed ? colors.background : colors.card,
                flex: 0,
              })}
            >
              <Text
                variant="bodyMedium"
                style={{ fontWeight: "500", color: colors.text }}
              >
                Newly Created Order
              </Text>
              <Text
                variant="displaySmall"
                style={{
                  fontWeight: "bold",
                  color: colors.primary,
                  marginTop: 20,
                }}
              >
                {newlyCreatedSales.length}
              </Text>
            </Pressable>
          </View>
        </View>

        <View
          style={{
            ...cardStyles.paper,
            backgroundColor: colors.card,
          }}
        >
          <Input
            label="Search by invoice id, customer name"
            searchIcon
            onChangeText={(text) => {
              if (text.length > 0) {
                const replaceText = text.trim().toLowerCase();

                const filterData = salesData.filter(
                  (item: any) =>
                    item.customerName
                      .trim()
                      .toLowerCase()
                      .includes(replaceText) ||
                    item.saleOrderId.trim().toLowerCase().includes(replaceText)
                );

                setModifiedData(filterData);
              } else {
                setModifiedData(salesData);
              }
            }}
          />

          <SalesTable
            data={salesData}
            modifiedData={modifiedData}
            setModifiedData={setModifiedData}
          />
        </View>
      </View>
    </SafeAreaView>
  );
};

export default Sales;
