import {
  DrawerActions,
  useNavigation,
  useTheme,
} from "@react-navigation/native";
import React, { useContext, useEffect, useState } from "react";
import {
  View,
  TouchableOpacity,
  Image,
  useWindowDimensions,
  Pressable,
} from "react-native";
import {
  Avatar,
  Icon,
  IconButton,
  Text,
} from "react-native-paper";

import { GlobalContext } from "../../GlobalContext/GlobalContext";
import { cardStyles } from "../theme/applicationTheme";

type hamburgerProps = {
  setShowDialog: any;
  setHamburgerHeight: any;
};

const HeaderComponent = ({
  setShowDialog,
  setHamburgerHeight,
}: hamburgerProps) => {
  const { colors } = useTheme();

  const navigation = useNavigation();

  const dimensions = useWindowDimensions();

  const isLargeScreen = dimensions.width >= 1000;
  const [businessName, setBusinessName] = useState("Reefa Foods"); // Set the business name of the user

  // grab global context for business and set the businessValues
  const { business } = useContext(GlobalContext);


  useEffect(() => {
    if (business) {
      setBusinessName(business.businessName);
    }
  }, [business]);

  return (
    <View
      style={{
        gap: 10,
        paddingTop: 5,
        paddingLeft: 20,
        paddingRight: 20,
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: colors?.card,
        ...cardStyles.elevated,
      }}
      onLayout={(e) => setHamburgerHeight(e.nativeEvent.layout.height)}
    >
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          gap: 10,
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        {!isLargeScreen && (
          <TouchableOpacity
            style={{
              backgroundColor: colors.background,
              height: 50,
              width: 50,
              borderRadius: 10,
              justifyContent: "center",
              alignItems: "center",
            }}
            onPress={() => {
              navigation.dispatch(DrawerActions.openDrawer());
            }}
          >
            <Icon source="view-headline" size={24} color="grey" />
          </TouchableOpacity>
        )}
        <View
          style={{
            justifyContent: "flex-start",
            alignItems: "flex-start",
            width: 50,
          }}
        >
          <Image
            source={require("../../assets/adaptive-icon.png")}
            style={{ resizeMode: "contain", height: 50, width: 50 }}
          />
        </View>

        <View style={{ flex: 1 }}>
          <Text
            variant="titleLarge"
            style={{
              color: colors.text,
              fontWeight: "bold",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
            numberOfLines={1}
          >
            ZimmerLogistics
          </Text>
        </View>
      </View>
      <View style={{ width: null, borderColor: colors.border, borderWidth: 1 }}>
        <Text
          variant="titleLarge"
          style={{
            color: colors.text,
            fontWeight: "bold",
            justifyContent: "center",
            alignItems: "center",
          }}
          numberOfLines={1}
        >
          {businessName}
        </Text>
      </View>

      <View
        style={{
          flex: 1,
          flexDirection: "row",
          gap: 10,
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <IconButton
          icon="bell"
          size={24}
          style={{
            height: 50,
            width: 50,
            borderRadius: 10,
            justifyContent: "center",
            alignItems: "center",
          }}
          iconColor={colors.text}
          onPress={() => {
            // Handle onPress event
          }}
        />

        <Pressable
          style={({ pressed }) => ({
            opacity: pressed ? 0.5 : 1,
            borderRadius: 100,
          })}
          onPress={() => setShowDialog(true)}
        >
          <Avatar.Image
            size={50}
            source={require("../../assets/icons/avatar.png")}
          />
        </Pressable>
      </View>
    </View>
  );
};

export default HeaderComponent;
