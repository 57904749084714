import { TransitionPresets, createStackNavigator } from '@react-navigation/stack'
import React from 'react'

import PersonalAddUser from '../src/screens/humanResources/PersonalAddUser'
import PersonalClockPage from '../src/screens/humanResources/PersonalClockPage'
import HomeHR from '../src/screens/humanResources/HomeHR'

const Stack = createStackNavigator()

const PersonalStack = () => {
    return (
        <Stack.Navigator screenOptions={{ headerShown: false, ...TransitionPresets.RevealFromBottomAndroid }}>
            <Stack.Screen name='HomeHR' component={HomeHR} />
            <Stack.Screen name='PersonalClockPage' component={PersonalClockPage} />
            <Stack.Screen name='PersonalAddUser' component={PersonalAddUser} />
        </Stack.Navigator>
    )
}

export default PersonalStack