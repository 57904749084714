import { HttpsCallableResult, httpsCallable } from 'firebase/functions';
import { useContext } from 'react'

import { GlobalContext } from './GlobalContext'
import { InventoryProps, businessItemProps, contextProps, functionNamesProps } from './types';
import { functions, } from '../firebase/firebaseConfig';

export const useUserContext = () => useContext<contextProps>(GlobalContext);

export const useFunctions =
    <T extends functionNamesProps>(functionName: T) =>
        (requestData?: FunctionParamsMap[T] | object): Promise<HttpsCallableResult<unknown>> =>
            httpsCallable<unknown, unknown>(functions, functionName)(requestData);

type FunctionParamsMap = {
    getUser: {
        uid: string;
    };

    fetchDashboardSales: {
        businessId: string;
    }

    // business
    addBusiness: {
        business: string;
        files: {
            documentType: 'inventories' | 'sales' | 'employees';
            fileData: any;
        }[]
    };
    fetchBusiness: {
        id: string;
    }
    fetchBusinesses: object
    updateBusiness: {
        businessId: string;
        business: businessItemProps;

    }
    // add employee
    addEmployee: {
        businessId: string;
        employee: any;
    }

    payrollSummary: {
        businessId: string;
    }
    fetchPaymentAccount: {
        businessId: string;
    }
    fetchEmployees: {
        businessId: string;
    }
    fetchEmployee: {
        businessId: string;
        employeeId: string;
    }
    updateEmployee: {
        businessId: string;
        employee: any;//will change it
    }
    upcomingSalaryDate: {
        businessId: string;
    }
    upcomingSalaryAmount: {
        businessId: string;
    }

    employeeTask: {
        businessId: string;
        employeeId: string;
        employeeTaskdata: {
            name: string;
            description: string;
            dueDate: string;
            taskStatus: string;
            createdBy: string;
            createdDate: string;
            notes: string;
        }

    }

    fetchTasks: {
        businessId: string;
    }

    employeeTaskCompletion: {
        businessId: string;
    }

    // inventory
    addInventory: {
        businessId: string;
        inventory: InventoryProps;
    }
    fetchInventories: {
        businessId: string;
    }
    updateInventory: {
        businessId: string;
        inventory: InventoryProps;
    }
    deleteInventory: {
        businessId: string;
        inventoryId: string;
    }

    fetchBanks: object

    fetchTelcos: object

    clockIn: {
        punchIn: string;
        businessId: string;
        employeeId: string;
    }
    clockOut: {
        id: string;
        punchOut: string;
        businessId: string;
    }

    requestClientPayment: {
        businessId: string;
        amount: number | string;
    }
};
