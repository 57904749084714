import { getAuth, onAuthStateChanged , UserInfo } from "firebase/auth";
import React, { createContext, useEffect, useState } from "react";

import { businessItemProps, getUserProps } from "./types";
import { useFunctions } from "./useUserContext";
import { app } from "../firebase/firebaseConfig";

interface GlobalContextProps {
  user: any | null;
  business: any;
}

export const GlobalContext = createContext<GlobalContextProps>({
  user: null,
  business: {}
});

export const GlobalProvider = (props: any) => {
  const [user, setUser] = useState<any>(null);

  const [business, setBusiness] = useState<businessItemProps>();
  const { children } = props;

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, (userInfo) => {
      if (userInfo) {
        fetchUserData(userInfo);
      } 
    });
    return () => unsubscribe();
  }, []);

  // fetch business information
  const fetchBusinessData = async (userData: getUserProps) => {

    if (userData) {
      // grab user info from firebase function called fetchUser
      const { businessId } = userData;

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const getBusinessData = useFunctions('fetchBusiness');
      await getBusinessData({ id: businessId })
        .then((result) => {
          setBusiness(result.data as businessItemProps);
        })
        .catch((error) => {
          // Getting the Error details.
          console.error("Error retrieving business data:", error);
        });
    }
  };

  // fetch user information
  const fetchUserData = async (userData: UserInfo) => {
    if (userData) {
      // grab user info from firebase function called fetchUser
      const { uid } = userData;

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const getUserData = useFunctions('getUser');
      await getUserData({ uid })
        .then((result) => {
          const userData = result.data as getUserProps;
          setUser(userData)
          fetchBusinessData(userData);
        })
        .catch((error) => {
          // Getting the Error details.
          console.error("Error retrieving user data:", error);
        });
    }
  };

  return (
    <GlobalContext.Provider value={{ user, business }}>
      {children}
    </GlobalContext.Provider>
  );
};
