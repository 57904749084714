import { View, ScrollView, TouchableOpacity, StyleSheet } from "react-native";
import { Icon, TouchableRipple, Text } from "react-native-paper";
import * as React from "react";
import { Menu, MenuItem, MenuDivider } from "react-native-material-menu";
import { FlatList } from "react-native";
import { useTheme } from "@react-navigation/native";
import {
  headerProps,
  headerTitleProps,
  paginationProps,
  rowProps,
  rowTitleProps,
  tableProps,
} from "./tableProps";
import { CustomColors } from "../../api/customColors";

const Table = ({ children, viewStyle }: tableProps) => {
  return (
    <View style={viewStyle}>
      <ScrollView horizontal bounces={true}>
        <View style={{ flex: 1 }}>{children}</View>
      </ScrollView>
    </View>
  );
};

const Header = ({
  children,
  viewStyle,
  gap,
  dataState,
  setDataState,
}: headerProps) => {
  return (
    <View style={{ flexDirection: "row", gap, ...viewStyle }}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            dataState,
            setDataState,
          } as React.Attributes);
        }
        return child;
      })}
    </View>
  );
};

let HeaderTitle: React.FC<headerTitleProps> = ({
  noOfLines,
  textStyle,
  columnWidth,
  children,
  viewStyle,
  value,
  isObject,
  secondValue,
  isID,
  showSortingIcon = true,
  sortingIcon,
  isNumeric,
  dataState,
  setDataState,
}) => {
  const { colors }: { colors: CustomColors } = useTheme();

  const [visible, setVisible] = React.useState(false);

  const hideMenu = () => setVisible(false);

  const showMenu = () => setVisible(true);

  let selectValue: string | any = value ?? children;

  // let condition = (tempData: any[]) => {
  //     return tempData[0][children].includes('#') || tempData[0][children].includes('_') || tempData[0][children].includes('-') || tempData[0][children].includes('*') || tempData[0][children].includes('+')
  // }

  function customSortAscending(a: any, b: any) {
    const numA = !isObject ? parseInt(a[selectValue].match(/\d+/)[0], 10) : parseInt(a[selectValue][secondValue as string].match(/\d+/)[0], 10);
    const numB = !isObject ? parseInt(b[selectValue].match(/\d+/)[0], 10) : parseInt(b[selectValue][secondValue as string].match(/\d+/)[0], 10);
    const strA = !isObject ? a[selectValue].replace(/\d+/, "") : a[selectValue][secondValue as string].replace(/\d+/, "");
    const strB = !isObject ? b[selectValue].replace(/\d+/, "") : b[selectValue][secondValue as string].replace(/\d+/, "");

    if (strA < strB) return -1;
    if (strA > strB) return 1;

    if (numA < numB) return -1;
    if (numA > numB) return 1;

    return 0;
  }

  function customSortDescending(a: any, b: any) {
    // console.log(!isObject,a[selectValue][secondValue].match(/\d+/)[0], 10)
    const numA = !isObject ? parseInt(a[selectValue].match(/\d+/)[0], 10) : parseInt(a[selectValue][secondValue as string].match(/\d+/)[0], 10);
    // console.log(numA)
    const numB = !isObject ? parseInt(b[selectValue].match(/\d+/)[0], 10) : parseInt(b[selectValue][secondValue as string].match(/\d+/)[0], 10);
    const strA = !isObject ? a[selectValue].replace(/\d+/, "") : a[selectValue][secondValue as string].replace(/\d+/, "");
    const strB = !isObject ? b[selectValue].replace(/\d+/, "") : b[selectValue][secondValue as string].replace(/\d+/, "");

    if (strA > strB) return -1;
    if (strA < strB) return 1;

    if (numA > numB) return -1;
    if (numA < numB) return 1;

    return 0;
  }

  let handleIdAsc = () => {
    if (typeof selectValue === "string") {
      let tempData = [...dataState];
      tempData.sort(customSortAscending);
      setDataState(tempData);
    }
    setVisible(false);
  };
  let handleIdDec = () => {
    if (typeof selectValue === "string") {
      let tempData = [...dataState];
      tempData.sort(customSortDescending);
      setDataState(tempData);
    }
    setVisible(false);
  };

  let handleA_Z = () => {

    if (!isObject) {
      if (typeof selectValue === "string") {
        let tempData = [...dataState];
        tempData.sort((a, b) => a[selectValue].localeCompare(b[selectValue]));
        setDataState(tempData);
      }
    } else {
      if (typeof selectValue === "string") {
        let tempData = [...dataState];
        tempData.sort((a, b) => a[selectValue][secondValue as string].localeCompare(b[selectValue][secondValue as string]));
        setDataState(tempData);
      }
    }
    setVisible(false);
  };

  let handleZ_A = () => {

    if (!isObject) {
      if (typeof selectValue === "string") {
        let tempData = [...dataState];
        tempData.sort((a, b) => b[selectValue].localeCompare(a[selectValue]));
        setDataState(tempData);
      }
    } else {
      if (typeof selectValue === "string") {
        let tempData = [...dataState];
        tempData.sort((a, b) => b[selectValue][secondValue as string].localeCompare(a[selectValue][secondValue as string]));
        setDataState(tempData);
      }
    }
    setVisible(false);
  };

  let handle0_9 = () => {

    if (!isObject) {
      if (typeof selectValue === "string") {
        let tempData = [...dataState];
        tempData.sort((a, b) => a[selectValue] - b[selectValue]);
        setDataState(tempData);
      }
    } else {
      if (typeof selectValue === "string") {
        let tempData = [...dataState];
        tempData.sort((a, b) => a[selectValue][secondValue as string] - b[selectValue][secondValue as string]);
        setDataState(tempData);
      }
    }
    setVisible(false);
  };

  let handle9_0 = () => {

    if (!isObject) {
      if (typeof selectValue === "string") {
        let tempData = [...dataState];
        tempData.sort((a, b) => b[selectValue] - a[selectValue]);
        setDataState(tempData);
      }
    } else {
      if (typeof selectValue === "string") {
        let tempData = [...dataState];
        tempData.sort((a, b) => b[selectValue][secondValue as string] - a[selectValue][secondValue as string]);
        setDataState(tempData);
      }
    }
    setVisible(false);
  };

  const styles = StyleSheet.create({
    menuItem: {
      minWidth: "auto",
      height: "auto",
      paddingVertical: 10,
    },
    textStyle: {
      color: colors.subText,
    },
  });

  return (
    <View
      style={{
        width: columnWidth,
        justifyContent: "center",
        ...viewStyle,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          marginHorizontal: 5,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Text
          variant="titleMedium"
          style={{
            flex: 1,
            color: colors.text,
            ...textStyle,
          }}
          numberOfLines={noOfLines}
        >
          {children}
        </Text>

        {showSortingIcon && (
          <Menu
            visible={visible}
            anchor={
              <TouchableRipple
                borderless
                onPress={showMenu}
                style={{ borderRadius: 100 }}
              >
                {!sortingIcon ? (
                  <Icon
                    source={"dots-vertical"}
                    color={colors.subText}
                    size={20}
                  />
                ) : (
                  sortingIcon
                )}
              </TouchableRipple>
            }
            onRequestClose={hideMenu}
            style={{
              borderRadius: 10,
              borderWidth: 1,
              borderColor: colors.border,
              backgroundColor: colors.background,
              overflow: "hidden",
            }}
          >
            {isID ? (
              <>
                <MenuItem
                  style={styles.menuItem}
                  textStyle={styles.textStyle}
                  pressColor="#d7d7d790"
                  onPress={handleIdAsc}
                >
                  Ascending
                </MenuItem>
                <MenuDivider color={colors.border} />
                <MenuItem
                  style={styles.menuItem}
                  textStyle={styles.textStyle}
                  pressColor="#d7d7d790"
                  onPress={handleIdDec}
                >
                  Descending
                </MenuItem>
              </>
            ) : !isNumeric ? (
              <>
                <MenuItem
                  style={styles.menuItem}
                  textStyle={styles.textStyle}
                  pressColor="#d7d7d790"
                  onPress={handleA_Z}
                >
                  Ascending (A-Z)
                </MenuItem>
                <MenuDivider color={colors.border} />
                <MenuItem
                  style={styles.menuItem}
                  textStyle={styles.textStyle}
                  pressColor="#d7d7d790"
                  onPress={handleZ_A}
                >
                  Descending (Z-A)
                </MenuItem>
              </>
            ) : (
              <>
                <MenuItem
                  style={styles.menuItem}
                  pressColor="#d7d7d790"
                  textStyle={styles.textStyle}
                  onPress={handle0_9}
                >
                  Ascending (0-9)
                </MenuItem>
                <MenuDivider color={colors.border} />
                <MenuItem
                  style={styles.menuItem}
                  pressColor="#d7d7d790"
                  textStyle={styles.textStyle}
                  onPress={handle9_0}
                >
                  Descending (9-0)
                </MenuItem>
              </>
            )}
          </Menu>
        )}
      </View>
    </View>
  );
};

const Row = ({ children, gap, viewStyle, rowHeight }: rowProps) => {
  return (
    <View
      style={{
        gap,
        flexDirection: "row",
        height: rowHeight,
        ...viewStyle,
      }}
    >
      <>{children}</>
    </View>
  );
};

let RowTitle: React.FC<rowTitleProps> = ({
  value,
  viewStyle,
  textStyle,
  noOfLines = 1,
  onPress,
  onViewPress,
  rowWidth,
  rowHeight = 24,
}: rowTitleProps) => {
  const { colors }: { colors: CustomColors } = useTheme();

  return onPress === undefined ? (
    <View style={{ width: rowWidth, ...viewStyle }}>
      <View style={{ margin: 5 }}>
        <Text
          variant="bodyLarge"
          style={{
            color: colors.subText,
            ...textStyle,
          }}
          numberOfLines={noOfLines}
        >
          {value}
        </Text>
      </View>
    </View>
  ) : (
    <TouchableOpacity
      style={{ width: rowWidth, ...viewStyle }}
      activeOpacity={onPress === undefined ? 1 : 0.3}
      onPress={onPress}
    >
      <View style={{ margin: 5 }}>
        <Text
          variant="bodyLarge"
          style={{
            width: rowWidth,
            color: colors.subText,
            ...textStyle,
          }}
          numberOfLines={noOfLines}
        >
          {value}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const totalPage = (data: any[], noOfRows: number) => {
  return Math.ceil(data.length / noOfRows);
};

let Pagination = ({
  width,
  rowsData = [10, 20, 50],
  setRows,
  totalPages,
  viewStyle,
  buttonStyle,
  label,
  currentSelectedRows,
  currentPage,
  setCurrentPage,
}: paginationProps) => {
  const { colors }: { colors: CustomColors } = useTheme();

  const [visible, setVisible] = React.useState(false);

  const hideMenu = () => setVisible(false);

  const showMenu = () => setVisible(true);

  let next = () => {
    if (currentPage < totalPages - 1) setCurrentPage(currentPage + 1);
  };

  let previous = () => {
    if (currentPage !== 0) setCurrentPage(currentPage - 1);
  };

  const styles = StyleSheet.create({
    menuItem: {
      flex: 1,
      minWidth: "auto",
      height: "auto",
      backgroundColor: colors.background,
      paddingVertical: 10,
    },
    textStyle: {
      color: colors.subText,
    },
  });

  return (
    <View
      style={{
        flexDirection: "row",
        width,
        gap: 10,
        alignItems: "center",
        justifyContent: "flex-end",
        ...viewStyle,
      }}
    >
      <Menu
        animationDuration={100}
        visible={visible}
        anchor={
          <View
            style={{
              flexDirection: "row",
              width,
              gap: 10,
              alignItems: "center",
              justifyContent: "flex-end",
              ...viewStyle,
            }}
          >
            <Text variant="bodyMedium" style={{ color: colors.subText }}>
              Rows Per Page:
            </Text>
            <TouchableRipple
              borderless
              onPress={showMenu}
              style={{
                backgroundColor: colors.background,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                paddingHorizontal: 8,
                paddingVertical: 3,
                borderRadius: 100,
              }}
            >
              <>
                <Text variant="bodyMedium" style={{ color: colors.subText }}>
                  {" "}
                  {currentSelectedRows}
                </Text>
                <Icon source={"menu-down"} size={20} color={colors.subText} />
              </>
            </TouchableRipple>
          </View>
        }
        onRequestClose={hideMenu}
        style={{
          borderRadius: 10,
          borderWidth: 1,
          borderColor: colors.border,
          overflow: "hidden",
          backgroundColor: colors.background,
        }}
      >
        <FlatList
          data={rowsData}
          ItemSeparatorComponent={() => <MenuDivider color={colors.border} />}
          renderItem={({ item }) => (
            <MenuItem
              style={styles.menuItem}
              textStyle={styles.textStyle}
              onPress={() => {
                setRows(item);
                hideMenu();
                setCurrentPage(0);
              }}
            >
              {item}
            </MenuItem>
          )}
        />
      </Menu>
      <Text variant="bodyMedium" style={{ color: colors.subText }}>
        {label}
      </Text>

      <TouchableRipple
        disabled={!(currentPage !== 0)}
        borderless
        style={{ borderRadius: 100, ...buttonStyle }}
        onPress={previous}
      >
        <Icon
          source={"chevron-left"}
          color={currentPage !== 0 ? colors.text : colors.border}
          size={24}
        />
      </TouchableRipple>
      <TouchableRipple
        disabled={!(currentPage < totalPages - 1)}
        borderless
        style={{ borderRadius: 100, ...buttonStyle }}
        onPress={next}
      >
        <Icon
          source={"chevron-right"}
          color={currentPage < totalPages - 1 ? colors.text : colors.border}
          size={24}
        />
      </TouchableRipple>
    </View>
  );
};

let deleteRow = (
  itemIndex: number,
  dataState: any[],
  setDataState: React.Dispatch<React.SetStateAction<any[]>>
) => {
  let newData: any[] = [];

  dataState.forEach((item, index) => {
    if (index !== itemIndex) {
      newData.push(item);
    }
  });

  setDataState(newData);
};

let editRow = (
  itemIndex: number,
  dataState: any[],
  setDataState: React.Dispatch<React.SetStateAction<any[]>>,
  editedData: object
) => {
  let newData: any[] = [];

  dataState.forEach((item, index) => {
    if (index === itemIndex) {
      newData.push(editedData);
    } else {
      newData.push(item);
    }
  });

  setDataState(newData);
};

Header.Title = HeaderTitle;

Row.Cell = RowTitle;

Table.EditRow = editRow;
Table.DeleteRow = deleteRow;
Table.Pagination = Pagination;
Table.TotalPages = totalPage;

export { Table, Header, Row };
