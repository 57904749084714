/* eslint-disable react/no-this-in-sfc */
import * as Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";

import { lineChartProps } from "./types";
import { currencyFormatter } from "../util/constants";

const CustomLineChart = ({
  chartDimension,
  chartSubtitle,
  chartType,
  stepLine,
  showLegend,
  chartTitle,
  colors,
  lineChartData,
  lineChartSeriesName = "Payments",
}: lineChartProps) => {
  // function getRandomNumber(min: number, max: number) {
  //   return Math.floor(Math.random() * (max - min + 1)) + min;
  // }

  const timestamps: any[] = [];

  for (let month = 0; month < 12; month++) {
    const date = new Date(new Date().getFullYear(), month, 1);
    const timestamp = date.getTime();
    timestamps.push(timestamp);
  }


  // exampleData start
  // function generateData() {
  //   const startDate = new Date();  // Current date
  //   startDate.setFullYear(startDate.getFullYear() - 2);  // 2 years ago]

  //   const data = [];

  //   for (let i = 0; i < 24; i++) {
  //     const timestamp = startDate.getTime();
  //     const value1 = Math.floor(Math.random() * 100);
  //     const value2 = Math.floor(Math.random() * 100);

  //     data.push([timestamp, value1], [timestamp, value2]);

  //     // Move to the next month
  //     startDate.setMonth(startDate.getMonth() + 1);
  //   }

  //   // Sort the data based on timestamps
  //   data.sort((point1, point2) => point1[0] - point2[0]);

  //   return data;
  // }

  // const exampleData = generateData();
  // exampleData end

  const [data, setChartData] = useState<any>([]);
  const [chartOptions, setChartOptions] = useState<any>({});

  useEffect(() => {
    if (lineChartData && lineChartData.length > 0) {
      setChartData(lineChartData);
    }
  }, [lineChartData]);

  useEffect(
    () =>
      setChartOptions({
        chart: {
          type: chartType,
          backgroundColor: colors.card,
          borderRadius: 10,
          plotBackgroundColor: "transparent",
          plotBorderColor: "transparent",
          height: chartDimension.y,
          width: chartDimension.x,
        },
        rangeSelector: {
          allButtonsEnabled: true,
          enabled: true, // stock chart
          inputEnabled: false,
          buttons: [
            {
              type: "day",
              count: 1,
              text: "1m",
              title: "View 1 month",
            },
            {
              type: "month",
              count: 6,
              text: "6m",
              title: "View 6 months",
            },
            {
              type: "year",
              count: 1,
              text: "1y",
              title: "View 1 year",
            },
            {
              type: "all",
              text: "All",
              title: "View all",
            },
          ],
          buttonTheme: {
            // styles for the buttons
            fill: colors.background,
            stroke: "none",
            "stroke-width": 0,
            r: 8,
            style: {
              color: colors.primary,
              fontWeight: "bold",
            },
            states: {
              select: {
                fill: colors.border,
                style: {
                  color: "white",
                },
              },
            },
          },
        },
        navigator: {
          series: {
            color: colors.primary,
          },
        },
        accessibility: {
          enabled: false,
        },
        title: {
          text: chartTitle ? chartTitle : undefined,
          align: "center",
          style: {
            color: colors.text,
          },
        },
        subtitle: {
          text: chartSubtitle ? chartSubtitle : undefined,
          align: "center",
          style: {
            color: colors.text,
          },
        },
        xAxis: {
          title: {
            text: undefined,
          },
          labels: {
            formatter (
              this: Highcharts.AxisLabelsFormatterContextObject
            ): string {
              // eslint-disable-next-line react/no-this-in-sfc
              return Highcharts.dateFormat("%b", Number(this.value));
            },
            style: {
              color: colors.text,
            },
          },
        },
        yAxis: {
          title: {
            text: undefined,
          },
          gridLineColor: colors.border,
          labels: {
            style: {
              color: colors.text,
            },
          },
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          headerFormat: "<b>{series.name}</b><br />",
          formatter (
            this: Highcharts.TooltipFormatterContextObject
          ): string {
            return (
              "The value for <b>" +
              new Date(Number(this.x)).toDateString() +
              "</b> is <b>" +
              currencyFormatter(this.y || 0) +
              "</b>"
            );
          },
        },
        legend: {
          enabled: showLegend,
          itemStyle: {
            color: colors.text,
          },
        },
        series: [
          {
            type: chartType ?? "line",
            marker: {
              symbol: "square",
            },
            name: lineChartSeriesName,
            color: colors.primary,
            data,
            tooltip: {
              valueDecimals: 2,
            },
          },
        ],
        lang: {
          noData: "No data to display",
        },
        noData: {
          style: {
            fontWeight: "bold",
            fontSize: "15px",
            color: "#303030",
          },
        },
        plotOptions: {
          series: {
            step: stepLine ? "center" : undefined,
          },
        },
      }),
    [data]
  );


  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
      constructorType="stockChart"
    />
  );
};

export default CustomLineChart;
