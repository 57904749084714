import { useTheme } from "@react-navigation/native";
import * as DocumentPicker from "expo-document-picker";
import React, { useState } from "react";
import { FlatList, Pressable, StyleSheet, ScrollView, View, ActivityIndicator } from "react-native";
import { Divider, Icon, Text } from "react-native-paper";

import { CustomColors } from "../../api/customColors";
import Input from "../../component/Common/Input/Input";
import CustomButton from "../../component/Common/button";
import { isWeb } from "../../component/Common/isWeb";
import CustomDropdown from "../../component/CustomDropdown/CustomDropdown";
import { useForm } from "react-hook-form";
import FormsInput from "../../component/Common/Input/FormsInput";
import { businessItemSchema } from "../../component/Common/Input/inputSchema";
import { businessItemProps } from "../../GlobalContext/types";
import { useFunctions, useUserContext } from "../../GlobalContext/useUserContext";
import countryList from 'country-list'
import * as FileSystem from 'expo-file-system';

let FileUploader: any;
if (isWeb) FileUploader = require("react-drag-drop-files").FileUploader;

const OnBoardingAddBusiness = (props: any) => {

  const { control, handleSubmit } = useForm();

  const { colors }: { colors: CustomColors } = useTheme();
  const { user } = useUserContext();

  const [cl] = useState(countryList.getNames().map((item: string) => ({
    label: item,
    value: item
  })))


  const [currentStep, setCurrentStep] = useState(1);
  const [selectedFiles, setSelectedFiles] = useState<{ name: string; data: string | ArrayBuffer | null; }[]>([]);
  const [selectedFileTypes, setSelectedFileTypes] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedFileTypesWeb, setSelectedFileTypesWeb] = useState<string[]>([]);

  const [isLoading, setIsLoading] = useState(false)

  const gotoNextStep = async (data: businessItemProps) => {
    if (currentStep < 3) setCurrentStep(currentStep + 1);
    else if (currentStep === 3) {
      setIsLoading(true);
      let addBussiness = useFunctions('addBusiness');
      await addBussiness({
        business: data,
        files: selectedFiles.map((file) => ({
          documentType: 'inventories',
          fileData: file.data
        }))
      })
        .then(res => {
          props.navigation.navigate("OnBoarding", { fetchAgain: Date.now() });
          setIsLoading(false);
        })
        .catch(err => {
          console.log('error addBusiness', err.message)
          setIsLoading(false);
        });
    }
  };

  const goPreviousStep = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  const handelCancel = () => {
    props.navigation.navigate("OnBoarding");
  };

  const stepText = (stepNumber: number) => {
    if (stepNumber === 1) return "Business Information";
    else if (stepNumber === 2) return "Business Benefits";
    else if (stepNumber === 3) return "Upload Documents";
  };

  const StepComponent = ({
    title,
    icon,
    size,
    stepNumber,
  }: {
    title: string;
    icon: string;
    size: number;
    stepNumber: number;
  }) => {
    return (
      <View
        style={{
          flexDirection: "row",
          gap: 10,
          padding: 10,
          alignItems: "center",
          overflow: "hidden",
          borderRadius: 10,
          backgroundColor:
            stepNumber === currentStep ? colors.card : "transparent",
        }}
      >
        <Icon
          source={icon}
          size={size}
          color={stepNumber === currentStep ? colors.primary : colors.subText}
        />
        <View>
          <Text style={{ color: colors.subText, opacity: 0.8 }}>{title}</Text>
          <Text
            style={{
              color: stepNumber === currentStep ? colors.primary : colors.text,
              fontWeight: stepNumber === currentStep ? "bold" : "normal",
              marginTop: 5,
            }}
          >
            {stepText(stepNumber)}
          </Text>
        </View>
      </View>
    );
  };

  // handleDelete selected files
  const handleDelete = (data: { name: string; data: string | ArrayBuffer | null; }, index: number) => {
    const filteredData: { name: string; data: string | ArrayBuffer | null; }[] = [];

    selectedFiles.map((item) => {
      if (item.name !== data.name) {
        filteredData.push(item);
      }
    });

    setSelectedFiles(filteredData);
  };

  // web drag n drop
  const handleChange = async (file: any) => {
    const data: { name: string; data: string | ArrayBuffer | null }[] = [];

    const fileReadPromises: Promise<void>[] = Object.keys(file).map(
      (keyValue: string) => {
        return new Promise<void>((resolve) => {
          const f = new FileReader();

          f.onload = () => {
            console.log('result', f.result)
            data.push({
              name: file[keyValue].name,
              data: f.result
            });
            resolve();
          };

          f.readAsDataURL(file[keyValue]);
        });
      }
    );

    await Promise.all(fileReadPromises);
    setSelectedFiles(data);
  };

  const pickDocument = async () => {
    try {
      const result = await DocumentPicker.getDocumentAsync({
        multiple: true,
        type: selectedFileTypes,
      });


      if (result.assets) {

        let filesData = await Promise.all(
          result.assets.map(async (item) => {
            const base64 = await FileSystem.readAsStringAsync(item.uri, { encoding: 'base64' });

            return {
              name: item.name,
              data: `data:${item.mimeType};base64,${base64}`,
            };
          })
        );
        console.log(filesData)
        setSelectedFiles(filesData as []);
      }
    } catch (error) {
      console.error("Error picking documents: ", error);
    }
  };

  // for dropdown
  const fileUploadTypes = [
    { label: "Excel", value: "excel" },
    { label: "Word", value: "word" },
    { label: "Power Point", value: "powerpoint" },
    { label: "PDF", value: "pdf" },
  ];

  return (
    <View style={{ flex: 1, backgroundColor: colors.background }}>
      <View style={{ flex: 1, padding: 10 }}>
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Text
            variant="bodyLarge"
            style={{ color: colors.subText, opacity: 0.8 }}
          >
            Add User
          </Text>
          <Text
            variant="titleLarge"
            style={{ color: colors.text, fontWeight: "bold" }}
          >
            {stepText(currentStep)}
          </Text>
        </View>

        <View style={{ flex: 8, flexDirection: "row", gap: 10 }}>
          {/* left side  */}
          <View style={{ flex: 1 }}>
            <StepComponent
              title="Step 1"
              icon="information-outline"
              size={18}
              stepNumber={1}
            />
            <StepComponent
              title="Step 2"
              icon="account-details-outline"
              size={18}
              stepNumber={2}
            />
            <StepComponent
              title="Step 3"
              icon="account-details-outline"
              size={18}
              stepNumber={3}
            />
          </View>

          {/* right side */}
          <View style={{ flex: 3, flexDirection: "row" }}>
            <View
              style={{
                flex: 2,
                backgroundColor: colors.card,
                borderRadius: 10,
                overflow: "hidden",
              }}
            >
              <ScrollView contentContainerStyle={{ padding: 10 }}>
                {currentStep === 1 && (
                  <View style={{ flex: 1 }}>
                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Business Name
                        </Text>
                        <FormsInput
                          control={control}
                          placeHolder="Business Name"
                          name="businessName"
                          rules={businessItemSchema['businessName']}
                        />
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Business Phone
                        </Text>
                        <FormsInput
                          control={control}
                          placeHolder="Business Phone"
                          name="businessPhone"
                          rules={businessItemSchema['businessPhone']}
                        />
                      </View>
                    </View>

                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Address Line 1
                        </Text>
                        <FormsInput
                          control={control}
                          placeHolder="Business Address Line 1"
                          name="businessAddressLine1"
                          rules={businessItemSchema['businessAddressLine1']}
                        />
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Address Line 2
                        </Text>
                        <FormsInput
                          control={control}
                          placeHolder="Business Address Line 2"
                          name="businessAddressLine2"
                          rules={businessItemSchema['businessAddressLine2']}
                        />
                      </View>
                    </View>

                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >

                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Address Line 3
                        </Text>
                        <FormsInput
                          control={control}
                          placeHolder="Business Address Line 3"
                          name="businessAddressLine3"
                          rules={businessItemSchema['businessAddressLine3']}
                        />
                      </View>

                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Webiste
                        </Text>
                        <FormsInput
                          control={control}
                          placeHolder="Business Website"
                          name="businessWebsite"
                          rules={businessItemSchema['businessWebsite']}
                        />
                      </View>

                    </View>

                    <View style={{ flex: 1, zIndex: 999 }}>
                      <Text style={{ color: colors.subText }}>
                        Country
                      </Text>
                      <FormsInput
                        control={control}
                        name="businessCountry"
                        placeHolder="Select Country"
                        DropdownSearchable
                        DropdownDirection="TOP"
                        style={{
                          marginTop: 5,
                          backgroundColor: "transparent",
                        }}
                        rules={businessItemSchema['businessCountry']}
                        isDropdown
                        DropdownData={cl}
                      />
                    </View>

                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>City</Text>
                        <FormsInput
                          control={control}
                          placeHolder="City"
                          name="businessCity"
                          rules={businessItemSchema['businessCity']}
                        />
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>State</Text>
                        <FormsInput
                          control={control}
                          placeHolder="State"
                          name="businessState"
                          rules={businessItemSchema['businessState']}
                        />
                      </View>
                    </View>

                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>Zip Code</Text>
                        <FormsInput
                          control={control}
                          placeHolder="Zip Code"
                          name="businessZipCode"
                          rules={businessItemSchema['businessZipCode']}
                        />
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Email
                        </Text>
                        <FormsInput
                          control={control}
                          placeHolder="Email"
                          name="businessEmail"
                          rules={businessItemSchema['businessEmail']}
                        />
                      </View>
                    </View>

                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Sub-account Code
                        </Text>
                        <FormsInput
                          control={control}
                          placeHolder="Subaccount Code"
                          name="subaccountCode"
                          rules={businessItemSchema['subaccountCode']}
                        />
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Fax
                        </Text>
                        <FormsInput
                          control={control}
                          placeHolder="Fax"
                          name="businessFax"
                          rules={businessItemSchema['businessFax']}
                        />
                      </View>
                    </View>

                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >

                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Product Tier
                        </Text>
                        <FormsInput
                          control={control}
                          name="businessProductTier"
                          placeHolder="Select Tier"
                          style={{
                            marginTop: 5,
                            backgroundColor: "transparent",
                          }}
                          rules={businessItemSchema['businessProductTier']}
                          isDropdown
                          DropdownData={[
                            { label: 'Gold', value: 'Gold' },
                            { label: 'Silver', value: 'Silver' },
                            { label: 'Premium', value: 'Premium' },
                          ]}
                        />
                      </View>

                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Type
                        </Text>
                        <FormsInput
                          control={control}
                          placeHolder="Type"
                          name="businessType"
                          rules={businessItemSchema['businessType']}
                        />
                      </View>
                    </View>






                  </View>
                )}
                {currentStep === 2 && (
                  <View style={{ flex: 1, height: 500 }}>
                    <View
                      style={{ flexDirection: "row", gap: 10, marginTop: 10 }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Bonus Allocated
                        </Text>
                        <FormsInput
                          control={control}
                          placeHolder="Bonus Allocated"
                          name="bonusAllocated"
                          rules={businessItemSchema['bonusAllocated']}
                        />

                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{ color: colors.subText }}>
                          Profit Sharing Allocated
                        </Text>
                        <FormsInput
                          control={control}
                          placeHolder="Profit Sharing Allocated"
                          name="profitSharingAllocated"
                          rules={businessItemSchema['profitSharingAllocated']}
                        />
                      </View>
                    </View>

                    <View style={{ flex: 1, marginTop: 10 }}>
                      <Text style={{ color: colors.subText }}>
                        Type
                      </Text>
                      <FormsInput
                        control={control}
                        name="type"
                        placeHolder="Select Type"
                        style={{
                          marginTop: 5,
                          backgroundColor: "transparent",
                        }}
                        rules={businessItemSchema['type']}
                        isDropdown
                        DropdownData={[
                          { label: 'Percentage', value: 'percentage' },
                          { label: 'Weight', value: 'weight' },
                          { label: 'Quantity', value: 'quantity' },
                        ]}
                      />
                    </View>

                  </View>
                )}
                {currentStep === 3 && (
                  <View style={{ flex: 1, height: 500 }}>
                    <View
                      style={{
                        flexDirection: "row",
                        gap: 10,
                        marginBottom: 10,
                        zIndex: 999,
                      }}
                    >
                      <View style={{ flex: 1 }}>
                        <CustomDropdown
                          data={fileUploadTypes}
                          placeholder="Select File Type"
                          setValue={(t: any) => {
                            setSelectedFile(t());

                            const selectedValue = t();

                            if (selectedValue === "pdf") {
                              if (isWeb) setSelectedFileTypesWeb(["PDF"]);
                              else setSelectedFileTypes(["application/pdf"]);
                            } else if (selectedValue === "word") {
                              if (isWeb) setSelectedFileTypesWeb(["DOCX"]);
                              else
                                setSelectedFileTypes([
                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                ]);
                            } else if (selectedValue === "excel") {
                              if (isWeb) setSelectedFileTypesWeb(["XLSX"]);
                              else
                                setSelectedFileTypes([
                                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                ]);
                            } else if (selectedValue === "powerpoint") {
                              if (isWeb) setSelectedFileTypesWeb(["PPTX"]);
                              else
                                setSelectedFileTypes([
                                  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                                ]);
                            }
                          }}
                          value={selectedFile}
                          style={{ backgroundColor: colors.card }}
                        />
                      </View>

                      {!isWeb && selectedFile.length !== 0 && (
                        <CustomButton
                          value="Pick Document"
                          onPress={pickDocument}
                          viewStyle={{
                            borderRadius: 10,
                            backgroundColor: colors.card,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        />
                      )}
                    </View>

                    {FileUploader && selectedFile.length !== 0 && (
                      <FileUploader
                        multiple
                        name="file"
                        hoverTitle="Upload"
                        handleChange={handleChange}
                        types={selectedFileTypesWeb}
                      />
                    )}

                    {selectedFiles.length > 0 && (
                      <View style={{ marginTop: 10 }}>
                        <Text
                          variant="bodyLarge"
                          style={{ color: colors.text, fontWeight: "bold" }}
                        >
                          Selected Files
                        </Text>
                        <FlatList
                          data={selectedFiles}
                          ListHeaderComponent={() => {
                            return (
                              <View style={{ flexDirection: "row" }}>
                                <Text
                                  style={{
                                    flex: 1,
                                    color: colors.text,
                                    fontWeight: "bold",
                                    textAlign: "center",
                                  }}
                                >
                                  Sr #
                                </Text>
                                <Text
                                  style={{
                                    flex: 8,
                                    color: colors.text,
                                    fontWeight: "bold",
                                  }}
                                >
                                  File Name
                                </Text>
                                <Text
                                  style={{
                                    flex: 1,
                                    color: colors.text,
                                    fontWeight: "bold",
                                    textAlign: "center",
                                  }}
                                >
                                  Delete
                                </Text>
                              </View>
                            );
                          }}
                          renderItem={({ item, index }) => {
                            return (
                              <View
                                style={{
                                  flex: 1,
                                  flexDirection: "row",
                                  gap: 10,
                                  paddingHorizontal: 10,
                                  paddingVertical: 5,
                                }}
                              >
                                <Text
                                  style={{
                                    flex: 1,
                                    color: colors.text,
                                    opacity: 0.8,
                                    textAlign: "center",
                                  }}
                                >
                                  {index + 1}.
                                </Text>
                                <Text style={{ flex: 8, color: colors.text }}>
                                  {item.name}
                                </Text>
                                <Pressable
                                  onPress={() => handleDelete(item, index)}
                                  style={({ pressed }) => ({
                                    flex: 1,
                                    opacity: pressed ? 0.5 : 1,
                                    justifyContent: "center",
                                    alignItems: "center",
                                  })}
                                >
                                  <Icon
                                    source="close"
                                    size={18}
                                    color={colors.text}
                                  />
                                </Pressable>
                              </View>
                            );
                          }}
                        />
                      </View>
                    )}
                  </View>
                )}
              </ScrollView>
            </View>

            <View style={{ flex: 1 }} />
          </View>
        </View>
      </View>

      <View
        style={{
          flexDirection: "row",
          gap: 10,
          backgroundColor: colors.card,
          justifyContent: "flex-end",
          padding: 20,
        }}
      >
        <CustomButton
          value="Cancel"
          onPress={handelCancel}
          viewStyle={{
            paddingVertical: 15,
            paddingHorizontal: 20,
            backgroundColor: "transparent",
            borderWidth: 1,
            borderColor: colors.primary,
            borderRadius: 10,
          }}
          textStyle={{ color: colors.primary, fontSize: 18 }}
        />

        <CustomButton
          value="Save as Draft"
          onPress={() => { }}
          viewStyle={{
            paddingVertical: 15,
            paddingHorizontal: 20,
            backgroundColor: "transparent",
            borderWidth: 1,
            borderColor: colors.primary,
            borderRadius: 10,
          }}
          textStyle={{ color: colors.primary, fontSize: 18 }}
        />

        <Divider
          style={{ height: "100%", width: 1, backgroundColor: colors.border }}
        />

        <CustomButton
          value="Previous"
          onPress={goPreviousStep}
          viewStyle={{
            paddingVertical: 15,
            paddingHorizontal: 20,
            backgroundColor: "transparent",
            borderWidth: 1,
            borderColor: colors.primary,
            borderRadius: 10,
          }}
          textStyle={{ color: colors.primary, fontSize: 18 }}
        />

        <CustomButton
          value={currentStep === 3 ? "Save" : "Next"}
          onPress={handleSubmit(gotoNextStep)}
          viewStyle={{
            paddingVertical: 15,
            paddingHorizontal: 20,
            backgroundColor: colors.primary,
            borderRadius: 10,
          }}
          textStyle={{ color: "black", fontSize: 18 }}
        />
      </View>

      {isLoading && (
        <View style={{ ...StyleSheet.absoluteFillObject, justifyContent: 'center', alignItems: 'center', backgroundColor: '#00000030' }}>
          <ActivityIndicator color={colors.primary} size={'large'} />
        </View>
      )}
    </View>
  )
}
export default OnBoardingAddBusiness;