import { useIsFocused, useRoute, useTheme } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { StyleSheet, View, TouchableOpacity, Pressable, ActivityIndicator } from "react-native";
import { Icon, Text } from "react-native-paper";
import { CustomColors } from "../../api/customColors";
import { paymentData } from "../../assets/dummyData/dummyData";
import Input from "../../component/Common/Input/Input";
import OnBoardingTable from "../../component/page/onboarding/OnboardingTable";
import { useFunctions, useUserContext } from "../../GlobalContext/useUserContext";
import { businessItemProps } from "../../GlobalContext/types";
import { Drawer } from "react-native-drawer-layout";
import OnboardingSidePanel from "../../component/page/onboarding/OnboardingSidePanel";





const OnBoarding = (props: any) => {

  const { business, user } = useUserContext();

  const { colors }: { colors: CustomColors } = useTheme();

  const [businessesData, setBusinessesData] = useState<businessItemProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [showSidePanel, setShowSidePanel] = useState(false)
  const [selectedData, setSelectedData] = useState<businessItemProps>()
  const [modifiedData, setModifiedData] = useState<businessItemProps[]>(businessesData);

  const [fetchAgain, setFetchAgain] = useState(false)

  const [tableSortActiveIndex, setTableSortActiveIndex] = useState<
    string | null
  >(null);

  const tableSortArray = [...new Set(paymentData.map((item) => item.status))];
  const {
    navigation: { navigate },
  } = props;

  const tableSort = (item: string) => {
    setTableSortActiveIndex(item);

    const filterData: any[] = [];

    paymentData.forEach((data) => {
      if (data.status === item) filterData.push(data);
    });

    setModifiedData(filterData);
  };

  const clearFilter = () => {
    setTableSortActiveIndex(null);
    setModifiedData(businessesData);
  };

  let getBusinesses = async () => {
    setIsLoading(true);
    let get = useFunctions('fetchBusinesses');

    await get().then(res => {
      setBusinessesData(res.data as businessItemProps[]);
      setModifiedData(res.data as businessItemProps[])
      setIsLoading(false);
      console.log('first business', res.data)
    })
      .catch(err => {
        setIsLoading(false);
        console.log('error', err.message)
      })

  }

  let runAgain = useRoute().params?.fetchAgain

  useEffect(() => {
    getBusinesses();
  }, [fetchAgain,runAgain])


  const styles = StyleSheet.create({
    topRightButton: {
      gap: 10,
      borderRadius: 100,
      alignItems: "center",
      flexDirection: "row",
      padding: 10,
      justifyContent: "center",
      backgroundColor: colors.card,
    },
    searchBarView: {
      gap: 10,
      overflow: "hidden",
      marginVertical: 10,
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    tableSortArray: {
      gap: 20,
      paddingTop: 10,
      flexWrap: "wrap",
      alignItems: "center",
      flexDirection: "row",
    },
    tableSubStatusText: {
      gap: 10,
      borderWidth: 2,
      borderRadius: 5,
      paddingVertical: 3,
      flexDirection: "row",
      alignItems: "center",
      paddingHorizontal: 20,
      justifyContent: "center",
    },
  });

  return (
    <View style={{ flex: 1, backgroundColor: colors.background }}>

      <Drawer
        open={showSidePanel}
        onOpen={() => setShowSidePanel(true)}
        onClose={() => setShowSidePanel(false)}
        drawerPosition="right"
        drawerType="front"
        swipeEnabled={false}
        drawerStyle={{
          right: 0,
          width: '70%',
          marginVertical: 10,
          borderTopLeftRadius: 30,
          borderBottomLeftRadius: 30
        }}
        renderDrawerContent={() => {
          return (
            <OnboardingSidePanel
              setShowSidePanel={setShowSidePanel}
              selectedData={selectedData as businessItemProps}
              setFetchAgain={setFetchAgain}
              fetchAgain={fetchAgain}
            />
          )
        }}

      >

        <View style={{ flex: 1, padding: 10 }}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text
              variant="bodyLarge"
              style={{ color: colors.text, flex: 2, fontWeight: "bold" }}
            >
              OnBoarding
            </Text>

            <View
              style={{
                flex: 1,
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Pressable onPress={() => { navigate('AddBusiness', { screen: 'OnBoardingAddBusiness' }) }} style={styles.topRightButton}>
                <View
                  style={{ backgroundColor: colors.primary, borderRadius: 100 }}
                >
                  <Icon source="plus" size={18} color="white" />
                </View>
                <Text style={{ color: colors.text }}>Add Bussiness</Text>
              </Pressable>
            </View>
          </View>

          <View
            style={{
              flex: 1,
              marginTop: 10,
              borderRadius: 10,
              padding: 10,
              backgroundColor: colors.card,
            }}
          >
            {/* table sort array */}
            <View style={styles.tableSortArray}>
              {tableSortArray.map((item, index) => {
                const isSelected = item === tableSortActiveIndex;

                const successIconColor = "#165e3d";
                const pendingIconColor = "#b5850b";
                const declineIconColor = "#b83131";
                const createIconColor = "#3d42ad";

                let iconName;
                let iconColor;

                if (item === "Succeeded") {
                  iconColor = successIconColor;
                  iconName = "check-circle";
                } else if (item === "Declined") {
                  iconColor = declineIconColor;
                  iconName = "minus-circle";
                } else if (item === "Pending") {
                  iconName = "clock-time-three";
                  iconColor = pendingIconColor;
                } else if (item === "Create") {
                  iconColor = createIconColor;
                  iconName = "flag";
                }

                return (
                  <TouchableOpacity key={index} onPress={() => tableSort(item)}>
                    <View
                      style={{
                        ...styles.tableSubStatusText,
                        borderColor: isSelected ? iconColor : "transparent",
                        backgroundColor: colors.background,
                      }}
                    >
                      <Icon source={iconName} size={18} color={iconColor} />
                      <Text
                        variant="bodySmall"
                        style={{
                          color: iconColor,
                          fontWeight: isSelected ? "bold" : "normal",
                        }}
                      >
                        {item}
                      </Text>
                    </View>
                  </TouchableOpacity>
                );
              })}

              <TouchableOpacity
                onPress={clearFilter}
                style={{
                  ...styles.tableSubStatusText,
                  borderWidth: 1,
                  borderColor: colors.border,
                }}
              >
                <Icon source="close" size={18} color={colors.text} />
                <Text
                  variant="bodySmall"
                  style={{ color: colors.text }}
                  selectable={false}
                >
                  Clear Filter
                </Text>
              </TouchableOpacity>
            </View>

            {/* search bar, etc */}
            <View style={{ marginTop: 10 }}>
              <Input
                label="Search by invoice Id, customer name"
                onChangeText={(text) => {
                  if (text.length > 0) {
                    const replaceText = text.replace(/\s/g, "");

                    const filterData = businessesData.filter((item) => {
                      let found = false;
                      Object.values(item).forEach((value) => {
                        if (
                          typeof value === "string" &&
                          value.toLowerCase().includes(replaceText.toLowerCase())
                        ) {
                          found = true;
                        } else if (
                          !isNaN(Number(replaceText)) &&
                          Number(value) === Number(replaceText)
                        ) {
                          found = true;
                        }
                      });
                      return found;
                    });

                    setModifiedData(filterData);
                  } else {
                    setModifiedData(businessesData);
                  }
                }}
              />
            </View>

            {!isLoading ?
              <OnBoardingTable
                setIsLoading={setIsLoading}
                setShowSidePanel={setShowSidePanel}
                setSelectedData={setSelectedData as any}
                businessessData={businessesData}
                modifiedData={modifiedData}
                setModifiedData={setModifiedData}
              /> :
              <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator color={colors.primary} size={'large'} />
              </View>
            }
          </View>
        </View>

      </Drawer>
    </View>
  );
};


export default OnBoarding;
