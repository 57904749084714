import { useTheme } from '@react-navigation/native'
import React, { useState } from 'react'
import { TextStyle, ViewStyle } from 'react-native'
import DropDownPicker, { DropDownDirectionType, RenderListItemPropsInterface, ListModeType } from 'react-native-dropdown-picker'

import { CustomColors } from '../../api/customColors'
import { cardStyles } from '../theme/applicationTheme'

type dropdownProps = {
    data: {
        label: string;
        value: string;
    }[];
    mode?: ListModeType;
    direction?: DropDownDirectionType;
    placeholder: string;
    style?: ViewStyle;
    arrowStyle?: ViewStyle;
    placeholderStyle?: ViewStyle | TextStyle;
    maxHeight?: number;
    disabled?: boolean;
    zIndex?: number;
    value: any;
    setValue: any;
    searchable?: boolean;
    renderListItem?: (props: RenderListItemPropsInterface<any>) => JSX.Element;
}

const CustomDropdown = ({
    data,
    mode,
    style,
    value,
    zIndex,
    setValue,
    disabled,
    maxHeight,
    direction,
    arrowStyle,
    placeholder,
    renderListItem,
    searchable = false,
    placeholderStyle
}: dropdownProps) => {

    const { colors }: { colors: CustomColors } = useTheme();

    const [open, setOpen] = useState(false);

    return (
        <DropDownPicker
            open={open}
            value={value}
            closeAfterSelecting
            renderListItem={renderListItem}
            items={data}
            searchable={searchable}
            searchPlaceholder="search..."
            searchTextInputStyle={{ color: colors.text }}
            searchPlaceholderTextColor={colors.subText}
            disabled={disabled}
            zIndex={zIndex ?? 99}
            maxHeight={maxHeight}
            zIndexInverse={zIndex ?? 99}
            dropDownDirection={direction ?? 'AUTO'}
            listMode={mode ?? 'SCROLLVIEW'}
            dropDownContainerStyle={{
                borderWidth: 1,
                borderColor: colors.border,
                backgroundColor: colors.background,
                ...cardStyles.elevated
            }}
            placeholder={placeholder}
            placeholderStyle={{ color: colors.subText, ...placeholderStyle }}
            textStyle={{ color: colors.text }}
            tickIconStyle={{ backgroundColor: colors.background, borderRadius: 10 }}
            arrowIconStyle={{ backgroundColor: colors.background, borderRadius: 100, ...arrowStyle }}
            labelStyle={{ color: colors.text }}

            style={{
                borderWidth: 1,
                borderRadius: 10,
                borderColor: colors.border,
                backgroundColor: colors.background,
                ...style,
            }}
            setOpen={setOpen}
            setValue={setValue}
        />
    )
}

export default CustomDropdown