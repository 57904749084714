import { useTheme } from "@react-navigation/native";
import React from "react";
import { StyleSheet, View, ViewStyle } from "react-native";
import { HelperText, TextInput } from "react-native-paper";

import { CustomColors } from "../../../api/customColors";
// import { TextInput } from 'react-native-paper';

export type InputProps = {
  // name?: string;
  // control?: any;
  // rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  // isDropdown?: boolean;
  // DropdownData?: {
  //     label: string;
  //     value: string;
  // }[];
  // DropdownArrowStyle?: ViewStyle;
  // DropdownSearchable?: boolean;
  // DropdownOnChange?: (t: any) => void;
  // DropdownMaxHeight?: number;
  // DropdownDirection?: DropDownDirectionType;
  // isCheckbox?: boolean;
  // CheckboxLabel?: string;
  // keyboradType?: KeyboardTypeOptions;
  // inputMode?: InputModeOptions;
  // isNumericValue?: boolean;

  label?: string;
  placeHolder?: string;
  hideLabel?: boolean;
  disabled?: boolean;
  numberOfLines?: number;
  multiline?: boolean;
  onBlur?: any;
  onChangeText?: (text: string) => void;
  secureTextEntry?: boolean;
  value?: any;
  style?: ViewStyle;
  searchIcon?: boolean;
  left?: any;
  error?: boolean;
  errorText?: string;
  mask?: string;
};

const Input = ({
  // name,
  // control,
  // rules,
  // isDropdown = false,
  // DropdownData,
  // DropdownArrowStyle,
  // DropdownSearchable,
  // DropdownOnChange,
  // DropdownMaxHeight,
  // DropdownDirection,
  // isCheckbox,
  // CheckboxLabel,
  // keyboradType,
  // onChangeText,
  // inputMode,
  // isNumericValue,

  label,
  placeHolder,
  numberOfLines,
  disabled,
  hideLabel,
  multiline = false,
  value,
  secureTextEntry = false,
  style,
  searchIcon = false,
  left,
  error,
  errorText,
  onChangeText,
}: InputProps) => {
  const { colors }: { colors: CustomColors } = useTheme();

  const styles = StyleSheet.create({
    textInput: {
      width: "auto",
      marginTop: 5,
      backgroundColor: colors.card,
    },
  });

  // if (control) {
  //     return (
  //         <Controller
  //             control={control}
  //             name={name as string}
  //             rules={rules}
  //             render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
  //                 return (
  //                     <View>
  //                         {!isDropdown && !isCheckbox ?
  //                             <TextInput
  //                                 style={[styles.textInput, style]}
  //                                 underlineColor='transparent'
  //                                 activeOutlineColor='transparent'
  //                                 outlineColor='transparent'
  //                                 activeUnderlineColor='transparent'
  //                                 contentStyle={{ borderRadius: 10, borderWidth: error ? 2 : 1, borderColor: error ? 'red' : colors.border }}
  //                                 value={value}
  //                                 keyboardType={keyboradType}
  //                                 onBlur={onBlur}
  //                                 left={left}
  //                                 secureTextEntry={secureTextEntry}
  //                                 numberOfLines={numberOfLines}
  //                                 multiline={multiline}
  //                                 placeholder={placeHolder ? placeHolder : label}
  //                                 placeholderTextColor="#99999990"
  //                                 inputMode={inputMode}
  //                                 onChangeText={text => {
  //                                     if (!isNumericValue) onChange(text);
  //                                     else {
  //                                         let t = text.replace(/[^0-9]/g, '')
  //                                         // t.replace(/[^0-9]/g, '')
  //                                         // parseInt(t)
  //                                         if (value === 'NaN') {
  //                                             // console.log('first')
  //                                             onChange(12)
  //                                         }
  //                                         onChange(t);
  //                                     }

  //                                     if (onChangeText) {
  //                                         onChangeText(text);
  //                                     }
  //                                 }}
  //                                 right={searchIcon && <TextInput.Icon icon="magnify" color={colors.text} />}
  //                             /> :
  //                             isDropdown ?
  //                                 <CustomDropdown
  //                                     value={value}
  //                                     setValue={(t: any) => {
  //                                         onChange(t())
  //                                         if (DropdownOnChange) {
  //                                             DropdownOnChange(t());
  //                                         }
  //                                     }}
  //                                     data={DropdownData}
  //                                     placeholder={placeHolder as string}
  //                                     style={{ borderWidth: error ? 2 : 1, borderColor: error ? 'red' : colors.border, ...style }}
  //                                     arrowStyle={DropdownArrowStyle}
  //                                     searchable={DropdownSearchable}
  //                                     maxHeight={DropdownMaxHeight}
  //                                     direction={DropdownDirection}
  //                                 /> :
  //                                 isCheckbox ? (
  //                                     <View style={{
  //                                         gap: 10,
  //                                         alignItems: "center",
  //                                         flexDirection: "row",
  //                                     }}>
  //                                         <Checkbox
  //                                             status={value ? "checked" : "unchecked"}
  //                                             uncheckedColor={colors.border}
  //                                             color={colors.primary}
  //                                             onPress={() => {
  //                                                 // if (CheckboxSetStatus) {
  //                                                 // CheckboxSetStatus(!CheckboxStatus)

  //                                                 onChange(!value)
  //                                                 // }
  //                                             }}
  //                                         />
  //                                         <Text style={{ color: colors.subText }}>
  //                                             {CheckboxLabel}
  //                                         </Text>
  //                                     </View>
  //                                 ) : null}
  //                         {error && <Text style={{ color: 'red', marginVertical: 5, textAlign: 'right' }}>{error.message}</Text>}
  //                     </View>
  //                 )
  //             }}
  //         />
  //     )
  // } else {
  return (
    <View>
      <TextInput
        disabled={disabled}
        label={hideLabel ? label : undefined}
        style={[styles.textInput, style]}
        outlineStyle={{ borderRadius: 10 }}
        value={value}
        textColor={colors.text}
        // onBlur={onBlur}

        secureTextEntry={secureTextEntry}
        numberOfLines={numberOfLines}
        multiline={multiline}
        placeholder={placeHolder ? placeHolder : label}
        placeholderTextColor="#99999990"
        onChangeText={onChangeText}
        mode="outlined"
        left={left}
        activeOutlineColor={colors.primary}
        outlineColor={colors.text}
        right={
          searchIcon && <TextInput.Icon icon="magnify" color={colors.text} />
        }
        theme={{
          colors: {
            onSurfaceDisabled: "#ddd",
          },
        }}
        error={error}
      />
      <HelperText type="error" visible={error}>
        {errorText}
      </HelperText>
    </View>
  );

  // }
};

export default Input;
