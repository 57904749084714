import { View, Text, ScrollView, TouchableOpacity, StyleSheet } from 'react-native'
import React from 'react'
import { Icon } from 'react-native-paper'
import { shadowSet } from '../../util/shadow'
import { useTheme } from '@react-navigation/native'
import { CustomColors } from '../../../api/customColors'

const PaymentShippingDropDownList = () => {
    
    const { colors }: { colors: CustomColors } = useTheme();
    
    const styles = StyleSheet.create({
        fontBold: {
            fontWeight: 'bold'
        },
        dropdownBox: {
            padding: 5,
            width: '100%',
            marginTop: 10,
            borderRadius: 10,
            alignItems: 'center',
            flexDirection: 'row',
            paddingHorizontal: 10,
            justifyContent: 'center',
            backgroundColor: colors.background,
        }
    })

    return (
        <ScrollView style={{ flex: 1 }}>
            <View style={{ flex: 1, marginTop: 10 }}>
                <Text style={{color:colors.text}}>Name</Text>
                <TouchableOpacity style={styles.dropdownBox}>
                    <Text style={{ flex: 1,color:colors.text }}>Some Text</Text>
                    <Icon source={'menu-down'} size={24} color="#008489" />
                </TouchableOpacity>
            </View>
            <View style={{ flex: 1, marginTop: 10 }}>
                <Text style={{color:colors.text}}>Email</Text>
                <TouchableOpacity style={styles.dropdownBox}>
                    <Text style={{ flex: 1,color:colors.text }}>Some Text</Text>
                    <Icon source={'menu-down'} size={24} color="#008489" />
                </TouchableOpacity>
            </View>
            <View style={{ flex: 1, marginTop: 10 }}>
                <Text style={{color:colors.text}}>Payment Method</Text>
                <TouchableOpacity style={styles.dropdownBox}>
                    <Text style={{ flex: 1,color:colors.text }}>Some Text</Text>
                    <Icon source={'menu-down'} size={24} color="#008489" />
                </TouchableOpacity>
            </View>
            <View style={{ flex: 1, marginTop: 10 }}>
                <Text style={{color:colors.text}}>Card Number</Text>
                <TouchableOpacity style={styles.dropdownBox}>
                    <Text style={{ flex: 1,color:colors.text }}>Some Text</Text>
                    <Icon source={'menu-down'} size={24} color="#008489" />
                </TouchableOpacity>
            </View>
            <View style={{ flex: 1, marginTop: 10 }}>
                <Text style={{color:colors.text}}>CVV</Text>
                <TouchableOpacity style={styles.dropdownBox}>
                    <Text style={{ flex: 1,color:colors.text }}>Some Text</Text>
                    <Icon source={'menu-down'} size={24} color="#008489" />
                </TouchableOpacity>
            </View>
            <View style={{ flex: 1, marginTop: 10 }}>
                <Text style={{color:colors.text}}>Expiry Date.</Text>
                <TouchableOpacity style={styles.dropdownBox}>
                    <Text style={{ flex: 1,color:colors.text }}>Some Text</Text>
                    <Icon source={'menu-down'} size={24} color="#008489" />
                </TouchableOpacity>
            </View>
            <View style={{ flex: 1, marginTop: 10 }}>
                <Text style={{color:colors.text}}>Phone Number</Text>
                <TouchableOpacity style={styles.dropdownBox}>
                    <Text style={{ flex: 1,color:colors.text }}>Some Text</Text>
                    <Icon source={'menu-down'} size={24} color="#008489" />
                </TouchableOpacity>
            </View>
            <View style={{ flex: 1, marginTop: 10 }}>
                <Text style={{color:colors.text}}>Email Tracking</Text>
                <TouchableOpacity style={styles.dropdownBox}>
                    <Text style={{ flex: 1,color:colors.text }}>Some Text</Text>
                    <Icon source={'menu-down'} size={24} color="#008489" />
                </TouchableOpacity>
            </View>
        </ScrollView>
    )
}

export default PaymentShippingDropDownList
